import { PrinterDriverScreenShot, AppUpload } from '../../../assets/img/guide';

const uploadRecordCopy = {
  page: 'upload_record',
  pageTitle: 'Uploading a Document',
  pageSubtitle: `You can upload records three ways:
  
  Once your record is uploaded, you can make edits, annotate, and send the document to any fax number or email address.`,
  sections: [
    {
      title: '1. Using the Printer Driver',
      id: 'usingDriver',
      subtitle: `Any screen you can print you can upload to Medsender (Windows only). 
      Click print when you are ready to upload a document, and simply select the Medsender 
      File printer as the destination.`,
      img: PrinterDriverScreenShot,
    },
    {
      subtitle: `If you do not see the Medsender printer as an available option, check to 
      make sure you have installed the Medsender Printer Driver.`,
      divider: true,
    },
    {
      title: '2. EMR Integration',
      id: 'emr',
      subtitle: `If Medsender is integrated with your EMR, you may be able to upload directly 
      from the EMR system. Refer to the Chrome Extension portion of our guide.`,
      divider: true,
    },
    {
      title: '3. Manual Upload',
      id: 'manual',
      subtitle: `Users can manually upload any file on their device by clicking the Upload 
      button located on the home page or Uploaded Documents inbox.`,
      img: AppUpload,
    },
    {
      subtitle: `A dialog box will appear. Click Choose File to upload the PDF from your computer. 
      The upload will require a name but date of birth is optional. Select the appropriate privacy level (determines viewing permissions for the individual document) and 
      click the Upload button to complete the upload.`,
    },
  ],
};

export default uploadRecordCopy;
