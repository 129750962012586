import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalWrapper from './ModalWrapper';

class ResendRecord extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      params: this.props.modalType.data,
    };
  }

  /*
   * Resends records to the recipients. If the recipient is a fax number, it will
   * send a new access code page. If the recipient is a Medsender User, they will
   * simply receive a new email notification of record received.
   */
  resend = async () => {
    const { recipient, days } = this.state.params;
    const { isBulkResend, resendRecord, bulkResend } = this.props;
    if (isBulkResend) {
      await bulkResend(days);
    } else {
      await resendRecord(recipient);
    }
    if (!this.props.error) {
      this.context.router.history.push('/app/sent');
    }
    this.props.hideModal();
  }

  modalMessage = () => {
    const { isLoading } = this.props;
    const { recipient } = this.state.params;
    if (isLoading) {
      return (<span> Resending, Please wait... </span>);
    }
    if (!this.props.isBulkResend) {
      return (
        <span style={{ fontWeight: '600' }}>
          Are you sure you want to resend this record?
          <br /><br />
          <span style={{ fontWeight: '300' }}>
            {
              `The selected record will be resent to  ${recipient.receiver_name
                || recipient.receiver_email
                || `fax number ${recipient.receiver_fax}`}.`
            }
          </span>
        </span>
      );
    } return (
      <span style={{ fontWeight: '600' }}>
        Are you sure you want to do a bulk resend?
        <br /><br />
        <span style={{ fontWeight: '300' }}>
          {`All records sent before today and have not been viewed in the past ${this.state.params.days} days will be resent.`}
        </span>
      </span>
    );
  }
  render() {
    const { isLoading } = this.props;
    const customContentStyle = {
      width: '25%',
    };
    return (
      <ModalWrapper
        hideModal={this.props.hideModal}
        action={this.resend}
        customContentStyle={customContentStyle}
        actionName={this.props.isBulkResend ? 'Resend Records' : 'Resend Record'}
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading}
        modalTitle="Resend Record"
      >
        {this.modalMessage()}
      </ModalWrapper>
    );
  }
}

ResendRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

ResendRecord.propTypes = {
  bulkResend: PropTypes.func.isRequired,
  isBulkResend: PropTypes.bool.isRequired,
  resendRecord: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default connect(state => ({
  error: state.records.error,
  isLoading: state.records.isLoading,
}))(ResendRecord);
