import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { TextField, Dialog, FlatButton } from 'material-ui';

import { validSendEmail as validEmail } from '../../../global/validation';
import { MODAL_ENUMS } from '../../../reducers/directMessaging';

class EnterDirectAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      directAddress: '',
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitDirectAddress = async () => {
    const { directAddress } = this.state;
    const { verifyDirectAddress, changeModalState, checkForDirectAddress } = this.props;
    const invalidEmail = !validEmail(directAddress);
    this.setState({ invalidEmail })
    if (invalidEmail) return;
    await verifyDirectAddress(directAddress);
    if (this.props.errorCode) return;
    checkForDirectAddress();
  }

  clearSelectedAddress = () => {
    const { hideModal, changeModalState } = this.props;
    changeModalState(MODAL_ENUMS.LOADING);
    hideModal();
  }

  errorText = (errorCode, message) => {
    let errorMsg = message;
    if (!errorCode) errorMsg = 'Please enter a valid DIRECT address';
    return (
      <p style={{ color: 'red', textAlign: 'center' }}>{ errorMsg }</p>
    );
  }

  clearSelectedAddress = () => {
    const { hideModal, changeModalState } = this.props;
    changeModalState(MODAL_ENUMS.LOADING);
    hideModal();
  }

  render() {
    const modalDescription = 'Enter a DIRECT Address you would like to forward to';
    const { errorCode } = this.props;
    const { invalidEmail } = this.state;
    let errorCodeMessage = '';
    const serverError = (errorCode && !invalidEmail);
    switch (errorCode) {
      case 500:
        errorCodeMessage = 'Something is wrong on our end. Please try again.';
        break;
      case 400:
        errorCodeMessage = 'That address is already associated with this account';
      default:
        errorCodeMessage = 'Oops! An error has occurred. Please try again.';
    }

    return (
      <Dialog
        open={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="CLOSE"
            primary={true}
            keyboardFocused={true}
            onClick={this.clearSelectedAddress}
          />,
          <FlatButton
            label="SUBMIT"
            primary={true}
            onClick={this.submitDirectAddress}
          />,
        ]}
      >
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ color: 'black', fontWeight: '700', marginBottom: '15px' }}>Forward to EMR</span>
            <p style={{ marginBottom: '15px', lineHeight: '30px' }}>{modalDescription}</p>
          </div>
          { (serverError || invalidEmail) && this.errorText(errorCode, errorCodeMessage) }
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              // eslint-disable-next-line object-curly-newline
              style={{ height: '50px', width: '50%', paddingLeft: '10px', marginTop: '10px', fontSize: '15px' }}
              placeholder="DIRECT address"
              name="directAddress"
              onChange={this.handleInputChange}
            />
          </div>
        </>
      </Dialog>
    );
  }
}

EnterDirectAddress.defaultProps = {
  errorCode: null,
};

EnterDirectAddress.propTypes = {
  changeModalState: PropTypes.func.isRequired,
  verifyDirectAddress: PropTypes.func.isRequired,
  checkForDirectAddress: PropTypes.func.isRequired,
  errorCode: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
};

export default EnterDirectAddress;
