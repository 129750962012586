/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import IconButton from 'material-ui/IconButton';
import Link from 'react-router-dom/Link';
import { getIcon } from './constants';
import './inbox.scss';

class InboxTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      enableSelectAll: props.enableSelectAll || false,
      keyParam: 'file_token',
    };
  }

  componentWillMount() {
    const { mode } = this.props;
    if (mode === 'Uploaded') {
      this.setState({ keyParam: 'file_token' });
    } else if (mode === 'Received' || mode === 'Sent') {
      this.setState({ keyParam: 'send_token' });
    } else if (mode === 'Assigned') {
      this.setState({ keyParam: 'slug' });
    }
  }

  /**
   * Handle row selection/deselection in the table
   * This will enable/disable the buttons in the header
   * @param  {Array} selectedRowKeys array of this.state.key_param strings indicating
   * which rows selected
   * @param {Array} selectedRowData array of dataSource row objects selected
   * @return {Void}
   */
  handleRowClick = (selectedRowKeys, selectedRowData) => {
    let updatedSelectedRowKeys = selectedRowKeys;
    let updatedSelectedRowData = selectedRowData;
    // The sent records inbox should only allow one record to be selected at a time
    //
    // This is because currently, selecting a record in the sent inbox is used to
    // resend exactly 1 record. When the backend can support multiple, we should be able
    // to safely remove this code.
    //
    // We only worry about this if 1 record is already selected. We will then change the
    // selected record to the newer one
    const { mode, rowSelected, userIsOnChromeExtensionViewer } = this.props;
    const disableMultipleSelection = mode === 'Sent' || userIsOnChromeExtensionViewer;
    if (disableMultipleSelection && selectedRowKeys.length > 1) {
      const {
        selectedRowKeys: previousSelectedRowKeys,
        keyParam,
      } = this.state;
      // Since a row is already selected, selectedRowKeys will contain two keys. But the inbox
      // can should only have one selected at a time, so we will figure out what to set as the
      // new one by determining which key was previously set and removing it.
      updatedSelectedRowKeys = selectedRowKeys.filter(rowKey => !previousSelectedRowKeys.includes(rowKey));
      // We need to pass the new selected row's data and store in redux, so index in and get it
      const newSelectedRowKey = updatedSelectedRowKeys[0];
      updatedSelectedRowData = selectedRowData.filter(rowData => rowData[keyParam] === newSelectedRowKey);
    }
    // Store rows selected in Redux for other options, like merging
    rowSelected(updatedSelectedRowData);
    // Update local state
    this.setState({ selectedRowKeys: updatedSelectedRowKeys });
  }

  /**
   *
   * @param {Boolean} selected whether the select all checkbox was clicked
   * @param {Array} selectedRows array of dataSource rows that were requested
   * to be selected
   * @param {Array} changeRows array of dataSource rows that were not previously
   * selected (selectedRows - changeRows) = rows that were selected  before
   */
  onSelectAll = (selected, selectedRows, changeRows) => {
    if (selected) {
      // If a user has multiple rows selected (but not all) and then clicks
      // the select all checkbox, we will de-select every row
      // that has been selected.
      if (selectedRows.length !== changeRows.length) {
        return this.setState({ selectedRowKeys: [] });
      }
    }
  }

  handleTableChange = (pages, filters, sorter) => {
    const { actions, paramTools } = this.props;
    const { params, stringify, stringifyOptions } = paramTools;
    const { getRecords } = actions;
    params.page = pages.current;
    // Uncomment when ready to use this
    // see pagination.pageSize prop in <Table>
    // params.page_size = pages.pageSize;
    // NOTE: <ChromeExtensionReceived> page also sees this
    // handleTableChange bubble up and has a custom handler
    // for the getRecords() function it passes
    getRecords(stringify(params, stringifyOptions));
  }

  renderLink(row, tooltipPosition) {
    const icon = getIcon(row);
    const patientQuery = row.patient ? '?patient=true' : '';
    const iconStyle = {
      color: icon.color,
      top: '7px',
      marginTop: '-15px',
      left: '8px',
      height: '17px',
      width: '17px',
    };
    return (
      <>
        <Link to={`/app/records/status/record/${row.file_token}/receiver/${row.send_token + patientQuery}`}>
          <div className="status-badge">Status</div>
        </Link>
        <IconButton
          iconClassName={`material-icons ${row.fax_status}`}
          tooltip={icon.text}
          tooltipPosition={tooltipPosition}
          iconStyle={iconStyle}
        >
          {icon.name}
        </IconButton>
      </>
    );
  }

  render() {
    const { columnTitles, rows, totalResults, page, mode, userIsOnChromeExtensionViewer } = this.props;
    const { selectedRowKeys, keyParam, enableSelectAll } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: (this.handleRowClick),
      onSelectAll: (this.onSelectAll),
      hideSelectAll: !enableSelectAll,
    };
    return (
      <Table
        dataSource={rows}
        onChange={this.handleTableChange}
        rowSelection={rowSelection}
        handleTableChange={this.handleTableChange}
        rowClassName={record => record.viewed && 'record-row-viewed'}
        onRow={(record, rowIndex) => {
          const {
            uri,
            send_token: sendToken,
            file_token: fileToken,
            slug: assignDocumentId,
            sms_uri: smsUri,
          } = record;
          const { getReplyData, getSmsData, push } = this.props;
          return {
            // Add an event listener to each row within the table. This is so that
            // when a row is clicked, it can be selected without having to click
            // into the checkbox area. Functionality inspired by Dropbox.
            onClick: () => {
              if (uri) {
                // Open up secure reply if a row with a reply is clicked
                getReplyData(uri, sendToken);
                return;
              }
              // Don't allow selecting rows by clicking if more than 1 is
              // already selected
              if (selectedRowKeys.length > 1) return;
              const rowSelected = rows[rowIndex];
              // keyParam is necessary when sending data to handleRowClick because when mode is
              // Received, the data is indexed into using a distinct keyParam, file_token
              this.handleRowClick([rowSelected[keyParam]], [rowSelected]);
            },
            // Double clicking on the row will open up the <RecordViewer>
            // ~Inspired by Dropbox~
            onDoubleClick: () => {
              // Replies are also handled by a single click, so ignore double clicks
              if (!userIsOnChromeExtensionViewer && uri) return;
              if (smsUri) {
                getSmsData(smsUri);
                return;
              }
              const viaChromeExtension = userIsOnChromeExtensionViewer;
              const recordViewerPath = viaChromeExtension
                ? `/app/chrome/view/${fileToken}`
                : `/app/view/${fileToken}`;
              const sendTokenParam = sendToken
                ? `&sendToken=${sendToken}`
                : '';
              const assignedDocumentParam = assignDocumentId ? `&assignedDocumentId=${assignDocumentId}` : '';
              push(`${recordViewerPath}?from=${mode.toLowerCase()}${sendTokenParam}${assignedDocumentParam}`);
            },
          };
        }}
        rowKey={record => record[keyParam]}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          defaultPageSize: userIsOnChromeExtensionViewer ? 5 : 10,
          // TODO (Ammar Khan)
          // Uncomment when we are ready to support this, verify no other things will break
          // (backend already supported)
          // pageSize: parseInt(this.props.paramTools.params.page_size, 10) || 10,
          total: totalResults,
          current: page,
          showTotal: (total, range) => {
            // When rendered in the <ChromeExtensionReceived> component, some rows
            // get filtered out because replies are not displayed, despite being retrieved
            // from the backend. When this happens, there appears to be a mismatch of
            // the range of records which are displayed. They don't account for the filtered records,
            // so in an effort to be simple, we simply won't display any text when userIsOnChromeExtensionViewer is true.
            if (userIsOnChromeExtensionViewer) return '';
            return `Viewing ${range[0]} - ${range[1]} of ${total} records`
          },
        }}
        columns={columnTitles}
      />
    );
  }
}

InboxTable.defaultProps = {
  userIsOnChromeExtensionViewer: false,
};

InboxTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columnTitles: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    changeInboxPage: PropTypes.func.isRequired,
    getRecords: PropTypes.func.isRequired,
  }).isRequired,
  totalPages: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  getReplyData: PropTypes.func.isRequired,
  getSmsData: PropTypes.func.isRequired,
  rowSelected: PropTypes.func.isRequired,
  paramTools: PropTypes.object.isRequired,
  enableSelectAll: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  userIsOnChromeExtensionViewer: PropTypes.bool,
};

export default InboxTable;
