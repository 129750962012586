import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import { Header, PageLoading, RecordStatus, ErrorDisplay } from '../components';
import { getStatusOfSentRecordById, getStatusOfSentPatientRecordById } from '../actions/records';
import { openModal } from '../actions/modal';
import '../components/main.scss';

const parseOptions = {
  ignoreQueryPrefix: true,
};

class SentRecordStatus extends Component {
  constructor(props) {
    super(props);
    this.recordId = props.match.params.record_id;
  }

  componentDidMount() {
    const sendToken = this.props.match.params.id;
    const { location } = this.props;
    const params = parse(location.search, parseOptions);

    if (params.patient === 'true') {
      this.props.getStatusOfSentPatientRecordById(this.recordId, sendToken);
    } else {
      this.props.getStatusOfSentRecordById(this.recordId, sendToken);
    }
  }

  isPatient() {
    const { location } = this.props;
    const params = parse(location.search, parseOptions);

    return params.patient === 'true';
  }

  render() {
    const { data, openModal } = this.props;
    return (
      <div>
        <Helmet>
          <title>Record Status - Medsender</title>
        </Helmet>
        <div
          className={`med-container ${this.props.docked ? 'true-dock' : 'false-dock'}`}
        >
          <Header
            main="Sent record status"
            subtext="All the details are below"
            locationPath={[
              { icon: 'home', text: 'Home', link: '/app' },
              { icon: 'inbox', text: 'Sent', link: '/app/sent' },
            ]}
          />
          { this.props.loading && <PageLoading /> }
          { data && !this.props.loading &&
            <RecordStatus
              data={data}
              openModal={openModal}
              fileToken={this.recordId}
              patient={this.isPatient()}
            />
          }
          { this.props.errorMessage && !this.props.loading ?
            <p style={{ textAlign: 'center', marginTop: '60px' }}>
              <ErrorDisplay message={this.props.message} />
            </p> : null
          }
        </div>
      </div>
    );
  }
}

SentRecordStatus.defaultProps = {
};

SentRecordStatus.propTypes = {
  getStatusOfSentRecordById: PropTypes.func.isRequired,
  getStatusOfSentPatientRecordById: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
};

export default connect(state => ({
  loading: state.records.isLoading,
  data: state.records.currentRecordStatus,
  errorMessage: state.records.errorMessage,
}), {
  getStatusOfSentRecordById,
  getStatusOfSentPatientRecordById,
  openModal,
})(SentRecordStatus);

export {
  SentRecordStatus,
};
