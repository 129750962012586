import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Button } from 'antd';
import { CommentOutlined, SendOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import './ChatTab.scss';

let cableSubscription;

class ChatTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      width: '0%',
      thread: null,
      messageContent: '',
      msgs: [],
      showStartMessage: true,
      senderOrg: null,
      recipientOrg: null,
      conversationId: null,
    };
    this.messagesEndRef = React.createRef();
    this.subscriptionDebounce = null;
  }

  componentDidMount() {
    this.setupSubscription();
    const url = "/api/v1/conversations/" + this.props.sendToken;
    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(response => {
        if (response) {
          this.setState({
            visible: true,
            width: '25%',
            msgs: response.messages,
            showStartMessage: response.messages.length === 0,
            senderOrg: response.sender_org,
            recipientOrg: response.recipient_org,
            conversationId: response.conversation_id,
          });
          this.scrollToBottomFast();
        }
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.loggedIn !== this.props.loggedIn ||
      prevState.conversationId !== this.state.conversationId ||
      prevProps.user !== this.props.user
    ) {
      this.setupSubscription();
    }

    if (prevState.msgs !== this.state.msgs) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.cleanupSubscription();
  }

  setupSubscription = async () => {
    const { loggedIn, user, cable } = this.props;
    const { conversationId } = this.state;

    if (!cableSubscription && loggedIn && conversationId && Boolean(user)) {
      cableSubscription = cable.subscriptions.create(
        {
          channel: 'ChatsChannel',
          send_token: conversationId,
        },
        {
          received: (response) => {
            if (response.messages) {
              this.setState({
                thread: response,
                visible: true,
                width: '25%'
              });
            } else {
              this.addMessageToThread(response);
              this.markMessageAsRead(response.messageId);
            }
          },
        },
      );
    }
  };

  cleanupSubscription = () => {
    if (cableSubscription) {
      cableSubscription.unsubscribe();
      cableSubscription = null;
    }
  };

  addMessageToThread = (message) => {
    this.setState((prevState) => ({
      msgs: [...prevState.msgs, message],
      showStartMessage: false
    }));
  };

  markMessageAsRead = (messageId) => {
    cableSubscription.perform('mark_as_read', { message_id: messageId });
  };

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollToBottomFast = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'instant' });
    }
  };

  renderMessage = (message, index) => {
    const { user } = this.props
    const isSender = message.sender_email === user.uid;
    const isSameOrg = this.state.recipientOrg === this.state.senderOrg;
    const isSenderOrg = message.sender_org === user.organization;
    const renderName = index === 0 || message.sender_name !== this.state.msgs[index - 1].sender_name;

    const textClassName = isSender
      ? 'sender-side-user-text'
      : isSameOrg
      ? 'receiver-side-same-org-text'
      : isSenderOrg
      ? 'sender-side-other-user-text'
      : 'receiver-side-other-org-text';

    const bubbleClassName = isSender
        ? 'sender-side-user'
        : isSameOrg
        ? 'receiver-side-same-org'
        : isSenderOrg
        ? 'sender-side-other-user'
        : 'receiver-side-other-org';

    return (
      <div key={index}>
        {renderName && (
          <div className={`message-email ${textClassName}`}>{message.sender_name}</div>
        )}
        <div className={`message ${bubbleClassName}`}>
          <p>{`${message.content}`}</p>
        </div>
        <div ref={this.messagesEndRef} />
      </div>
    );
  };

  sendMessage = () => {
    const { user } = this.props;
    const { conversationId } = this.state;
    if (cableSubscription) {
      cableSubscription.send({
        user: user.name,
        content: this.state.messageContent,
        sender_id: user.uid,
        send_token: conversationId,
        sender_org: user.organization
      });
      this.setState({
        messageContent: '',
        showStartMessage: false
      });
    }
  };

  render() {
    const { visible, width, thread, messageContent, msgs, showStartMessage } = this.state;
    const { user } = this.props
    const lastMessage = thread?.messages[thread.messages.length - 1];
    const isLastMessageLocal = lastMessage?.sender_org === user.organization;

    let isSender = null;
    let otherOrg = null;

    if (this.state.senderOrg === user.organization) {
      isSender = true;
    } else {
      isSender = false;
    }

    if (isSender) {
      otherOrg = this.state.recipientOrg;
    } else {
      otherOrg = this.state.senderOrg;
    }

    let hideChat = false;

    // disable chat if orgs are null
    if (this.state.recipientOrg == null || this.state.senderOrg == null) {
      if (!this.state.visible) {
        hideChat = true;
      }
    }

    // disable chat if internal conversation (orgs are same)
    if (this.state.recipientOrg === this.state.senderOrg) {
      hideChat = true;
    }

    const showDrawer = () => {
      this.setState({
        width: '25%',
        visible: true,
      });
    };

    const onClose = () => {
      this.setState({
        width: '0%',
        visible: false,
      });
    };

    const toggleDrawer = () => {
      if (visible) {
        onClose();
      } else {
        showDrawer();
      }
    };

    if (!hideChat) {
      return (
        <div className="chat-tab">
          <Button
            type="primary"
            icon={<CommentOutlined style={{ fontSize: '32px' }}/>}
            onClick={toggleDrawer}
            className={`drawer-button ${visible && !hideChat ? 'open' : ''}`}
            disabled={hideChat}
          />
          <Drawer
            mask={false}
            title={`Conversation with ${otherOrg}`}
            placement="right"
            onClose={onClose}
            visible={visible && !hideChat}
            width={width}
            closable={false}
            bodyStyle={{ padding: '20px' }}
            className="chat-drawer"
          >
            <div className="messages-container">
              {showStartMessage ? (
                <div className="start-message">
                  <p>{`Type below to start a conversation with ${otherOrg}?`}</p>
                </div>
              ) : (
                msgs.map(this.renderMessage)
              )}
              {isLastMessageLocal && (
                <div className="last-viewed">
                  {`Last viewed by ${this.props.user.name}`}
                </div>
              )}
            </div>
            <div className="message-input">
              <TextArea
                placeholder="Write message"
                value={messageContent}
                onChange={(e) =>
                  this.setState({ messageContent: e.currentTarget.value })
                }
                autoSize
                className="textarea"
              />
              <Button type="primary" className="send-button" onClick={this.sendMessage}>
                <SendOutlined/>
              </Button>
            </div>
          </Drawer>
        </div>
      );
    } else {
      return <></>
    }
  }
}

ChatTab.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  sendToken: PropTypes.string.isRequired,
  cable: PropTypes.object.isRequired,
};

export default ChatTab;
