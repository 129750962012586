import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

class LoadingDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: props.loading,
      error: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.loading,
      error: nextProps.error,
    });
  }

  closeDialog() {
    this.setState({ open: false, error: null });
    this.props.cancel();
  }

  render() {
    const { error, open } = this.state;
    const { closeDialog } = this;
    const title = error ? 'Error' : 'Sending record...please wait';
    return (
      <Dialog
        title={title}
        actions={[
          <FlatButton
            label="OK"
            primary={true}
            onClick={closeDialog.bind(this)}
          />,
        ]}
        modal={false}
        open={error ? true : open}
        onRequestClose={closeDialog.bind(this)}
      >
        {
          error ? <p style={{ color: 'red' }}>Error: {error}</p> : null
        }
        { !error ? <p>This should only take a moment </p> : null
        }
      </Dialog>
    );
  }
}

LoadingDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default LoadingDialog;
