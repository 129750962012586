// Edit record component displays the modal content for updating a record.
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Input,
  DatePicker,
  Form,
  message,
  Tag,
  Divider,
  Alert,
  Checkbox,
  Row,
  Tooltip,
  Select,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { stringify } from 'qs';

import ModalWrapper from './ModalWrapper';
import PrivacyLevel from './PrivacyLevel';
import { formatDate, validDOB } from '../../global/validation';
import { verifyFeatureAccess } from '../../global/featureFlags';

const { Search } = Input;
const { Option } = Select;

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

/* global toastr */

// The typing timer is used in the search bar. It waits until the user's
// keystrokes are idle for 1 second before sending the request
// to Rails
let typingTimer = null;

class EditPatient extends Component {
  formRef = React.createRef();

  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.object.isRequired,
    sendToken: PropTypes.string,
    allDocumentCategories: PropTypes.object.isRequired,
    getDocumentCategories: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    rowSelected: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    editReceivedRecordLabels: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    getLabelsByQuery: PropTypes.func.isRequired,
    selectedRecord: PropTypes.array.isRequired,
    incrementReferralCount: PropTypes.func.isRequired,
    decrementReferralCount: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sendToken: '',
  }

  state = {
    patientName: this.props.modalType.data.patient.patient_name,
    patientDOB: this.props.modalType.data.patient.patient_dob,
    fileToken: this.props.modalType.data.patient.file_token,
    nameValid: '',
    patientValid: '',
    privacyLevel: this.props.modalType.data.patient.privacy_level,
    labels: [],
    selectedLabels: [],
    searchLoading: false,
    sendTokens: this.props.modalType.data.patient.send_token,
    autoLabelAccess: true,
    isPatientInfoIncorrect: false,
    incorrectDOB: false,
    incorrectName: false,
    initialLabels: [],
    documentTitle: this.props.modalType.data.patient.document_title,
    documentCategories: [],
    documentCategory: this.props.modalType.data.patient.document_category,
    referralNote: this.props.modalType.data.patient.referral_note,
    referringProvider: this.props.modalType.data.patient.referring_provider,
    referringProviderOffice: this.props.modalType.data.patient.provider_office,
  };

  componentDidMount = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const page = 1;
    const pageSize = 20;
    const { getLabelsByPage, getDocumentCategories } = this.props;
    await getLabelsByPage(page, pageSize);
    await getDocumentCategories();
    const { data, selectedRecord, modalType } = this.props;
    const autoLabel = await verifyFeatureAccess('ai_extraction');
    this.setState(() => {
      const labelsState = modalType.data.selectedLabelsRecordViewer ? modalType.data.selectedLabelsRecordViewer : selectedRecord[0]?.labels || [];
      return {
        labels: data,
        selectedLabels: [...labelsState],
        initialLabels: [...labelsState],
        privacyLevel: modalType.data.patient.privacy_level,
        autoLabelAccess: autoLabel,
        documentCategories: this.props.allDocumentCategories.categories,
      };
    });
    this.determineVisibility();
    this.validateFormFields();
  }

  componentWillReceiveProps(nextProps) {
    const {
      patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory,
    } = this.state;

    // Destructure nextProps
    const {
      patient_name: newPatientName,
      patient_dob: newPatientDOB,
      fileToken,
      privacyLevel: newPrivacyLevel,
      document_title: newDocumentTitle,
      document_category: newDocumentCategory,
    } = nextProps.modalType.data.patient;

    if (this.checkPatientInfo(patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory)) {
      this.setState({
        patientName: newPatientName,
        patientDOB: newPatientDOB,
        privacyLevel: newPrivacyLevel,
        documentTitle: newDocumentTitle,
        documentCategory: newDocumentCategory,
      });
    }
  }

  validateFormFields = () => {
    const { validateFields } = this.formRef.current;
    validateFields(['name', 'documentTitle']);
  };

  determineVisibility = async () => {
    const display = await verifyFeatureAccess('department_sharing');
    this.setState({ display });
  }

  deselectLabel = (index) => {
    const { selectedLabels } = this.state;
    const currentlySelected = selectedLabels;
    currentlySelected.splice(index, 1);
    this.setState({
      selectedLabels: [...currentlySelected],
    });
  }

  checkLabelsChanged = () => {
    const { selectedLabels, initialLabels } = this.state;
    if (selectedLabels.length !== initialLabels.length) {
      return true;
    }
    for (let i = 0; i < selectedLabels.length; i++) {
      if (selectedLabels[i].id !== initialLabels[i].id) {
        return true;
      }
    }
    return false;
  }

  update = async (event) => {
    event.preventDefault();
    const {
      fileToken,
      patientDOB,
      patientName,
      privacyLevel,
      selectedLabels,
      sendTokens,
      incorrectName,
      incorrectDOB,
      documentTitle,
      documentCategory,
      referralNote,
      referringProvider,
      referringProviderOffice,
    } = this.state;
    const {
      editReceivedRecordLabels, mode, edit, hideModal, modalType, incrementReferralCount, decrementReferralCount,
    } = this.props;
    const { fromPage } = modalType.data;
    const isPatientInfoIncorrect = (incorrectDOB || incorrectName);
    const selectedLabelIds = [];
    selectedLabels.map(label => selectedLabelIds.push(label.id));
    if (this.checkPatientInfo(patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory)) {
      const dob = formatDate(patientDOB);
      // only make request to update labels when looking at a received record
      if (window.location.pathname.includes('received') || fromPage === 'received') {
        // If sending update from received inbox, check if we're updating the document category.
        const oldCategory = this.props.modalType.data.patient.document_category;

        // Check if we're changing the document category.
        if (oldCategory !== documentCategory) {
          if (documentCategory?.toLowerCase() === 'referral') {
            // If the new document category is referral, increment the new referral count.
            await incrementReferralCount();
          } else if (oldCategory?.toLowerCase() === 'referral') {
            // If the old document category was referral, decrement the new referral count.
            await decrementReferralCount();
          }
        }

        if (this.checkLabelsChanged()) {
          await editReceivedRecordLabels(sendTokens || modalType.data.record, selectedLabelIds, selectedLabels, modalType.data.receiverType);
        }
        const { updatedLabel } = this.props;
        if (updatedLabel) {
          message.success({
            content: 'Labels changed',
            style: { marginTop: '70px' },
          });
        }
      }
      if (this.checkPatientInfo(patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory)) {
        await edit(fileToken, dob, patientName, mode, privacyLevel, fromPage, incorrectName, incorrectDOB, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice);
      }
      hideModal();
      this.updateRowSelected();

      // Open the send dialog after updating the patient data if 'showSendDialog'
      // was passed in as props. This will only happen if the user is attempting
      // to send a record without the patient name &/or dob
      if ('showSendDialog' in this.props.modalType.data) {
        this.props.modalType.data.showSendDialog(true);
      }
      return;
    }
    if (!patientName && !isPatientInfoIncorrect) return;
    if (!patientDOB && !isPatientInfoIncorrect) {
      await edit(fileToken, null, patientName, mode, privacyLevel, fromPage, incorrectName, incorrectDOB, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice);
      hideModal();
      this.updateRowSelected();

      // Open the send dialog after updating the patient data if 'showSendDialog'
      // was passed in as props. This will only happen if the user is attempting
      // to send a record without the patient name &/or dob
      if ('showSendDialog' in modalType.data) {
        modalType.data.showSendDialog(true);
      }
      return;
    }
    if (isPatientInfoIncorrect) {
      await edit(fileToken, patientDOB, patientName, mode, privacyLevel, fromPage, incorrectDOB, incorrectName, documentTitle, documentCategory, referralNote, referringProvider, referringProviderOffice);
      return hideModal();
    }
    const validDate = validDOB(patientDOB);
    if (!validDate && !isPatientInfoIncorrect) {
      this.setState({ patientValid: 'Invalid DOB' });
      return;
    }
    toastr.warning('No new changes. Click cancel to close.');
  }

  renderSelectLabel = () => {
    const { isLoading, hideModal } = this.props;
    const { labels, selectedLabels, searchLoading } = this.state;
    const { onSearch } = this;
    if (isLoading) {
      return (<span> Updating labels, Please wait... </span>);
    }
    const maxAdditionalLabels = 5 - selectedLabels.length;
    const allowedAdditionalLabels = maxAdditionalLabels > 0;
    const noLabelsSelected = selectedLabels.length === 0;
    return (
      <React.Fragment>
        <Divider />
        <p style={{ display: 'flex', alignItems: 'flex-end', fontSize: 14, color: 'rgba(0, 0, 0, .85' }}>
          Current Labels
          <p style={{
            color: allowedAdditionalLabels ? 'grey' : 'red',
            fontSize: '12px',
            marginBottom: '3px',
            marginLeft: '10px',
          }}
          >
            {allowedAdditionalLabels
              ? `(You can add up to ${maxAdditionalLabels} more label(s))`
              : 'Remove some current labels to add additional labels.'
            }
          </p>
        </p>
        {noLabelsSelected
          ? <div>No labels selected</div>
          : (
            selectedLabels.map((label, index) => (
              <Tag
                color={label.color}
                closable={true}
                key={`${label.name}${index}`} 
                onClose={() => this.deselectLabel(index)}
                style={{
                  color: label.is_dark_text ? 'black' : 'white',
                  marginBottom: '5px',
                }}
              >
                {label.name}
              </Tag>
            ))
          )
        }
        <p style={{ marginTop: '40px', fontSize: 14, color: 'rgba(0, 0, 0, .85' }}>Additional Labels</p>
        <p style={{ color: 'grey', fontSize: 14 }}>
          Navigate to
          <Link to="/app/user?tab=manage_labels" onClick={hideModal}> Manage Labels </Link>
          in settings to add, edit, and delete labels.
        </p>
        <Search
          placeholder="search labels"
          allowClear
          onSearch={onSearch}
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          style={{ width: 200 }}
          disabled={!allowedAdditionalLabels}
          loading={searchLoading}
          onKeyUp={(e) => {
            this.setState({ searchLoading: true });
            // Maintain a 1 second timer before sending request
            // If any new key is pressed during the course of that
            // timer, reset the timer
            clearTimeout(typingTimer);
            const query = e.target.value;
            typingTimer = setTimeout(() => {
              this.onSearch(query);
            }, 1000);
          }}
        />

        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>

          {labels.map((label) => {
            const labelIsSelected = selectedLabels.find(selectedLabel => selectedLabel.name === label.name);
            const recordAlreadyHasLabel = selectedLabels.find(selectedRecordLabel => selectedRecordLabel.name === label.name);
            const labelTextColor = label.is_dark_text ? 'black' : 'white';
            return (
              <Tag
                onClick={() => !recordAlreadyHasLabel && allowedAdditionalLabels && this.selectLabel(label)}
                color={recordAlreadyHasLabel ? 'white' : label.color}
                style={{
                  color: recordAlreadyHasLabel ? label.color : labelTextColor,
                  marginBottom: '15px',
                  cursor: allowedAdditionalLabels ? 'pointer' : 'not-allowed',
                  border: recordAlreadyHasLabel ? `1px dotted ${label.color}` : (labelIsSelected && '2px solid black'),
                }}
              >
                {label.name}
              </Tag>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  renderPatientInfoIncorrectCheckBox = (isPatientInfoIncorrect) => {
    return (
      <>
        <Checkbox
          checked={isPatientInfoIncorrect}
          onChange={() => this.setState(prevState => ({
            isPatientInfoIncorrect: !prevState.isPatientInfoIncorrect,
            incorrectName: !prevState.isPatientInfoIncorrect ? prevState.incorrectName : false,
            incorrectDOB: !prevState.isPatientInfoIncorrect ? prevState.incorrectDOB : false,
          }))}
          disabled={false}
          style={{ marginBottom: '10px', display: 'inline' }}
        >
          Is patient Information wrongly extracted?
        </Checkbox>
        <Tooltip
          overlayStyle={{ marginLeft: '20px' }}
          title="Reporting errors with our AI Extraction will help us train our model and become more accurate in the future"
        >
          <QuestionCircleOutlined />
        </Tooltip>
        <br />
        <br />
      </>
    );
  }

  renderPatientInfoIncorrectCheckBoxes = () => {
    const { incorrectDOB, incorrectName } = this.state;
    return (
      <Form.Item
        label="Which information was wrongly extracted?"
        style={{ marginTop: '10px' }}
      >
        <Checkbox.Group>
          <Row>
            <Checkbox
              checked={incorrectName}
              value="incorrect_name"
              style={{ lineHeight: '32px' }}
              disabled={false}
              onChange={() => this.setState(prevState => ({
                incorrectName: !prevState.incorrectName,
              }))}
            >
              Patient Name
            </Checkbox>
          </Row>
          <Row>
            <Checkbox
              checked={incorrectDOB}
              value="incorrect_dob"
              style={{ lineHeight: '32px' }}
              disabled={false}
              onChange={() => this.setState(prevState => ({
                incorrectDOB: !prevState.incorrectDOB,
              }))}
            >
              Patient Date of Birth
            </Checkbox>
          </Row>
        </Checkbox.Group>
      </Form.Item>
    );
  }

  handleNameChange = (event) => {
    this.setState({
      patientName: event.target.value,
    });
    this.validateFormFields();
  }

  handleNoteChange = (event) => {
    this.setState({
      referralNote: event.target.value,
    });
    this.validateFormFields();
  }

  handleReferringProviderChange = (event) => {
    this.setState({
      referringProvider: event.target.value,
    });
    this.validateFormFields();
  }

  handleReferringProviderOfficeChange = (event) => {
    this.setState({
      referringProviderOffice: event.target.value,
    });
    this.validateFormFields();
  }

  handleDocumentTitleChange = (event) => {
    this.setState({
      documentTitle: event.target.value,
    });
    this.validateFormFields();
  }

  /**
   * @param {String} dateString format MM/DD/YYYY
   */
  handleDateChange = (_, dateString) => {
    this.setState({ patientDOB: dateString });
  }

  handleDropdownChange = (value) => {
    return this.setState({
      incorrectName: value.includes('name'),
      incorrectDOB: value.includes('dob'),
    });
  }

  // select label functions
  selectLabel = (label) => {
    const { selectedLabels } = this.state;
    const currentlySelected = [...selectedLabels];
    const indexOfSelectedLabel = currentlySelected.findIndex(selectedLabel => selectedLabel.name === label.name);
    const labelAlreadySelected = currentlySelected.find(selectedLabel => selectedLabel.name === label.name);
    if (!labelAlreadySelected) {
      this.setState({
        selectedLabels: [...currentlySelected, label],
      });
    } else {
      currentlySelected.splice(indexOfSelectedLabel, 1);
      this.setState({
        selectedLabels: currentlySelected,
      });
    }
  }

  onSearch = async (value) => {
    const { getLabelsByQuery } = this.props;
    const params = {
      page: 1,
      page_size: 20,
      contains: value,
    };
    const query = stringify(params, stringifyOptions);
    await getLabelsByQuery(query);
    const { data } = this.props;
    this.setState({
      labels: data,
      searchLoading: false,
    });
  }

  searchKeyUp = (event) => {
    this.setState({ searchLoading: true });
    // Maintain a 1 second timer before sending request
    // If any new key is pressed during the course of that
    // timer, reset the timer
    clearTimeout(typingTimer);
    const query = event.target.value;
    typingTimer = setTimeout(() => {
      this.searchQuery(query);
    }, 1000);
  }

  updateRowSelected() {
    // eslint-disable-next-line object-curly-newline
    const { fileToken, patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory } = this.state;
    const selectedRow = {
      patient_name: patientName,
      patient_dob: patientDOB,
      file_token: fileToken,
      index: this.props.modalType.data.patient.index,
      privacy_level: privacyLevel,
      labels: selectedLabels,
      document_title: documentTitle,
      document_category: documentCategory,
    };
    this.props.rowSelected([selectedRow]);
  }

  checkPatientInfo(patientDOB, patientName, privacyLevel, selectedLabels, documentTitle, documentCategory) {
    const {
      patient:
        {
          patient_dob: oldPatientDOB,
          patient_name: oldPatientName,
          privacy_level: oldPrivacyLevel,
          document_title: oldDocumentTitle,
          document_category: oldDocumentCategory,
          labels,
        },
    } = this.props.modalType.data;
    const { incorrectName, incorrectDOB } = this.state;
    return (
      oldPatientDOB !== patientDOB
      || oldPatientName !== patientName
      || oldPrivacyLevel !== privacyLevel
      || oldDocumentTitle !== documentTitle
      || oldDocumentCategory !== documentCategory
      || labels !== selectedLabels
      || incorrectName
      || incorrectDOB
    );
  }

  disableSubmitButton() {
    const {
      patientName,
      patientDOB,
      privacyLevel,
      incorrectName,
      incorrectDOB,
      documentTitle,
      documentCategory,
      referralNote,
      referringProvider,
      referringProviderOffice,
    } = this.state;
    const labelsChanged = this.checkLabelsChanged();
    const { modalType: { data, data: { patient } } } = this.props;
    if (data.buttonText && data.buttonText === 'Continue') {
      return (
        patient.patient_name === patientName
        && !incorrectName
        && !documentTitle
      );
    }
    return (
      (patient.patient_name === patientName
      && patient.patient_dob === patientDOB
      && patient.privacy_level === privacyLevel
      && patient.document_title === documentTitle
      && patient.document_category === documentCategory
      && !incorrectName
      && !incorrectDOB
      && !labelsChanged
      && patient.referral_note === referralNote
      && patient.referring_provider === referringProvider
      && patient.provider_office === referringProviderOffice)
      || (!patientName && !documentTitle)
    );
  }

  render() {
    // eslint-disable-next-line object-curly-newline
    const { patientName, patientDOB, privacyLevel, display, isPatientInfoIncorrect, autoLabelAccess, sendTokens, documentTitle, documentCategory, documentCategories, referralNote, referringProvider, referringProviderOffice} = this.state;
    const {
      isLoading, hideModal, modalType, sendToken,
    } = this.props;
    const {
      data: {
        patient: {
          has_wrong_extraction_been_reported: hasWrongExtractionBeenReported,
          ai_status: aiStatus,
          patient_info_edited: isPatientInfoEdited,
          failed_column: failedColumn,
        },
        fromPage,
      },
    } = modalType;
    const { title, buttonText } = modalType.data;
    const dialogTitle = title || 'Edit Patient Information';
    const dialogButtonText = buttonText || 'Update';
    const bodyStyle = { margin: '20px' };
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const singleColumnFailed = aiStatus === 'failed' && failedColumn !== 'both';
    const isAiExtractionCorrect = aiStatus === 'successful' || singleColumnFailed;
    return (
      <ModalWrapper
        hideModal={hideModal}
        bodyStyle={bodyStyle}
        action={this.update}
        actionName={dialogButtonText}
        dismiss="Cancel"
        modalTitle={dialogTitle}
        isLoading={isLoading}
        disabled={this.disableSubmitButton()}
        isDrawer={true}
        customContentStyle={{ width: '100%' }}
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            this.update(e);
          }
        }}>
          {!autoLabelAccess && (
            <Alert
              style={{ marginBottom: 10, cursor: 'pointer' }}
              message={(
                <div>
                  <span style={{ fontWeight: 500 }}>Tired of labeling your documents?</span>
                  &nbsp;Schedule a 15 minute call with us to learn about our new AI-powered auto-labeling feature.
                </div>
              )}
              onClick={this.openWebinarLink}
              type="info"
              showIcon
            />
          )}
          {modalType.data.patient.can_edit_patient_info && (
            <Form
              layout="vertical"
              onFinish={this.update}
              ref={this.formRef}
              initialValues={{
                name: patientName, documentTitle, note: referralNote, provider: referringProvider, office: referringProviderOffice,
              }}
            >
              <Form.Item
                hasFeedback
                label="Patient Name"
                name="name"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const docTitle = getFieldValue('documentTitle');
                      if (value || docTitle) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Both Patient Name and Document Title cannot be blank'));
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Name"
                  onChange={this.handleNameChange}
                />
              </Form.Item>
              <Form.Item
                label="Date of Birth MM/DD/YYYY"
              >
                <DatePicker
                  onChange={this.handleDateChange}
                  name="patientDOB"
                  format={dateFormat}
                  defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                  value={patientDOB && moment(patientDOB, dateFormat)}
                />
              </Form.Item>

              {(isAiExtractionCorrect && !hasWrongExtractionBeenReported && !isPatientInfoEdited) && this.renderPatientInfoIncorrectCheckBox(isPatientInfoIncorrect)}
              {isPatientInfoIncorrect && this.renderPatientInfoIncorrectCheckBoxes()}

              <Form.Item
                hasFeedback
                label="Document Title"
                name="documentTitle"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const name = getFieldValue('name');
                      if (name || value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Both Patient Name and Document Title cannot be blank'));
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Document Title"
                  onChange={this.handleDocumentTitleChange}
                />
              </Form.Item>
              <div>Document Category</div>
              <Select
                style={{ width: 300, marginTop: 20 }}
                label="Document Category"
                allowClear
                showSearch
                onChange={event => this.setState({ documentCategory: event })}
                value={documentCategory}
              >
                {documentCategories.map(emrCategory => <Option key={emrCategory.id} value={emrCategory.emr_category}>{emrCategory.emr_category}</Option>)}
              </Select>
              {(documentCategory === 'Referral')
              && (
                <div>
                  <Form.Item
                    style={{ marginTop: 20 }}
                    label="Note"
                    name="note"
                  >
                    <Input.TextArea
                      placeholder="Additional Note"
                      onChange={this.handleNoteChange}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: 20 }}
                    label="Referring Provider"
                    name="provider"
                  >
                    <Input
                      placeholder="Referring Provider"
                      onChange={this.handleReferringProviderChange}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: 20 }}
                    label="Referring Provider Office"
                    name="office"
                  >
                    <Input
                      placeholder="Referring Provider Office"
                      onChange={this.handleReferringProviderOfficeChange}
                    />
                  </Form.Item>
                </div>
              )}
            </Form>
          )}
          {(modalType.data.patient.can_edit_privacy_level && display) && (
            <Form
              layout="vertical"
            >
              <Form.Item
                label="Privacy Setting"
              >
                <PrivacyLevel
                  valueSelected={privacyLevel}
                  display={display}
                  handleChange={(privacyLevel) => {
                    this.setState({ privacyLevel });
                  }}
                />
              </Form.Item>
            </Form>
          )}
          {(window.location.pathname.includes('received') || fromPage === 'received') && (sendToken || sendTokens) && (modalType.data.receiverType !== 'email_attachment') && this.renderSelectLabel()}
        </div>
      </ModalWrapper>
    );
  }
}

export default connect(state => ({
  documentCategories: state.auth,
  allDocumentCategories: state.userProfile.allDocumentCategories,
  errorMessage: state.records.errorMessage,
}))(EditPatient);
