const rowSelected = selectedRows => (dispatch) => {
  dispatch({
    type: 'SELECTED_ROWS',
    payload: selectedRows,
  });
};

export {
  rowSelected,
};
