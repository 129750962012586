// Connects the Edit patient info modal component with store

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ResendRecord from '../../components/modal/ResendRecord';
import { resendRecord, bulkResend } from '../../actions/records';
import { rowSelected } from '../../actions/inboxTableActions';

class ResendRecordDialog extends Component {

  static propTypes = {
    resendRecord: PropTypes.func.isRequired,
    bulkResend: PropTypes.func.isRequired,
    modalType: PropTypes.object.isRequired,
  }

  render() {
    const isBulkResend = this.props.modalType.data.recipient === undefined;
    const resendRecordFunction = isBulkResend ? this.props.bulkResend : this.props.resendRecord;
    return (
      <div>
        <ResendRecord
          {...this.props}
          resendRecord={resendRecordFunction}
          isBulkResend={isBulkResend}
          error={this.props.modalType.error}
        />
      </div>
    );
  }
}

export default connect(null, { rowSelected, resendRecord, bulkResend })(ResendRecordDialog);
