import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Tabs,
  Input,
  Spin,
  Form,
  Tooltip,
  Radio,
  Checkbox,
  DatePicker,
  Tag,
  Button,
  Alert,
  Popconfirm,
  notification,
} from 'antd';
import { QuestionCircleOutlined, PlusCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';

import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash.debounce';
import {
  validSendEmail,
  validPatientRecipient,
  validDOB,
  validFaxNumber,
  validPartialNumber,
  formatDate,
  formatValidFaxNumber,
} from '../../global/validation';
import { verifyFeatureAccess } from '../../global/featureFlags';
import DraggableModal from '../modal/DraggableModal';
import isTouchDevice from '../../util/helpers/isTouchDevice';

const { TabPane } = Tabs;

const customContentStyle = {
  width: '60%',
};

// Add positioning to the loader
const inputBox = {
  display: 'flex',
  alignItems: 'baseline',
  flexFlow: 'row nowrap',
};

const dropDownStyle = {
  width: 300,
  left: -20,
};

// Theme for react-autosuggest and styling for sub components
// https://github.com/moroshko/react-autosuggest#theme-optional
const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '600px',
    marginTop: '10px',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    width: 600,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 13,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    maxHeight: 150,
    overflowY: 'auto',
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  sectionContainer: {
    borderTop: '1px solid #ccc',
  },
  sectionTitle: {
    padding: '10px 0 0 10px',
    fontSize: 13,
    color: '#777',
  },
  sectionContainerFirst: {
    borderTop: '0',
  },
};

const sendContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const inputMaxText = 'Subject should have a maximum of 150 characters';

class SendRecordsDialog extends Component {
  constructor(props) {
    super();
    this.state = {
      open: props.open,
      // only set it to sender information if user clicked reply
      contact: '',
      org: '',
      subject: '',
      inputError: 'Enter a valid 10 digit fax number or email address',
      hasDuplicateRecipient: false,
      isProviderValid: true,
      emailValid: false,
      patientRecipientValid: false,
      patientDOBValid: false,
      patientNameValid: false,
      faxNumberValid: false,
      isChecked: true,
      isPatient: false,
      signatureRequired: false,
      patient: {
        recipient: '',
        name: props.patientName || '',
        dob: props.patientDOB || '',
      },
      recipients: [],
      value: '',
      multipleProviderRecipients: [],
      patientValue: '',
      documentType: 'medical_record',
      callerId: !props.defaultFaxNumber ? null : props.defaultFaxNumber,
      removeCoversheet: false,
      showRemoveCoversheetCheckbox: false,
      // only set it to sender information if user clicked reply
      providerRecipientValue: props.isReplyButtonClicked ? (props.senderContactReply ? props.senderContactReply : '') : '',
      hasSingleValidEmailRecipient: false,
      hasSingleValidFaxRecipient: false,
      allowPatientEdits: true,
      showConfirmUpdatePatientInfo: false,
      selectedRecord: props.selectedRecord,
      // only used when user hits reply in the record viewer
      senderContactReply: '',
      noReplyInformationError: false, // used to display error message when user tries to reply to record sent to himself
      disableSecureReplies: false,
    };

    // Wait a half a second before making request.
    // Ensures the function does not fire on every key stroke
    this.debouncedLoadSuggestions = debounce(this.loadSuggestions, 500);

    // Ref for record info form
    this.recordInfoFormRef = React.createRef(null);
  }

  async componentDidMount() {
    const { senderContactReply, isReplyButtonClicked, senderOrganizationReply, senderNameReply } = this.props;
    this.setState({
      showRemoveCoversheetCheckbox: await verifyFeatureAccess('hide_coversheet'),
    });
    if (isReplyButtonClicked) {
      if (senderContactReply) {
        const isValidFaxNumber = validFaxNumber(senderContactReply).valid;
        this.setState({
          hasDuplicateRecipient: false,
          isProviderValid: true,
          org: senderOrganizationReply,
          contact: senderNameReply,
        });
        if (isValidFaxNumber) {
          this.setState({
            hasSingleValidFaxRecipient: true,
          });
        } else {
          this.setState({
            hasSingleValidEmailRecipient: true,
          });
        }
      } else {
        this.setState({ noReplyInformationError: true })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.open !== nextProps.open) {
      this.setState({
        open: nextProps.open,
      });
    }

    // If new search results come in update the state
    if (this.state.recipients !== nextProps.dataSource) {
      this.setState({
        recipients: nextProps.dataSource,
      });
    }
    const { patientName, patientDOB } = this.props;

    // Patient name and DOB are stored in the parent component. If the dialog's current state
    // has them as blank, replace it with whatever is passed from parent component.
    if (!this.state.patient.dob || !this.state.patient.name) {
      this.setState((prevState) => {
        return {
          patientDOBValid: validDOB(nextProps.patientDOB),
          patientNameValid: Boolean(nextProps.patientName),
          patient: {
            ...prevState.patient,
            dob: nextProps.patientDOB,
            name: nextProps.patientName || '',
          },
        };
      });
    }

    // If nextProps patient name or DOB don't match current props, set state to new props.
    if ((patientName !== nextProps.patientName || patientDOB !== nextProps.patientDOB)) {
      this.setState((prevState) => {
        return {
          patientDOBValid: validDOB(nextProps.patientDOB),
          patientNameValid: Boolean(nextProps.patientName),
          patient: {
            ...prevState.patient,
            dob: nextProps.patientDOB,
            name: nextProps.patientName || '',
          },
        };
      });
    }

    if (!this.state.isPatient) {
      this.setState({ patientValue: '' });
    }
  }


  // Store input reference for the patient field.
  storePatientInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.patientInput = autosuggest.input;
    }
  }

  // Store input reference for the provider field.
  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  }

  // Autosuggest calls this function when the user interacts with the text input
  // by:
  // a) Typing something
  // b) Focusing on the input
  // c) something else, documented here: https://git.io/fNQ4P
  onSuggestionsFetchRequested = ({ value }) => {
    // Only fetch suggestions if text in input has changed
    // This avoids API calls when the input is focused with the same value
    const { value: oldValue, isPatient } = this.state;
    const fetchNewSuggestion = (value.trim() !== oldValue.trim());

    if (fetchNewSuggestion) {
      if (isPatient) {
        this.setState({
          patientRecipientValid: validPatientRecipient(value),
          patientNameValid: value !== '',
        });
      } else {
        const { valid: faxNumberValid } = validFaxNumber(value);
        this.setState({
          faxNumberValid,
          emailValid: validSendEmail(value),
          org: '',
          contact: '',
        });
      }
      // Allow search for partial fax numbers with special characters e.g 999-080-
      const { valid: partialNumberValid, rawNumber: number } = validPartialNumber(value);

      if (partialNumberValid) {
        return this.debouncedLoadSuggestions(number);
      }
      return this.debouncedLoadSuggestions(value);
    }
  };

  // Updates the state when recipient is typed in
  onChange = (event, { newValue }) => {
    if (this.state.isPatient) {
      this.setState((prevState) => {
        return {
          patientRecipientValid: validPatientRecipient(newValue),
          patientValue: newValue,
          patient: {
            ...prevState.patient,
            recipient: newValue,
          },
        };
      });
    } else {
      const { multipleProviderRecipients } = this.state;
      const trimmedInput = newValue.trim();
      const isValidFaxNumber = validFaxNumber(trimmedInput).valid;
      const isValidEmail = validSendEmail(trimmedInput);
      const isValidFaxNumberOrEmail = isValidEmail || isValidFaxNumber;
      let singleValidInputFieldRecipient = isValidFaxNumberOrEmail;
      if (singleValidInputFieldRecipient) {
        if (isValidFaxNumber) {
          singleValidInputFieldRecipient = 'hasSingleValidFaxRecipient';
        } else {
          singleValidInputFieldRecipient = 'hasSingleValidEmailRecipient';
        }
      }
      const recipients = multipleProviderRecipients.map(recipient => recipient.recipient);
      const faxNumberOrEmail = (isValidFaxNumber ? formatValidFaxNumber(trimmedInput, 'Domestic') : trimmedInput);
      this.setState({
        providerRecipientValue: newValue,
        hasSingleValidEmailRecipient: false,
        hasSingleValidFaxRecipient: false,
      });
      if (recipients.includes(faxNumberOrEmail)) {
        this.setState({
          isProviderValid: true,
          hasDuplicateRecipient: true,
        });
        return;
      }
      if (!isValidFaxNumberOrEmail && trimmedInput) {
        this.setState({
          isProviderValid: false,
          hasDuplicateRecipient: false,
        });
        return;
      }
      this.setState((prevState) => {
        const faxNumberValid = validFaxNumber(newValue).valid;
        const signatureRequired = faxNumberValid ? false : prevState.signatureRequired;
        return {
          signatureRequired,
          isProviderValid: true,
          [singleValidInputFieldRecipient]: true,
        };
      });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    // Clear suggestions
    // TODO: add support for when user focuses away from input to cache
    // suggestions
    this.setState({ recipients: [] });
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. If the suggestion selected is a fax number, It populates
  // recipient, org. and contact. If its an email address, it populates only the recipient
  getSuggestionValue = (suggestion) => {
    const { isPatient } = this.state;
    if (isPatient) {
      this.setState((prevState) => {
        return {
          patientValue: suggestion.recipient,
          patientDOBValid: validDOB(suggestion.dob),
          patientNameValid: Boolean(suggestion.name),
          patient: {
            ...prevState.patient,
            dob: suggestion.dob,
            name: suggestion.name,
            recipient: suggestion.recipient,
          },
        };
      });
      return suggestion.recipient;
    }
    // If  valid number, then change display type to domestic. e.g (919) 818-8181
    const { valid: faxNumberValid, rawNumber: recipient } = validFaxNumber(suggestion.recipient);

    this.recordInfoFormRef.current?.setFieldsValue({
      ...(!this.state.org && suggestion.organization && { org: suggestion.organization }),
      ...(!this.state.contact && suggestion.name && { contact: suggestion.name }),
    });

    this.setState({
      org: suggestion.organization,
      contact: suggestion.name,
    });
    return faxNumberValid ? formatValidFaxNumber(recipient, 'Domestic') : suggestion.recipient;
  };

  // Gets suggestion of a particular section e.g users, organizations
  getSectionSuggestions(section) {
    return section.recipients;
  }

  // Fetches suggestions after hititing search api endpoint
  loadSuggestions(value) {
    if (this.state.isPatient) {
      this.props.searchRecipient('patient', value);
    } else {
      this.props.searchRecipient('provider', value);
    }
  }

  // Cancel button on dialog
  cancel = () => {
    const { defaultFaxNumber } = this.props;
    this.setState({
      searchText: '',
      org: '',
      contact: '',
      value: '',
      patientValue: '',
      patient: {
        recipient: '',
        name: '',
        dob: '',
      },
      patientRecipientValid: false,
      patientDOBValid: false,
      patientNameValid: false,
      faxNumberValid: false,
      emailValid: false,
      isChecked: false,
      signatureRequired: false,
      documentType: 'medical_record',
      callerId: defaultFaxNumber,
      showRemoveCoversheetCheckbox: false,
      providerRecipientValue: '',
      multipleProviderRecipients: [],
      hasDuplicateRecipient: false,
      isProviderValid: true,
      hasSingleValidEmailRecipient: false,
      hasSingleValidFaxRecipient: false,
      disableSecureReplies: false,
    });

    // Closes the send record maodal
    this.props.handleCancel(false);
    // TODO (Ammar Khan)
    // handle canceling of state in component and redux store
    // and then send to parent cancel function
  }

  submit = async () => {
    const {
      org,
      contact,
      value,
      isChecked,
      emailValid,
      faxNumberValid,
      isPatient,
      patient,
      documentType,
      memo,
      subject,
      callerId,
      signatureRequired,
      removeCoversheet,
      providerRecipientValue,
      isProviderValid,
      hasDuplicateRecipient,
      allowPatientEdits,
      disableSecureReplies,
    } = this.state;

    const { handleSubmit, defaultFaxNumber, associatedFaxNumbers } = this.props;

    patient.dob = formatDate(patient.dob);
    patient.signatureRequired = signatureRequired;

    const firstNumber = associatedFaxNumbers[0] && associatedFaxNumbers[0][0];

    const recipient = faxNumberValid ? validFaxNumber(value).rawNumber : value;
    // adding in the input field value to multipleProviderRecipients if it's valid
    const inputFieldValueIsValid = isProviderValid && !hasDuplicateRecipient && providerRecipientValue;
    if (inputFieldValueIsValid && !isPatient) { await this.addProviderRecipient() }
    // updated in addProviderRecipient called above so destructured here instead of the beginning of the function
    const { multipleProviderRecipients } = this.state;

    const callerIdValue = callerId || firstNumber || null;
    handleSubmit(org, contact, callerIdValue, isChecked, emailValid, isPatient, patient, documentType, memo, subject, signatureRequired, removeCoversheet, multipleProviderRecipients, allowPatientEdits, disableSecureReplies);
    
    this.setState({
      org: '',
      contact: '',
      faxNumberValid: false,
      emailValid: false,
      patientRecipientValid: false,
      patientNameValid: false,
      patientDOBValid: false,
      isPatient: false,
      signatureRequired: false,
      patient: {
        recipient: '',
        name: '',
        dob: '',
      },
      value: '',
      memo: '',
      documentType: 'medical_record',
      subject: '',
      callerId: defaultFaxNumber,
      removeCoversheet: false,
      showRemoveCoversheetCheckbox: false,
      multipleProviderRecipients: [],
      providerRecipientValue: '',
      hasDuplicateRecipient: false,
      isProviderValid: true,
      allowPatientEdits: true,
      disableSecureReplies: false,
    });
  }

  // Updates the state with the field value
  handleInputChange = (event) => {
    const change = {
      [event.target.name]: event.target.value,
    };
    this.setState(change);
  }

  handleDateChange = (_, dateString) => {
    this.setState({ patient: { ...this.state.patient, dob: dateString } });
  }

  patientMeta = (data, e) => {
    // Write e.target.value to variable since target will be set to null on a released/nullified synthetic event. https://fb.me/react-event-pooling
    const value = e.target.value;
    switch (data) {
      case 'recipient':
        this.setState((prevState) => {
          return {
            patientRecipientValid: validPatientRecipient(value),
            patient: {
              ...prevState.patient,
              recipient: value,
            },
          };
        });
        break;
      case 'dob': {
        const valid = validDOB(value);
        this.setState((prevState) => {
          return {
            patientDOBValid: valid,
            patient: { ...prevState.patient, dob: value },
          };
        });
        break;
      }
      case 'name':
        this.setState((prevState) => {
          return {
            patientNameValid: value !== '',
            patient: { ...prevState.patient, name: value },
          };
        });
        break;
    }
  }

  // Check if the recipient email or fax number is valid
  validInput() {
    const { isPatient } = this.state;
    if (isPatient) {
      // eslint-disable-next-line object-curly-newline
      const { patientRecipientValid, patientNameValid, patient } = this.state;
      const isDobValid = patient.dob && !(Number.isNaN(new Date(patient.dob).getTime()));
      const dataValid = isDobValid && patientRecipientValid && patientNameValid;
      return dataValid;
    }
    return false;
  }

  displaySuggestionText = (suggestion) => {
    const contactName = suggestion.name;
    const organizationName = suggestion.organization;
    const recipient = validFaxNumber(suggestion.recipient).valid ? formatValidFaxNumber(suggestion.recipient, 'Domestic') : suggestion.recipient;
    if (organizationName && contactName) return `${contactName} (${organizationName}) - ${recipient}`;
    if (!contactName && !organizationName) return `${recipient}`;
    if (!contactName && organizationName) return `${organizationName} - ${recipient}`;
    return `${contactName} - ${recipient}`;
  }

  handleActive = (tab) => {
    if (tab === 'Patient') {
      this.setState({ isPatient: true });
      // Focus on patient field.
      if (this.patientInput) {
        setTimeout(() => {
          this.patientInput.focus();
        }, 100);
      }
    } else {
      this.setState({ isPatient: false });
      // Focus on provider field.
      if (this.input) {
        setTimeout(() => {
          this.input.focus();
        }, 100);
      }
    }
  }

  // Only shows suggestion when 3 or more charcters
  shouldRenderSuggestions = value => value.trim().length > 2

  // Styles the render suggestions component.
  renderSuggestion = suggestion => (
    <div>
      {this.displaySuggestionText(suggestion)}
    </div>
  );

  // Renders the section of the search results
  renderSectionTitle = (section) => {
    return (
      <strong>{section.title}</strong>
    );
  }

  isMedsenderUser = (faxNumberOrEmail) => {
    return axios.get(`${window.location.origin}/api/v1/records/medsender_user?sender=${faxNumberOrEmail}`).then(response => response.data.is_medsender_user);
  }

  addProviderRecipient = async () => {
    const {
      providerRecipientValue,
      multipleProviderRecipients,
      contact,
      org,
    } = this.state;
    // check to see if providerRecipientValue is valid
    if (providerRecipientValue.trim() !== '') {
      const isValidFaxNumber = validFaxNumber(providerRecipientValue).valid;
      const isValidEmail = validSendEmail(providerRecipientValue);
      const isValidFaxNumberOrEmail = isValidEmail || isValidFaxNumber;
      let recipientType = 'email';
      if (isValidFaxNumber) {
        recipientType = 'fax';
      }
      // newRecipientObject is an object passed in to multipleProviderRecipients and contains the following attributes, recipient, isValid, recipientType
      // recipientType could be either email, fax number or invalid
      const recipients = multipleProviderRecipients.map(recipient => recipient.recipient);
      const faxNumberOrEmail = (isValidFaxNumber ? formatValidFaxNumber(providerRecipientValue, 'Domestic') : providerRecipientValue);
      if (recipients.includes(faxNumberOrEmail.trim())) {
        this.setState({
          hasDuplicateRecipient: true,
        });
      } else if (!isValidFaxNumberOrEmail) {
        this.setState({
          hasDuplicateRecipient: false,
          isProviderValid: false,
        });
      } else {
        const isMedsenderUser = await this.isMedsenderUser(faxNumberOrEmail);
        const newRecipientObject = { recipient: faxNumberOrEmail, recipientType, isMedsenderUser };

        // Add contact/org info there's a single recipient in multipleProviderRecipients list
        if (multipleProviderRecipients.length < 1) {
          newRecipientObject.contact = contact;
          newRecipientObject.org = org;
        }

        const newMultipleRecipients = [...multipleProviderRecipients, newRecipientObject];
        // multipleProviderRecipients is an array of newRecipientObjects
        this.setState({
          multipleProviderRecipients: [...newMultipleRecipients],
          providerRecipientValue: '',
          hasDuplicateRecipient: false,
          isProviderValid: true,
          hasSingleValidEmailRecipient: false,
          hasSingleValidFaxRecipient: false,
          contact: newRecipientObject.contact || '',
          org: newRecipientObject.org || '',
        });
      }
    }
  }

  removeRecipient = (_, index) => {
    const { multipleProviderRecipients } = this.state;
    const newRecipientArray = [...multipleProviderRecipients];
    newRecipientArray.splice(index, 1);

    // If sender has removed multiple recipients, and a single recipient is remaining
    // Check the contact and org info for that first recipient
    if (multipleProviderRecipients.length === 2) {
      return this.setState({
        multipleProviderRecipients: [...newRecipientArray],
        contact: newRecipientArray[0].contact || '',
        org: newRecipientArray[0].org || '',
      });
    }
    return this.setState({
      multipleProviderRecipients: [...newRecipientArray],
    });
  }

  renderProviderInputComponent = (inputProps) => {
    const {
      multipleProviderRecipients,
      providerRecipientValue,
      hasDuplicateRecipient,
      isProviderValid,
      senderContactReply,
    } = this.state;
    
    let recipientsHasMedsenderUser = false;
    multipleProviderRecipients.forEach((recipient) => {
      if (recipient.isMedsenderUser) {
        recipientsHasMedsenderUser = true;
      }
    });
    return (
      <>
        <div style={{ display: 'flex', color: 'red' }}>
          {!isProviderValid ? 'Invalid Fax Number or Email' : ''}
        </div>
        <div style={{ display: 'flex', color: 'red' }}>
          {hasDuplicateRecipient ? 'Duplicate recipient already added' : ''}
        </div>
        <div style={{ display: 'flex' }}>
          <Input
            allowClear
            placeholder="Recipient Name, Fax Number or Email Address"
            onChange={e => this.setState({ providerRecipientValue: e.target.value })}
            onPressEnter={() => this.addProviderRecipient()}
            name="contact"
            maxLength={250}
            value={providerRecipientValue}
            style={{ marginTop: 10 }}
            {...inputProps}
          />
          <Button onClick={() => this.addProviderRecipient()} type="primary" shape="circle" style={{ marginLeft: '10px', marginTop: '9px' }} icon={<PlusCircleOutlined />} />
        </div>
        <br />
        {multipleProviderRecipients.map((recipientObj, index) => (
          <Tag icon={recipientObj.isMedsenderUser ? <ThunderboltOutlined /> : ''}
            color={recipientObj.isMedsenderUser ? "purple" : "blue"}
            key={`${recipientObj.recipient}${index}`}
            closable
            onClose={() => this.removeRecipient(recipientObj, index)}
          >
            {recipientObj.recipient}
          </Tag>
        ))}
        <br />
      </>
    );
  }

  renderPatientInputComponent = (inputProps) => {
    const {
      providerRecipientValue,
    } = this.state;
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Input
            placeholder="Patient Email Address or Cell Phone Number"
            onChange={e => this.setState({ providerRecipientValue: e.target.value })}
            onPressEnter={() => this.addProviderRecipient()}
            name="contact"
            value={providerRecipientValue}
            style={{ marginTop: 10 }}
            {...inputProps}
          />
        </div>
        <br />
      </>
    );
  }

  checkRequireSignature = (checked) => {
    // due to adding the checkbox for preventing users from editing, we want to ensure if signature is required, edits are allowed
    this.setState({ signatureRequired: checked });
    if (checked) {
      this.setState({
        allowPatientEdits: true,
      });
    }
  }

  addSingleRecipient = (faxNumberOrEmail, recipientType, isMedsenderUser) => {
    const { multipleProviderRecipients } = this.state;
    const newRecipientObject = { recipient: faxNumberOrEmail, recipientType, isMedsenderUser };
    const newMultipleRecipients = [...multipleProviderRecipients, newRecipientObject];
    this.setState({
      multipleProviderRecipients: [...newMultipleRecipients],
      providerRecipientValue: '',
    });
  }

  checkIfMedsenderUser = async (faxNumberOrEmail, recipientType) => {
    const { multipleProviderRecipients } = this.state;
    const isMedsenderUser = await this.isMedsenderUser(faxNumberOrEmail);
    const recipients = multipleProviderRecipients.map(recipient => recipient.recipient);
    if (recipients.includes(faxNumberOrEmail.trim())) {
      return isMedsenderUser
    }
    if (isMedsenderUser) {
      notification.info({
        message: 'Medsender User Detected!',
        description:
          'We offer a variety of perks when sending a record to a medsender user, such as guaranteed instant delivery,  gives recipients the ability to search through the record, and the ability to message the sender/recipient via Medsender Connect Chat!',
      });
      this.addSingleRecipient(faxNumberOrEmail, recipientType, isMedsenderUser);

    }
    return isMedsenderUser
  }

  isValidProviderInput = (hasNoOrInvalidLength) => {
    const { isPatient, providerRecipientValue } = this.state;
    if (isPatient) {
      return false
    } else if (!hasNoOrInvalidLength){
      const trimmedInput = providerRecipientValue.trim();
      const isValidFaxNumber = validFaxNumber(trimmedInput).valid;
      const isValidEmail = validSendEmail(providerRecipientValue);
      const isValidFaxNumberOrEmail = isValidEmail || isValidFaxNumber;
      const faxNumberOrEmail = (isValidFaxNumber ? formatValidFaxNumber(providerRecipientValue, 'Domestic') : providerRecipientValue);
      let recipientType = 'email';
      if (isValidFaxNumber) {
        recipientType = 'fax';
      }
      if (isValidFaxNumberOrEmail) {
        this.checkIfMedsenderUser(faxNumberOrEmail, recipientType)
      }
      return false
    }
    return hasNoOrInvalidLength
  }
  
  renderModalActionButton = (hasNoOrInvalidLength) => {
    const { isPatient, patient } = this.state;
    const { patientDOB, patientName } = this.props;
    const patientInformationChanged = patient.name !== patientName || patient.dob !== patientDOB;
    if (isPatient && patientInformationChanged) {
      return (
        <Popconfirm
          title="Patient information you've entered is different from we have on file. Send and update patient information?"
          disabled={!this.validInput()}
          onConfirm={this.submit.bind(this)}
          cancelText="Cancel"
          okText="Send and Update"
        >
          <Button
            disabled={this.validInput() ? false : (isPatient || hasNoOrInvalidLength)}
          >
            Send
          </Button>
        </Popconfirm>
      );
    }
    return (
      <Button
        type="primary"
        disabled={this.validInput() ? false : this.isValidProviderInput(hasNoOrInvalidLength)}
        onClick={this.submit.bind(this)}
      >
        Send
      </Button>
    );
  }

  render() {
    const {
      open,
      recipients,
      patientRecipientValid,
      patientNameValid,
      org,
      contact,
      value,
      patientValue,
      patient,
      isPatient,
      memo,
      callerId,
      removeCoversheet,
      showRemoveCoversheetCheckbox,
      multipleProviderRecipients,
      providerRecipientValue,
      hasDuplicateRecipient,
      isProviderValid,
      hasSingleValidEmailRecipient,
      hasSingleValidFaxRecipient,
      allowPatientEdits,
      signatureRequired,
      noReplyInformationError,
    } = this.state;

    const validEmailRecipients = multipleProviderRecipients.filter(recipient => recipient.recipientType === 'email');
    const validFaxRecipients = multipleProviderRecipients.filter(recipient => recipient.recipientType === 'fax');
    const invalidLength = (multipleProviderRecipients.length > 25);
    const validProviderRecipientFieldValue = providerRecipientValue && !hasDuplicateRecipient && isProviderValid;
    const hasNoOrInvalidLength = ((!validProviderRecipientFieldValue && multipleProviderRecipients.length === 0) || invalidLength);

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      value: providerRecipientValue,
      onChange: this.onChange,
      autoFocus: true, // Focus on provider field once component is rendered.
    };

    const inputPatientProps = {
      value: patientValue,
      onChange: this.onChange,
    };

    const {
      cancel,
      submit,
      patientMeta,
      renderSectionTitle,
      renderSuggestion,
      renderProviderInputComponent,
      renderPatientInputComponent,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      getSuggestionValue,
      shouldRenderSuggestions,
      getSectionSuggestions,
      handleActive,
    } = this;

    const { userCanSendFaxRecordsOnly } = this.props;

    let displayConfirmationDialogBeforeSending;

    if (isPatient) {
      displayConfirmationDialogBeforeSending = patientRecipientValid && patientNameValid && patient.dob;
    } else {
      displayConfirmationDialogBeforeSending = !hasNoOrInvalidLength;
    }

    const { searchLoading, associatedFaxNumbers: faxNumbers } = this.props;

    // The document type will be displayed in the dialog text. The _ is replaced
    // because the default type is 'medical_record' => 'medical record' after
    // being replaced
    const documentType = this.state.documentType.replace('_', ' ');
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const isPatientDobValid = patient.dob && !(Number.isNaN(new Date(patient.dob).getTime()));
    return (
      <DraggableModal
        footer={[
          <Button onClick={cancel}>Cancel</Button>,
          this.renderModalActionButton(hasNoOrInvalidLength),
        ]}
        visible={open}
        handleClass='.ant-tabs-nav'
        contentStyle={customContentStyle}
        width={800}
        onCancel={cancel}
      >
        <Tabs defaultActiveKey="Provider" onChange={handleActive}>
          <TabPane tab="Provider" key="Provider">
            <div style={sendContainer}>
              {
                (noReplyInformationError || !isTouchDevice())
                && (
                  <Alert
                    style={{ marginBottom: 10 }}
                    message={noReplyInformationError
                      ? (
                        <div>
                          You cannot reply to a record that you sent or someone in your organization sent to themselves
                        </div>
                      ) : (
                        <div>
                          <span style={{ fontWeight: 500 }}>New: </span>
                          This dialog is draggable now! Feel free to drag it around to be able to view the document.
                        </div>
                      )}
                    type={noReplyInformationError ? 'error' : 'info' }
                    showIcon
                    hidden
                  />
                )
              }
              <p>Enter the Recipient(s)</p>
              <p style={{ margin: 0, fontSize: 15 }}>
                <b>
                  Type any fax number, email, or organization/person name
                </b>
              </p>
              <div style={inputBox}>
                <Autosuggest
                  multiSection={true}
                  suggestions={recipients}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={renderProviderInputComponent}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  theme={theme}
                  ref={this.storeInputReference}
                />
                {searchLoading && (
                  <Spin
                    style={{ right: '3%' }}
                  />
                )}
              </div>
              {(validFaxRecipients.length > 0 || validEmailRecipients.length > 0 || hasSingleValidFaxRecipient || hasSingleValidEmailRecipient) && (
                <>
                  <Form style={{ marginTop: 20 }} layout="vertical" ref={this.recordInfoFormRef}>
                    {multipleProviderRecipients.length < 2 && !((hasSingleValidFaxRecipient || hasSingleValidEmailRecipient) && multipleProviderRecipients.length === 1)
                      && (
                      <>
                        <Form.Item
                          hasFeedback
                          name="org"
                          label="Recipient Organization"
                          style={{ width: '600px' }}
                        >
                          <Input
                            placeholder="(optional) Organization Name"
                            onChange={this.handleInputChange}
                            name="org"
                            maxLength={100}
                            defaultValue={org !== '' ? org : ''}
                          />
                        </Form.Item>
                        <Form.Item
                          hasFeedback
                          name="contact"
                          label="Recipient Name"
                          style={{ width: '600px' }}
                        >
                          <Input
                            placeholder="(optional) Organization Contact"
                            onChange={this.handleInputChange}
                            maxLength={100}
                            name="contact"
                            defaultValue={contact !== '' ? contact : ''}
                          />
                        </Form.Item>
                      </>
                      )
                    }
                    {!validEmailRecipients.length > 0 && !hasSingleValidEmailRecipient
                      && (
                        <Form.Item
                          hasFeedback
                          name="memo"
                          label="Memo"
                          style={{ width: '600px' }}
                        >
                          <Input.TextArea
                            placeholder="(optional) Memo/Note"
                            onChange={this.handleInputChange}
                            name="memo"
                            defaultValue={memo !== '' ? memo : ''}
                            autoSize={{ minRows: 1, maxRows: 6 }}
                          />
                        </Form.Item>
                      )}
                  </Form>
                </>
              )}
              {((validEmailRecipients.length > 0 || hasSingleValidEmailRecipient)) && (
                <Form style={{ marginTop: 25 }} layout="vertical">
                  <Form.Item
                    hasFeedback
                    name="subject"
                    label="Subject"
                    style={{ width: '600px' }}
                    rules={[
                      () => ({
                        validator(_, subject) {
                          if (subject.length < 150) return Promise.resolve();
                          return Promise.reject('Subject cannot have more than 150 characters');
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="(optional) Subject"
                      onChange={this.handleInputChange}
                      name="subject"
                    />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name="memo"
                    label="Memo"
                    style={{ width: '600px' }}
                  >
                    <Input.TextArea
                      placeholder="(optional) Memo/Note"
                      onChange={this.handleInputChange}
                      name="memo"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      defaultValue={memo !== '' ? memo : ''}
                    />
                  </Form.Item>
                </Form>
              )}
              {((validFaxRecipients.length > 0 && faxNumbers.length > 0) || (hasSingleValidFaxRecipient && faxNumbers.length > 0)) && (
                <div>
                  <div style={{ display: 'flex', height: '30px' }}>
                    <p>
                      Send record with this Caller ID ...
                    </p>
                    <Tooltip
                      overlayStyle={{ zIndex: 1600, marginLeft: '10px' }}
                      title="Outgoing caller id for any fax transmission"
                    >
                      <QuestionCircleOutlined style={{ margin: '5px 0px 0px 5px' }} />
                    </Tooltip>
                  </div>
                  <DropDownMenu
                    value={callerId || faxNumbers[0][0]}
                    onChange={(event, index, number) => this.setState({ callerId: number })}
                    autoWidth={false}
                    style={dropDownStyle}
                  >
                    {faxNumbers.map(number => <MenuItem key={number[0]} value={number[0]} primaryText={`${number[0]} - ${number[1]}`} />)}
                  </DropDownMenu>
                </div>
              )}
              <div style={{ margin: '0 0 10px' }}>
                <p style={{ display: 'inline-block', marginTop: '7px' }}>
                  Allow the recipient to access this document for...
                </p>
                <Tooltip
                  overlayStyle={{ zIndex: 1600, marginLeft: '10px' }}
                  title="The recipient will be able to access the document for this amount of time"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <br />
                <Radio.Group
                  onChange={e => this.setState({ documentType: e.target.value })}
                  defaultValue="medical_record"
                  style={{ marginBottom: 20 }}
                >
                  <Radio value="medical_record" style={{ marginLeft: '10px' }}>30 days</Radio>
                  <Radio value="claim" style={{ marginLeft: '10px' }}>60 days</Radio>
                </Radio.Group>
                <p>Additional options</p>
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={(e) => {
                    this.setState({ isChecked: e.target.checked });
                  }}
                >
                  Allow the recipient to send this document to others
                </Checkbox>
                <br/>
                <Checkbox
                  style={{ margin: '10px 0px' }}
                  disabled={userCanSendFaxRecordsOnly}
                  onChange={(e) => this.checkRequireSignature(e.target.checked)}
                >
                  Require the recipient to sign this document before replying
                </Checkbox>
                {showRemoveCoversheetCheckbox
                  && (
                    <Checkbox
                      style={{ margin: '0 0 10px' }}
                      checked={removeCoversheet}
                      onChange={(e) => {
                        this.setState({ removeCoversheet: e.target.checked });
                      }}
                    >
                      Send record to recipients without Medsender cover page
                    </Checkbox>
                  )
                }
                <br />
                {(validEmailRecipients.length > 0 || hasSingleValidEmailRecipient) && (
                  <Checkbox
                    style={{ margin: '0 0 10px' }}
                    checked={userCanSendFaxRecordsOnly ? true : allowPatientEdits}
                    disabled={signatureRequired}
                    onChange={(e) => {
                      this.setState({ allowPatientEdits: e.target.checked });
                    }}
                  >
                    Allow recipient to edit record
                  </Checkbox>
                )}
                {this.validInput() && value !== '' && (
                  <p style={{ color: 'red', textAlign: 'center' }}>
                    {this.state.inputError}
                  </p>
                )}
                {displayConfirmationDialogBeforeSending && (
                  <p>
                    Clicking &quot;Send&quot; will send this document.
                    Only click if you are absolutely sure and you&apos;ve
                    made sure this is the correct recipient(s).
                    Please Note: You cannot send a record to more than 25 recipients at a time.
                  </p>
                )}
              </div>
            </div>
          </TabPane>
          <TabPane tab="Patient" key="Patient">
            <div style={sendContainer}>
              <p>Enter the Patient&#39;s Info</p>
              <div style={inputBox}>
                <Autosuggest
                  multiSection={true}
                  suggestions={recipients}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputPatientProps}
                  renderInputComponent={renderPatientInputComponent}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  theme={theme}
                  ref={this.storePatientInputReference}
                />
                {searchLoading && <Spin size="medium" />}
              </div>
              {(patient.dob && patientRecipientValid && patientNameValid) && (
                <Form style={{ marginTop: 25 }} layout="vertical">
                  <Form.Item
                    name="memo"
                    label="Memo"
                    style={{ width: '600px' }}
                  >
                    <Input.TextArea
                      placeholder="Memo/Note (optional)"
                      onChange={this.handleInputChange}
                      name="memo"
                      autoSize={{ minRows: 1, maxRows: 6 }}
                      defaultValue={memo !== '' ? memo : ''}
                    />
                  </Form.Item>
                </Form>
              )}
              <Form style={{ marginTop: 30 }} layout="vertical">
                <div style={{ display: 'flex' }}>
                  <Form.Item
                    hasFeedback
                    label="Name"
                    style={{ width: '300px' }}
                    initialValue={patient.name !== '' ? patient.name : ''}
                  >
                    <Input
                      placeholder="Patient name"
                      onChange={patientMeta.bind(this, 'name')}
                      value={patient.name}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Date of Birth (MM/DD/YYYY)"
                    style={{ marginLeft: 10 }}
                    initialValue={isPatientDobValid ? moment(patient.dob, dateFormat) : ''}
                  >
                    <DatePicker
                      popupStyle={{ zIndex: 1600 }}
                      onChange={this.handleDateChange}
                      format={dateFormat}
                      value={isPatientDobValid ? moment(patient.dob, dateFormat) : ''}
                    />
                  </Form.Item>
                </div>
              </Form>
              <div style={{ margin: '20px 0px 10px' }}>
                <p>Additional options</p>
                <Checkbox
                  style={{ margin: '10px 0px' }}
                  disabled={userCanSendFaxRecordsOnly}
                  onChange={(e) => {
                    this.setState({ signatureRequired: e.target.checked });
                  }}
                >
                  Require the recipient to sign this document before replying
                </Checkbox>
                <br />
                <Checkbox
                    style={{ margin: '0 0 10px' }}
                    onChange={(e) => {
                    this.setState({ disableSecureReplies: e.target.checked });
                  }}
                >
                  Don't allow the recipient to reply to this document
                </Checkbox>
                {displayConfirmationDialogBeforeSending && (
                  <p>
                    You are about to send a record to <b>{patient.recipient}</b>
                    . Clicking &quot;Send Record&quot; will send this
                    record. Only click if you are absolutely sure and
                    you&apos;ve made sure this is the correct recipient.
                  </p>
                )}
              </div>
            </div>
          </TabPane>
        </Tabs>
      </DraggableModal>
    );
  }
}

SendRecordsDialog.defaultProps = {
  patientName: '',
  patientDOB: '',
  isReplyButtonClicked: false,
  senderContactReply: '',
};

SendRecordsDialog.propTypes = {
  associatedFaxNumbers: PropTypes.array.isRequired,
  defaultFaxNumber: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  dataSource: PropTypes.array.isRequired,
  searchRecipient: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  userCanSendFaxRecordsOnly: PropTypes.bool.isRequired,
  senderContactReply: PropTypes.string,
  isReplyButtonClicked: PropTypes.bool,
};

export default SendRecordsDialog;
