// Modal Wrapper component outlines the structure for the modal component.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Drawer, Button, Popconfirm,
} from 'antd';
import DraggableModal from './DraggableModal';

class ModalWrapper extends Component {
  state = {
    showPopConfirm: false,
  }

  showPopConfirm = () => {
    const { showConfirmation } = this.props;
    this.setState({ showPopConfirm: showConfirmation });
  }

  confirm = async () => {
    await this.setState({ showPopConfirm: false });

    const { action } = this.props;
    return action();
  }

  render() {
    const {
      customContentStyle,
      bodyStyle,
      modalTitle,
      dismiss,
      hideModal,
      actionName,
      disabled,
      action,
      children,
      form,
      isLoading,
      width,
      showConfirmation,
      isDrawer,
      isDraggable,
      closable,
    } = this.props;
    const { showPopConfirm } = this.state;

    let ModalComponent = Modal;

    if (isDrawer) {
      ModalComponent = Drawer;
    } else if (isDraggable) {
      ModalComponent = DraggableModal;
    }

    return (
      <ModalComponent
        visible={true}
        style={customContentStyle}
        bodyStyle={bodyStyle}
        onClose={hideModal}
        width={width}
        title={modalTitle}
        onCancel={dismiss && hideModal}
        closable={closable}
        maskStyle={{ opacity: isDraggable && '0.5' }}
        footer={[
          (dismiss
            && (
              <Button onClick={hideModal} key="1">
                {dismiss}
              </Button>
            )),
          // removes the action button when loading to prevent users from clicking twice
          actionName && (
            <Popconfirm
              title="Editing existing labels will change them on the documents already tagged with this label. This may cause unintended mislabeling of previously labeled documents."
              visible={showPopConfirm}
              onConfirm={this.confirm}
              onCancel={() => this.setState({ showPopConfirm: false })}
              key="2"
            >
              <Button
                type="primary"
                key="submit"
                form={form}
                htmlType="submit"
                disabled={disabled}
                loading={isLoading}
                onClick={showConfirmation ? this.showPopConfirm : action}
              >
                {actionName}
              </Button>
            </Popconfirm>
          )]}
      >
        {children}
      </ModalComponent>
    );
  }
}

ModalWrapper.defaultProps = {
  action: null,
  actionName: null,
  dismiss: null,
  disabled: false,
  modalTitle: '',
  width: '520px',
  form: '',
  isLoading: false,
  showConfirmation: false,
  bodyStyle: {},
  isDrawer: false,
  isDraggable: false,
  closable: true,
};

ModalWrapper.propTypes = {
  customContentStyle: PropTypes.object.isRequired,
  bodyStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  hideModal: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  action: PropTypes.func,
  actionName: PropTypes.string,
  dismiss: PropTypes.string,
  disabled: PropTypes.bool,
  modalTitle: PropTypes.string,
  form: PropTypes.string,
  isLoading: PropTypes.bool,
  showConfirmation: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isDraggable: PropTypes.bool,
  closable: PropTypes.bool,
};

export default ModalWrapper;
