import {
  AdminTools,
  AuditLogs,
  AdminDepartments,
  AdminFaxNums,
  AdminUsers,
  AdminAnalytics,
  AdminDomains,
} from '../../../assets/img/guide';

const adminTools = {
  pageTitle: 'Admin Tools',
  pageSubtitle: `
    Admins can access the admin dashboard by navigating from the user profile page or directly at https://app.medsender.com/admin. 
    The dashboard gives you access to the following tools that you can leverage to manage your organization.`,
  sections: [
    {
      title: 'Admin Users',
      id: 'adminUsers',
      subtitle: `Add and edit admin users. Admin users are distinct from web-app users. 
      A user can be just an admin user (without access to the web-app), a normal user (without access to admin tools), 
      or an admin user and a web-app user (with access to all).
      `,
      img: AdminTools,
      divider: true,
    },
    {
      title: 'Audit Logs',
      id: 'auditLogs',
      subtitle: `
        View detailed audit logs of all actions taken by users in your organization 
        (e.g., viewing a record, sending a record, etc.). Information such as the IP address of the 
        user taking the action is recorded and displayed here.
      `,
      img: AuditLogs,
      divider: true,
    },
    {
      title: 'Departments',
      id: 'departments',
      subtitle: `
        View, add, and edit your existing departments. 
        Departments are groups of users and can be helpful to keep records organized within your organization. 
        For example, you may wish to separate out different fax numbers into different departments, such as for a 
        billing fax number and a main fax number. Or you may wish to have a separate department for each additional 
        office location if you have multiple offices.
      `,
      img: AdminDepartments,
      divider: true,
    },
    {
      title: 'Fax Numbers',
      id: 'faxNums',
      subtitle: `
        View, assign, or re-assign fax numbers to users, departments, or the entire organization. 
        Users will only be able to see records sent to the fax number they, or the department they 
        are in, are associated with.
      `,
      img: AdminFaxNums,
      divider: true,
    },
    {
      title: 'Users',
      id: 'users',
      subtitle: `
        Add new users, revoke users, edit user email addresses, reset passwords, or edit a 
        user’s department assignment(s).
      `,
      img: AdminUsers,
      divider: true,
    },
    {
      title: 'Manage Organization Domains',
      id: 'organizationDomains',
      subtitle: `
        View registered domains or add a new domain. 
        For security purposes, you can only add users with an email from your list of domains.
      `,
      img: AdminDomains,
      divider: true,
    },
    {
      title: 'Analytics',
      id: 'analytics',
      subtitle: `
        View records sent, records received, page volumes, and view counts over time.
      `,
      img: AdminAnalytics,
      divider: true,
    },
  ],
};

export default adminTools;
