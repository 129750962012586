// InstallMedsender component displays the modal content.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

import ModalWrapper from './ModalWrapper';
import { validEmail } from '../../global/validation';

// Custom style for the modal
const customContentStyle = {
  width: '35%',
  textAlign: 'center',
  disabled: 'true',
};

class AddNewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      emailError: null,
      nameError: null,
      errorMessage: '',
      domainMatches: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { hideModal } = this.props;
    if (nextProps.newUser.email) {
      setTimeout(() => {
        hideModal();
      }, 1500);
    }
  }

  handleSubmit = async () => {
    const userDomains = JSON.parse(localStorage.getItem('allowed_user_domains'));
    const { name, email, domainMatches } = this.state;
    const { addNewUser } = this.props;

    if (!(validEmail(email) && name.length > 1)) {
      return this.setState({
        emailError: validEmail(email) ? null : 'Email is not valid',
        nameError: name.length > 1 ? null : 'Should be 2 or more characters',
      });
    }

    if (!userDomains.includes(email.split('@')[1]) && domainMatches) {
      return this.setState({
        domainMatches: false,
      });
    }

    const user = { email, name };
    await addNewUser(user);
    const { errorStatus: { error, errorMessage } } = this.props;
    if (error) {
      return this.setState({
        name: '',
        email: '',
        emailError: null,
        errorMessage,
      });
    }
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      emailError: null,
      nameError: null,
    });
  }

  render() {
    const { hideModal, errorStatus: { error }, isLoading, newUser } = this.props;
    const { emailError, nameError, errorMessage, domainMatches } = this.state;
    const isUserCreated = newUser.name && newUser.email;
    const isCreatingUser = (isLoading || newUser.email);

    return (
      <div>
        <ModalWrapper
          hideModal={hideModal}
          customContentStyle={customContentStyle}
          dismiss={!isCreatingUser ? 'Cancel' : ''}
          action={this.handleSubmit}
          actionName={!isCreatingUser ? 'Submit' : ''}
          modalTitle="Create User"
        >
          <div>
            {isLoading && <p> Creating user. Please wait ... </p>}
            {!isLoading && !isUserCreated && (domainMatches || error)
              && (
                <>
                  <p style={{ textAlign: 'left' }}>
                    New users will be invited via email.
                  </p>
                  {error && <p style={{ textAlign: 'left', fontSize: '14px', color: 'red' }}>{errorMessage}</p>}
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                      name="email"
                      onChange={this.handleInputChange}
                      hintText="name@example.com"
                      floatingLabelText="Email"
                      floatingLabelFixed={true}
                      floatingLabelStyle={{ color: '#4A148C' }}
                      errorText={emailError}
                      errorStyle={{ textAlign: 'left' }}
                      style={{ width: '200px' }}
                      type="text"
                    />
                    <TextField
                      name="name"
                      onChange={this.handleInputChange}
                      hintText="name"
                      floatingLabelText="Name"
                      floatingLabelFixed={true}
                      floatingLabelStyle={{ color: '#4A148C' }}
                      errorText={nameError}
                      errorStyle={{ textAlign: 'left' }}
                      style={{ width: '200px' }}
                      type="text"
                    />
                  </div>
                </>
              )
            }
            {!domainMatches && !isUserCreated && !isLoading && !error && <p>Domain does not match. Are you sure you want to proceed?</p>}
            {isUserCreated && <p>{`${newUser.email} has been successfully created.`}</p>}
          </div>
        </ModalWrapper>
      </div>
    );
  }
}

AddNewUser.propTypes = {
  hideModal: PropTypes.func.isRequired,
  addNewUser: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorStatus: PropTypes.object.isRequired,
  newUser: PropTypes.object.isRequired,
};

export default AddNewUser;
