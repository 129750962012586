/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
  Tooltip,
  Spin,
  Switch,
} from 'antd';

class ToggleFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featureFlags: [
        {
          name: 'Publish Forms', id: 'publish_forms', description: 'Allows users to publish forms in the uploads view. This creates a unique link for the record that users can embed or share for patients to fill out forms. Form submissions are viewable in the uploads view.', enabled: false,
        },
        {
          name: 'Personalized Address Book', id: 'personalized_address_book', description: 'Allows the address book to be assigned to the user instead of the whole org.', enabled: false,
        },
        {
          name: 'Admin Lock Labels', id: 'manage_label', description: 'When enabled, only admins can create/edit/delete labels.', enabled: false,
        },
        {
          name: 'Admin Lock Address Book', id: 'admin_lock_address_book', description: 'If enabled, only Admins can create, edit, or delete contacts from the address book.', enabled: false,
        },
        {
          name: 'Admin Lock Categories', id: 'manage_category', description: 'If enabled, only Admins can create, edit, or delete document categories.', enabled: false,
        },
        {
          name: 'Department Labels', id: 'department_labels', description: 'Allows labels to be on a per department basis rather for the whole org', enabled: false,
        },
        {
          name: 'Flatten Annotations', id: 'flatten_annotations', description: 'When the user saves changes to a document, those changes become permanent and can’t be removed. Useful for signatures if the user doesn’t want the signature to be able to be removed by others in the future.', enabled: false,
        },
        {
          name: 'Add Name And Date To Signature', id: 'add_name_and_date_to_signature', description: 'When signing a document in record viewer we will add the name of the user and date to the signature. For example, it’d say “Signed By Test User on 4/3/2024.', enabled: false,
        },
        {
          name: 'Default To Dept Name', id: 'default_to_dept_name', description: 'If a fax number is owned by a department, when sending a fax we’ll use the departments name rather than the organizations name to list on the coversheet we generate.', enabled: false,
        },
        {
          name: 'Remove Patient Info In Coversheet', id: 'remove_patient_info_in_coversheet', description: 'We will no longer include the patient’s name and DOB in the coversheet we generate when the user sends a fax.', enabled: false,
        },
      ],
    };
  }

  componentWillMount = async () => {
    const activeFeatures = this.props.allActiveFeatures;

    const { featureFlags } = this.state;
    featureFlags.forEach((feature, index) => {
      if (activeFeatures.includes(feature.id)) {
        featureFlags[index].enabled = true;
      }
    });

    if (activeFeatures.includes('direct_faxes')) {
      featureFlags.push({
        name: 'Hide Coversheet', id: 'hide_coversheet', description: 'Displays a checkbox in the send dialog after typing in a fax number to remove our coversheet from the fax.', enabled: activeFeatures.includes('hide_coversheet'),
      });
    }
    if (activeFeatures.includes('referral_management')) {
      featureFlags.push({
        name: 'Only show referrals in Referral Management', id: 'hide_referrals', description: 'Will no longer show documents categorized as “referral” in the inbox and only show them in the “referral management” view.', enabled: activeFeatures.includes('hide_referrals'),
      });
    }

    this.setState({ featureFlags });
  }

  handleToggle = async (id, checked) => {
    const { featureFlags } = this.state;
    const updatedFlags = featureFlags.map((flag) => {
      return flag.id === id ? { ...flag, enabled: checked } : flag;
    });

    this.setState({ featureFlags: updatedFlags });

    if (checked) {
      await this.props.enableFeature(id);
    } else {
      await this.props.disableFeature(id);
    }
  };

  render() {
    const {
      featureFlags,
    } = this.state;
    const {
      isLoading,
    } = this.props;

    const columns = [
      {
        title: 'Features',
        dataIndex: 'name',
        key: 'name',
        render: (feature) => {
          return (
            <Tooltip key={feature}>
              {feature}
            </Tooltip>
          );
        },
      },
      {
        title: 'Feature Description',
        dataIndex: 'description',
        key: 'description',
        render: (description) => {
          return (
            <Tooltip key={description}>
              {description}
            </Tooltip>
          );
        },
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (enabled, record) => (
          <Switch
            checked={enabled}
            onChange={checked => this.handleToggle(record.id, checked)}
          />
        ),
      },
    ];

    if (isLoading) return (<Spin size="large" />);

    return (
      // eslint-disable-next-line object-curly-newline
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <h2>Toggle Features</h2>
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            dataSource={featureFlags}
            columns={columns}
            rowKey={feature => feature.id}
            bordered
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

ToggleFeatures.defaultProps = {
  isLoading: false,
};

ToggleFeatures.propTypes = {
  isLoading: PropTypes.bool,
  allActiveFeatures: PropTypes.array.isRequired,
  enableFeature: PropTypes.func.isRequired,
  disableFeature: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.userProfile.isLoading,
  allActiveFeatures: state.userProfile.allActiveFeatures,
}))(ToggleFeatures);
