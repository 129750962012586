import moment from 'moment-timezone';
import { handleError } from '../global/error_handler';

const handlingErrors = (statusCode, customMessage) => {
  if (statusCode === 500) {
    return `Oh no! We're currently experiencing issues on our end. Please try again.`;
  }
  if (statusCode === 520) {
    return `An unexpected error occured, please try again.`;
  }
  if (statusCode === 404) {
    return `Whoops! The file you're looking for can't be found!`;
  }
  if (statusCode === 400) {
    return `We were unable to process that request. Please try again.`
  }
  return customMessage || 'Oops, we ran into an issue. Please try again.';
};

const initialState = {
  isLoading: false,
  retrying: false,
  retryAttemptNumber: 0,
  data: [],
  uploaded_data: [],
  sent_data: [],
  received_data: [],
  assigned_data: [],
  referred_data: [],
  department_users: [],
  archived_records: [],
  currentRecord: null,
  patientName: '',
  patientDOB: '',
  patientGender: '',
  patientAddress: '',
  patientZipCode: '',
  patientCity: '',
  practitionerName: '',
  patientPhoneNumber: '',
  referralNote: '',
  secure_link: '',
  secure_signed_url: '',
  fileToken: '',
  sendToken: null,
  // uploaded, requested, or sent?
  mode: null,
  error: false,
  errorMessage: null,
  archiveErrorMessage: '',
  canSend: false,
  guestAccess: '',
  emailValidMessage: '',
  updatedTag: false,
  privacyLevel: '',
  canEditPatientInfo: false,
  canEditPrivacyLevel: false,
  hasResponse: false,
  canEditRecord: true,
  recordHasUnsavedEdits: false,
  signatureData: [],
  attachmentsData: [],
  signatureRequired: false,
  shouldFlatten: false,
  nameToSignatureRequired: false,
  isFormDocument: false,
  isFormSubmission: false,
  isEmailSubmission: false,
  documentType: 'medical_record',
  updatedLabel: false,
  updatedStatus: false,
  selectedLabels: [],
  receiverType: null,
  hasWrongExtractionBeenReported: false,
  hasWrongLabelingBeenReported: false,
  isLabelEdited: false,
  numPages: null,
  archived: false,
  senderContact: '',
  senderName: '',
  senderOrganization: '',
  receivedAt: '',
  callerName: '',
  createdAt: '',
  nextSendToken: null,
  nextFileToken: null,
  assignedRecord: {},
  currentReferralStatus: null,
};

const failedState = {
  ...initialState,
  error: true,
};

function records(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGGED_OUT':
      return initialState;
    case 'BEGIN_GET_UPLOADED_RECORDS':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'uploaded',
        errorMessage: null,
      };
    case 'FAILED_GET_UPLOADED_RECORDS':
      return {
        ...failedState,
        mode: 'uploaded',
        errorCode: action.payload.response && action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get uploaded records'),
      };
    case 'FINISH_GET_UPLOADED_RECORDS': {
      if (typeof action.payload.data === 'object' && !Array.isArray(action.payload.data) && action.payload.data !== null) {
        return {
          ...state,
          isLoading: false,
          data: [],
          mode: 'uploaded',
          uploaded_data: [],
          currentRecord: null,
          errorMessage: action.payload.data.message,
        };
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        uploaded_data: action.payload.data,
        headers: action.payload.headers,
        mode: 'uploaded',
        currentRecord: null,
      };
    }
    case 'BEGIN_DUPLICATE_RECORD':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'uploaded',
        errorMessage: null,
      };
    case 'FAILED_DUPLICATE_RECORD':
      return {
        ...failedState,
        mode: 'uploaded',
        errorCode: action.payload.response && action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to copy record'),
      };
    case 'FINISH_DUPLICATE_RECORD':
      return {
        ...state,
        isLoading: false,
        mode: 'uploaded',
        currentRecord: null,
        fileToken: action.payload.data.file_token,
      };
    case 'RETRIEVED_RECORD':
      return {
        ...state,
        isLoading: false,
        currentRecord: action.payload,
      };
    case 'BEGIN_GET_SINGLE_UPLOADED_RECORD':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'uploaded',
        errorMessage: null,
        patientName: '',
        patientDOB: '',
        patientGender: '',
        patientPhoneNumber: '',
        patientAddress: '',
        patientZipCode: '',
        patientCity: '',
        practitionerName: '',
      };
    case 'FAILED_GET_SINGLE_UPLOADED_RECORD':
      return {
        ...failedState,
        uploaded_data: state.uploaded_data,
        sent_data: state.sent_data,
        errorMessage: handlingErrors(action.payload.response.status, 'Unable to access record'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_SINGLE_UPLOADED_RECORD':
      return {
        ...state,
        uploaded_data: state.uploaded_data,
        sent_data: state.sent_data,
        isLoading: false,
        mode: 'uploaded',
        fileToken: action.payload.data.file_token,
        currentRecord: action.payload.data.signed_url,
        sendToken: action.payload.data.send_token,
        patientName: action.payload.data.patient_name,
        patientDOB: action.payload.data.patient_dob,
        patientGender: action.payload.data.patient_gender,
        patientPhoneNumber: action.payload.data.patient_phone_number,
        patientAddress: action.payload.data.patient_address,
        patientZipCode: action.payload.data.patient_zip_code,
        referralNote: action.payload.data.referral_note,
        referringProvider: action.payload.data.referring_provider,
        referringProviderOffice: action.payload.data.provider_office,
        patientCity: action.payload.data.patient_city,
        fileTitle: action.payload.data.document_title,
        patientState: action.payload.data.patient_state,
        patientEmail: action.payload.data.patient_email,
        patientFirstName: action.payload.data.patient_first_name,
        patientLastName: action.payload.data.patient_last_name,
        referringProvider: action.payload.data.referring_provider,
        providerOffice: action.payload.data.provider_office,
        primaryPlan: action.payload.data.primary_plan,
        primaryMemberId: action.payload.data.primary_member_id,
        practitionerName: action.payload.data.practitioner_name,
        selectedLabels: action.payload.data.labels,
        canSend: action.payload.data.can_send,
        privacyLevel: action.payload.data.privacy_level,
        canEditPrivacyLevel: action.payload.data.can_edit_privacy_level,
        canEditPatientInfo: action.payload.data.can_edit_patient_info,
        canEditRecord: action.payload.data.can_edit,
        isFormDocument: action.payload.data.is_form_document,
        isFormSubmission: action.payload.data.is_jotorm_submission,
        isEmailSubmission: action.payload.data.is_email_submission,
        senderContact: action.payload.data.sender_contact,
        senderName: action.payload.data.sender_name,
        senderOrganization: action.payload.data.sender_organization,
        receiverType: action.payload.data.receiver_type,
        aiStatus: action.payload.data.ai_status,
        hasWrongExtractionBeenReported:
          action.payload.data.has_wrong_extraction_been_reported,
        hasWrongLabelingBeenReported:
          action.payload.data.has_wrong_labeling_been_reported,
        patientInfoEdited: action.payload.data.patient_info_edited,
        isLabelEdited: action.payload.data.is_label_edited,
        failedColumn: action.payload.data.failed_column,
        numPages: action.payload.data.num_pages,
        archived: action.payload.data.archived,
        receivedAt: action.payload.data.received_at,
        callerName: action.payload.data.caller_name,
        documentTitle: action.payload.data.document_title,
        documentCategory: action.payload.data.document_category,
        createdAt: action.payload.data.created_at,
      };
    case 'BEGIN_GET_SENT_RECORDS':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'sent',
        errorMessage: null,
      };
    case 'FAILED_GET_SENT_RECORDS':
      return {
        ...failedState,
        mode: 'sent',
        errorMessage: handlingErrors(action.payload.response.status, 'failed to get sent records'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_SENT_RECORDS': {
      if (typeof action.payload.data === 'object' && !Array.isArray(action.payload.data) && action.payload.data !== null) {
        return {
          ...state,
          isLoading: false,
          data: [],
          mode: 'sent',
          currentRecord: null,
          errorMessage: action.payload.data.message,
        };
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        sent_data: action.payload.data,
        mode: 'sent',
        headers: action.payload.headers,
        currentRecord: null,
      };
    }
    case 'BEGIN_ARCHIVE_RECORDS':
      return {
        ...state,
        isLoading: true,
        error: false,
        archiveErrorMessage: '',
      };
    case 'FAILED_ARCHIVE_RECORDS':
      return {
        ...failedState,
        received_data: state.received_data,
        archiveErrorMessage: action.payload.response.data.error || action.payload.response.statusText,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to get archived records.'),
      };
    case 'FINISHED_ARCHIVE_RECORDS':
      return {
        ...state,
        isLoading: false,
      };
    case 'BEGIN_UNARCHIVE_RECORD':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'FAILED_UNARCHIVE_RECORD':
      return {
        ...failedState,
        received_data: state.received_data,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to get archived records.'),
      };
    case 'FINISHED_UNARCHIVE_RECORD':
      return {
        ...state,
        isLoading: false,
      };
    case 'FINISHED_REVOKE_RECORD':
      return {
        ...state,
        isLoading: false,
        mode: 'uploaded',
      };
    case 'BEGIN_UPLOAD_RECORD':
      return {
        ...state,
        currentRecord: null,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_UPLOAD_RECORD': {
      const error = action.payload;
      const errorMessage = error.response.status === 409
        ? handleError(error) : 'Failed to upload record. Please try again.';
      return {
        ...failedState,
        errorMessage,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISHED_UPLOAD_RECORD':
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case 'BEGIN_REVOKE_ACCESS':
      return {
        ...state,
        isLoading: true,
        error: false,
        mode: 'revoke',
        errorMessage: null,
      };
    case 'FAILED_REVOKE_ACCESS':
      return {
        ...failedState,
        uploaded_data: state.uploaded_data,
        sent_data: state.sent_data,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to revoke access.'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_REVOKE_ACCESS':
      return {
        ...state,
        isLoading: false,
        data: [],
        mode: 'sent',
        currentRecord: null,
        errorMessage: action.payload.data.message,
      };
    case 'REMOVE_SENT_RECORD':
      /**
       * Removes the removed sent record from the sent data
       * if the file token and the receiver matches the record is filtered out
       */
      return {
        ...state,
        sent_data: state.sent_data
          .map(record => ((record.file_token === action.payload.recordId && record.receiver_fax === action.payload.receiver)
            ? { ...record, record_status: 'revoked' }
            : record)),
      };
    case 'REMOVE_RECEIVED_RECORDS':
      return {
        ...state,
        received_data: state.received_data.filter((row) => {
          return !state.archived_records.includes(row.file_token);
        }),
      };
    case 'BEGIN_GET_RECEIVED_RECORDS':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'received',
        errorMessage: null,
      };
    case 'FAILED_GET_RECEIVED_RECORDS':
      return {
        ...failedState,
        mode: 'received',
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get received records.'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_RECEIVED_RECORDS':
      // If there are no records, the response is an object with a message key.
      // Here we check if the data is an object (signifying a success/error message)
      // In this case, return an empty array
      if (typeof action.payload.data === 'object' && !Array.isArray(action.payload.data) && action.payload.data !== null) {
        return {
          ...state,
          isLoading: false,
          data: [],
          received_data: [],
          mode: 'received',
          currentRecord: null,
          errorMessage: action.payload.data.message,
        };
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        received_data: action.payload.data,
        headers: action.payload.headers,
        mode: 'received',
        currentRecord: null,
      };
    case 'BEGIN_GET_REFERRAL_STATUS':
      return {
        ...state,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_REFERRAL_STATUS':
      return {
        ...failedState,
        error: true,
        errorMessage: 'Failed to retrieve referral details.',
      };
    case 'FINISHED_GET_REFERRAL_STATUS':
      return {
        ...state,
        currentReferralStatus: action.payload.data.document_status?.referral_status_id,
      };
    case 'BEGIN_UPDATE_ASSIGNED_RECORD_STATUS':
      return {
        ...state,
        error: false,
      };
    case 'FAILED_UPDATE_ASSIGNED_RECORD_STATUS':
      return {
        ...failedState,
        mode: 'assigned',
        errorMessage: 'There was an issue updating the status. Please try again.',
      };
    case 'FINISHED_UPDATE_ASSIGNED_RECORD_STATUS':
      return {
        ...state,
        isLoading: false,
        updatedStatus: true,
        mode: 'assigned',
      };
    case 'BEGIN_UPDATE_REFERRAL_STATUS':
      return {
        ...state,
        error: false,
      };
    case 'FAILED_UPDATE_REFERRAL_STATUS':
      return {
        ...failedState,
        mode: 'referral',
        errorMessage: 'There was an issue updating the status. Please try again.',
      };
    case 'FINISHED_UPDATE_REFERRAL_STATUS':
      return {
        ...state,
        isLoading: false,
        updatedStatus: true,
        mode: 'referral',
      };
    case 'BEGIN_GET_ASSIGNED_RECORDS':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'assigned',
        errorMessage: null,
      };
    case 'FAILED_GET_ASSIGNED_RECORDS':
      return {
        ...failedState,
        mode: 'assigned',
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get assigned records.'),
        errorCode: action.payload.response.status,
      };
    case 'FINISHED_GET_ASSIGNED_RECORDS':
      // If there are no records, the response is an object with a message key.
      // Here we check if the data is an object (signifying a success/error message)
      // In this case, return an empty array
      if (typeof action.payload.data === 'object' && !Array.isArray(action.payload.data.assigned_documents) && action.payload.data !== null) {
        return {
          ...state,
          isLoading: false,
          data: [],
          assigned_data: [],
          department_users: [],
          mode: 'assigned',
          currentRecord: null,
          errorMessage: action.payload.data.message,
        };
      }
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        assigned_data: action.payload.data.assigned_documents,
        department_users: action.payload.data.department_users,
        headers: action.payload.headers,
        mode: 'assigned',
        currentRecord: null,
      };
    case 'BEGIN_GET_ASSIGNED_RECORD':
      return {
        ...state,
        isLoading: true,
        assignedRecord: {},
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_ASSIGNED_RECORD':
      return {
        ...failedState,
        error: true,
        errorMessage: 'There was an issue getting the assigned record. Please try again.',
      };
    case 'FINISHED_GET_ASSIGNED_RECORD':
      return {
        ...state,
        isLoading: false,
        assignedRecord: action.payload.data.assigned_document,
      };
    case 'BEGIN_GET_SINGLE_RECORD_STATUS':
      return {
        ...state,
        isLoading: true,
        currentRecord: null,
        error: false,
        mode: 'sent',
        errorMessage: null,
      };
    case 'FAILED_GET_SINGLE_RECORD_STATUS': {
      const { data: response } = action.payload.response;
      // Checking for status of faxes that don't exist
      if (action.payload.response.status === 400) {
        return {
          ...failedState,
          uploaded_data: state.uploaded_data,
          sent_data: state.sent_data,
          errorMessage: handlingErrors(action.payload.response.error, 'There is no status for this record'),
          errorCode: action.payload.response.status,
        };
      }
      return {
        ...failedState,
        uploaded_data: state.uploaded_data,
        sent_data: state.sent_data,
        errorMessage: response.message || response.error,
      };
    }
    case 'FINISHED_GET_SINGLE_RECORD_STATUS': {
      // If no fax status is available, show as error message
      if (!action.payload.data.receiver) {
        return {
          ...state,
          isLoading: false,
          errorMessage: 'The fax status for this record has not yet been made available',
          currentRecordStatus: null,
        };
      }
      return {
        ...state,
        isLoading: false,
        mode: 'sent',
        currentRecordStatus: action.payload.data,
      };
    }
    case 'FINISHED_PRINT_USER_RECORD': {
      return {
        ...state,
        downloadUrl: action.payload.data.signed_url,
      };
    }    
    case 'BEGIN_PRINT_GUEST_RECORD': {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case 'FINISHED_PRINT_GUEST_RECORD': {
      return {
        ...state,
        isLoading: false,
        guestAccessUrl: action.payload.data.signed_url,
      };
    }
    case 'FAILED_PRINT_GUEST_RECORD': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to print guest record'),
        errorCode: action.payload.response.status,
      };
    }
    case 'BEGIN_SUBMIT_FORM': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FINISHED_SUBMIT_FORM': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_SUBMIT_FORM': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'BEGIN_CREATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FINISHED_CREATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_CREATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'BEGIN_UPDATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FINISHED_UPDATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_UPDATE_ASSIGNED_RECORD': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'BEGIN_UPDATE_RECORD': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FINISHED_UPDATE_RECORD': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_UPDATE_RECORD': {
      return {
        ...failedState,
        uploaded_data: state.uploaded_data,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.status, 'Unable to update record. Please try again.'),
      };
    }
    case 'BEGIN_UPDATE_RECORD_NOTE': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FINISHED_UPDATE_RECORD_NOTE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_UPDATE_RECORD_NOTE': {
      return {
        ...failedState,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.status, 'Unable to update record. Please try again.'),
      };
    }
    case 'BEGIN_EDIT_NOTIFY': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
        updatedTag: false,
      };
    }
    case 'FINISHED_EDIT_NOTIFY': {
      const success = action.payload.status !== 204;
      const emailValidMessage = `We couldn't find that user in your organization. To send to that email, try using the send button instead.`
      return {
        ...state,
        isLoading: false,
        updatedTag: success,
        emailValidMessage: success ? action.payload.data.message : emailValidMessage,
      };
    }
    case 'FAILED_EDIT_NOTIFY': {
      return {
        ...failedState,
        updatedTag: false,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_EDIT_RECEIVED_RECORD_LABELS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FINISHED_EDIT_RECEIVED_RECORD_LABELS': {
      return {
        ...state,
        updatedLabel: true,
        isLoading: false,
      };
    }
    case 'FAILED_EDIT_RECEIVED_RECORD_LABELS': {
      return {
        ...failedState,
        errorMessage: handlingErrors(action.payload.response.status || '500', 'Unable to update record. Please try again.'),
      };
    }
    case 'CHANGE_SELECTED_LABELS': {
      return {
        ...state,
        selectedLabels: [],
      };
    }
    case 'UPDATE_UPLOADED_RECORDS': {
      // Finds the record  that needs to be updated
      const {
        recordId,
        patientName,
        patientDOB,
        mode,
        privacyLevel,
        incorrectPatientInfo,
        documentTitle,
        documentCategory,
        referringProvider,
        referringProviderOffice,
      } = action.payload;
      const DOB = patientDOB && moment(patientDOB).format('MM/DD/YYYY');
      const dataToUpdate = `${mode}_data`;
      return {
        ...state,
        [dataToUpdate]: state[dataToUpdate].map((data) => {
          if (data.file_token === recordId) {
            return {
              ...data,
              patient_name: patientName,
              patient_dob: DOB,
              privacy_level: privacyLevel,
              has_wrong_extraction_been_reported: incorrectPatientInfo,
              patient_info_edited: true,
              document_title: documentTitle,
              document_category: documentCategory,
              referring_provider: referringProvider,
              provider_office: referringProviderOffice,
            };
          }
          return data;
        }),
        patientName,
        privacyLevel,
        patientDOB: DOB,
        documentTitle,
        documentCategory,
        referringProvider,
        referringProviderOffice,
        hasWrongExtractionBeenReported: state.hasWrongExtractionBeenReported || incorrectPatientInfo,
      };
    }
    case 'UPDATE_RECEIVED_RECORDS': {
      const {
        sendToken, tag, labels, isLabelIncorrect,
      } = action.payload;
      const infoToUpdate = tag || labels;
      const stateToUpdate = tag ? 'tag' : 'labels';
      const objectType = state.data.assigned_documents ? 'assigned_data' : 'received_data';
      const objects = state.data.assigned_documents ? state.assigned_data : state.received_data;
      return {
        ...state,
        [objectType]: objects.map((data) => {
          if (data.send_token === sendToken || sendToken.includes(data.send_token)) {
            return {
              ...data,
              [stateToUpdate]: infoToUpdate,
              has_wrong_labeling_been_reported: isLabelIncorrect,
              is_label_edited: stateToUpdate === 'labels',
            };
          }
          return data;
        }),
        tag,
        hasWrongLabelingBeenReported: isLabelIncorrect,
        isLabelEdited: stateToUpdate === 'labels',
      };
    }
    case 'UPDATE_RECEIVED_REPLY': {
      const { sendToken } = action.payload;
      return {
        ...state,
        received_data: state.received_data.map((data) => {
          if (data.send_token === sendToken || sendToken.includes(data.send_token)) {
            return {
              ...data,
              viewed: true,
            };
          }
          return data;
        }),
      };
    }
    case 'BEGIN_GET_RECORD_LINK': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
        secure_link: '',
      };
    }
    case 'FINISHED_GET_RECORD_LINK': {
      return {
        ...state,
        isLoading: false,
        secure_link: action.payload.data.secure_link,
      };
    }
    case 'FAILED_GET_RECORD_LINK': {
      return {
        ...failedState,
        isLoading: false,
        secure_link: '',
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to get record link'),
      };
    }
    case 'BEGIN_GET_SECURE_URL': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: '',
        secure_signed_url: '',
        hasResponse: false,
      };
    }
    case 'FINISHED_GET_SECURE_URL': {
      return {
        ...state,
        isLoading: false,
        canEditRecord: action.payload.data.can_edit,
        secure_signed_url: action.payload.data.signed_url,
        documentType: action.payload.data.document_type || state.documentType,
        subject: action.payload.data.subject,
        recipient_email: action.payload.data.recipient_email,
        recipient_name: action.payload.data.recipient_name,
        send_token: action.payload.data.send_token,
        hasResponse: action.payload.data.has_reply,
        signatureRequired: action.payload.data.signature_required,
        shouldFlatten: action.payload.data.should_flatten,
        nameToSignatureRequired: action.payload.data.name_to_signature_required,
      };
    }
    case 'FAILED_GET_SECURE_URL': {
      return {
        ...failedState,
        isLoading: false,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
      };
    }
    case 'BEGIN_PRINT_SECURE_URL': {
      return {
        ...state,
      };
    }
    case 'FINISHED_PRINT_SECURE_URL': {
      return {
        ...state,
        isLoading: false,
        printUrl: action.payload.data.signed_url,
      };
    }
    case 'FAILED_PRINT_SECURE_URL': {
      return {
        ...failedState,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error),
      };
    }
    case 'BEGIN_MERGE_RECORDS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_MERGE_RECORDS': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to merge records. Please try again'),
      };
    }
    case 'FINISHED_MERGE_RECORDS': {
      return {
        ...state,
        isLoading: false,
        uploaded_data: [action.payload.data, ...state.uploaded_data],
      };
    }
    case 'BEGIN_MERGE_REFERRALS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_MERGE_REFERRALS': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to merge referrals. Please try again'),
      };
    }
    case 'FINISHED_MERGE_REFERRALS': {
      return {
        ...state,
        isLoading: false,
        received_data: [action.payload.data, ...state.received_data],
      };
    }
    case 'BEGIN_RESEND_RECORD': {
      return {
        ...state,
        isLoading: true,
        isResendingUnviewed: true,
      };
    }
    case 'FINISHED_RESEND_ONE_RECORD': {
      // The record that was resent is the most recent, which is why
      // it is the 0th index in the data returned by Rails
      const { file_token: resentFileToken, send_token } = action.payload.data.sent_data[0];
      const { sent_data: dataCurrentlyOnPage } = state;
      return {
        ...state,
        isLoading: false,
        isResendingUnviewed: false,
        // After a record is resent, the send token, which allows users to view the status of a
        // record, changes. In order for users to view the status of the latest resend, we need to
        // update the frontend values with the new send token sent by Rails, that way a refresh is
        // not required
        //
        // Loop through all data on page and update record that was resent
        sent_data: dataCurrentlyOnPage.map((row) => {
          if (row.file_token === resentFileToken) {
            return {
              ...row,
              send_token, // Replace with new send token
            };
          }
          return row;
        }),
      };
    }
    case 'FINISHED_RESEND_RECORD': {
      return {
        ...state,
        isLoading: false,
        isResendingUnviewed: false,
        totalRecordsResent: action.payload.data.number_resent,
      };
    }
    case 'FAILED_RESEND_RECORD': {
      return {
        ...failedState,
        isResendingUnviewed: true,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to resend the record(s)'),
      };
    }
    case 'BEGIN_RESEND_ALL_FAILED_FAXES':
      return {
        ...state,
        isLoading: true,
        isResendingFailed: true,
      };
    case 'FAILED_RESEND_ALL_FAILED_FAXES':
      return {
        ...state,
        isLoading: false,
        isResendingFailed: false,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to resend the records'),
      };
    case 'FINISHED_RESEND_ALL_FAILED_FAXES':
      return {
        ...state,
        // Modify failed faxes to have status 'inprogress'.
        sent_data: state.sent_data.map(x => ({
          ...x,
          fax_status: ['failure', 'partialsuccess'].includes(x.fax_status) && x.fax_error_message !== 'No fax tone detected' ? 'inprogress' : x.fax_status,
        })),
        isLoading: false,
        isResendingFailed: false,
        totalRecordsResent: action.payload.data.number_resent,
      };
    case '@@router/LOCATION_CHANGE': {
      const { pathname } = action.payload;
      if (!pathname.includes('app/view/')) {
        return {
          ...state,
          patientName: '',
          patientDOB: '',
          secure_link: '',
          secure_signed_url: '',
          retrying: false,
        };
      }
      return state;
    }
    case 'PROGRESS_DOWNLOAD_RECORDS_ZIP':
      return {
        ...state,
        total: action.payload.total,
        progress: action.payload.loaded,
      };
    case 'FAILED_DOWNLOAD_RECORDS_ZIP':
      return {
        ...state,
        total: 0,
        progress: 0,
        downloadError: action.payload.error,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Failed to download records.'),
      };
    // Equivalent to FINISHED_RECORDS_DOWNLOAD_ZIP
    // This is used because the values should be reset after the download dialog is closed.
    // Resetting before the values close would show wrong values in the progress indicator.
    case 'HIDE_MODAL':
      return {
        ...state,
        total: 0,
        progress: 0,
        downloadError: undefined,
      };
    case 'SELECTED_ROWS':
      return {
        ...state,
        total: 0,
        progress: 0,
        downloadError: undefined,
      };
    case 'UPDATE_SELECTED_RECORD': {
      return {
        ...state,
        patientName: action.payload.patient_name,
        patientDOB: action.payload.patient_dob,
        privacyLevel: action.payload.privacy_level,
      };
    }
    case 'BEGIN_SAVE_ANNOTATIONS_ON_RECORD': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'FAILED_SAVE_ANNOTATIONS_ON_RECORD': {
      return {
        ...failedState,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.error,
        recordHasUnsavedEdits: true,
      };
    }
    case 'FINISHED_SAVE_ANNOTATIONS_ON_RECORD': {
      return {
        ...state,
        isLoading: false,
        fileToken: action.payload.data.file_token,
        recordHasUnsavedEdits: false,
        currentRecord: action.payload.data.signed_url,
      };
    }
    case 'BEGIN_GET_NEXT_RECORD':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FINISHED_GET_NEXT_RECORD':
      return {
        ...state,
        nextSendToken: action.payload.data.send_token,
        nextFileToken: action.payload.data.file_token,
        isLoading: false,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_NEXT_RECORD':
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
        error: true,
        errorCode: action.payload.response.status,
      };
    case 'SET_UNSAVED_EDITS_ON_RECORD': {
      return {
        ...state,
        recordHasUnsavedEdits: true,
      };
    }
    case 'BEGIN_SAVE_SIGNATURE': {
      return {
        ...state,
      };
    }
    case 'FAILED_SAVE_SIGNATURE': {
      return {
        ...failedState,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.error,
      };
    }
    case 'FINISHED_SAVE_SIGNATURE': {
      return {
        ...state,
      };
    }
    case 'BEGIN_SPLIT_RECORD':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_SPLIT_RECORD':
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data.error,
      };
    case 'FINISH_SPLIT_RECORD':
      return {
        ...state,
        isLoading: false,
        fileToken: action.payload.data.file_token,
      };
    case 'BEGIN_GET_SIGNATURE': {
      return {
        ...state,
      };
    }
    case 'FAILED_GET_SIGNATURE': {
      return {
        ...failedState,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.error,
      };
    }
    case 'FINISHED_GET_SIGNATURE': {
      return {
        ...state,
        signatureData: action.payload.data,
      };
    }
    case 'BEGIN_GET_ATTACHMENTS': {
      return {
        ...state,
      };
    }
    case 'FAILED_GET_ATTACHMENTS': {
      return {
        ...failedState,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.error,
      };
    }
    case 'FINISHED_GET_ATTACHMENTS': {
      return {
        ...state,
        attachmentsData: action.payload.data,
      };
    }
    case 'RETRYING_REQUEST': {
      return {
        ...state,
        retrying: true,
        retryAttemptNumber: action.payload.retries,
      };
    }
    case 'RETRYING_FAILED': {
      return {
        ...state,
        retrying: false,
        retryAttemptNumber: 0,
      };
    }
    case 'BEGIN_UPLOAD_TO_GOOGLE_DRIVE': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_UPLOAD_TO_GOOGLE_DRIVE': {
      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
      };
    }
    case 'FINISH_UPLOAD_TO_GOOGLE_DRIVE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'BEGIN_UPLOAD_TO_SFTP_FOLDER': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_UPLOAD_TO_SFTP_FOLDER': {
      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
      };
    }
    case 'FINISH_UPLOAD_TO_SFTP_FOLDER': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'BEGIN_UPLOAD_TO_SHAREPOINT_FOLDER': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_UPLOAD_TO_SHAREPOINT_FOLDER': {
      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
      };
    }
    case 'FINISH_UPLOAD_TO_SHAREPOINT_FOLDER': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'BEGIN_AUTOUPLOAD': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_AUTOUPLOAD': {
      let errorMessage = action.payload.response.data.error;
      if (action.payload.response.status === 422) {
        // Assume EMR credential does not exist.

        // Get org name
        let orgName = localStorage.getItem('m8_org');
        if (!orgName || orgName === 'undefined') {
          orgName = Date.now().toString(36).slice(0, 6);
        } else {
          orgName = orgName.replace('^A-Za-z0-9', ' ').toLowerCase().replace(/\s+/g, '');
        }
        errorMessage = `In order to enable this feature, please create an account with the email address emr+${orgName}@medsender.com in your EMR and email them to support@medsender.com.`;
      }

      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage,
      };
    }
    case 'FINISH_AUTOUPLOAD': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'BEGIN_SMSMESSAGE': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_SMSMESSAGE': {
      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
      };
    }
    case 'FINISH_SMSMESSAGE': {
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'BEGIN_TASK_CREATION': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    }
    case 'FAILED_TASK_CREATION': {
      let errorMessage = action.payload.response.data.error;
      if (action.payload.response.status === 422) {
        // Assume EMR credential does not exist.

        // Get org name
        let orgName = localStorage.getItem('m8_org');
        if (!orgName || orgName === 'undefined') {
          orgName = Date.now().toString(36).slice(0, 6);
        } else {
          orgName = orgName.replace('^A-Za-z0-9', ' ').toLowerCase().replace(/\s+/g, '');
        }
        errorMessage = `In order to enable task creation, please create an account with the email address emr+${orgName}@medsender.com in your EMR and email them to support@medsender.com.`;
      }

      return {
        ...state,
        error: true,
        isLoading: false,
        errorCode: action.payload.response.status,
        errorMessage,
      };
    }
    case 'FINISH_TASK_CREATION': {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

export default records;
