import {
  purple800,
  purple700,
  deepPurple700,
  purple100,
  white,
  purpleA200,
  purpleA100,
  // grey900,
  // grey600,
  grey400,
  purple200,
  darkBlack,
  fullBlack,
} from 'material-ui/styles/colors';

import { fade } from 'material-ui/utils/colorManipulator';
import { spacing, getMuiTheme } from 'material-ui/styles';

// purpleA200 is the pink accent
const rawBaseTheme = {
  ...spacing,
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color: purple800,
    primary2Color: purple700,
    primary3Color: purple100,
    accent1Color: purpleA200,
    accent2Color: purpleA100,
    accent3Color: purple200,
    textColor: darkBlack,
    alternateTextColor: white,
    canvasColor: white,
    borderColor: grey400,
    disabledColor: fade(darkBlack, 0.3),
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack,
  },
  datePicker: {
    selectColor: purple800,
    headerColor: purple800,
  },
};

export default getMuiTheme(rawBaseTheme);
