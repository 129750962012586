// Revoke record component displays the modal component
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

class RevokeRecord extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      params: this.props.modalType.data,
    };
  }

  /*
   * revokes records updates backend and store
   * hides modal and redirects to sent records page
   * shows toast on success
  */
  revoke = () => {
    const params = this.state.params;
    this.props.revokeRecordAcessById(params.recordId, params.sendToken);
    this.props.hideModal();
    this.context.router.history.push('/app/sent');
    /* global toastr */
    toastr.success('Access to this record has been revoked successfully');
  };

  render() {
    const customContentStyle = {
      width: '25%',
    };
    return (
      <ModalWrapper
        hideModal={this.props.hideModal}
        action={this.revoke}
        customContentStyle={customContentStyle}
        actionName={'Revoke Access'}
        dismiss={'Cancel'}
        modalTitle="Revoke Record Access"
      >
        <span style={{ fontWeight: '600' }}>
          Are you sure you want to revoke access to this record?
          <br /><br />
          <span style={{ fontWeight: '300' }}>
            The recipient will no longer be able to view the record.
          </span>
        </span>
      </ModalWrapper>
    );
  }
}

RevokeRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

RevokeRecord.propTypes = {
  revokeRecordAcessById: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
};


export default RevokeRecord;
