import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AssignRecord from '../../components/modal/AssignRecord';
import { getDocumentStatuses, getUserDepartmentsByQuery } from '../../actions/userProfile';
import { assignDocument, markDocumentAsCompleted } from '../../actions/records';

class AssignRecordDialog extends Component {
  static propTypes = {
    assignDocument: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AssignRecord
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  aiTag: state.auth.data.aiTag,
}),
{
  assignDocument,
  getDocumentStatuses,
  getUserDepartmentsByQuery,
  markDocumentAsCompleted,
})(AssignRecordDialog);
