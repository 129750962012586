// Connects the Archive record modal action

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddRecord from '../../components/modal/AddRecord/';
import { modalLogin, resendModalOtp, startOver } from '../../actions/modal';

class AddRecordDialog extends Component {
  render() {
    return (
      <div>
        <AddRecord {...this.props} />
      </div>

    );
  }
}

AddRecordDialog.propTypes = {
  modalLogin: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    errorStatus: state.modal.errorStatus,
    errorMessage: state.modal.errorMessage,
    loading: state.modal.isLoading,
    loggedIn: state.modal.loggedIn,
    needs2FA: state.modal.needs2FA,
    otpAuthToken: state.modal.otpAuthToken,
    user: state.modal.user,
    fax: state.auth.fax,
    recordSaved: state.modal.recordSaved,
    errorSaving: state.modal.errorSaving,
  })
  , { modalLogin, resendModalOtp, startOver })(AddRecordDialog);
