// Passes on the trial User info from the localStorage

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Referral from '../../components/modal/Referral';
import { rowSelected } from '../../actions/inboxTableActions';
import { sendReferral, clearReferral } from '../../actions/appActions';
import searchRecipient from '../../actions/search';


class ReferralDialog extends Component {
  static propTypes = {
    dataSource: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }

  render() {
    const { isLoading, dataSource } = this.props;
    return (
      <div>
        <Referral
          {...this.props}
          searchLoading={isLoading}
          dataSource={dataSource}
        />
      </div>
    );
  }
}

export default connect(state => ({
  dataSource: state.search.results,
  isLoading: state.search.isLoading,
  status: state.appState.status,
  error: state.appState.error,
}), {
  Referral,
  rowSelected,
  sendReferral,
  searchRecipient,
  clearReferral,
})(ReferralDialog);
