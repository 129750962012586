/* eslint-disable react/no-array-index-key */
import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  message,
  Select,
  Form,
  Input,
  Alert,
} from 'antd';


import { updateReferral, updateRecordReferralNote, getReferralDocumenStatus } from '../../actions/records';
import { getReferredStatuses } from '../../actions/userProfile';
import { verifyFeatureAccess } from '../../global/featureFlags';

import ModalWrapper from './ModalWrapper';

class UpdateReferral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendToken: this.props.modalType.data.sendToken,
      parentType: this.props.modalType.data.parentType,
      fileToken: this.props.modalType.data.fileToken,
      referralStatuses: [],
      referralStatusId: 1,
      referralStatus: null,
      referralNote: this.props.modalType.data.referralNote,
    };
  }

  componentDidMount = async () => {
    const { getReferredStatuses, getReferralDocumenStatus } = this.props;

    const { fileToken, sendToken, parentType } = this.props.modalType.data;

    const canUpdateReferralStatus = await verifyFeatureAccess('referral_management');

    await getReferredStatuses();

    await getReferralDocumenStatus(fileToken, sendToken, parentType);

    const { allReferredStatuses, currentReferralStatus } = this.props;

    const selectedStatus = allReferredStatuses.find(status => status.id === currentReferralStatus);
    this.setState({
      referralStatuses: allReferredStatuses,
      referralStatus: selectedStatus,
      referralStatusId: selectedStatus?.id,
      canUpdateReferralStatus,
    });
  }

  update = async (event) => {
    event.preventDefault();
    const {
      sendToken,
      parentType,
      referralStatus,
      referralNote,
      fileToken,
    } = this.state;

    await this.props.updateReferral(fileToken, sendToken, referralStatus, parentType);
    await this.props.updateRecordReferralNote(fileToken, referralNote || '');
    const { updatedStatus, hideModal } = this.props;
    if (updatedStatus) {
      hideModal();
      message.success({
        content: 'Referral status successfully updated',
        style: { marginTop: '70px' },
      });
    }
  }

  handleChange = (value) => {
    this.setState({ referralStatus: value, referralStatusId: value.id });
  }

  handleNoteChange = (e) => {
    this.setState({ referralNote: e.target.value });
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const {
      referralStatuses,
      referralStatusId,
      referralNote,
      canUpdateReferralStatus,
    } = this.state;

    const disableButton = canUpdateReferralStatus;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.update}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={!disableButton || isLoading}
        modalTitle="Update Referral"
        form="updateReferral"
      >
        <Form
          layout="vertical"
          onFinish={this.update}
          id="updateReferral"
          initialValues={{
            additionalNote: referralNote,
          }}
        >
          {!disableButton && (
            <div style={{ marginBottom: '25px' }}>
              <Alert
                message="Your plan doesn't include access to update statuses for referred records. Please upgrade your plan to make any changes."
                type="info"
                showIcon
              />
            </div>
          )}

          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Referral Status"
              style={{ display: 'flex' }}
            >
              <Select
                style={{ width: '400px' }}
                placeholder="Select Referral Status"
                onChange={(selectedId) => {
                  const selectedStatus = referralStatuses.find(status => status.id === selectedId);
                  this.handleChange(selectedStatus);
                }
              }
                value={referralStatusId}
              >
                {referralStatuses.map(status => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              style={{ width: '400px' }}
              label="Additional Note"
              name="additionalNote"
            >
              <Input.TextArea
                placeholder="Additional Note"
                onChange={this.handleNoteChange}
              />
            </Form.Item>
          </div>
        </Form>
      </ModalWrapper>
    );
  }
}

UpdateReferral.defaultProps = {
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  currentReferralStatus: null,
};

UpdateReferral.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  allReferredStatuses: PropTypes.array.isRequired,
  getReferredStatuses: PropTypes.func.isRequired,
  updatedStatus: PropTypes.bool.isRequired,
  updateReferral: PropTypes.func.isRequired,
  updateRecordReferralNote: PropTypes.func.isRequired,
  getReferralDocumenStatus: PropTypes.func.isRequired,
  currentReferralStatus: PropTypes.number,
  paramTools: PropTypes.object,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedStatus: state.records.updatedStatus,
  currentReferralStatus: state.records.currentReferralStatus,
  data: state.labels.data,
  allReferredStatuses: state.userProfile.allReferredStatuses,
  departmentUsers: state.userProfile.departmentUsers,
}), {
  updateReferral,
  updateRecordReferralNote,
  getReferralDocumenStatus,
  getReferredStatuses,
})(UpdateReferral);
