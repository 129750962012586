import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import moment from 'moment-timezone';

class WelcomePrompt extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    billingTrialDetails: PropTypes.shape({
      billing_on_file: PropTypes.bool,
      trial_end_date: PropTypes.string,
      trial_days_remaining: PropTypes.number,
      plan_type: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  render() {
    const { billingTrialDetails, logout } = this.props;
    const { open } = this.state;
    if (!billingTrialDetails) return null;
    const {
      trial_end_date: trialEndDate,
      trial_days_remaining: trialDaysRemaining,
    } = billingTrialDetails;
    let trialEndDateObj = new Date(trialEndDate);
    // This condition should not occur. It is a safety measure in case the date comes
    // back as 'none' AND billing information isn't on file. We'll then tell the
    // customer that their trial ended today
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(trialEndDateObj)) trialEndDateObj = new Date();

    // We'll use this to format the trial end date as Monday, January 1
    const trialEndDay = trialEndDateObj.getDate();
    const trialEndDayOfTheWeek = moment(trialEndDateObj).format('dddd');
    const trialEndMonth = moment.months(trialEndDateObj.getMonth());

    const trialExpired = trialDaysRemaining !== null && trialDaysRemaining !== undefined && trialDaysRemaining < 1;

    return (
      <Dialog
        actions={[
          <FlatButton
            label={trialExpired ? 'Sign out' : 'Close'}
            onClick={() => {
              localStorage.setItem('m8_prompt_dismissed_time', new Date().toISOString());
              this.setState({ open: false });
              if (trialExpired) logout();
            }}
          />,
        ]}
        open={open}
      >
        <h3 style={{ textAlign: 'center' }}>
          {
            trialExpired
              ? 'Your trial access has expired'
              : 'Welcome to Medsender!'
          }
        </h3>
        <p>
          {
            trialExpired
              ? `
                  Your trial ended on ${trialEndDayOfTheWeek}, ${trialEndMonth} ${trialEndDay}.
                  If you'd like to test drive Medsender for a little longer, contact our support
                  team.
                `
              : (
                <>
                  During your trial, you&apos;ll have full access to sending and
                  experiencing our supported EMR integrations to make for a seamless HIPAA compliant record
                  sharing experience.
                  <br />
                  <br />
                  <span style={{ fontWeight: 'bold' }}>Want to experience receiving faxes?&nbsp;</span>
                  Our team can help get you set up with a new number. Just get in touch with support.
                  <br />
                  <br />
                  {/* eslint-disable react/jsx-one-expression-per-line */}
                  We want to give you plenty of time to test drive Medsender. That&apos;s why you&apos;ve got until&nbsp;
                  {trialEndDayOfTheWeek}, {trialEndMonth} {trialEndDay}! We&apos;re also here to help during your trial.
                  If you need anything from us, don&apos;t hesitate to reach out!
                </>
              )
          }
        </p>
      </Dialog>
    );
  }
}

export default WelcomePrompt;
