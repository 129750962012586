import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Collapse, Button, Descriptions } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';

import ErrorDisplay from '../viewer/errorDisplay';
import './replyMessageSidebar.scss';

const { Panel } = Collapse;

class replyMessageSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  getUrl = async (url) => {
    const { getAttachmentUrl } = this.props;
    await getAttachmentUrl(url);
    const { attachmentUrl } = this.props;
    window.open(attachmentUrl);
  }

  render() {
    const { error, data } = this.props;
    const {
      subject,
      fax_number: faxNumber,
      from,
      memo,
      name,
      phone_number: phoneNumber,
      reply_attachments: replyAttachments,
      record,
      is_sms: isSms,
    } = data;

    const originalMessageInfo = [
      { label: 'Subject', content: subject || 'N/A' },
      { label: isSms ? 'SMS' : 'Memo', content: memo || 'N/A' },
    ];

    const senderContactInfo = [
      { label: 'Name', content: name || 'N/A' },
      { label: 'Email', content: from || 'N/A' },
      { label: 'Fax Number', content: faxNumber || 'N/A' },
      { label: 'Phone Number', content: phoneNumber || 'N/A' },
    ];

    return (
      <Drawer
        title="Reply"
        placement="right"
        closable={true}
        onClose={this.props.toggleReplySidebar}
        visible={this.state.visible}
        width={430}
        style={{ marginTop: 64, padding: 0 }}
      >
        <div className="sidebar-style">
          {error ? <ErrorDisplay sidebar={true} message="Error retrieving reply. Please try again" />
            : (
              <>
                <Collapse>
                  <Panel header="Original Message">
                    {record.subject
                      && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p style={{ marginBottom: '0px' }}>Subject</p>
                          <div className="input-field">{record.subject}</div>
                        </div>
                      )
                    }
                    {record.memo
                      && (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ marginBottom: '0px' }}>Memo</p>
                        <div className="input-field">{record.memo}</div>
                      </div>
                      )}
                    <h4 style={{ paddingTop: '20px' }}>MEDSENDER RECORD</h4>
                    <Button icon={<PaperClipOutlined />} type="dashed" onClick={() => window.open(record.viewer)}>
                      Record
                    </Button>
                  </Panel>
                </Collapse>
                <div style={{ borderTop: '1px solid lightgrey', padding: '25px' }}>
                  <Descriptions title="Reply" bordered size="small" column={1}>
                    {originalMessageInfo.map(info => <Descriptions.Item label={info.label}>{info.content}</Descriptions.Item>)}
                  </Descriptions>
                  {replyAttachments && replyAttachments.length > 0
                    && (
                    <>
                      <h4 style={{ paddingTop: '20px' }}>Attachment(s)</h4>
                      {replyAttachments.map(attachment => (
                        <Button icon={<PaperClipOutlined />} type="dashed" onClick={() => this.getUrl(attachment.uri)}>
                          {attachment.file_name}
                        </Button>
                      ))}
                    </>
                    )}
                  <Descriptions title="Sender Contact Info" bordered size="small" column={1} style={{ marginTop: 50 }}>
                    {senderContactInfo.map(info => <Descriptions.Item label={info.label}>{info.content}</Descriptions.Item>)}
                  </Descriptions>
                </div>
              </>
            )
          }
        </div>
      </Drawer>
    );
  }
}

replyMessageSidebar.defaultProps = {
  data: {},
  error: false,
  attachmentUrl: null,
};

replyMessageSidebar.propTypes = {
  error: PropTypes.bool,
  data: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    fax_number: PropTypes.number,
    from: PropTypes.string,
    memo: PropTypes.string,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    reply_attachments: PropTypes.array,
    record: PropTypes.object,
    is_sms: PropTypes.bool,
  }),
  getAttachmentUrl: PropTypes.func.isRequired,
  attachmentUrl: PropTypes.string,
};

export default replyMessageSidebar;
