import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddLabel from '../../components/modal/AddLabel';
import { createNewLabel, updateLabel, getLabelsByPage } from '../../actions/labels';

class AddLabelDialog extends Component {
  static propTypes = {
    createNewLabel: PropTypes.func.isRequired,
    updateLabel: PropTypes.func.isRequired,
    getLabelsByPage: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddLabel
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null,
  {
    createNewLabel,
    updateLabel,
    getLabelsByPage,
  })(AddLabelDialog);
