import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  CloudDownloadOutlined,
  PrinterOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { Header, PageLoading, Viewer } from '../components';
import { printGuestRecord, setUnsavedAnnotationsOnRecord } from '../actions/records';
import download from '../actions/helpers/download';
import { openModal } from '../actions/modal';
import { sendReplyToRecord } from '../actions/replies';
import '../components/main.scss';

/* global toastr */

class RecordViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordBlobObject: null,
      recordHasReply: false,
      unsavedSignatures: false,
    };
  }

  openGetEmailModal = () => {
    this.props.openModal({ type: 'GET_EMAIL' });
  }

  downloadRecord = async () => {
    const payload = Object.assign(
      {},
      this.props.recordAccessCodes,
      { email: 'guest-viewer@medsender.com' }
    );
    await this.props.printGuestRecord(payload);
    await download(this.props.guestAccessUrl);
  }

  openReplyToModal = async () => {
    const {
      subject,
      recipientName,
      sendToken,
      hasResponse,
      recipientFax,
      openModal: openReplyModal,
      recordHasUnsavedEdits,
      signatureRequired,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(false);
    }

    const { recordBlobObject } = this.state;

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    const data = {
      sendToken,
      hasResponse,
      recipientName,
      recipientFax,
      subject,
      recordBlobObject,
    };
    const modalType = {
      type: 'REPLY_TO_EMAIL',
      data,
    };
    this.setState({
      recordHasReply: true,
    });
    openReplyModal(modalType);
  }

  setUnsavedSignatures = (unsavedSignatures) => {
    this.setState({ unsavedSignatures });
  };

  // This function allows guest users to quickly submit their signature/edits without requiring them
  // to input any additional information like name and email/fax number.
  guestSignAndSubmitRecord = async (file) => {
    const {
      recipientFax,
      sendToken,
      hasResponse,
      signatureRequired,
      recordHasUnsavedEdits,
      sendReplyToRecord,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(true);
      return;
    }

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    // If signature is not required and record edits is not present.
    if (!recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Please make changes to the document before hitting finish');
    }

    if (hasResponse) {
      return toastr.error("You've already replied to this record.");
    }

    const bodyFormData = new FormData();
    bodyFormData.append('subject', 'Signed Reply');
    bodyFormData.append('from', recipientFax);
    bodyFormData.append('edited_record', file.get('file'), 'signed_record.pdf');

    // Make request to BE to securely reply to the record.
    await sendReplyToRecord(sendToken, bodyFormData);

    const { replyError, replyErrorMessage } = this.props;
    if (replyError && replyErrorMessage === 'Record already has an associated reply') {
      toastr.error('You have already replied to this record.');
      return;
    }

    if (replyError) {
      toastr.error('There was an issue saving your edits, please try again.');
      return;
    }

    this.setState({
      recordHasReply: true,
    });

    toastr.success('Your edits have been submitted.');
  }

  // Save file to state when `Save Changes` button is hit.
  beginSaveAnnotationsOnRecord = async (file) => {
    const { error, openModal: openMessageModal } = this.props;


    // If saving annonations/signature on record has no error the save the file blob in local state.
    if (!error) {
      const { recordHasReply } = this.state;

      if (recordHasReply) {
        const modalType = {
          type: 'MESSAGE_MODAL',
          message: 'You have already sent a secure reply for this record therefore you cannot make any further changes.',
        };
        return openMessageModal(modalType);
      }
      toastr.success('Changes saved');
      return this.setState({
        recordBlobObject: file,
      });
    }
    toastr.error('Unable to save changes. Please try again');
  }

  updateRecordBlob = (file) => {
    // Updates the file blob saved in state,
    this.setState({
      recordBlobObject: file,
    });
  }

  render() {
    const { recordAccessCodes: { source } } = this.props;
    if (this.props.guestAccess === '') {
      return (<Redirect to="/app/access_code" />);
    }

    if (this.props.loading) {
      return (<PageLoading />);
    }
    return (
      <div>
        <Helmet>
          <title>Record Viewer - Medsender</title>
        </Helmet>
        <div
          style={{ marginLeft: '0' }}
          className="record-viewer"
        >
          <Header
            main="Record Viewer"
            subtext="Search and navigate your guest record"
            locationPath={[
              { icon: 'home', text: 'Home', link: '/login' },
              { icon: 'list', text: 'View record (guest)', link: '' },
            ]}
            buttons={[
              {
                text: 'Download Record',
                icon: <CloudDownloadOutlined />,
                disabled: false,
                func: () => this.downloadRecord(),
              },
              {
                text: 'Print Record',
                icon: <PrinterOutlined />,
                disabled: false,
                func: () => this.openGetEmailModal(),
              },
              {
                text: 'Secure Reply',
                icon: <FormOutlined />,
                disabled: false,
                func: () => this.openReplyToModal(),
                hide: source === 'api',
              },
            ]}
          />
          <Viewer
            documentUrl={this.props.guestAccess}
            recordId={this.props.match.params.id}
            canEditRecord={source !== 'api'}
            recordNeedsToBeSaved={this.props.recordHasUnsavedEdits}
            setUnsavedEdits={this.props.setUnsavedAnnotationsOnRecord}
            saveAnnotationsOnRecord={this.beginSaveAnnotationsOnRecord}
            updateRecordBlob={this.updateRecordBlob}
            guestSignAndSubmitRecord={this.guestSignAndSubmitRecord}
            saveChangesButtonName="FINISH"
            setClick={click => this.flattenAnnotations = click}
            unsavedSignatures={this.state.unsavedSignatures}
            setUnsavedSignatures={this.setUnsavedSignatures}
            shouldFlatten={this.props.shouldFlatten}
          />
        </div>
      </div>
    );
  }
}

RecordViewer.defaultProps = {
  sendToken: '',
  subject: '',
  recipientName: null,
};

RecordViewer.propTypes = {
  guestAccess: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sendToken: PropTypes.string,
  subject: PropTypes.string,
  recipientFax: PropTypes.string.isRequired,
  recipientName: PropTypes.string,
  hasResponse: PropTypes.bool.isRequired,
  signatureRequired: PropTypes.bool.isRequired,
  recordHasUnsavedEdits: PropTypes.bool.isRequired,
  sendReplyToRecord: PropTypes.func.isRequired,
  replyError: PropTypes.bool.isRequired,
  replyErrorMessage: PropTypes.string.isRequired,
  recordAccessCodes: PropTypes.shape({
    source: PropTypes.string.isRequired,
    fax_code: PropTypes.string.isRequired,
    access_code: PropTypes.string.isRequired,
  }).isRequired,
  shouldFlatten: PropTypes.bool.isRequired,
};

export default connect(state => ({
  recipientFax: state.auth.fax.fax_code,
  subject: state.auth.subject,
  recipientName: state.auth.recipientName,
  sendToken: state.auth.sendToken,
  guestAccess: state.auth.guestAccess,
  guestAccessUrl: state.records.guestAccessUrl,
  recordAccessCodes: state.auth.fax,
  record: state.auth.fax,
  loading: state.auth.isVerifyingLoading,
  hasResponse: state.auth.hasResponse,
  recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
  signatureRequired: state.auth.signatureRequired,
  replyError: state.replies.error,
  replyErrorMessage: state.replies.errorMessage,
  shouldFlatten: state.auth.shouldFlatten,
}),
{
  openModal,
  printGuestRecord,
  setUnsavedAnnotationsOnRecord,
  sendReplyToRecord,
})(RecordViewer);
export {
  RecordViewer,
};
