import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

import LoginLoader from '../components/login/loading';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  // Determine if the user is authenticated or not
  authenticatedSelector: (state) => {
    return state.auth.data !== null;
  },
  authenticatingSelector: state => state.auth.isLoading,
};

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  // Render this component when the authenticatingSelector returns true
  AuthenticatingComponent: LoginLoader,
  redirectPath: (state) => {
    // Redirects back to the reset password page
    // If there's a password mismatch or invalid token
    if (state.auth.resetPasswordToken) {
      const resetPasswordUrl = `/reset_password?reset_token=${state.auth.resetPasswordToken}&password_set=${state.auth.passwordSet}`;
      return resetPasswordUrl;
    }
    return '/login';
  },
});

const userIsNotAuthenticatedDefaults = {
  // Determine if the user is authenticated or not
  authenticatedSelector: (state) => {
    return state.auth.data === null && state.auth.isLoading === false;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
};

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  // This sends the user either to the query param route if we have one
  // or to main page if none is specified and the user is already logged in
  redirectPath: (state, ownProps) => {
    // If users "Save to Medsender" and then login, redirect them to
    // record viewer
    if (state.modal.url) return state.modal.url;
    // If user is accessing patient portal, don't redirect them to provider login.
    if (state.auth.patient) return ownProps.match.url;
    // Otherwise handle the query params in the route or redirect to /app
    // by default
    // DO NOT CHANGE THIS LINE BELOW! YOU HAVE BEEN WARNED!
    return locationHelper.getRedirectQueryParam(ownProps) || '/app';
  },
  // This prevents us from adding the query parameter when we send the user
  // away from the login page
  allowRedirectBack: false,
});

// export const userIsAdminRedir = connectedRouterRedirect({
//   redirectPath: '/',
//   allowRedirectBack: false,
//   authenticatedSelector: state => state.auth.data !== null && state.auth.data.isAdmin,
//   predicate: auth => auth.isAdmin,
//   wrapperDisplayName: 'UserIsAdmin',
// });
