import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Subtext extends PureComponent {
  render() {
    const { text } = this.props;
    if (text !== '') {
      return (
        <span className="subtext"> {text} </span>
      );
    } return null;
  }
}

Subtext.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Subtext;
