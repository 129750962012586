import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ProcessPayment from './index';


class ProcessUpgradeSubscriptionPayment extends Component {
  upgradeAccount = (cbInstance, chargebeeCallbacks) => {
    const { modalPlan, closeModal } = this.props;
    const callbacks = chargebeeCallbacks();
    cbInstance.openCheckout({
      ...callbacks,
      hostedPage: async () => {
        return axios.get(`${window.location.origin}/api/v1/payments/checkout_existing_subscription?plan=${modalPlan}`, encodeURIComponent({})).then(response => response.data);
      },
      close: () => {
        closeModal();
      },
    });
  }

  render() {
    return (
      <ProcessPayment
        handlePayment={this.upgradeAccount}
        {...this.props}
      />
    );
  }
}

ProcessUpgradeSubscriptionPayment.propTypes = {
  modalPlan: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ProcessUpgradeSubscriptionPayment;
