import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FlatButton, Dialog, DropDownMenu, MenuItem } from 'material-ui';
import { MODAL_ENUMS } from '../../../reducers/directMessaging';

const btnStyle = {
  border: '0px',
  color: '#522da8',
  fontSize: '15px',
  fontWeight: '500',
  height: '40px',
  marginTop: '0px',
  marginBottom: '-20px',
  width: '200px',
};

class ConfirmForwarding extends Component {
  selectDirectAddressFromList = (event, index, value) => {
    const { changeSelectedDirectAddress } = this.props;
    changeSelectedDirectAddress(value);
  }

  forwardRecord = async () => {
    const { modalType, confirmForwardRecord, addresses, indexSelectedDirectAddress, changeModalState } = this.props;
    const { data } = modalType;
    const { slug: addressID } = addresses[indexSelectedDirectAddress];
    await confirmForwardRecord(data, addressID);
    changeModalState(MODAL_ENUMS.RECORD_FORWARDED);
  }

  verifySelectedAddress = () => {
    const { changeModalState } = this.props;
    changeModalState(MODAL_ENUMS.VERIFY_DIRECT_ADDRESS);
  }

  render() {
    const errorMessageTemplate = {
      400: 'Unable to forward records received from Medsender users.',
      403: `For security reasons we cannot forward records sent from other Medsender users. 
      We apologize for any inconvenience!`,
      404: 'Record not found.',
      409: 'This record has already been forwarded to your EMR',
    };

    const modalDescription = `Select the DIRECT Address you would like to use from the dropdown menu.
                              Confirm you want to forward this record to your EMR.`;
    const modalDescriptionUnverifiedAddress = `The selected DIRECT address is not yet verified. Click
                                               below to verify or choose a different DIRECT address from
                                               the list`;
    const { addresses, indexSelectedDirectAddress, changeModalState, errorCode, hideModal } = this.props;
    let errorCodeMessage = 'Something went wrong and we were unable to forward your record. Please try again.';
    if ([400, 403, 404, 409].includes(errorCode)) errorCodeMessage = errorMessageTemplate[errorCode];
    const selectedAddressVerified = addresses && addresses[indexSelectedDirectAddress].verified;
    return (
      <Dialog
        open={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="CLOSE"
            primary={true}
            keyboardFocused={true}
            onClick={hideModal}
          />,
          <FlatButton
            label={selectedAddressVerified ? 'FORWARD' : 'VERIFY'}
            primary={true}
            onClick={selectedAddressVerified ? this.forwardRecord : this.verifySelectedAddress}
          />,
        ]}
      >
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ color: 'black', fontWeight: '700', marginBottom: '15px' }}>Forward to EMR</span>
            <DropDownMenu
              style={{ width: '300px', margin: '0 auto' }}
              autoWidth={false}
              value={indexSelectedDirectAddress}
              onChange={this.selectDirectAddressFromList}
            >
              {
                addresses && addresses.length > 0 && addresses.map((address, index) => (
                  <MenuItem
                    value={index}
                    key={index + address}
                    primaryText={address.address + (!address.verified ? ' (unverified) ' : '')}
                    style={{ color: !address.verified && 'red' }}
                  />
                ))
              }
            </DropDownMenu>
            <p style={{ marginBottom: '15px', lineHeight: '30px' }}>
              {selectedAddressVerified ? modalDescription : modalDescriptionUnverifiedAddress}
            </p>
            <FlatButton
              type="button"
              style={btnStyle}
              onClick={() => changeModalState(MODAL_ENUMS.ENTER_DIRECT_ADDRESS)}
            >
            Add new DIRECT Address
            </FlatButton>
          </div>
          { errorCode && <p style={{ color: 'red', textAlign: 'center' }}>{errorCodeMessage}</p> }
        </>
      </Dialog>
    );
  }
}

ConfirmForwarding.defaultProps = {
  indexSelectedDirectAddress: 0,
  errorCode: null,
};

ConfirmForwarding.propTypes = {
  hideModal: PropTypes.func.isRequired,
  changeSelectedDirectAddress: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  indexSelectedDirectAddress: PropTypes.number,
  addresses: PropTypes.array.isRequired,
  confirmForwardRecord: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
  modalType: PropTypes.object.isRequired,
};

export default ConfirmForwarding;
