import React from 'react';
import PropTypes from 'prop-types';
import { NotFound, Disconnected, OtherError } from '../../assets/img';

import './viewer.scss';

const ErrorImage = ({ error: errorCode, sidebar }) => {
  let image = OtherError;
  if (errorCode === 500) image = Disconnected;
  if (errorCode === 404) image = NotFound;
  return (<img className={`${sidebar && 'sidebar-image'}`} src={image} alt="error message graphic" />);
};

const ErrorDisplay = ({ sidebar, message, error }) => (
  <div className="error">
    <ErrorImage error={error} sidebar={sidebar}/>
    <div className={`error-message ${sidebar && 'sidebar'}`}>
      {message}
    </div>
    {error
      && (
        <div className="please-try-again">
          If the problem persists,&nbsp;
          <a style={{ marginTop: 10 }} href="https://medsender.com/support" rel="noopener noreferrer" target="_blank">
          we&apos;re here to help.
          </a>
        </div>
      )
    }
  </div>
);

ErrorDisplay.propTypes = {
  error: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorDisplay;
