import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

class RecordForwarded extends Component {
  render() {
    const modalDescription = 'Document successfully forwarded to your EMR.';
    const { errorCode, hideModal } = this.props;

    const assignErrorMessage = (code) => {
      if (code === 403) return 'Error: Cannot forward documents from Medsender users.';
      if (code === 400) return 'Error: Unable to forward the selected record. Please try again.';
      if (code === 409) return 'Error: This record has already been forwarded to your EMR';
      return 'An error has occurred. Please try again';
    };

    return (
      <Dialog
        open={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="CLOSE"
            primary={true}
            keyboardFocused={true}
            onClick={hideModal}
          />,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ color: 'black', fontWeight: '700', marginBottom: '15px' }}>Forward to EMR</span>
          <p style={{ marginBottom: '15px', lineHeight: '30px' }}>
            {(errorCode) ? assignErrorMessage(errorCode) : modalDescription}
          </p>
        </div>
      </Dialog>
    );
  }
}

RecordForwarded.defaultProps = {
  errorCode: null,
};

RecordForwarded.propTypes = {
  hideModal: PropTypes.func.isRequired,
  errorCode: PropTypes.number,
};

export default RecordForwarded;
