// Passes on the trial User info from the localStorage

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReplyToEmail from '../../components/modal/ReplyToEmail';
import { sendReplyToRecord } from '../../actions/replies';

class ReplyToEmailDialog extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div>
        <ReplyToEmail
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  isLoading: state.replies.isLoading,
  status: state.appState.status,
  error: state.appState.error,
  hasResponse: state.auth.hasResponse || state.records.hasResponse,
}), {
  sendReplyToRecord,
})(ReplyToEmailDialog);
