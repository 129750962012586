import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SmsMessage from '../../components/modal/SmsMessage';
import { triggerSmsMessage } from '../../actions/records';

class SmsMessageDialog extends Component {
  render() {
    return (
      <div>
        <SmsMessage
          {...this.props}
        />
      </div>
    );
  }
}

SmsMessageDialog.defaultProps = {
};

SmsMessageDialog.propTypes = {
  triggerSmsMessage: PropTypes.func.isRequired,
};

export default connect(state => ({
  sendToken: state.auth.data.sendToken,
}), {
  triggerSmsMessage,
})(SmsMessageDialog);
