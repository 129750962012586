import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromeIcon, FirefoxIcon, EdgeIcon } from '../../assets/img/browser-icons';

import ModalWrapper from './ModalWrapper';

const upgradeableBrowsers = [
  {
    alt: 'Google Chrome',
    installLink: 'https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en',
    imgSrc: ChromeIcon,
  },
  {
    alt: 'Mozilla Firefox',
    installLink: 'https://www.mozilla.org/en-US/firefox/new/',
    imgSrc: FirefoxIcon,
  },
  {
    alt: 'Microsoft Edge',
    installLink: 'https://www.microsoft.com/en-us/edge',
    imgSrc: EdgeIcon,
  },
];

class UpgradeBrowser extends Component {
  browserIcons = () => {
    const style = { height: '70px' };
    return upgradeableBrowsers.map(({ installLink, alt, imgSrc }, i) => {
      // Chrome is recommended
      if (i === 0) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <a
              href={installLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={imgSrc} alt={alt} style={style} />
            </a>
            <span style={{ fontSize: '12px', textAlign: 'center' }}>
              (recommended)
            </span>
          </div>
        );
      } return (
        <a
          href={installLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={imgSrc} alt={alt} style={{ ...style, padding: '0px 10px' }} />
        </a>
      );
    });
  }

  modalMessage = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ fontWeight: '800', textAlign: 'center', marginBottom: '20px' }}>
          <h1>Heads up!</h1>
          <br />
          <h2>Your browser doesn&apos;t support our newest viewer.</h2>
        </div>
        <p>
          You can now mark up, fill out &amp; sign documents right within Medsender.
          <br />
          <br />
          To access these new capabilities, you&apos;ll need to upgrade your browser.
        </p>
        <p style={{ fontStyle: 'italic', textAlign: 'center', marginTop: '10px' }}>
          Download and install a supported browser below:
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          {this.browserIcons()}
        </div>
      </div>
    );
  }

  render() {
    const { hideModal } = this.props;
    return (
      <ModalWrapper
        hideModal={hideModal}
        action={hideModal}
        actionName="Close"
        dismiss=""
        customContentStyle={{ width: '40%' }}
        modalTitle="Upgrade Browser"
      >
        {this.modalMessage()}
      </ModalWrapper>
    );
  }
}

UpgradeBrowser.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default UpgradeBrowser;
