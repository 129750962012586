// Edit record component displays the modal content for updating a record.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash.debounce';
import CircularProgress from 'material-ui/CircularProgress';

import ModalWrapper from './ModalWrapper';
import {
  validSendEmail,
  validFaxNumber,
  validPartialNumber,
  formatValidFaxNumber,
} from '../../global/validation';

const pStyle = {
  textAlign: 'center',
  fontWeight: '600',
};

// Add positioning to the loader
const inputBox = {
  display: 'flex',
  alignItems: 'baseline',
  flexFlow: 'row nowrap',
};


// Theme for react-autosuggest and styling for sub components
// https://github.com/moroshko/react-autosuggest#theme-optional
const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '500px',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 64,
    width: 600,
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 13,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    maxHeight: 150,
    overflowY: 'auto',
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  sectionContainer: {
    borderTop: '1px solid #ccc',
  },
  sectionTitle: {
    padding: '10px 0 0 10px',
    fontSize: 13,
    color: '#777',
  },
  sectionContainerFirst: {
    borderTop: '0',
  },
};

class Referral extends Component {

  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.object.isRequired,
    rowSelected: PropTypes.func.isRequired,
    sendReferral: PropTypes.func.isRequired,
  }

  constructor(props) {
    super();
    this.state = {
      nameValid: '',
      faxValid: '',
      emailValid: '',
      isPatient: false,
      value: '',
      recipients: [],
      patient: {
        email: '',
        name: '',
        fax: '',
        company: '',
      },
    };
    this.debouncedLoadSuggestions = debounce(this.loadSuggestions, 500);
  }


  componentWillReceiveProps(nextProps) {
    // If new search results come in update the state
    if (this.state.recipients !== nextProps.dataSource) {
      this.setState({
        recipients: nextProps.dataSource,
      });
    }
  }

  // Autosuggest calls this function when the user interacts with the text input
  // by:
  // a) Typing something
  // b) Focusing on the input
  // c) something else, documented here: https://git.io/fNQ4P
  onSuggestionsFetchRequested = ({ value }) => {
    // Only fetch suggestions if text in input has changed
    // This avoids API calls when the input is focused with the same value
    const { value: oldValue, isPatient } = this.state;
    const fetchNewSuggestion = (value.trim() !== oldValue.trim());

    if (fetchNewSuggestion) {
      if (isPatient) {
        this.setState({
          patientEmailValid: validSendEmail(value),
          patientNameValid: value !== '',
        });
      } else {
        const { valid: faxNumberValid } = validFaxNumber(value);
        this.setState({
          faxNumberValid,
          emailValid: validSendEmail(value),
          org: '',
          contact: '',
        });
      }
      // Allow search for partial fax numbers with special characters e.g 999-080-
      const { valid: partialNumberValid, rawNumber: number } = validPartialNumber(value);

      if (partialNumberValid) {
        return this.debouncedLoadSuggestions(number);
      }
      return this.debouncedLoadSuggestions(value);
    }
  };

  // Fetches suggestions after hititing search api endpoint
  loadSuggestions(value) {
    const { isPatient } = this.state;
    const { searchRecipient } = this.props;
    if (isPatient) {
      searchRecipient('patient', value);
    } else {
      searchRecipient('provider', value);
    }
  }

  displaySuggestionText = (suggestion) => {
    const contactName = suggestion.name;
    const organizationName = suggestion.organization;
    const recipient = validFaxNumber(suggestion.recipient).valid ? formatValidFaxNumber(suggestion.recipient, 'Domestic') : suggestion.recipient;
    if (organizationName && contactName) return `${contactName} (${organizationName}) - ${recipient}`;
    if (!contactName && !organizationName) return `${recipient}`;
    if (!contactName && organizationName) return `${organizationName} - ${recipient}`;
    return `${contactName} - ${recipient}`;
  }

  // Gets suggestion of a particular section e.g users, organizations
  getSectionSuggestions(section) {
    return section.recipients;
  }

  // Only shows suggestion when 3 or more charcters
  shouldRenderSuggestions = value => value.trim().length > 2

  // Store input reference for the patient field.
  storePatientInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.patientInput = autosuggest.input;
    }
  }

  // Store input reference for the provider field.
  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  }

  validateName = (name) => {
    if (name && name.trim() !== '') return true;
    return false;
  }

  validateInput = () => {
    const { name, fax, email } = this.state.patient;
    this.setState({
      nameValid: '',
      faxValid: '',
      emailValid: '',
    });
    if (!this.validateName(name)) {
      this.setState({ nameValid: 'Name cannot be blank' })
      return false;
    }
    if (!validFaxNumber(fax).valid) {
      this.setState({ faxValid: 'Input a valid fax number'})
      return false;
    }
    if (email.length > 0 && !validSendEmail(email)) {
      this.setState({ emailValid: 'Input a valid email' })
      return false;
    }
    return true;
  }

  update = async (event) => {
    const { patient } = this.state;
    const { name, fax, email, company } = patient;
    const { sendReferral } = this.props;
    event.preventDefault();
    const referral = { name, email, fax_number: fax, company_name: company };
    const referralInfo = { referral };
    if (this.validateInput()) {
      sendReferral(referralInfo);
    }
  }

  // Updates the state when recipient is typed in
  onChange = (event, { newValue }) => {
    if (this.state.isPatient) {
      this.setState((prevState) => {
        return {
          patientEmailValid: validSendEmail(newValue),
          patientValue: newValue,
          patient: {
            ...prevState.patient,
            email: newValue,
          },
        };
      });
    } else {
      this.setState({
        value: newValue,
        faxNumberValid: validFaxNumber(newValue).valid,
        emailValid: validSendEmail(newValue),
      });
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    const reciever = suggestion.recipient;
    this.setState(prevState => ({
      ...prevState,
      patient: {
        name: suggestion.name,
        email: validSendEmail(reciever) ? reciever : '',
        fax: validFaxNumber(reciever).valid ? reciever : '',
        company: suggestion.organization ? suggestion.organization : '',
      },
    }));
  }

  // Renders the section of the search results
  renderSectionTitle = (section) => {
    return (
      <strong>{section.title}</strong>
    );
  }

  // Renders custom input component from material UI
  renderInputComponent = (inputProps) => {
    const { isPatient } = this.state;
    return (
      <TextField
        hintText={isPatient ? 'Patient Email Address' : 'Recipient Name, Fax Number or Email Address'}
        floatingLabelText="Search from your Medsender contacts"
        {...inputProps}
      />
    );
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. If the suggestion selected is a fax number, It populates
  // recipient, org. and contact. If its an email address, it populates only the recipient
  getSuggestionValue = (suggestion) => {
    const { isPatient } = this.state;
    if (isPatient) {
      this.setState((prevState) => {
        return {
          patientValue: suggestion.recipient,
          patientDOBValid: validDOB(suggestion.dob),
          patientNameValid: Boolean(suggestion.name),
          patient: {
            ...prevState.patient,
            dob: suggestion.dob,
            name: suggestion.name,
            email: suggestion.recipient,
          },
        };
      });
      return suggestion.recipient;
    }
    // If  valid number, then change display type to domestic. e.g (919) 818-8181
    const { valid: faxNumberValid, rawNumber: recipient } = validFaxNumber(suggestion.recipient);
    if (faxNumberValid) {
      this.setState({
        org: suggestion.organization,
        contact: suggestion.name,
      });
      return formatValidFaxNumber(recipient, 'Domestic');
    }
    return suggestion.recipient;
  };

  handleInputChange = (event) => {
    const name = event.target.name;
    const { patient } = this.state;
    if (name === 'patientName') {
      const value = event.target.value;
      this.setState(prevState => ({
        ...prevState,
        patient: {
          ...patient,
          name: value,
        },
      }));
    }
    if (name === 'patientFax') {
      const value = event.target.value;
      this.setState(prevState => ({
        ...prevState,
        patient: {
          ...patient,
          fax: value,
        },
      }));
    }
    if (name === 'patientEmail') {
      const value = event.target.value;
      this.setState(prevState => ({
        ...prevState,
        patient: {
          ...patient,
          email: value,
        },
      }));
    }
    if (name === 'patientCompany') {
      const value = event.target.value;
      this.setState(prevState => ({
        ...prevState,
        patient: {
          ...patient,
          company: value,
        },
      }));
    }
  }

  // Styles the render suggestions component.
  renderSuggestion = suggestion => (
    <div>
      {this.displaySuggestionText(suggestion)}
    </div>
  );

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    // Clear suggestions
    // TODO: add support for when user focuses away from input to cache
    // suggestions
    this.setState({ recipients: [] });
  };

  // This makes a call to clear the props status before closing the modal so the user can refer again
  closeReferralModal = () => {
    const { clearReferral, hideModal } = this.props;
    clearReferral();
    hideModal();
  }

  // Focus on the input on page load
  focus(input) {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }

  render() {
    const { patient, nameValid, faxValid, emailValid, value, recipients } = this.state;
    const dialogButtonText = 'Refer';
    const {
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      renderSuggestion,
      getSuggestionValue,
      shouldRenderSuggestions,
      getSectionSuggestions,
      renderInputComponent,
      renderSectionTitle,
    } = this;
    const { searchLoading, error, status, hideModal } = this.props;
    const customContentStyle = {
      width: '600px',
    };

    const inputProps = {
      value,
      onChange: this.onChange,
      autoFocus: true, // Focus on provider field once component is rendered.
    };
    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={status ? this.closeReferralModal : this.update}
        actionName={status ? 'CLOSE' : dialogButtonText}
        dismiss={status ? null : 'Cancel'}
        modalTitle="Refer a Friend"
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            this.update(e);
          }
        }}>
            <>
              {status ? <p style={{color: 'black', textAlign: 'center'}}>
                  Thank you very much for referring Medsender to a colleague and helping us make healthcare communication more reliable. 
                  We will let you know if they sign up using your referral!
                </p> : (
                <div>
                  <div>
                    {error && <p style={{color: 'red', textAlign: 'center'}}>Unable to refer this time. Please try again.</p>}
                    <p style={{ marginBottom: '15px', lineHeight: '30px', textAlign: 'center' }}>
                      Know someone interested in HIPAA compliant faxing? <br/> 
                      Refer someone below and you will each get $100 when they sign up.*
                    </p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <div style={inputBox}>
                      <Autosuggest
                        multiSection={true}
                        suggestions={recipients}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={this.onSuggestionSelected}
                        shouldRenderSuggestions={shouldRenderSuggestions}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        renderInputComponent={renderInputComponent}
                        renderSectionTitle={renderSectionTitle}
                        getSectionSuggestions={getSectionSuggestions}
                        theme={theme}
                        ref={this.storePatientInputReference}
                      />
                      {searchLoading && (
                        <CircularProgress
                          size={15}
                          thickness={2}
                          style={{ right: '3%' }}
                        />
                      )}
                    </div>
                  
                    <TextField
                      // defaultValue={name}
                      onChange={this.handleInputChange}
                      name="patientName"
                      floatingLabelText="Name"
                      ref={this.focus}
                      errorText={nameValid}
                      value={patient.name}
                    />
                    <br />
                    <TextField
                      onChange={this.handleInputChange}
                      name="patientFax"
                      floatingLabelText="Fax"
                      errorText={faxValid}
                      value={patient.fax}
                    />
                    <br />
                    <TextField
                      onChange={this.handleInputChange}
                      name="patientEmail"
                      floatingLabelText="Email (optional)"
                      errorText={emailValid}
                      value={patient.email}
                    />
                    <br />
                    <TextField
                      onChange={this.handleInputChange}
                      name="patientCompany"
                      floatingLabelText="Company (optional)"
                      value={patient.company}
                    />
                  </div>
                  <p style={{ marginBottom: '15px', lineHeight: '30px', textAlign: 'center', fontSize: '12px' }}>
                      *Subject to referral qualifying for a Medsender premium plan with a minimum contract of 6 months
                  </p>
                </div>
              )
              }
            </>
        </div>
      </ModalWrapper>
    );
  }
}

Referral.PropTypes = {
  dataSource: PropTypes.array.isRequired,
  searchLoading: PropTypes.bool.isRequired,
  searchRecipient: PropTypes.func.isRequired,
  error: PropTypes.string,
}
export default Referral;
