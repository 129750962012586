import crud from './helpers/crud';

const checkForDirectAddress = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHECK_FOR_DIRECT',
      fail: 'FAILED_CHECK_FOR_DIRECT',
      end: 'FINISH_CHECK_FOR_DIRECT',
    },
    method: 'GET',
    url: '/api/v1/direct_addresses',
  })
);

const confirmForwardRecord = (sendTokenID, addressID) => (
  crud({
    dispatch: {
      begin: 'BEGIN_CONFIRM_FORWARDING',
      fail: 'FAILED_CONFIRM_FORWARDING',
      end: 'FINISH_CONFIRM_FORWARDING',
    },
    method: 'POST',
    url: `/api/v1/direct_messages/`,
    data: {
      send_token: sendTokenID,
      address_id: addressID,
    },
  })
);

const verifyDirectAddress = address => (
  crud({
    dispatch: {
      begin: 'BEGIN_VERIFY_ADDRESS',
      fail: 'FAILED_VERIFY_ADDRESS',
      end: 'FINISH_VERIFY_ADDRESS',
    },
    method: 'POST',
    url: '/api/v1/direct_addresses',
    data: {
      address,
    },
  })
);

const resendVerificationCode = addressID => (
  crud({
    dispatch: {
      begin: 'BEGIN_RESEND_VERIFICATION_CODE',
      fail: 'FAILED_RESEND_VERIFICATION_CODE',
      end: 'FINISH_RESEND_VERIFICATION_CODE',
    },
    method: 'POST',
    url: `/api/v1/direct_addresses/${addressID}/resend`,
  })
);

const confirmVerificationCode = (addressID, code) => (
  crud({
    dispatch: {
      begin: 'BEGIN_CONFIRM_VERIFICATION_CODE',
      fail: 'FAILED_CONFIRM_VERIFICATION_CODE',
      end: 'FINISH_CONFIRM_VERIFICATION_CODE',
    },
    method: 'PATCH',
    url: `/api/v1/direct_addresses/${addressID}/verify`,
    data: {
      verification_code: code,
    },
  })
);

const changeModalState = (newState) => {
  return {
    type: 'CHANGE_MODAL_STATE',
    value: newState,
  };
};

const changeSelectedDirectAddress = (addressIndex) => {
  return {
    type: 'CHANGE_SELECTED_DIRECT_ADDRESS',
    value: addressIndex,
  };
};

export {
  checkForDirectAddress,
  confirmForwardRecord,
  verifyDirectAddress,
  resendVerificationCode,
  confirmVerificationCode,
  changeModalState,
  changeSelectedDirectAddress,
};
