import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();

    return (
      <span className="footer">
        By using Medsender, you agree to the&nbsp;
        <a href="https://static.medsender.com/docs/m7r_privacy_policy.pdf">Privacy Policy</a> and&nbsp;
          <a href="https://static.medsender.com/docs/m7r_terms_of_use.pdf">Terms of Use</a>
        <br />
          &copy; {currentYear} Medsender, Inc.
      </span>
    );
  }
}

export default Footer;
