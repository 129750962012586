import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import {
  message,
  Form,
  Input,
  Checkbox,
  DatePicker,
  Select,
  AutoComplete,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalWrapper from './ModalWrapper';


class AutoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: props.modalType.data.fileName,
      receiverType: props.modalType.data.receiverType,
      documentCategory: props.modalType.data.documentCategory || '',
      documentCategories: [],
      providerName: props.modalType.data.providerName,
      createTaskAfterUpload: localStorage.createTaskAfterUpload,
      patientName: this.props.modalType.data.patientName || '',
      patientFirstName: '',
      patientMiddleName: '',
      patientLastName: '',
      patientDOB: this.props.modalType.data.patientDOB || '',
      patientMrn: '',
      assignTo: undefined,
      searchedTemplates: null,
      emaTaskTemplates: null,
      searchedNames: null,
      task: '',
      searchedTasks: '',
      taskDetails: '',
      taskDetailsSearchOptions: null,
      notes: `Task created by ${localStorage.m8_name}`,
      priority: 'Normal',
      noTaskCreation: this.props.modalType.data.noTaskCreation,
    };
    this.priorityOptions = ['Normal', 'High', 'STAT'];
    this.taskOptions = [
      'Authorization', 'Communication', 'Fax', 'Notify', 'Obtain', 'Order', 'Pathology', 'Prescribe', 'Reassure',
      'Recall', 'Re-Evaluate', 'Refer', 'Return To Clinic', 'Review', 'Schedule', 'Sign', 'Test',
    ];

    this.taskDetailsOptions = [
      'Additional Testing', 'Benign', 'Claim Review', 'Discuss at Follow-up', 'Follow-up Appt', 'Labs', 'PCP',
      'Medication', 'Normal', 'No Further Treatment', 'Procedure', 'Referring Provider', 'Results', 'Sign/Cosign',
      'Surgery',
    ];

    this.state.searchedTasks = this.taskOptions;
    this.state.taskDetailsSearchOptions = this.taskDetailsOptions;
  }

  componentDidMount = async () => {
    const {
      getDocumentCategories,
      getEmaTaskProviders,
      getTaskTemplatesByQuery,
      modalType: { data: { sendToken } },
    } = this.props;
    await getDocumentCategories();
    await getEmaTaskProviders(sendToken);
    this.setState({
      fileName: this.props.modalType.data.fileName,
      providerName: this.props.modalType.data.providerName,
      searchedNames: this.props.allEmaTaskProviders,
      documentCategories: this.props.allDocumentCategories.categories,
    });
    if (this.props.allEmaTaskProviders.length === 1) {
      let newAssignTo = this.props.allEmaTaskProviders[0];
      const autoUploadEmr = localStorage.m8_auto_upload_emr;
      if (autoUploadEmr === 'EMA') {
        // Set assign to as array
        newAssignTo = [newAssignTo];
      }
      this.setState({ assignTo: newAssignTo });
    }
    const query = `?email=${localStorage.getItem('m8_uid')}`;
    await getTaskTemplatesByQuery(query);
    this.setState({
      emaTaskTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
      searchedTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
    });
    const autoUploadEmr = localStorage.m8_auto_upload_emr;
    const { patientName } = this.state;
    if (autoUploadEmr === 'EMA' && patientName) {
      // Handle edge case where the patient_name has 2 surnames (this is common for Spanish names)
      if (patientName.includes(',') && patientName.split(',')[0].trim().includes(' ') && patientName.split(',')[0].trim().split(' ').length > 1) {
        const parsedNames = this.parseName(patientName);
        this.setState({
          patientFirstName: parsedNames.firstName,
          patientLastName: parsedNames.lastName,
        });
      } else {
        this.setState({
          patientFirstName: parseFullName(patientName).first,
          patientMiddleName: parseFullName(patientName).middle,
          patientLastName: parseFullName(patientName).last,
        });
      }
    }
  }

  handleFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  }

  handleSelectionChange = (e, variable) => {
    this.setState({ [variable]: e });
  };

  handleCreateTaskAfterUpload = async (e) => {
    if (!localStorage.createTaskAfterUpload) {
      localStorage.setItem('createTaskAfterUpload', true);
    } else {
      localStorage.removeItem('createTaskAfterUpload');
    }
    this.setState({ createTaskAfterUpload: e.target.checked });
  }

  handleDateChange = (_, dateString) => {
    this.setState({ patientDOB: dateString });
  };

  handleAssignToSearch = (e) => {
    if (e) {
      const filtered = this.props.allEmaTaskProviders.filter(provider => provider.toLowerCase().includes(e.toLowerCase()));
      this.setState({ searchedNames: filtered });
    } else {
      this.setState({ searchedNames: this.props.allEmaTaskProviders });
    }
  };

  handleTaskSearch = (e) => {
    if (e) {
      const filtered = this.taskOptions.filter(task => task.toLowerCase().includes(e.toLowerCase()));
      this.setState({ searchedTasks: filtered });
    } else {
      this.setState({ searchedTasks: this.taskOptions });
    }
  };

  handleSearchTaskDetails = (e) => {
    this.setState({ taskDetails: e });
    const filteredOptions = this.taskDetailsOptions.filter(option => (
      option.toLowerCase().includes(e.toLowerCase())));
    this.setState({ taskDetailsSearchOptions: filteredOptions });
  };

  handleTaskTemplateChange = (e) => {
    // if user clears out selection, we will reload the list of templates to choose from.
    if (e === undefined) {
      this.handleTaskTemplateSearch();
      return;
    }
    const index = this.state.emaTaskTemplates.map(template => template.id).indexOf(e);
    const selectedTemplate = this.state.emaTaskTemplates[index];
    const assigtoArray = selectedTemplate.assign_to_as_array;
    if (assigtoArray.includes('Ordering Provider')) {
      assigtoArray.splice(assigtoArray.indexOf('Ordering Provider'), 1);
    }
    this.handleSelectionChange(selectedTemplate.priority, 'priority');
    this.handleSelectionChange(selectedTemplate.task_details, 'taskDetails');
    this.handleSelectionChange(selectedTemplate.task_type, 'task');
    this.handleSelectionChange(assigtoArray, 'assignTo');
  };

  handleTaskTemplateSearch = (e) => {
    const unfilteredObjects = this.state.emaTaskTemplates;
    if (e) {
      const filtered = this.state.emaTaskTemplates.map(template => template.task_name).filter(option => (option.toLowerCase().includes(e.toLowerCase())));
      const filteredObject = [];
      filtered.forEach((template) => {
        const index = this.state.emaTaskTemplates.map(t => t.task_name).indexOf(template);
        filteredObject.push(this.state.emaTaskTemplates[index]);
      });
      this.setState({ searchedTemplates: filteredObject });
    } else {
      this.setState({ searchedTemplates: unfilteredObjects });
    }
  };

  handleSubmit = async () => {
    const {
      hideModal, triggerAutoUpload, triggerAutoUploadAndTaskCreation, modalType: { data: { sendToken } },
    } = this.props;
    const {
      fileName,
      receiverType,
      documentCategory,
      providerName,
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      patientDOB,
      patientMrn,
      assignTo,
      task,
      taskDetails,
      priority,
      notes,
      createTaskAfterUpload,
      noTaskCreation,
    } = this.state;

    let formattedDOB = '';
    if (patientDOB !== '') {
      const splitDate = patientDOB.split('/');
      formattedDOB = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
    }
    if (createTaskAfterUpload && !noTaskCreation) {
      await triggerAutoUploadAndTaskCreation({
        sendToken, receiverType, documentCategory, fileName, patientName, patientFirstName, patientLastName, patientMiddleName, patientDOB: formattedDOB, patientMrn, assignTo, task, taskDetails, priority, notes,
      });
    } else {
      const assignee = assignTo || providerName;
      await triggerAutoUpload({
        sendToken, receiverType, documentCategory, fileName, practitionerName: assignee, patientName, patientFirstName, patientLastName, patientMiddleName, patientDOB: formattedDOB, patientMrn,
      });
    }
    hideModal();
    const { error, errorMessage } = this.props;
    if (!error){
      message.success({
        content: 'Record will be uploaded shortly. We will notify you of any issues via email.',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessage,
        style: { marginTop: '70px' },
      });
    }
  }

  parseName(name) {
    let firstName = '';
    let lastName = '';
    const parts = name.split(',');
    lastName = parts[0].trim();
    firstName = parts[1].trim();

    // Handle edge cases for Spanish names with multiple surnames
    if (lastName.split(' ').length > 1 && !lastName.includes('-')) {
      const lastNames = lastName.split(' ');
      lastName = `${lastNames[0]} ${lastNames[1]}`;
    }

    return { firstName, lastName };
  }


  renderAssignToSelect() {
    const autoUploadEmr = localStorage.m8_auto_upload_emr;
    const noProviderMessage = 'Contact your Admin to add EMR Providers';
    return (
      <Form.Item
        label={this.props.allEmaTaskProviders && this.props.allEmaTaskProviders.length === 0
          ? (
            <div>
              Assign to EMR Staff or Staff Group
              <Tooltip title="Please contact your Admin to add Providers">
                <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
              </Tooltip>
            </div>
          ) : (
            <div>
              Assign to EMR Staff or Staff Group
            </div>
          )}
        style={{ flex: 1 }}
      >
        <Select
          mode={autoUploadEmr === 'EMA' ? 'multiple' : undefined}
          showSearch
          placeholder="Select a provider"
          optionFilterProp="children"
          onChange={e => this.handleSelectionChange(e, 'assignTo')}
          onSearch={this.handleAssignToSearch}
          filterOption={false}
          value={this.state.assignTo}
          allowClear
          notFoundContent={noProviderMessage}
        >
          {this.state.searchedNames && this.state.searchedNames.map((e, index) => (
            <Select.Option key={e + index} value={e}>
              {e}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  renderPatientName(patientName) {
    const autoUploadEmr = localStorage.m8_auto_upload_emr;

    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        {autoUploadEmr !== 'EMA' ? (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Patient Name"
              style={{ flex: 1 }}
            >
              <Input
                placeholder="Patient name"
                name="patientName"
                defaultValue={patientName}
                onChange={this.handleFieldChange}
              />
            </Form.Item>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Patient First Name"
              style={{ flex: 1 }}
            >
              <Input
                placeholder="First name"
                name="patientFirstName"
                value={this.state.patientFirstName}
                onChange={this.handleFieldChange}
              />
            </Form.Item>
            <Form.Item
              label="Patient Middle Name"
              style={{ flex: 1 }}
            >
              <Input
                placeholder="Middle name"
                name="patientMiddleName"
                value={this.state.patientMiddleName}
                onChange={this.handleFieldChange}
              />
            </Form.Item>
            <Form.Item
              label="Patient Last Name"
              style={{ flex: 1 }}
            >
              <Input
                placeholder="Last name"
                name="patientLastName"
                value={this.state.patientLastName}
                onChange={this.handleFieldChange}
              />
            </Form.Item>
          </div>
        )}
      </div>
    );
  }

  renderDocumentCategorySelect(documentCategories, documentCategory) {
    const autoUploadEmr = localStorage.m8_auto_upload_emr;
    return (
      <Form.Item
        name="documentCategory"
        label="Document category"
        style={{ flex: 1 }}
        rules={autoUploadEmr === 'EMA' ? [
          () => ({
            validator(_, name) {
              if (name && name.trim() !== '') return Promise.resolve();
              return Promise.reject('Category cannot be blank');
            },
          }),
        ] : []}
      >
        <Select
          label="Document Category"
          placeholder="Select a document category"
          allowClear
          showSearch
          onChange={event => this.setState({ documentCategory: event })}
          value={documentCategory}
        >
          {documentCategories.map(emrCategory => <Select.Option key={emrCategory.id} value={emrCategory.emr_category}>{emrCategory.emr_category}</Select.Option>)}
        </Select>
      </Form.Item>
    );
  }

  renderPatientMRNandDOB(emr, patientMrn, patientDOB) {
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Form.Item
          label="Patient Date of Birth"
          style={{ flex: 0.48 }}
        >
          <DatePicker
            onChange={this.handleDateChange}
            name="patientDOB"
            format={dateFormat}
            defaultValue={patientDOB && moment(patientDOB, dateFormat)}
            value={patientDOB && moment(patientDOB, dateFormat)}
          />
        </Form.Item>
        <Form.Item
          label={emr === 'ECW' ? 'Account Number (Optional)' : 'Patient MRN (Optional)'}
          style={{ flex: 1 }}
        >
          <Input
            placeholder={emr === 'ECW' ? 'Account Number' : 'Patient MRN'}
            name="patientMrn"
            defaultValue={patientMrn}
            onChange={this.handleFieldChange}
          />
        </Form.Item>
      </div>
    );
  }

  render() {
    const { hideModal } = this.props;
    const
      {
        fileName, documentCategories, documentCategory, providerName, patientName, patientFirstName, patientLastName, patientDOB, patientMrn, notes, assignTo, createTaskAfterUpload, noTaskCreation,
      } = this.state;
    const autoUploadEmr = localStorage.m8_auto_upload_emr;
    const docTitleSupport = ['Kareo', 'OncoEMR', 'Nymbl', 'EMA', 'ECW', 'AllegianceMD', 'Elation'];
    const customContentStyle = {
      width: '340px',
    };
    const disableSubmitButton = () => {
      if (this.state.createTaskAfterUpload && !noTaskCreation) {
        return (
          ((patientFirstName === '' || patientLastName === '' || patientDOB === '') && patientMrn === '')
          || (this.state.assignTo && this.state.assignTo.length === 0)
          || !this.state.assignTo
          || this.state.task === ''
          || this.state.taskDetails === ''
          || (autoUploadEmr === 'EMA' && !documentCategory)
        );
      }
      if (autoUploadEmr === 'EMA') {
        return (
          (docTitleSupport.includes(autoUploadEmr) ? fileName === '' : providerName === '')
        || !documentCategory
        || ((patientFirstName === '' || patientLastName === '' || patientDOB === '') && patientMrn === '')
        );
      }
      if (autoUploadEmr === 'ECW') {
        return (
          ((patientName === '' || patientDOB === '') && patientMrn === '')
          || !assignTo
          || !documentCategory
        );
      }
      if (autoUploadEmr === 'AdvancedMD') {
        return providerName === '' || providerName === null;
      }
      return (
        (docTitleSupport.includes(autoUploadEmr) ? fileName === '' : providerName === '')
        || ((patientName === '' || patientDOB === '') && patientMrn === '')
      );
    };

    return (
      <div>
        <ModalWrapper
          customContentStyle={customContentStyle}
          hideModal={hideModal}
          dismiss="Cancel"
          action={this.handleSubmit}
          actionName="Submit"
          disabled={disableSubmitButton()}
          modalTitle="Confirm document"
          isDraggable
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            initialValues={{
              taskAfterUpload: createTaskAfterUpload, documentCategory, providerName, fileName, notes,
            }}
          >
            {(docTitleSupport.includes(autoUploadEmr))
            && (
              <Form.Item
                hasFeedback
                label="Document Title Name"
                name="fileName"
                rules={[
                  () => ({
                    validator(_, name) {
                      if (name && name.trim() !== '') return Promise.resolve();
                      return Promise.reject('Name cannot be blank');
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Document Title Name"
                  name="fileName"
                  onChange={this.handleFieldChange}
                />
              </Form.Item>
            )}
            {autoUploadEmr === 'ECW'
            && (
              <>
                {this.renderPatientName(patientName)}
                {this.renderPatientMRNandDOB(autoUploadEmr, patientMrn, patientDOB)}
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div style={{ flex: 0.5 }}>
                    {this.renderDocumentCategorySelect(documentCategories, documentCategory)}
                  </div>
                  <div style={{ flex: 0.5 }}>
                    {this.renderAssignToSelect(true)}
                  </div>
                </div>

              </>
            )}
            {['EMA', 'Kareo', 'OncoEMR', 'AllegianceMD', 'Elation'].includes(autoUploadEmr)
            && this.renderDocumentCategorySelect(documentCategories, documentCategory)}
            {autoUploadEmr === 'AllegianceMD' && this.renderAssignToSelect(true)}
            {(autoUploadEmr === 'AdvancedMD')
            && (
              <>
                {this.renderDocumentCategorySelect(documentCategories, documentCategory)}
                <Form.Item
                  hasFeedback
                  label="Provider Name"
                  name="providerName"
                  rules={[
                    () => ({
                      validator(_, name) {
                        if (name && name.trim() !== '') return Promise.resolve();
                        return Promise.reject('Name cannot be blank');
                      },
                    }),
                  ]}
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Last name, First name"
                    name="providerName"
                    onChange={this.handleFieldChange}
                  />
                </Form.Item>
              </>
            )}

            {(autoUploadEmr === 'EMA')
            && (
              <>
                {this.renderPatientName(patientName)}
                {this.renderPatientMRNandDOB(autoUploadEmr, patientMrn, patientDOB)}
                {(autoUploadEmr === 'EMA') && !noTaskCreation
                && (
                <Form.Item
                  style={{ display: 'flex', marginTop: 20 }}
                  name="taskAfterUpload"
                  valuePropName="checked"
                  onChange={this.handleCreateTaskAfterUpload}
                >
                  <Checkbox>Create task after upload</Checkbox>
                </Form.Item>
                )}
              </>
            )}

            {(createTaskAfterUpload && !noTaskCreation)
              && (
              <>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {autoUploadEmr === 'EMA'
                  && (
                  <Form.Item
                    label="Task Templates"
                    style={{ flex: 0.5 }}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleTaskTemplateChange}
                      onSearch={this.handleTaskTemplateSearch}
                      filterOption={false}
                      allowClear
                    >
                      {this.state.searchedTemplates && this.state.searchedTemplates.map(e => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.task_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  )}
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Form.Item
                    label="Task"
                    style={{ flex: 0.5 }}
                  >
                    <Select
                      showSearch
                      placeholder="Task"
                      value={this.state.task}
                      optionFilterProp="children"
                      onChange={e => this.handleSelectionChange(e, 'task')}
                      onSearch={this.handleTaskSearch}
                      filterOption={false}
                    >
                      {this.state.searchedTasks.map(e => (
                        <Select.Option key={e} value={e}>
                          {e}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Task Details"
                    style={{ flex: 0.5 }}
                  >
                    <AutoComplete
                      placeholder="Task Details"
                      value={this.state.taskDetails}
                      options={this.state.taskDetailsSearchOptions.map(option => ({ value: option }))}
                      onSearch={this.handleSearchTaskDetails}
                      onSelect={e => this.handleSelectionChange(e, 'taskDetails')}
                    />
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {this.renderAssignToSelect()}
                  <Form.Item
                    label="Priority"
                    style={{ flex: 0.5 }}
                  >
                    <Select
                      allowClear={false}
                      value={this.state.priority}
                      options={this.priorityOptions.map(option => ({ value: option }))}
                      onChange={e => this.handleSelectionChange(e, 'priority')}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  name="notes"
                  label="Notes (optional)"
                  onChange={this.handleNotesChange}
                >
                  <Input.TextArea
                    placeholder="Notes (optional)"
                  />
                </Form.Item>
              </>
              )}
          </Form>
        </ModalWrapper>
      </div>
    );
  }
}

AutoUpload.defaultProps = {
  allEmaTaskTemplates: {},
};

AutoUpload.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  triggerAutoUploadAndTaskCreation: PropTypes.func.isRequired,
  allDocumentCategories: PropTypes.object.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
  allEmaTaskProviders: PropTypes.array.isRequired,
  getTaskTemplatesByQuery: PropTypes.func.isRequired,
  getEmaTaskProviders: PropTypes.func.isRequired,
  allEmaTaskTemplates: PropTypes.object,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
};

export default connect(state => ({
  allDocumentCategories: state.userProfile.allDocumentCategories,
  allEmaTaskTemplates: state.userProfile.allEmaTaskTemplates,
  allEmaTaskProviders: state.userProfile.allEmaTaskProviders,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
}))(AutoUpload);
