// Connects the Edit patient info modal component with store

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EditPatient from '../../components/modal/EditPatient';
import { getDocumentCategories } from '../../actions/userProfile';
import { updatePatientInfo, editReceivedRecordLabels } from '../../actions/records';
import { rowSelected } from '../../actions/inboxTableActions';
import { getLabelsByPage, getLabelsByQuery } from '../../actions/labels';
import { incrementReferralCount, decrementReferralCount } from '../../actions/socket';

class EditPatientDialog extends Component {

  static propTypes = {
    updatePatientInfo: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <EditPatient
          {...this.props}
          edit={this.props.updatePatientInfo}
        />
      </div>
    );
  }
}

export default connect(state => ({
  mode: state.records.mode,
  isLoading: state.records.isLoading,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
  sendToken: state.records.sendToken,
}), {
  getDocumentCategories,
  rowSelected,
  updatePatientInfo,
  getLabelsByPage,
  getLabelsByQuery,
  incrementReferralCount,
  editReceivedRecordLabels,
  decrementReferralCount,
})(EditPatientDialog);
