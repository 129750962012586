const handlingErrors = (statusCode, customMessage) => {
  if (statusCode === 500) {
    return `Oh no! We're currently experiencing issues on our end. Please try again.`;
  }
  if (statusCode === 520) {
    return `An unexpected error occured, please try again.`;
  }
  if (statusCode === 404) {
    return `Whoops! The file you're looking for can't be found!`;
  }
  if (statusCode === 400) {
    return `We were unable to process that request. Please try again.`
  }
  return customMessage || 'Oops, we ran into an issue. Please try again.';
};

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  errorMessage: null,
  headers: null,
  errorCode: null,
};

const failedState = {
  error: true,
  data: [],
};

function addresses(state = initialState, action) {
  switch(action.type){
    case 'BEGIN_GET_ADDRESSES':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_ADDRESSES':
      return {
        ...failedState,
        isLoading: false,
        errorMessage: handlingErrors(action.payload.response.status, 'Failed to get contacts'),
        errorCode: action.payload.response.status,
      };
    case 'FINISH_GET_ADDRESSES':
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.payload.data,
        headers: action.payload.headers,
      };
    case 'BEGIN_ADD_NEW_ADDRESS':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_ADD_NEW_ADDRESS':
      return {
        ...failedState,
        error: true,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    case 'FINISH_ADD_NEW_ADDRESS':
      return {
        ...state,
        isLoading: false,
        error: false,
        data: action.payload.data,
      };
    case 'BEGIN_UPDATE_ADDRESS':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'FAILED_UPDATE_ADDRESS':
      return {
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: action.payload.response.data.error,
        data: {
          ...state.data,
        },
      };
    case 'FINISH_UPDATE_ADDRESS':
      let updatedArray = [...state.data.recipients];
      const originalAddressIndex = updatedArray.findIndex(x => x.id === action.payload.data.recipient.id);
      const originalAddress = updatedArray[originalAddressIndex];
      updatedArray.splice(originalAddressIndex, 1, { ...originalAddress, ...action.payload.data.recipient})
      return {
        ...state,
        isLoading: false,
        errorCode: null,
        error: false,
        data: {
          ...state.data,
          recipients: updatedArray,
        },
      };
    case 'BEGIN_DELETE_ADDRESS':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'FAILED_DELETE_ADDRESS':
      return {
        ...failedState,
        error: true,
        errorMessage: action.payload.response.data.error,
      };
    case 'FINISH_EDIT_ADDRESS':
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    default:
      return state;
  }
}

export default addresses;
