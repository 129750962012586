import { useState, useEffect } from 'react';

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 641) {
        setBreakpoint('xsmall');
      } else if (width < 1025) {
        setBreakpoint('small');
      } else if (width < 1441) {
        setBreakpoint('medium');
      } else {
        setBreakpoint('large');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return breakpoint;
};
