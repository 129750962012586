/**
 * Extracts error message from error. If the API returns either error or message key, returns error
 * message from that. Otherwise, devise returns an array of errors so we join them together and
 * return.
 * @param {Error} error
 * @return {String}
 */
export const handleError = (error) => {
  // API returns errors in either 'error' or 'message' key
  if (error.response.data.error || error.response.data.message) {
    return error.response.data.error || error.response.data.message;
  }
  // Devise auth returns an array of errors and errorMessage joins them into a single string
  return error.response.data.errors.join(' ');
};
  
/**
 * Function to log unexpected errors related to authentication reqeusts to sentry.
 */
export const handleUnexpectedAuthError = (error, dispatch, type) => {
  // Something unexpectedly went wrong, capture error in Sentry
  window.raven.captureException(error);
  dispatch({
    type,
    payload: {
      error: 'Something went wrong. Try refreshing the page or contact support',
    },
  });
};
