import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DownloadZip from '../../components/modal/DownloadZip';

class DownloadZipDialog extends Component {

    render() {
        return (<div><DownloadZip {...this.props} /></div>);
    }
}

export default DownloadZipDialog;
