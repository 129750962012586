/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Tag,
  message,
  Input,
  Form,
} from 'antd';
import { stringify } from 'qs';

import ModalWrapper from './ModalWrapper';

const { Search } = Input;

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

// The typing timer is used in the search bar. It waits until the user's
// keystrokes are idle for 1 second before sending the request
// to Rails
let typingTimer = null;

class AddLabelToMultipleRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendTokens: props.modalType.data.record,
      receiverTypes: props.modalType.data.receiverTypes,
      labels: [],
      selectedLabels: [],
      maxAdditionalLabels: 5,
      searchLoading: false,
      receivers: [],
      labelCounts: [],
      labelLimitedExceeded: false,
    };
  }

  componentDidMount = async () => {
    // eslint-disable-next-line react/destructuring-assignment
    const page = 1;
    const pageSize = 20;
    const { getLabelsByPage } = this.props;
    await getLabelsByPage(page, pageSize);
    const { data, selectedRecord } = this.props;
    let maxLabelCount = 0;
    for (let i = 0; i < selectedRecord.length; i++) {
      maxLabelCount = maxLabelCount > selectedRecord[i].labels.length ? maxLabelCount : selectedRecord[i].labels.length;
    }

    const labelCounts = new Array(selectedRecord.length).fill(0);
    for (let i = 0; i < selectedRecord.length; i++) {
      labelCounts[i] = selectedRecord[i].labels.length;
    }

    this.setState({
      labels: data,
      receivers: selectedRecord,
      maxAdditionalLabels: 5 - maxLabelCount,
      labelCounts,
    });
  }

  selectLabel = (label) => {
    const { selectedLabels, receivers, labelCounts, maxAdditionalLabels } = this.state;
    const currentlySelected = [...selectedLabels];
    const indexOfSelectedLabel = currentlySelected.findIndex(selectedLabel => selectedLabel.name === label.name);
    const labelAlreadySelected = currentlySelected.find(selectedLabel => selectedLabel.name === label.name);
    if (maxAdditionalLabels === 0) {
      this.setState({ labelLimitedExceeded: true });
      return;
    }
    let maxLabelCount = 0;
    for (let i = 0; i < receivers.length; i++) {
      if (!receivers[i].labels.some(item => item.id === label.id)) {
        labelCounts[i] += 1;
      }
      if (maxLabelCount < labelCounts[i]) {
        maxLabelCount = labelCounts[i];
      }
    }
    this.setState({ maxAdditionalLabels: 5 - maxLabelCount });
    if (!labelAlreadySelected) {
      this.setState({
        selectedLabels: [...currentlySelected, label],
      });
    } else {
      currentlySelected.splice(indexOfSelectedLabel, 1);
      this.setState({
        selectedLabels: currentlySelected,
      });
    }
  }

  deselectLabel = (label, index) => {
    const { receivers, labelCounts } = this.state;
    for (let i = 0; i < receivers.length; i++) {
      if (!receivers[i].labels.some(item => item.id === label.id)) {
        labelCounts[i] -= 1;
        this.setState({ labelLimitedExceeded: false });
      }
    }
    let maxLabelCount = 0;
    for (let i = 0; i < labelCounts.length; i++) {
      if (maxLabelCount < labelCounts[i]) {
        maxLabelCount = labelCounts[i];
      }
    }

    const { selectedLabels } = this.state;
    const currentlySelected = selectedLabels;
    currentlySelected.splice(index, 1);
    this.setState(prevState => ({
      ...prevState,
      selectedLabels: [...currentlySelected],
      receivers,
      maxAdditionalLabels: 5 - maxLabelCount,
    }));
  }

  update = async (event) => {
    event.preventDefault();
    const {
      receivers,
      sendTokens,
      selectedLabels,
      receiverTypes,
    } = this.state;

    const { editReceivedRecordLabels } = this.props;
    const selectedLabelIds = [];
    selectedLabels.map(label => selectedLabelIds.push(label.id));
    for (let i = 0; i < receivers.length; i++) {
      const uniqueLabelIds = new Set(receivers[i].labels.map(label => label.id));
      const uniqueLabelsSelected = selectedLabels.filter(label => !uniqueLabelIds.has(label.id));
      const allLabels = [...receivers[i].labels, ...uniqueLabelsSelected];
      await editReceivedRecordLabels(sendTokens[i], selectedLabelIds, allLabels, receiverTypes[i], false, null, true);
    }

    const { updatedLabel, hideModal } = this.props;
    if (updatedLabel) {
      hideModal();
      message.success({
        content: 'Labels changed',
        style: { marginTop: '70px' },
      });
      this.updateRowSelected();
    }
  }

  onSearch = async (value) => {
    const { getLabelsByQuery } = this.props;
    const params = {
      page: 1,
      page_size: 20,
      contains: value,
    };
    const query = stringify(params, stringifyOptions);
    await getLabelsByQuery(query);
    const { data } = this.props;
    this.setState({
      labels: data,
      searchLoading: false,
    });
  }

  searchKeyUp = (event) => {
    this.setState({ searchLoading: true });
    // Maintain a 1 second timer before sending request
    // If any new key is pressed during the course of that
    // timer, reset the timer
    clearTimeout(typingTimer);
    const query = event.target.value;
    typingTimer = setTimeout(() => {
      this.searchQuery(query);
    }, 1000);
  }

  renderModal = () => {
    const { isLoading, hideModal: handleHideModal } = this.props;
    const {
      labels, selectedLabels, searchLoading, maxAdditionalLabels, labelLimitedExceeded,
    } = this.state;
    const { onSearch } = this;
    if (isLoading) {
      return (<span> Updating labels, Please wait... </span>);
    }

    const allowedAdditionalLabels = maxAdditionalLabels > 0;
    const noLabelsSelected = selectedLabels.length === 0;
    return (
      <React.Fragment>
        <div style={{ fontWeight: '800', display: 'flex', alignItems: 'flex-end' }}>
          Add Labels to Selected Records
          <span style={{
            color: allowedAdditionalLabels ? 'grey' : '#FCD12A',
            fontSize: '12px',
            marginBottom: '3px',
            marginLeft: '10px',
            display: 'block',
          }}
          >
            {allowedAdditionalLabels
              ? `(You can add up to ${maxAdditionalLabels} more label(s))`
              : '(You can add 0 more labels)'
            }
            <span style={{ color: 'red', display: 'block' }}>
              {labelLimitedExceeded && 'Remove current labels from individual records to add additional labels.'}
            </span>
          </span>
        </div>

        {noLabelsSelected
          ? <div>Since you've selected multiple records, you can only add labels. To remove labels, you'll need to select one record at a time.</div>
          : (
            selectedLabels.map((label, index) => (
              <Tag
                color={label.color}
                closable={true}
                key={`${label.name}${index}`}
                onClose={() => this.deselectLabel(label, index)}
                style={{
                  color: label.is_dark_text ? 'black' : 'white',
                  marginBottom: '5px',
                }}
              >
                {label.name}
              </Tag>
            ))
          )
        }
        <p style={{ fontWeight: 800, marginTop: '40px' }}>Additional Labels</p>
        <p style={{ color: 'grey', fontSize: 14 }}>
          Navigate to
          <Link to="/app/user?tab=manage_labels" onClick={handleHideModal}> Manage Labels </Link>
          in settings to add, edit, and delete labels.
        </p>
        <Search
          placeholder="search labels"
          allowClear
          onSearch={onSearch}
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          style={{ width: 200 }}
          disabled={!allowedAdditionalLabels}
          loading={searchLoading}
          onKeyUp={(e) => {
            this.setState({ searchLoading: true });
            // Maintain a 1 second timer before sending request
            // If any new key is pressed during the course of that
            // timer, reset the timer
            clearTimeout(typingTimer);
            const query = e.target.value;
            typingTimer = setTimeout(() => {
              this.onSearch(query);
            }, 1000);
          }}
        />

        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>

          {labels.map((label) => {
            const labelIsSelected = selectedLabels.find(selectedLabel => selectedLabel.name === label.name);
            const recordAlreadyHasLabel = selectedLabels.find(selectedRecordLabel => selectedRecordLabel.name === label.name);
            const labelTextColor = label.is_dark_text ? 'black' : 'white';
            return (
              <Tag
                key={label.id}
                onClick={() => !recordAlreadyHasLabel && this.selectLabel(label)}
                color={recordAlreadyHasLabel ? 'white' : label.color}
                style={{
                  color: recordAlreadyHasLabel ? label.color : labelTextColor,
                  marginBottom: '15px',
                  cursor: allowedAdditionalLabels ? 'pointer' : 'not-allowed',
                  border: recordAlreadyHasLabel ? `1px dotted ${label.color}` : (labelIsSelected && '2px solid black'),
                }}
              >
                {label.name}
              </Tag>
            );
          })}
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }

  updateRowSelected() {
    const { selectedLabels, fileToken } = this.state;
    const { modalType, rowSelected } = this.props;
    const selectedRow = {
      labels: selectedLabels,
      file_token: fileToken,
      index: modalType.data.record.index,
    };
    rowSelected([selectedRow]);
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.update}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading}
        modalTitle="Add Labels"
        form="addLabelToRecord"
      >
        <Form
          layout="vertical"
          onFinish={this.update}
          id="addLabelToRecord"
        >
          {this.renderModal()}
        </Form>
      </ModalWrapper>
    );
  }
}

AddLabelToMultipleRecords.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  rowSelected: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updatedLabel: PropTypes.bool.isRequired,
  editReceivedRecordLabels: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selectedRecord: PropTypes.array.isRequired,
  getLabelsByPage: PropTypes.func.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedLabel: state.records.updatedLabel,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
}))(AddLabelToMultipleRecords);
