// Shows modal for asking the user to logout
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import ModalWrapper from './ModalWrapper';
import { validFaxNumber, validSendEmail } from '../../global/validation';

const pStyle = {
  textAlign: 'center',
  fontWeight: '600',
};

class ReplyToEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: '',
      body: '',
      recipientName: '',
      recipientEmail: '',
      recipientFax: '',
      recipientPhone: '',
      fileNames: [],
      sendToken: '',
      attachments: [],
      validName: '',
      validFax: '',
      validSubject: '',
      validEmail: '',
      replySuccess: false,
      hasResponse: false,
      recordBlobObject: null,
    };
  }

  componentWillMount = () => {
    const { modalType } = this.props;
    const { data } = modalType;
    const { subject, recipientName, recipientEmail, sendToken, hasResponse, recipientFax, recordBlobObject } = data;
    this.setState({
      subject: `Re: ${subject || ''}`,
      recipientFax,
      recipientName,
      recipientEmail,
      sendToken,
      hasResponse,
      recordBlobObject,
    });
  }

  validateName = (name) => {
    if (name && name.trim() !== '') return true;
    return false;
  }

  validateReply = async () => {
    const { subject, recipientName, recipientEmail, recipientFax } = this.state;
    const validateName = this.validateName(recipientName);
    const validateSubject = this.validateName(subject);

    await this.setState({
      validName: !validateName ? 'Name cannot be blank' : '',
      validFax: recipientFax && (validFaxNumber(recipientFax).valid ? '' : 'Invalid fax number'),
      validSubject: !validateSubject ? 'Subject cannot be blank' : '',
      validEmail: recipientEmail ? !validSendEmail(recipientEmail) && 'Invalid email' : 'Email cannot be empty',
    });

    this.sendReply()
  }

  sendReply = async () => {
    const { validName, validFax, validSubject, validEmail } = this.state;
    const {
      hideModal,
      error,
      sendReplyToRecord,
    } = this.props;

    const {
      subject,
      recipientName,
      recipientEmail,
      sendToken,
      attachments,
      recipientFax,
      recipientPhone,
      body,
      recordBlobObject,
    } = this.state;

    // Do not send request if any of the required or filled in fields are invalid
    if ( validName || validFax || subject && validSubject || !recipientEmail || validEmail  ) return;
    const bodyFormData = new FormData();
    bodyFormData.append('subject', subject);
    bodyFormData.append('name', recipientName);
    bodyFormData.append('from', recipientEmail);
    attachments.forEach(function(file) {bodyFormData.append('attachments[]', file)});
    recipientPhone && bodyFormData.append('phone_number', recipientPhone);
    recipientFax && bodyFormData.append('fax_number', recipientFax);
    body && bodyFormData.append('memo', body);
    recordBlobObject && bodyFormData.append('edited_record', recordBlobObject.get('file'), 'signed_record.pdf');

    await sendReplyToRecord(sendToken, bodyFormData);
    if (!error) {
      this.setState({
        replySuccess: true,
      });
      hideModal();
    }
  }

  attachFile = (files) => {
    const newFileNames = [];
    const newFiles = [];

    for (let i=0; i < files.length; i++) {
      newFileNames.push(files[i].name);
      newFiles.push(files[i]);
    }

    this.setState(prevState => ({
      fileNames: [...prevState.fileNames, ...newFileNames],
      attachments: [...prevState.attachments, ...newFiles],
    }));
  }

  removeAttachment = (index) => {
    const { fileNames, attachments } = this.state;
    const newFileNames = fileNames;
    const newAttachmentArray = attachments;
    newFileNames.splice(index, 1);
    newAttachmentArray.splice(index, 1);
    this.setState({
      fileNames: newFileNames,
      attachments: newAttachmentArray,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  modalMessage = () => {
    const { isLoading, error } = this.props;
    if (isLoading) {
      return (<span> Uploading, Please wait... </span>);
    }

    if (error) {
      return (<span> Error responding to record, please try again </span>);
    }

    const {
      subject,
      body,
      recipientEmail,
      recipientName,
      recipientFax,
      recipientPhone,
      fileNames,
      validName,
      validSubject,
      validFax,
      validEmail,
      replySuccess,
    } = this.state;

    const { hasResponse } = this.props;

    if (hasResponse) return <span> You have already responded to this record </span>;
    if (replySuccess) return <span> Reply successfully sent </span>;

    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <TextField
              value={`${subject}`}
              onChange={this.onChange}
              floatingLabelText="Subject"
              style={{ width: '100%' }}
              name="subject"
              errorText={validSubject}/>
          </div>
            <TextField
              value={body}
              onChange={this.onChange}
              floatingLabelText="Body"
              style={{ width: '100%' }}
              name="body"
              multiLine={true}
            />
          <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <RaisedButton
              containerElement="label"
              label="Attach document(s)"
              primary={true}
              style = {{ width: "30%", minWidth: '165px' }}
              labelStyle={{ textTransform: 'none', fontWeight: '400', fontSize: '15px'}}
            >
              <input
                onChange={e => this.attachFile(e.target.files)}
                style={{ display: 'none' }}
                type="file"
                name="file[]"
                multiple
              />
            </RaisedButton>
            <div style={{width: '65%', flexWrap: 'wrap', display: 'flex' }}>
              {fileNames && fileNames.map((file, index) => (
                <div style={{ backgroundColor: '#EEEEEE', margin: '5px', padding: '5px 20px 5px 10px', borderRadius: '10px', display: 'flex' }}>
                  <FontIcon
                    className="material-icons"
                    onClick={() => this.removeAttachment(index)} style={{ marginRight: '10px', color: 'grey', cursor: 'pointer' }}>
                    cancel
                  </FontIcon>
                  {file}
                </div>
              ))}
            </div>
          </div>
          <p>
            <b>Note: </b>
            You can only upload jpg, tiff, png, bmp, or pdf files.
          </p>
          <br />
          <h4 style={pStyle}>
            Your Contact Info
          </h4>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                onChange={this.onChange}
                value={recipientName}
                name="recipientName"
                floatingLabelText="Name*"
                style={{ width: '100%' }}
                errorText={validName}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                onChange={this.onChange}
                value={recipientEmail}
                name="recipientEmail"
                floatingLabelText="Email*"
                style={{ width: '100%' }}
                errorText={validEmail}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                onChange={this.onChange}
                value={recipientPhone}
                name="recipientPhone"
                style={{ width: '100%' }}
                floatingLabelText="Phone Number"
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TextField
                onChange={this.onChange}
                value={recipientFax}
                name="recipientFax"
                floatingLabelText="Fax Number"
                errorText={validFax}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const customContentStyle = {
      width: '90%',
    };

    const { replySuccess, hasResponse } = this.state;
    const { hideModal, isLoading, error } = this.props;
    const hasNoCurrentOrPastResponse = !replySuccess && !hasResponse;

    return (
      <ModalWrapper
        hideModal={hideModal}
        primary={true}
        action={this.validateReply}
        actionName={hasNoCurrentOrPastResponse && !isLoading && !error ? 'Reply' : ''}
        dismiss={replySuccess || hasResponse ? 'Close' : 'Cancel'}
        customContentStyle={customContentStyle}
        modalTitle="Reply To Record"
      >
        {this.modalMessage()}
      </ModalWrapper>
    );
  }
}

ReplyToEmail.propTypes = {
  hideModal: PropTypes.func.isRequired,
  sendReplyToRecord: PropTypes.func.isRequired,
  hasResponse: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};


export default ReplyToEmail;
