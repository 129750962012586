// Component that handles authentication and saving record to medsender
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  MedsenderUser,
  LoginModal,
} from './ModalContent';
import ModalWrapper from '../ModalWrapper';
import ModalLoading from '../ModalLoading';
import { validSendEmail as validEmail } from '../../../global/validation';

class AddRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
      email: '',
      password: '',
      otp: '',
      errorStatus: {
        error: false,
        usernameMsg: '',
        passwordMsg: '',
        optMsg: '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errorStatus.error === true) {
      // Upon error, reset state
      this.setState({
        email: '',
        password: '',
      });
    }
  }

  /**
   * Loads the component with the appropriate content
   * Uses props and local state to display the right content
   */
  selectModalContent() {
    const { loading, loggedIn, needs2FA } = this.props;
    const { account } = this.state;

    if ((account && loading) || loggedIn) {
      const status = loggedIn ? 'Authenticated Successfully. Saving Record ...' : 'Authenticating ...';
      return {
        dismiss: null,
        actionName: null,
        action: null,
        component: <ModalLoading status={status} />,
      };
    } else if (account && !loggedIn) {
      const loginComponent = (<LoginModal
        errorStatus={this.state.errorStatus}
        handleChange={this.handleChange}
        loginError={this.props.errorStatus}
        needs2FA={this.props.needs2FA}
        resendModalOtp={this.resendOtp}
        startOver={this.startOver}
      />);
      return {
        dismiss: 'CANCEL',
        actionName: needs2FA ? 'SUBMIT' : 'LOGIN',
        action: this.login,
        component: loginComponent,
      };
    }
    return {
      dismiss: 'NO',
      actionName: 'YES',
      action: this.accountAvailable,
      component: <MedsenderUser />,
    };
  }

  // Invoked when user has medsender account
  accountAvailable = () => {
    this.setState(prevState => ({
      account: !prevState.account,
    }));
  }

  /**
   * Hides the modal by updating global state
   * if User has no account redirect to medsender.com
   */
  hideModal = () => {
    const { errorSaving } = this.props;
    if (!this.state.account && !errorSaving) {
      window.location = 'https://medsender.com/signup';
    }
    this.props.hideModal();
  }

  // Validates input of login modal
  validateInput() {
    let errorStatus = {
      error: false,
      usernameMsg: '',
      passwordMsg: '',
      otpMsg: '',
    };

    if (this.props.needs2FA) {
      if (!this.state.otp) {
        errorStatus = {
          ...errorStatus,
          error: true,
          otpMsg: 'Verification code cannot be empty',
        };
        return errorStatus;
      }
      return errorStatus;
    }

    if (!validEmail(this.state.email)) {
      errorStatus = {
        ...errorStatus,
        error: true,
        usernameMsg: 'A valid email is required',
      };
    }

    if (!this.state.password) {
      errorStatus = {
        ...errorStatus,
        error: true,
        passwordMsg: 'Password cannot be empty',
      };
    }
    return errorStatus;
  }

  // Checks if user has a medsender account
  login = (event) => {
    event.preventDefault();
    // Validate input before submitting
    const inputValid = this.validateInput();

    this.setState({ errorStatus: inputValid });
    // If input is okay, continue with request
    if (inputValid.error === false) {
      const { needs2FA, otpAuthToken, fax, user } = this.props;
      const data = {};
      if (needs2FA) {
        data.email = user.uid
        data.otp_auth_token = otpAuthToken;
        data.otp_attempt = this.state.otp;
      } else {
        data.email = this.state.email;
        data.password = this.state.password;
      }
      return this.props.modalLogin(data, fax);
    }
  }

  // Handles resending of verifcation code
  resendOtp = (event) => {
    event.preventDefault();
    const data = {
      email: this.props.user.uid,
      otp_auth_token: this.props.otpAuthToken,
    };
    return this.props.resendModalOtp(data);
  }

  // Handles updating the global state to start over the login process
  startOver = (event) => {
    event.preventDefault();
    return this.props.startOver();
  }

  // Updates local state with user and password
  handleChange = (event) => {
    const change = {
      [event.target.name]: event.target.value,
    };
    this.setState(change);
  }

  render() {
    const customContentStyle = {
      width: '27%',
    };
    const modal = this.selectModalContent();
    return (
      <ModalWrapper
        hideModal={this.hideModal}
        action={modal.action}
        customContentStyle={customContentStyle}
        dismiss={modal.dismiss}
        actionName={modal.actionName}
      >
        {modal.component}
      </ModalWrapper>
    );
  }
}

AddRecord.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  fax: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errorSaving: PropTypes.bool.isRequired,
  errorStatus: PropTypes.object.isRequired,
  resendModalOtp: PropTypes.func.isRequired,
  startOver: PropTypes.func.isRequired,
  needs2FA: PropTypes.bool.isRequired,
  otpAuthToken: PropTypes.string.isRequired,
};

export default AddRecord;
