import {
  SendModal,
  SendRecipients,
  SendStatus,
  SendFax,
} from '../../../assets/img/guide';

const sendingRecordCopy = {
  pageTitle: 'Sending a Document',
  pageSubtitle: `Easily send a record to any fax number or email address. 
  (Sending to multiple recipients now supported)`,
  sections: [
    {
      title: 'How to Send',
      id: 'howToSend',
      subtitle: `Either select a record in an inbox and hit Send in the toolbar or 
      when viewing a record, select the “Send” button in the top left corner. This will open 
      the Send menu.`,
      img: SendModal,
      divider: true,
    },
    {
      title: '1. Send securely to an email address',
      id: 'sendToEmail',
      subtitle: `To send via email, type an email address in the recipient field. You can enter 
      a subject line for your email as well as a note for the body of the email. To request a form 
      or signature be filled out, click the second checkbox.`,
      img: SendRecipients,
      divider: true,
    },
    {
      title: '2. Send to a fax number',
      id: 'sendToFax',
      subtitle: `To send a fax, type in a fax number in the recipient field. You can specify a 
      recipient organization, recipient name, and a memo, all of which will be included in the 
      cover sheet.`,
      img: SendFax,
    },
    {
      subtitle: `
      You can track the status of all sent records in the “Sent Records” section on your dashboard.
      A green check mark indicates an email has been successfully delivered, and a green eye indicates 
      that the recipient has viewed the record. A green fax icon indicates that the fax was successfully sent.
      A green pen indicates that a record has been signed, while a red pen indicates that a record has not yet 
      been signed. A red exclamation mark indicates that a fax failed to send.`,
      img: SendStatus,
      divider: true,
    },
  ],
};

export default sendingRecordCopy;
