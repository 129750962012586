import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { BILLING_ENUMS } from './constants';
import {
  BillingMessage,
  PlanSelector,
  ProcessNewSubscriptionPayment,
  ProcessUpgradeSubscriptionPayment,
  WelcomePrompt,
  UpdatePaymentMethod,
  BasicPlan,
} from './modals';

class BillingPrompt extends Component {
  shouldDisplayPrompt = () => {
    const lastDismissTime = localStorage.getItem('m8_prompt_dismissed_time');
    // If users have never seen billing prompt, display
    if (!lastDismissTime || isNaN(new Date(lastDismissTime))) return true;

    const { billingTrialDetails } = this.props;

    const {
      trial_days_remaining: daysRemainingInTrial,
    } = billingTrialDetails;

    const currentTime = moment(new Date().toISOString());
    // How much time has passed between now and the last time they dismissed the modal
    const timeDifference = moment.duration(currentTime - moment(lastDismissTime));
    // Always display if user is on the last day or the trial is finished
    if (daysRemainingInTrial <= 1) {
      return true;
    }

    if (daysRemainingInTrial <= 7) {
      // Display prompt every single day once 7 days remain.
      // Wait until at least one day has passed before showing prompt again
      if (timeDifference._data.days >= 1) return true;
    }

    if (daysRemainingInTrial > 7) {
      // Display prompt a week when there is at least one week left
      // Wait until at least a week has passed before showing prompt again
      if (timeDifference._data.days > 6) return true;
    }
    return false;
  }

  isValidTrial = () => {
    const { billingTrialDetails } = this.props;
    return billingTrialDetails && billingTrialDetails.trial_days_remaining > 0;
  }

  isValidEndDate = () => {
    const { billingTrialDetails } = this.props;
    return billingTrialDetails && billingTrialDetails.trial_end_date !== 'none';
  }

  isBillingValid = () => {
    const { billingTrialDetails, planType } = this.props;
    return billingTrialDetails && billingTrialDetails.billing_on_file === true && (planType !== 'cancelled' && planType !== 'revoked');
  }

  isFirstTime = () => {
    const { billingTrialDetails } = this.props;
    const lastDismissTime = localStorage.getItem('m8_prompt_dismissed_time');
    const noEndDate = billingTrialDetails.trial_end_date === 'none';
    if (!lastDismissTime && !noEndDate && billingTrialDetails.trial_days_remaining > 0) return true;
    return false;
  }

  closeModal = () => {
    const MODAL_CLOSE = -1;
    const RESET_PLAN_TYPE = null;
    const { changeModal } = this.props;
    changeModal(MODAL_CLOSE, RESET_PLAN_TYPE, false);
  }

  render() {
    // If billing info is OK, don't display
    // If we should display, then display
    const { modalState, modalOpen } = this.props;
    const { closeModal } = this;
    const {
      PLAN_SELECTOR,
      UPGRADE_PLAN,
      BASIC_PLAN,
      PROCESS_NEW_SUBSCRIPTION_PAYMENT,
      PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT,
      UPDATE_PAYMENT_METHOD,
    } = BILLING_ENUMS;
    const validBilling = this.isBillingValid();
    const validTrial = this.isValidTrial();
    const validEndDate = this.isValidEndDate();
    const shouldDisplayPrompt = this.shouldDisplayPrompt();
    let hideModal = false;
    if (!modalOpen) {
      hideModal = validBilling || !shouldDisplayPrompt || !validEndDate;
    }

    // No need to display if billing information is already on file
    // Or if user chose to hide billing prompt until next time
    if (hideModal) return null;
    const isUpgradeInProgress = [UPGRADE_PLAN, PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT].includes(modalState);
    const billingProps = {
      validBilling,
      validTrial,
      isUpgradeInProgress,
      closeModal,
      ...this.props,
    };
    // Do not show welcome prompt if its first time and user is billing/ or on trial.
    if (this.isFirstTime() && !validBilling) return (<WelcomePrompt {...billingProps} />);
    if (modalState === PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT) return (<ProcessUpgradeSubscriptionPayment {...billingProps} />);
    if (modalState === PROCESS_NEW_SUBSCRIPTION_PAYMENT) return (<ProcessNewSubscriptionPayment {...billingProps} />);
    if (modalState === PLAN_SELECTOR) return (<PlanSelector {...billingProps} />);
    if (modalState === UPGRADE_PLAN) return (<PlanSelector {...billingProps} />);
    if (modalState === BASIC_PLAN) return (<BasicPlan {...billingProps} />);
    if (modalState === UPDATE_PAYMENT_METHOD) return (<UpdatePaymentMethod {...billingProps} />);
    return (<BillingMessage {...billingProps} />);
  }
}

BillingPrompt.defaultProps = {
  modalState: BILLING_ENUMS.DISPLAY_TRIAL_ENDING,
};

BillingPrompt.propTypes = {
  modalState: PropTypes.number,
  modalOpen: PropTypes.bool.isRequired,
  changeModal: PropTypes.func.isRequired,
};

export default BillingPrompt;
