const viewPort = window.innerWidth; 
const initialState = {
  sidebarVisible: false,
  isLoading: false,
  error: false,
  errorMessage: null,
  errorCode: null,
};

function pageThatRequiresSidebarOpenByDefault(url) {
  if (url.includes('/app/uploads')
    || url.includes('app/sent')
    || url.includes('app/received')
    || url.includes('app/assigned')
    || url.includes('app/referred')
  ) return true;
  return false;
}

function appState(state = initialState, action) {
  switch (action.type) {
    case 'SIDEBAR_TOGGLE':
      return {
        ...state,
        sidebarVisible: !action.payload.toggled,
      };
    case '@@router/LOCATION_CHANGE':
      if (pageThatRequiresSidebarOpenByDefault(action.payload.pathname)) {
        return {
          ...state,
          sidebarVisible: viewPort > '760' ? true : false,
        };
      }
      return {
        ...state,
        sidebarVisible: false,
      };
    case 'SEND_REFERRAL':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'FINISHED_SEND_REFERRAL':
      return {
        ...state,
        isLoading: false,
        error: false,
        status: action.payload.data.message,
      };
    case 'FAILED_SEND_REFERRAL':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'CLEAR_REFERRAL':
      return {
        ...state,
        status: null,
      };
    case 'REDIRECT_TO_MANAGE_LABELS':
      return {
        ...state,
      };
    case 'BEGIN_CHANGE_DEFAULT_ORGANIZATION':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_CHANGE_DEFAULT_ORGANIZATION':
      return {
        ...state,
        isLoading: false,
        error: true,
        errorCode: action.payload.response.status,
        errorMessage: 'Something went wrong. Try refreshing the page or contact support.',
      };
    case 'FINISHED_CHANGE_DEFAULT_ORGANIZATION':
      return {
        ...state,
        isLoading: false,
      };
    case 'SHOW_ERROR_DISPLAY':
      return {
        ...state,
        error: true,
        errorCode: action.payload.status,
        errorMessage: 'Something went wrong. Try refreshing the page or contact support.',
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default appState;
