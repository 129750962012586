const checkTriggered = () => (dispatch) => {
  dispatch({
    type: 'SIDEBAR_CHECKBOX_TRIGGER',
  });
};

const changeSidebarDate = newDate => (dispatch) => {
  dispatch({
    type: 'SIDEBAR_DATE_CHANGE',
    newDate,
  });
};

const changeSidebarMenu = newMenuItem => (dispatch) => {
  dispatch({
    type: 'SIDEBAR_SORT_MENU_CHANGE',
    newMenuItem,
  });
};

export {
  checkTriggered,
  changeSidebarDate,
  changeSidebarMenu,
};
