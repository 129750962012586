const setNumberOfNewReferrals = (newReferralsCount) => {
  return ({
    type: 'SET_NEW_REFERRALS_COUNT',
    payload: {
      newReferralsCount,
    },
  });
};

const incrementReferralCount = () => {
  return ({
    type: 'INCREMENT_NEW_REFERRALS_COUNT',
  });
};

const decrementReferralCount = () => {
  return ({
    type: 'DECREMENT_NEW_REFERRALS_COUNT',
  });
};

export {
  setNumberOfNewReferrals,
  incrementReferralCount,
  decrementReferralCount,
};
