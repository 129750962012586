// Connects the Revoke access modal action

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import RevokeRecord from '../../components/modal/RevokeRecord';
import { revokeRecordAcessById } from '../../actions/records';

class RevokeRecordDialog extends Component {
  render() {
    return (
      <div>
        <RevokeRecord
          {...this.props}
          revokeRecordAcessById={this.props.revokeRecordAcessById}
        />
      </div>
    );
  }
}

RevokeRecordDialog.propTypes = {
  revokeRecordAcessById: PropTypes.func.isRequired,
};

export default connect(null, { revokeRecordAcessById })(RevokeRecordDialog);
