import crud from './helpers/crud';

const sendToMultipleRecipients = (id, receivers, canSend, documentType, subject, memo, signatureRequired, callerId, allowPatientEdits, org = '', contact = '', removeCoversheet = false) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SEND_TO_MULTIPLE_RECIPIENTS',
      fail: 'FAILED_SEND_TO_MULTIPLE_RECIPIENTS',
      end: 'FINISHED_SEND_TO_MULTIPLE_RECIPIENTS',
    },
    method: 'POST',
    url: `/api/v1/records/${id}/send_to_multiple_users`,
    data: {
      receivers,
      can_send: canSend,
      document_type: documentType,
      signature_required: signatureRequired,
      subject,
      memo,
      caller_id: callerId,
      receiver_org: receivers.length === 1 ? org : null,
      receiver_name: receivers.length === 1 ? contact : null,
      remove_cover_sheet: removeCoversheet,
      editable: allowPatientEdits,
    },
  })
);

const sendPatientRecord = (id, patient, memo, disableSecureReplies) => (
  crud({
    dispatch: {
      begin: 'BEGIN_SEND_PATIENT_RECORD',
      fail: 'FAILED_SEND_PATIENT_RECORD',
      end: 'FINISHED_SEND_PATIENT_RECORD',
    },
    method: 'POST',
    url: `/api/v1/records/${id}/send_to_patient`,
    data: {
      patient: {
        recipient: patient.recipient,
        dob: patient.dob,
        name: patient.name,
        force: patient.force,
        signature_required: patient.signatureRequired,
        note: memo,
        disable_replies: disableSecureReplies,
      },
    },
  })
);

const updateCanEditStatus = () => {
  return ({
    type: 'UPDATE_CAN_EDIT_STATUS',
  });
};

export {
  sendPatientRecord,
  sendToMultipleRecipients,
  updateCanEditStatus,
};
