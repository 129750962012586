import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Dropdown,
  Menu,
} from 'antd';

import {
  UserOutlined,
  TeamOutlined,
  PoweroffOutlined,
  QuestionCircleOutlined,
  FieldTimeOutlined,
  HourglassOutlined,
  ArrowUpOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { BILLING_ENUMS, upgradablePlans } from '../billing/constants';

const bottomBorder = {
  borderBottom: '1px solid lightgrey',
  paddingBottom: '5px',
  marginBottom: '5px',
};

class StatusBar extends Component {
  openBillingModal = () => {
    const { changeModal } = this.props;
    changeModal(BILLING_ENUMS.PLAN_SELECTOR, -1, true);
  }

  openUpgradePlanModal = () => {
    const { changeModal } = this.props;
    changeModal(BILLING_ENUMS.UPGRADE_PLAN, -1, true);
  }

  loginAsAdmin = async () => {
    const { loginAdmin } = this.props;
    await loginAdmin();

    const { adminLoginSuccess, loginErrorMessage } = this.props;
    if (adminLoginSuccess) {
      const win = window.open(`${window.location.origin}/admin`, '_blank');
      win.opener = null;
    } else {
      /* global toastr */
      toastr.error(loginErrorMessage);
    }
  }

  // linkToProfile() and linkToSupportSite() are onClick handlers that programmatically change the url. Menu.Item
  // cannot be wrapped in a DOM element because it prevents props from being passed down and causing console errors
  linkToProfile = () => {
    this.context.router.history.push('/app/user');
  }

  linkToSupportSite = () => {
    const win = window.open('https://medsender.com/support', '_blank');
    win.opener = null;
  };

  openDocumentation = async () => {
    this.context.router.history.push('/app/guide');
  }

  render() {
    const referralButton = {
      border: '2px solid white',
      padding: '5px 10px',
      borderRadius: '20px',
      marginRight: '15px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: 0,
    };
    const {
      logout,
      user,
      isAdmin,
      billingTrialDetails: { 
        billing_on_file: billingOnFile,
        trial_days_remaining: trialDaysRemaining,
        trial_end_date: trialEndDate,
        plan_type: planType,
      },
    } = this.props;

    const currentYear = new Date().getFullYear();

    const menu = (
      <Menu>
        <Menu.Item key="0" icon={<UserOutlined />} onClick={this.linkToProfile} className="ant-dropdown-menu-item">
          Profile
        </Menu.Item>
        <Menu.Item key="1" icon={<TeamOutlined />} onClick={this.loginAsAdmin}>
          Admin Login (User Management)
        </Menu.Item>
        <Menu.Item key="2" icon={<QuestionCircleOutlined />} style={bottomBorder} className="ant-dropdown-menu-item" onClick={this.linkToSupportSite}>
          Contact/Help
        </Menu.Item>
        <Menu.Item key="3" icon={<FileTextOutlined />} style={bottomBorder} className="ant-dropdown-menu-item" onClick={this.openDocumentation}>
          Guides
        </Menu.Item>
        <Menu.Item key="4" icon={<PoweroffOutlined />} onClick={logout}>
          Sign out
          <span style={{
            marginLeft: '6px',
            fontSize: '.7em',
            color: 'gray',
            display: 'inline-block',
          }}
          >
            {user ? user.uid : null}
          </span>
        </Menu.Item>
        <p style={{ textAlign: 'center', fontSize: '.7em' }}>&copy; {currentYear} Medsender, Inc.</p>
      </Menu>
    );
    const iconStyle = { color: '#fff' };
    return (
      <div style={{ marginTop: '7px', display: 'flex', alignItems: 'center' }}>
        {
          (!billingOnFile && trialEndDate !== 'none') && (
            <button type="button" className="trial-overview" onClick={this.openBillingModal} style={referralButton}>
              {trialDaysRemaining > 0 ? <FieldTimeOutlined style={iconStyle} /> : <HourglassOutlined style={iconStyle} />}
              <div className="trial-overview-text" style={{ fontSize: '15px', marginLeft: '10px' }}>
                {
                trialDaysRemaining > 0
                  ? `${trialDaysRemaining} day(s) left in trial`
                  : 'Trial period ended'
                }
              </div>
            </button>
          )
        }
        {
          (upgradablePlans.includes(planType) && isAdmin) && (
            <button type="button" className="trial-overview" onClick={this.openUpgradePlanModal} style={referralButton}>
              <div className="trial-overview-text" style={{ fontSize: '15px', marginLeft: '2px' }}>
                <ArrowUpOutlined style={{ marginRight: '5px' }} />
                Upgrade Your Account
              </div>
            </button>
          )
        }
        {/* zIndex set to 1500 because purple header has a zIndex of 1100 */}
        <Dropdown overlay={menu} trigger={['click']} overlayStyle={{ zIndex: 1500 }}>
          <Avatar
            onClick={this.handleClick}
            style={{ cursor: 'pointer' }}
            size={35}
          >
            {user.name}
          </Avatar>
        </Dropdown>
      </div>
    );
  }
}

StatusBar.contextTypes = {
  router: PropTypes.object.isRequired,
};

StatusBar.defaultProps = {
  user: { uid: '', name: 'TU' },
};

StatusBar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  loginAdmin: PropTypes.func.isRequired,
  adminLoginSuccess: PropTypes.bool.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  changeModal: PropTypes.func.isRequired,
  billingTrialDetails: PropTypes.shape({
    plan_type: PropTypes.string.isRequired,
    billing_on_file: PropTypes.bool.isRequired,
    trial_end_date: PropTypes.string.isRequired,
    trial_days_remaining: PropTypes.number.isRequired,
  }).isRequired,
};

export default StatusBar;
