import React, { useState } from 'react';
import { Tag, Tooltip, Button, Select } from 'antd';
import Link from 'react-router-dom/Link';
import './constants.scss'

import Icon,
{
  UserOutlined,
  TeamOutlined,
  GlobalOutlined,
  LockOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  MessageTwoTone,
  LinkOutlined,
  EyeFilled,
  DownloadOutlined,
  CloudUploadOutlined,
  UploadOutlined,
  BellOutlined,
  BellTwoTone,
  SolutionOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  CarryOutOutlined,
  CommentOutlined,
  SyncOutlined,
  ExclamationOutlined,
  WarningOutlined,
  MessageOutlined,
  EditOutlined,
  ThunderboltTwoTone,
} from '@ant-design/icons';
import axios from 'axios';
import { validFaxNumber, formatValidFaxNumber, validEmail } from '../../../global/validation';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { getSentToProviderIcon, getSentToPatientIcon, getSignatureCompletedIcon } from './sent_inbox_constants';

const privacyLevelIcon = {
  organization: { icon: <GlobalOutlined />, tooltip: 'This record is shared with the organization' },
  department: { icon: <TeamOutlined />, tooltip: 'This record is shared with your department' },
  owner: { icon: <LockOutlined />, tooltip: 'This record is private' },
};

const renderPrivacyLevel = (privacyLevel) => {
  const { icon, tooltip } = privacyLevelIcon[privacyLevel];
  return (
    <Tooltip title={tooltip} placement="left">
      {icon}
    </Tooltip>
  );
};

const renderArchivedBy = (archivedBy) => {
  return (
    <Tooltip title={`Archived by: ${archivedBy}`}>
      <UserOutlined />
    </Tooltip>
  );
};

const renderUploadedBy = (uploadedBy) => {
  return (
    <Tooltip title={`Uploaded by: ${uploadedBy}`}>
      <UploadOutlined />
    </Tooltip>
  );
};

const recordViewerPath = (viaChromeExtension, fileToken) => {
  const path = viaChromeExtension ? `/app/chrome/view/${fileToken}` : `/app/view/${fileToken}`;
  return path;
};

const renderMerged = () => (
  <Tooltip title="This record was created by merging multiple records">
    <ShrinkOutlined style={{ marginLeft: '5px' }} />
  </Tooltip>
);

const renderSplitted = () => (
  <Tooltip title="This record was created by splitting a record">
    <ArrowsAltOutlined style={{ marginLeft: '5px' }} />
  </Tooltip>
);

const sharedColumnTitles = (fromPage, viaChromeExtension = false) => (
  [
    {
      title: 'Title',
      className: 'title',
      dataIndex: 'receiver_name',
      render: (text, record) => {
        const {
          document_title: documentTitle,
          document_category: documentCategory,
          file_token: fileToken,
          send_token: sendToken,
          slug: assignDocumentId,
        } = record;

        const sendTokenParam = sendToken ? `&sendToken=${sendToken}` : '';
        const assignedDocumentParam = assignDocumentId ? `&assignedDocumentId=${assignDocumentId}` : '';

        return (
          <>
            {record.file_token
              && (
                <span>
                  <Link
                    to={{
                      pathname: recordViewerPath(viaChromeExtension, fileToken),
                      search: `?from=${fromPage}${sendTokenParam}${assignedDocumentParam}`,
                    }}
                  >
                    <span style={{ fontWeight: '500' }}>{documentTitle}</span>
                  </Link>
                  <br />
                </span>
              )}
            {record.document_category && fromPage !== 'referred' && <span className="category-badge">{documentCategory}</span>}
          </>
        );
      },
    },
    {
      title: 'Patient Info',
      dataIndex: 'patient_name',
      className: 'patientName',
      render: (text, record) => {
        const {
          send_token: sendToken,
          file_token: fileToken,
          is_merged: isMerged,
          is_splitted: isSplitted,
          uri,
          ai_status: aiStatus,
          patient_info_edited: patientInfoEdited,
          failed_column: failedColumn,
          imported_into_emr: importedIntoEMR,
          auto_upload_created: autoUploadCreated,
          auto_upload_in_progress: autoUploadInProgress,
          task_in_progress: taskUploadInProgress,
          task_autoupload_in_progress: taskAutoUploadInProgress,
          task_after_chart_creation_in_progress: taskAfterChartInProgress,
          task_after_chart_creation_success: taskAfterChartSuccess,
          chart_task_error: chartTaskError,
          chart_in_progress: chartInProgress,
          chart_created: chartCreated,
          task_created: taskCreated,
          task_autoupload: taskAutoUpload,
          emr_import_error: emrImportError,
          task_creation_error: taskCreationError,
          chart_creation_error: chartCreationError,
          slug: assignDocumentId,
          is_patient_info_duplicate: duplicatePatientDetection,
          emr_patient_chart_link: emrPatientChartLink,
        } = record;
        let showAiIcon = false;
        if (aiStatus === 'failed') {
          showAiIcon = fromPage === 'received' && !patientInfoEdited && failedColumn !== 'both';
        } else if (aiStatus === 'successful') {
          showAiIcon = fromPage === 'received' && !patientInfoEdited;
        }

        // If the record object has a uri key, it is referencing a received record
        // that is a reply from a user or patient. File tokens are not directly accessible on replies
        //
        // Therefore, from a rendering perspective, in the received records page, the patient name will appear as
        // as normal text, not clickable but on chrome received it will be clickable. But replies will be visible (logic
        // is handled in the InboxTable component)
        if (uri && !viaChromeExtension) return text;

        const sendTokenParam = sendToken
          ? `&sendToken=${sendToken}`
          : '';

        let dateOfBirth = '';
        if (record.patient_dob) {
          const splitDate = record.patient_dob.split('/');
          dateOfBirth = splitDate[2] + '-' + splitDate[0] + '-' + splitDate[1];
        }

        const assignedDocumentParam = assignDocumentId ? `&assignedDocumentId=${assignDocumentId}` : '';

        let textElement;
        if (taskCreated) {
          textElement = <Tooltip title="Task for this record was successfully created in the EMR"><CarryOutOutlined />&nbsp;</Tooltip>;
        } else if (taskAutoUpload) {
          if (emrPatientChartLink) {
            textElement = (
              <Tooltip title="This record has successfully synced into the Patient's chart and a task was created for it. Click here to view Patient Chart.">
                <a href={emrPatientChartLink} rel="noreferrer" target="_blank">
                  <FileDoneOutlined />
                  &nbsp;
                </a>
              </Tooltip>
            );
          } else {
            textElement = <Tooltip title="This record has successfully synced into the Patient's chart and a task was created for it"><FileDoneOutlined />&nbsp;</Tooltip>;
          }
        } else if (chartCreated) {
          if (emrPatientChartLink) {
            textElement = (
              <Tooltip title="Patient Chart successfully created. Click here to View Patient Chart.">
                <a href={emrPatientChartLink} rel="noreferrer" target="_blank">
                  <CloudUploadOutlined />
                </a>
                &nbsp;
              </Tooltip>
            );
          } else {
            textElement = <Tooltip title="Patient Chart successfully created"><CloudUploadOutlined />&nbsp;</Tooltip>;
          }
        } else if (autoUploadCreated) {
          if (emrPatientChartLink) {
            textElement = (
              <Tooltip title="This record has successfully synced into the Patient's chart. Click here to View Patient Chart.">
                <a href={emrPatientChartLink} rel="noreferrer" target="_blank">
                  <CloudUploadOutlined />
                  &nbsp;
                </a>
              </Tooltip>
            );
          } else {
            textElement = <Tooltip title="This record has successfully synced into the Patient's chart"><CloudUploadOutlined />&nbsp;</Tooltip>;
          }
        } else if (taskAfterChartSuccess) {
          textElement = <Tooltip title="Patient chart successfully created and task has been assigned"><CloudUploadOutlined />&nbsp;</Tooltip>;
        } else if (importedIntoEMR) {
          if (emrPatientChartLink) {
            textElement = (
              <Tooltip title="This record was auto uploaded into your EMR. Click here to view Patient">
                <a href={emrPatientChartLink} rel="noreferrer" target="_blank">
                  <CloudUploadOutlined />
                  &nbsp;
                </a>
              </Tooltip>
            );
          } else {
            textElement = <Tooltip title="This record was auto uploaded into your EMR"><CloudUploadOutlined />&nbsp;</Tooltip>;
          }
        } else if (taskCreationError) {
          textElement = (
            <Tooltip title={taskCreationError}>
              <CarryOutOutlined style={{ color: '#b31c0e' }} />
              &nbsp;
            </Tooltip>
          );
        } else if (chartCreationError) {
          textElement = (
            <Tooltip title={chartCreationError}>
              <ExclamationCircleOutlined style={{ color: '#b31c0e' }} />
              &nbsp;
            </Tooltip>
          );
        } else if (chartTaskError) {
          textElement = (
            <Tooltip title={chartTaskError}>
              <CarryOutOutlined style={{ color: '#b31c0e' }} />
              &nbsp;
            </Tooltip>
          );
        } else if (!importedIntoEMR && emrImportError) {
          if (emrImportError === 'AI detected multiple patients in this record.') {
            textElement = (
              <Tooltip title={emrImportError}>
                <TeamOutlined style={{ color: '#b31c0e' }} />
                &nbsp;
              </Tooltip>
            );
          } else if (emrImportError === 'Failed to find the patient chart') {
            if (localStorage.getItem('m8_org') === 'National Spine & Pain Centers') {
              textElement = (
                <Tooltip title={"Failed to find patient chart. Please check the spelling of their name and DOB, or ensure the pateint chart exists."}>
                  <ExclamationOutlined style={{ color: 'red' }} />
                  &nbsp;
                </Tooltip>
              );
            } else {
              textElement = (
                <Tooltip title={"Failed to find patient chart. Please check the spelling of their name and DOB, or use the “Create New Chart” button if the patient doesn't exist."}>
                  <ExclamationOutlined style={{ color: 'orange' }} />
                  &nbsp;
                </Tooltip>
              );
            }
          } else {
            textElement = (
              <Tooltip title={emrImportError}>
                <ExclamationCircleOutlined style={{ color: '#b31c0e' }} />
                &nbsp;
              </Tooltip>
            );
          }
        } else if (chartInProgress) {
          textElement = <Tooltip title="Patient Chart is currently being created. Refresh the page in a few minutes to see updated status"><SyncOutlined spin style={{ color: 'orange' }} />&nbsp;</Tooltip>;
        } else if (autoUploadInProgress) {
          textElement = <Tooltip title="The record is currently syncing into the patient's chart. Refresh the page in a few minutes to see updated status"><SyncOutlined spin style={{ color: 'orange' }} />&nbsp;</Tooltip>;
        } else if (taskUploadInProgress) {
          textElement = <Tooltip title="A task is currently be created in this patient's chart. Refresh the page in a few minutes to see updated status"><SyncOutlined spin style={{ color: 'orange' }} />&nbsp;</Tooltip>;
        } else if (taskAutoUploadInProgress) {
          textElement = <Tooltip title="The record is currently syncing into the patient's chart and a task is being created. Refresh the page in a few minutes to see updated status"><SyncOutlined spin style={{ color: 'orange' }} />&nbsp;</Tooltip>;
        } else if (taskAfterChartInProgress) {
          textElement = <Tooltip title="Patient Chart created succesfully and a task is being created. Refresh the page in a few minutes to see updated status"><SyncOutlined spin style={{ color: 'orange' }} />&nbsp;</Tooltip>;
        } else {
          textElement = showAiIcon && <Tooltip title="The patient information was extracted using Medsender AI"><SolutionOutlined />&nbsp;</Tooltip>;
        }

        const nationalSpineOrg = localStorage.getItem('m8_org') === 'National Spine & Pain Centers';

        return (
          <>
            <div>
              {emrPatientChartLink
                ? (
                  <div style={{ display: 'inline' }}>
                    {textElement}
                  </div>
                ) : <div />
              }
              <Link
                to={{
                  pathname: recordViewerPath(viaChromeExtension, fileToken),
                  search: `?from=${fromPage}${sendTokenParam}${assignedDocumentParam}`,
                }}
              >
                {text
                  ? (
                    <div style={{ display: 'inline' }}>
                      {emrPatientChartLink
                        ? (
                          <div style={{ display: 'inline' }}>
                            {text}
                          </div>
                        ) : (
                          <div style={{ display: 'inline' }}>
                            {textElement}
                            {text}
                          </div>
                        )}
                    </div>
                  ) : <span className="missing-text">{showAiIcon && <Tooltip title="The patient information was extracted using Medsender AI"><SolutionOutlined />&nbsp;</Tooltip>}Not Provided</span>
                }
              </Link>
              <div style={{ color: 'grey' }}>
                {duplicatePatientDetection ? (
                  <div>
                    <Tooltip title="Patient with same Name and DOB detected in other records. Click here to see records.">
                      {nationalSpineOrg ? (
                        <WarningOutlined style={{ color: 'orange' }} />
                      ) : (
                        <ExclamationOutlined style={{ color: 'orange' }} />
                      )}
                      &nbsp;
                      <a href={`/app/received?contains=${record.patient_name}&dob=${dateOfBirth}&non_referrals=true&page=1`} style={{ color: '#FFA500' }}>
                        {record.patient_dob}
                      </a>
                    </Tooltip>
                  </div>
                ) : record.patient_dob}
                { (fromPage === 'uploaded' || fromPage === 'referred' || fromPage === 'received') && isMerged && renderMerged()}
                { isSplitted && renderSplitted() }
              </div>
            </div>
          </>
        );
      },
    },
  ]
);

export const sharedAssignedColumns = [
  {
    title: 'From',
    dataIndex: 'sender_email',
    render: (text, record) => (
      <>
        {record.sender_name
          && (
            <>
              <span style={{ fontWeight: '500' }}>{record.sender_name}</span>
              <br />
            </>
          )}
        {validFaxNumber(record.sender_email).valid ? formatValidFaxNumber(record.sender_email) : record.sender_email}
      </>
    ),
  },
  {
    title: 'To',
    dataIndex: 'receiver_name',
    render: (text, record) => {
      return (
        <>
          {record.receiver_name
            && (
              <span>
                <span style={{ fontWeight: '500' }}>{record.receiver_name}</span>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {record.organization && <span>&nbsp;({record.organization})</span>}
                <br />
              </span>
            )}
          {validFaxNumber(record.receiver).valid ? formatValidFaxNumber(record.receiver) : record.receiver}
        </>
      );
    },
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    render: (text, record) => {
      const { labels, tag } = record;
      const allDepartments = localStorage.getItem('allDepartments');
      return (
        <>
          {labels && labels.map(label => (
            <Tag style={{ margin: '5px', backgroundColor: label.color, color: label.is_dark_text ? 'black' : 'white' }}>
              {label.name}
            </Tag>
          ))
          }
          {/* show old tag here as long as it's not an email or department (for the purposes of maintaining functionality for users that used old tags) */}
          {tag && !validEmail(tag) && allDepartments.indexOf(tag) === -1
            && (
              <Tooltip
                title={
                  (
                    <>
                      This is an old tag that cannot be used as a label. Please go here
                      <Link to="/app/user?tab=manage_labels"> Manage Labels </Link>
                      to add a new label to your library
                    </>
                )}
              >
                <Tag style={{ color: 'rgb(106, 27, 154)', backgroundColor: 'white', border: '2px solid rgb(106, 27, 154)' }}>{tag}</Tag>
              </Tooltip>
            )}
        </>
      );
    },
  },
  {
    title: 'Date Received',
    dataIndex: 'date_sent',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text, record) => (
      <span style={{ fontWeight: '500' }}>{record.document_status_name}</span>
    ),
  },
  {
    title: 'Assigned By',
    dataIndex: 'assigned_by',
    render: (text, record) => (
      <span style={{ fontWeight: '500' }}>{record.assigned_from}</span>
    ),
  },
  {
    title: '',
    dataindex: 'note',
    render: (text, record) => {
      return (
        <>
          {record.note
            && (
              <Tooltip title={record.note}>
                <CommentOutlined />
              </Tooltip>
            )
          }
        </>
      );
    },
  },
];

export const sharedReferredColumns = [
  {
    title: 'Referrer Info',
    dataIndex: 'sender_email',
    className: 'referrerInfo',
    render: (text, record) => (
      <>
        {record.referring_provider
          && (
            <>
              <span style={{ fontWeight: '500' }}>{record.referring_provider}</span>
              <br />
            </>
          )}
        {record.provider_office
          && (
            <>
              <span style={{ fontWeight: '300' }}>{record.provider_office}</span>
              <br />
            </>
          )}
      </>
    ),
  },
  {
    title: 'From',
    dataIndex: 'sender_email',
    render: (text, record) => (
      <>
        {record.sender_name
          && (
            <>
              <span style={{ fontWeight: '500' }}>{record.sender_name}</span>
              <br />
            </>
          )}
        {validFaxNumber(record.sender_email).valid ? formatValidFaxNumber(record.sender_email) : record.sender_email}
      </>
    ),
  },
  {
    title: 'To',
    dataIndex: 'receiver_name',
    render: (text, record) => {
      return (
        <>
          {record.receiver_name
            && (
              <span>
                <span style={{ fontWeight: '500' }}>{record.receiver_name}</span>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {record.organization && <span>&nbsp;({record.organization})</span>}
                <br />
              </span>
            )}
          {validFaxNumber(record.receiver).valid ? formatValidFaxNumber(record.receiver) : record.receiver}
        </>
      );
    },
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    render: (text, record) => {
      const { labels, tag } = record;
      const allDepartments = localStorage.getItem('allDepartments');
      return (
        <>
          {labels && labels.map(label => (
            <Tag key={label.id} style={{ margin: '5px', backgroundColor: label.color, color: label.is_dark_text ? 'black' : 'white' }}>
              {label.name}
            </Tag>
          ))
          }
          {/* show old tag here as long as it's not an email or department (for the purposes of maintaining functionality for users that used old tags) */}
          {tag && !validEmail(tag) && allDepartments.indexOf(tag) === -1
            && (
              <Tooltip
                title={
                  (
                    <>
                      This is an old tag that cannot be used as a label. Please go here
                      <Link to="/app/user?tab=manage_labels"> Manage Labels </Link>
                      to add a new label to your library
                    </>
                )}
              >
                <Tag style={{ color: 'rgb(106, 27, 154)', backgroundColor: 'white', border: '2px solid rgb(106, 27, 154)' }}>{tag}</Tag>
              </Tooltip>
            )}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    className: 'status',
    render: (text, record) => {
      const { referral_status, referral_statuses } = record;
      const [referralStatus, setReferralStatus] = useState(referral_status ? referral_status.referral_status_id : null);
      let canChangeReferralStatus = true;
      const referralManagementStatus = localStorage.getItem('m8_referral_management');
      if (referralManagementStatus === 'false') {
        canChangeReferralStatus = false;
      }

      const handleStatusChange = async (status) => {
        if (canChangeReferralStatus) {
          await axios({
            method: 'POST',
            url: '/api/v1/records/:record_id/referred_document_statuses',
            data: {
              referred_document_status: status,
              parent_type: record.receiver_type,
              send_token: record.send_token,
              referral_status_id: status.referral_status_id,
            },
            headers: {
              'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
            },
          });
          setReferralStatus(status.id);
        } else {
          toastr.error("Your plan doesn't include access to update statuses for referred records. Please upgrade your plan to make any changes.");
        }
      };
      return (
        <>
          {(referral_status && referral_statuses) ? (
            <Select
              style={{ width: '175px' }}
              value={referralStatus}
              onChange={(e, selectedId) => {
                const selectedStatus = referral_statuses.find(status => status.id === selectedId.value);
                handleStatusChange(selectedStatus);
                if (!canChangeReferralStatus) {
                  e.preventDefault();
                }
              }}
            >
              {referral_statuses.map(status => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select
              style={{ width: '175px' }}
              onChange={(e, selectedId) => {
                const selectedStatus = referral_statuses.find(status => status.id === selectedId.value);
                handleStatusChange(selectedStatus);
                if (!canChangeReferralStatus) {
                  e.preventDefault();
                }
              }}
            >
              {referral_statuses?.map(status => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </>
      );
    },
  },
  {
    title: 'No. Pages',
    dataIndex: 'num_pages',
    render: (text, record) => (
      <span style={{ fontWeight: '500' }}>{record.num_pages}</span>
    ),
  },
  {
    title: 'Date Received',
    dataIndex: 'date_sent',
  },
  {
    title: '',
    dataIndex: 'note',
    render: (text, record) => {
      const {
        referral_sms_status: referralSmsStatus,
        referral_sms_sent_at: referralSmsSentAt,
        referral_sms_error_message: referralSmsErrorMessage,
        sms_uri: smsUri,
      } = record;

      let referralElement;
      if (referralSmsStatus) {
        let tooltipTitle;
        if ((referralSmsStatus === 'sent' || referralSmsStatus === 'delivered')) {
          tooltipTitle = `SMS Sent on ${referralSmsSentAt}`;
        } else if (referralSmsStatus === 'delivery_failed') {
          tooltipTitle = "SMS Failed to be sent. " + referralSmsErrorMessage;
        } else {
          tooltipTitle = 'SMS queued to be sent';
        }
        referralElement = (
          <Tooltip title={tooltipTitle} placement="topRight">
            <MessageOutlined />
          </Tooltip>
        );
      }
      return (
        <>
          {record.referral_note && (
            <div style={{ textAlign: 'center' }}>
              <Tooltip title={record.referral_note} placement="topLeft">
                <span style={{ display: 'inline-block' }}>
                  <EditOutlined />
                </span>
              </Tooltip>
            </div>
          )}
          {referralSmsStatus && (
            <div>
              { smsUri && referralElement}
            </div>
          )}
        </>
      );
    },
  },
];


export const sharedReceivedColumns = [
  {
    title: 'From',
    dataIndex: 'sender_email',
    render: (text, record) => (
      <>
        {record.sender_name
          && (
            <>
              <span style={{ fontWeight: '500' }}>{record.sender_name}</span>
              <br />
            </>
          )}
        {validFaxNumber(record.sender_email).valid ? formatValidFaxNumber(record.sender_email) : record.sender_email}
      </>
    ),
  },
  {
    title: 'To',
    dataIndex: 'receiver_name',
    render: (text, record) => {
      return (
        <>
          {record.receiver_name
            && (
              <span>
                <span style={{ fontWeight: '500' }}>{record.receiver_name}</span>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {record.organization && <span>&nbsp;({record.organization})</span>}
                <br />
              </span>
            )}
          {validFaxNumber(record.receiver).valid ? formatValidFaxNumber(record.receiver) : record.receiver}
        </>
      );
    },
  },
  {
    title: 'Labels',
    dataIndex: 'labels',
    render: (text, record) => {
      const { labels, tag } = record;
      const allDepartments = localStorage.getItem('allDepartments');
      return (
        <>
          {labels && labels.map(label => (
            <Tag style={{ margin: '5px', backgroundColor: label.color, color: label.is_dark_text ? 'black' : 'white' }}>
              {label.name}
            </Tag>
          ))
          }
          {/* show old tag here as long as it's not an email or department (for the purposes of maintaining functionality for users that used old tags) */}
          {tag && !validEmail(tag) && allDepartments.indexOf(tag) === -1
            && (
              <Tooltip
                title={
                  (
                    <>
                    This is an old tag that cannot be used as a label. Please go here
                      <Link to="/app/user?tab=manage_labels"> Manage Labels </Link>
                    to add a new label to your library
                    </>
                )}
              >
                <Tag style={{ color: 'rgb(106, 27, 154)', backgroundColor: 'white', border: '2px solid rgb(106, 27, 154)' }}>{tag}</Tag>
              </Tooltip>
            )}
        </>
      );
    },
  },
  {
    title: 'No. Pages',
    dataIndex: 'num_pages',
    render: (text, record) => (
      <span style={{ fontWeight: '500' }}>{record.num_pages}</span>
    ),
  },
  {
    title: 'Date Received',
    dataIndex: 'date_sent',
  },
  {
    title: '',
    dataindex: 'medsender_user',
    render: (text, record) => {
      const medsender_user = record.is_medsender_user
      return (
        medsender_user
          && (
          <Tooltip title={`This record was sent instantly by a Medsender user, providing you with the the ability to search through the record, and the ability to message the sender/recipient via Medsender Connect Chat!`} placement="left">
            <ThunderboltTwoTone twoToneColor="#691B9A"/>
          </Tooltip>
          )
      );
    },
  },
];

const sharedUploadedColumns = [
  {
    title: 'Date Uploaded',
    dataIndex: 'upload_date',
  },
];

const recordViewedIcon = (viewed, last_viewer) => (
  viewed ? (
    <>
      <Tooltip placement="topRight" title={`Record last viewed by ${last_viewer}`}>
        <EyeFilled style={{ paddingLeft: '5px' }} />
      </Tooltip>
    </>
  ) : <span style={{ marginLeft: '20px' }} />
);

const assigneeIcon = (assignee) => (
  assignee ? (
    <span style={{ position: 'relative', display: 'inline-block', marginLeft: '7.5px' }}>
      <Tooltip 
        placement="topRight" 
        title={`Assigned to ${assignee}`}
      >
        <UserOutlined style={{ paddingLeft: '5px' }} />
      </Tooltip>
      <span style={{ 
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: '0.6em',
        padding: '2px',
        transform: 'translate(50%, -50%)'
      }}>
        {assignee.trim().split(' ').map(word => word[0] ? word[0].toUpperCase() : "").join('')}
      </span>
    </span>
  ) : <span style={{ marginLeft: '20px' }} />
);

const showSenderEmail = record => (
  <>
    {record.receiver_email
      ? record.sender
      : <div>-</div>
    }
  </>
);

export const receivedInboxIcons = {
  title: '',
  dataIndex: 'patient_name',
  render: (text, record) => {
    const {
      can_edit_privacy_level: canEditPrivacyLevel,
      uri,
      viewed,
      download_count: downloadCount,
      is_uploaded_to_emr: isUploadedToEmr,
      tag,
      is_user_tagged_in_record: isUserTagged,
    } = record;
    const last_viewer = record.hasOwnProperty('last_viewer') ? record.last_viewer : null;
    const assignee = record.hasOwnProperty('assignee') ? record.assignee : null;
    const viewingArchivedRecords = window.location.search.includes('archived');
    return (
      <>
        { tag && (
        <Tooltip title={`${tag} was notified`}>
          {isUserTagged
            ? <BellTwoTone style={{ marginRight: '5px' }} />
            : <BellOutlined style={{ marginRight: '5px' }} />
          }
        </Tooltip>
        )}
        { canEditPrivacyLevel && renderPrivacyLevel(record.privacy_level)}
        <span style={{ marginLeft: '8px' }}>{viewingArchivedRecords && record.archived_by && renderArchivedBy(record.archived_by)}</span>
        { uri && <Tooltip title="You've received a reply to a previously sent record"><MessageTwoTone /></Tooltip>}
        { recordViewedIcon(viewed, last_viewer) }
        { assigneeIcon(assignee) }
        { downloadCount > 0 && (
          <Tooltip title={`Record has been downloaded ${downloadCount} time(s)`} placement="topRight">
            <span style={{ marginLeft: '5px' }}>
              <DownloadOutlined />
              {downloadCount}
            </span>
          </Tooltip>
        )}
      </>
    );
  },
};


export const chromeSelectorIcons = (inboxType) => {
  return {
    title: '',
    dataIndex: 'uploaded_icon',
    render: (text, record) => {
      const { is_uploaded_to_emr: isUploadedToEmr, viewed, last_viewer, send_token: sendToken, file_token: fileToken } = record;
      const sendTokenParam = sendToken
        ? `&sendToken=${sendToken}`
        : '';
      return (
        <>
          <Link
            to={{
              pathname: recordViewerPath(viaChromeExtension, fileToken),
              search: `?from=${inboxType}${sendTokenParam}`,
            }}
          >
            View Record
          </Link>
          {inboxType === 'received' && recordViewedIcon(viewed, last_viewer)}
          {isUploadedToEmr && (
            <Tooltip title="Record already uploaded to EMR" placement="left">
              <span style={{ margin: '10px' }}><CloudUploadOutlined /></span>
            </Tooltip>
          )}
        </>
      );
    },
  };
};

export const receivedColumns = [
  ...sharedColumnTitles('received'),
  ...sharedReceivedColumns,
  receivedInboxIcons,
];

export const assignedColumns = [
  ...sharedColumnTitles('assigned'),
  ...sharedAssignedColumns,
];

export const referredColumns = [
  ...sharedColumnTitles('referred'),
  ...sharedReferredColumns,
];

const viaChromeExtension = true;

export const chromeReceivedColumns = [
  ...sharedColumnTitles('received', viaChromeExtension),
  ...sharedReceivedColumns,
  chromeSelectorIcons('received'),
];

export const chromeUploadedColumns = [
  ...sharedColumnTitles('uploaded', viaChromeExtension),
  ...sharedUploadedColumns,
  chromeSelectorIcons('uploaded'),
];

export const sentColumns = [
  ...sharedColumnTitles('sent'),
  {
    title: 'Recipient',
    dataIndex: 'receiver_email',
    // Since antd only supports one dataIndex value, but the column
    // could contain either of the below, we custom render the content
    render: (text, record) => (
      <>
        {record.receiver_name
          && (
            <>
              <span style={{ fontWeight: '500' }}>{record.receiver_name}</span>
              <br />
            </>
          )}
        {record.receiver_email || record.receiver_fax}
      </>
    ),
  },
  {
    title: 'From',
    dataIndex: 'sender',
    // Since antd only supports one dataIndex value, but the column
    // could contain either of the below, we custom render the content
    render: (text, record) => (
      <>
        {record.sender_name
            && (
              <>
                <span style={{ fontWeight: '500' }}>{record.sender_name}</span>
                <br />
              </>
            )}
        {validFaxNumber(record.sender).valid
          ? formatValidFaxNumber(record.sender)
          : showSenderEmail(record)
        }
      </>
    ),
  },
  {
    title: 'Date Sent',
    dataIndex: 'date_sent',
  },
  {
    title: '',
    dataIndex: 'view_status',
    render: (text, record) => {
      const patientQuery = record.patient ? '?patient=true' : '';
      const viewStatusIcon = record.patient ? getSentToPatientIcon(record) : getSentToProviderIcon(record);
      const viewSignatureIcon = getSignatureCompletedIcon(record);
      const isMedsenderUser = record.is_medsender_user;
      return (
        <>
          <Tooltip title={isMedsenderUser ? `Sending a record to a Medsender user guarantees instant delivery, gives recipients the ability to search through the record, and the ability to message the sender/recipient via Medsender Connect Chat!` :  ''} placement="left">
            <Link to={`/app/records/status/record/${record.file_token}/receiver/${record.send_token + patientQuery}`}>
              <Button type="primary" style={{ background: isMedsenderUser ? 'purple' : 'light blue', borderColor: isMedsenderUser ? 'purple' : 'light blue' }}>Status</Button>
            </Link>
          </Tooltip>
          
          <Tooltip title={viewStatusIcon.text}>
            <Icon component={viewStatusIcon.name} style={{ marginLeft: '20px', color: `${viewStatusIcon.color}`, fontSize: 17 }} />
          </Tooltip>
          {viewSignatureIcon && (
            <Tooltip title={viewSignatureIcon.text}>
              <Icon component={viewSignatureIcon.name} style={{ marginLeft: '20px', color: `${viewSignatureIcon.color}`, fontSize: 17 }} />
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    title: '',
    dataindex: 'sent_by',
    render: (text, record) => {
      const sender = record.sender || localStorage.getItem('m8_uid');
      return (
        record.sender !== localStorage.getItem('m8_uid')
          && (
          <Tooltip title={`This record was sent by ${sender}`} placement="left">
            <LinkOutlined />
          </Tooltip>
          )
      );
    },
  },
  {
    title: '',
    dataindex: 'medsender_user',
    render: (text, record) => {
      const medsender_user = record.is_medsender_user
      return (
        medsender_user
          && (
          <Tooltip title={`Sending a record to a Medsender user guarantees instant delivery, gives recipients the ability to search through the record, and the ability to message the sender/recipient via Medsender Connect Chat!`} placement="left">
            <ThunderboltTwoTone twoToneColor="#691B9A"/>
          </Tooltip>
          )
      );
    },
  },
];

export const uploadedColumns = [
  ...sharedColumnTitles('uploaded'),
  {
    title: 'Date Uploaded',
    dataIndex: 'upload_date',
  },
  {
    title: '',
    dataIndex: 'patient_name',
    render: (text, record) => {
      const archivedBy = record.archived_by ? record.archived_by : 'nil';
      return (
        <>
          {record.can_edit_privacy_level && renderPrivacyLevel(record.privacy_level)}
          <span style={{ marginLeft: '8px' }}>{(record.record_status === 'archived') && (archivedBy !== 'nil') && renderArchivedBy(archivedBy)}</span>
          <span style={{ marginLeft: '8px' }}>{ renderUploadedBy(record.uploaded_by) }</span>
        </>
      );
    },
  },
];
