import {
  MergeRecords,
  MergedRecord,
} from '../../../assets/img/guide';

const mergingRecordsCopy = {
  pageTitle: 'Merging Records',
  pageSubtitle: `
    Medsender supports merging multiple documents into one record.
    Within the Uploaded Records page, the records you wish to merge and click “Merge Records” in the upper toolbar.
  `,
  sections: [
    {
      img: MergeRecords,
    },
    {
      subtitle: `When you merge separate records into a single record, the individual records will remain 
      unchanged, and a new, merged record containing all of the individual records will be created, with the name of the first document.`,
      img: MergedRecord,
    },
    {
      subtitle: 'A record created via merging is indicated by a dual arrow symbol to the right of the new document name.',
      divider: true,
    },
  ],
};

export default mergingRecordsCopy;
