import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import moment from 'moment-timezone';

import { BILLING_ENUMS } from '../constants';

class BillingMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  startPaymentFlow = async () => {
    await this.props.billingInfo();
    this.props.changeModal(BILLING_ENUMS.PLAN_SELECTOR);
  }

  render() {
    if (!this.props.billingTrialDetails) return null;
    let {
      trial_end_date: trialEndDate,
      trial_days_remaining: trialDaysRemaining,
    } = this.props.billingTrialDetails;
    let trialEndDateObj = new Date(trialEndDate);
    // This condition should not occur. It is a safety measure in case the date comes
    // back as 'none' AND billing information isn't on file. We'll then tell the
    // customer that their trial ended today
    if (isNaN(trialEndDateObj)) trialEndDateObj = new Date();

    // We'll use this to format the trial end date as Monday, January 1
    const trialEndDay = trialEndDateObj.getDate();
    const trialEndDayOfTheWeek = moment(trialEndDateObj).format('dddd');
    const trialEndMonth = moment.months(trialEndDateObj.getMonth());
    const { planType } = this.props;

    const trialExpired = trialDaysRemaining < 1;
    const planCancelled = planType === 'cancelled';
    const planRevoked = planType === 'revoked';

    return planCancelled || planRevoked ? (
      <Dialog
        actions={[
          <FlatButton
            label={"Sign out"}
            onClick={() => {
              localStorage.setItem(
                "m8_prompt_dismissed_time",
                new Date().toISOString()
              );
              this.setState({ open: false });
              this.props.logout();
            }}
          />,
        ]}
        open={this.state.open}
      >
        <h3 style={{ textAlign: "center" }}>
          Your subscription was cancelled
          {planCancelled
            ? '.'
            : ' due to failure of payment of your medsender subscription.'}
        </h3>
        <p>
          To enable it again, please contact Medsender support at (650) 419-3230 or email sales@medsender.com.
        </p>
      </Dialog>
    ) : (
      <Dialog
        actions={[
          <FlatButton
            label={trialExpired ? 'Sign out' : 'Later'}
            onClick={() => {
              localStorage.setItem('m8_prompt_dismissed_time', new Date().toISOString());
              this.setState({ open: false });
              if (trialExpired) this.props.logout();
            }}
          />,
          <FlatButton
            primary={true}
            label="Upgrade now"
            onClick={this.startPaymentFlow}
          />
        ]}
        open={this.state.open}
      >
        <h3 style={{ textAlign: 'center' }}>
          { trialExpired
            ? 'Thanks for trying out Medsender!'
            : 'Your trial is ending soon'
          }
        </h3>
        <p>
          In order to continue using Medsender, you&apos;ll need to upgrade to a paid plan. Your trial&nbsp;
          { trialExpired
            ? 'ended on'
            : 'is set to end on'
          }
          &nbsp;{trialEndDayOfTheWeek}, {trialEndMonth} {trialEndDay}.&nbsp;
          { trialExpired
            ? "After you select a plan, you'll be able to resume using Medsender as normal."
            : 'After this date, you will not be able to send or receive through Medsender.'
          }
        </p>
      </Dialog>
    )
  }
}

export default BillingMessage;
