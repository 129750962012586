import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import ModalWrapper from './ModalWrapper';

const { Option } = Select;

class SelectOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrganization: '',
    };
  }

  handleSubmit = async () => {
    const {
      hideModal,
      changeDefaultOrganization,
    } = this.props;
    const { currentOrganization } = this.state;
    await changeDefaultOrganization(currentOrganization, this.reloadPage);
    hideModal();
  }

  reloadPage = async () => {
    const {
      getLoginInfo: handleGetLoginInfo,
    } = this.props;
    await handleGetLoginInfo();
    window.location.reload();
  }

  render() {
    // Custom style for the modal
    const customContentStyle = {
      width: '26%',
      textAlign: 'center',
      disabled: 'true',
    };

    const { modalType: { data: { organizations, current_organization: currentOrganization } } } = this.props;

    return (
      <div>
        <ModalWrapper
          customContentStyle={customContentStyle}
          action={() => this.handleSubmit()}
          actionName="Submit"
          disabled={!this.state.currentOrganization}
          modalTitle="Please Select Branch"
          closable={false}
        >
          <p style={{ fontWeight: '600' }}>Location/Branch/Organization </p>
          <Select
            placeholder="Select Location/Branch/Organization"
            defaultValue={currentOrganization || null}
            style={{ width: 400 }}
            onChange={value => this.setState({ currentOrganization: value })}
          >
            {organizations.map(org => <Option value={org.name}>{org.name}</Option>)}
          </Select>
          <br />
        </ModalWrapper>
      </div>
    );
  }
}

SelectOrganization.propTypes = {
  hideModal: PropTypes.func.isRequired,
  changeDefaultOrganization: PropTypes.func.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
  modalType: PropTypes.shape({
    data: PropTypes.shape({
      multiple_organization_login_enabled: PropTypes.bool.isRequired,
      show_multiple_organization_login_modal: PropTypes.bool.isRequired,
      organizations: PropTypes.array.isRequired,
      current_organization: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  errorChangingOrganization: PropTypes.bool.isRequired,
};

export default SelectOrganization;
