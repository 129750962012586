import React, { Component } from 'react';
import { connect } from 'react-redux';
import FaxNumberInstructions from '../../components/modal/FaxNumberInstructions';

import { openModal, hideModal } from '../../actions/modal';

class FaxNumberInstructionsDialog extends Component {
  render() {
    return (
      <div>
        <FaxNumberInstructions {...this.props} />
      </div>
    );
  }
}

export default connect(state => ({
  isAdmin: state.userProfile.isAdmin,
}), {
  openModal,
  hideModal,
})(FaxNumberInstructionsDialog);
