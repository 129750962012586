import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubmitForm from '../../components/modal/SubmitForm';

import { openModal, hideModal } from '../../actions/modal';
import { submitForm } from '../../actions/records';

class SubmitFormDialog extends Component {
  render() {
    return (
      <div>
        <SubmitForm {...this.props} />
      </div>
    );
  }
}

export default connect(state => ({
  isAdmin: state.userProfile.isAdmin,
  isLoading: state.records.isLoading,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
}), {
  openModal,
  hideModal,
  submitForm,
})(SubmitFormDialog);
