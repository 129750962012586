import { Upload } from 'antd';
import styled from 'styled-components';

const { Dragger: antdDragger } = Upload;

const Dragger = styled(antdDragger)`
  height: 80px !important;
  &.ant-upload-drag-hover {
    filter: brightness(0.5);
  }
`;

export default Dragger;
