// Archive record component displays the modal content.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';
import { validSendEmail as validEmail } from '../../global/validation';

const errorStyle = {
  color: 'red',
};

class ArchiveRecord extends Component {
  constructor(props, context) {
    super(props, context);

    // map modal data to local state
    this.state = {
      error: false,
      archiveRecordsResultMessage: '',
      // used to track when the request has been made and to disable the archive button in modal so users don't click twice
      completedArchiveRecord: false,
    };
  }

  componentDidMount() {
    const {
      modalType,
    } = this.props;
    if (modalType.autoArchive) {
      this.archive();
    }
  }

  archive = async () => {
    const {
      modalType,
      archiveRecords,
      archiveReceivedRecords,
      hideModal,
    } = this.props;

    if (modalType.inboxLink === '/app/uploads') {
      await archiveRecords(modalType.data.tokens);
    } else {
      await archiveReceivedRecords(modalType.data.tokens);
    }

    const { archiveErrorMessage } = this.props;
    this.setState({ completedArchiveRecord: true });
    let message = 'Successfully archived all record(s)';
    if (archiveErrorMessage) {
      this.setState({ error: true });
      message = 'We were unable to archive the selected records. Please try again';
    }

    this.setState({ archiveRecordsResultMessage: message });
    await setTimeout(() => {
      hideModal();
      window.location.reload();
    }, 2500);
  };


  render() {
    const customContentStyle = {
      width: '45%',
    };

    const errorModalStyle = {
      width: '20%',
    };

    const {
      hideModal, archiveErrorMessage, isLoading, modalType,
    } = this.props;
    const { error, archiveRecordsResultMessage, completedArchiveRecord } = this.state;

    if (error) {
      return (
        <ModalWrapper
          hideModal={hideModal}
          customContentStyle={errorModalStyle}
          action={hideModal}
          actionName="OK"
          modalTitle="Archive Record"
        >
          <p style={errorStyle}>{ archiveErrorMessage || 'An error has occurred. Please try again' }</p>
        </ModalWrapper>
      );
    }

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.archive}
        actionName="Archive"
        dismiss="Cancel"
        modalTitle="Archive Records"
        isLoading={isLoading || completedArchiveRecord}
      >
        <span>
          {!modalType.autoArchive
            && (
            <>
              <span style={{ fontWeight: '600' }}>
                Are you sure you want to archive these records?
              </span>
              <br />
              <br />
            </>
            )
          }

          Recipients of this record will still have access to this record.
          After the record is archived, the page will automatically refresh.
          <br />
          <br />
          {archiveRecordsResultMessage
            && <p style={{ textAlign: 'center', archiveBottom: '0px', fontStyle: 'italic' }}>{archiveRecordsResultMessage}</p>
          }
        </span>
      </ModalWrapper>
    );
  }
}

ArchiveRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

ArchiveRecord.defaultProps = {
  archiveErrorMessage: '',
};

ArchiveRecord.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  archiveRecords: PropTypes.func.isRequired,
  archiveReceivedRecords: PropTypes.func.isRequired,
  archiveErrorMessage: PropTypes.string,
};

export default ArchiveRecord;
