const initialState = {
  CURRENT_PAGE: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'INBOX_PAGE_CHANGE' : {
      return {
        ...state,
        CURRENT_PAGE: action.newPage,
      };
    }
  }
  return state;
};
