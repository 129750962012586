import React, { Component } from 'react';

import FailedSSO from '../../components/modal/FailedSSO';

class FailedSSODialog extends Component {
  render() {
    return (
      <div>
        <FailedSSO
          {...this.props}
        />
      </div>
    );
  }
}

export default FailedSSODialog;
