import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import moment from 'moment';

import { formatDate, formatYearToMonth } from '../../../global/validation';

const { RangePicker } = DatePicker;

class DateSettings extends Component {
  constructor(props) {
    super(props);
    const { dob, date_start, date_end } = props.paramTools.params;
    this.state = {
      dob: formatYearToMonth(dob) || '',
      startDate: formatYearToMonth(date_start) || '',
      endDate: formatYearToMonth(date_end) || '',
      validDate: false,
      query: '',
    };
  }

  /**
   * handleDateRangePickerChange: handles changes in <RangePicker />
   * @param {Array} arrayOfSelectedDateRange two item array of start and end date like so: ['MM/DD/YYYY', 'MM/DD/YYYY']
   */
  handleDateRangePickerChange = (arrayOfSelectedDateRange) => {
    const [selectedStartDate, selectedEndDate] = arrayOfSelectedDateRange;
    const {
      startDate: previousStartDate,
      endDate: previousEndDate,
    } = this.state;

    const newStartDateSearch = previousStartDate !== selectedStartDate;
    const newEndDateSearch = previousEndDate !== selectedEndDate;
    const emptyDateRange = !selectedStartDate && !selectedEndDate;

    this.setState({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });

    if (emptyDateRange) {
      // Clear search results
      // antD has a built in button on their inputs that allow users to clear the field
      this.updateQuery('', ['date_start', 'date_end']);
    } else if (newStartDateSearch || newEndDateSearch) {
      const dateQueryToUpdate = newStartDateSearch ? selectedStartDate : selectedEndDate;
      this.updateQuery(formatDate(dateQueryToUpdate), newStartDateSearch ? 'date_start' : 'date_end');
    }
  }

  /**
   * handleDateInputFieldChange: this handles changes in <DatePicker />
   * @param  {String} selectedDate formatted in one of these ways: 'MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'
   * @param  {String} inputFieldName [tracks which date field is being changed]
   */
  handleDateInputFieldChange = (selectedDate, inputFieldName) => {
    // eslint-disable-next-line react/destructuring-assignment
    const newSearch = (selectedDate !== this.state[inputFieldName]);
    const emptySearch = (selectedDate.trim() === '');
    this.setState({ [inputFieldName]: selectedDate });
    // Perform search
    if (newSearch) {
      this.updateQuery(formatDate(selectedDate));
    }
    // Clear search results
    // antD has a built in button on their inputs that allow users to clear the field
    if (emptySearch) {
      this.updateQuery('');
    }
  };

  /**
   * updateQuery: This updates the URL query to any Sidebar settings changes.
   * @param  {String} newDate YYYY-MM-DD
   * @param {String} rangePickerQueryToUpdate 'date_start' || 'date_end' || ['date_start', 'date_end']
   * [passed in when values in date range picker are changed to specify which query to update]
   */
  updateQuery = (newDate, rangePickerQueryToUpdate) => {
    const { paramTools, inboxActions, queryType } = this.props;
    const { params, stringify, stringifyOptions } = paramTools;
    const { getRecords } = inboxActions;
    const queryParamToUpdate = rangePickerQueryToUpdate || queryType;
    // rangePicker passes in ['date_start', 'date_end'] to clear the value of both range values
    if (Array.isArray(queryParamToUpdate)) {
      delete params[queryParamToUpdate[0]];
      delete params[queryParamToUpdate[1]];
      params.page = 1;
    }
    if (params[queryParamToUpdate] === '' || newDate === '') {
      delete params[queryParamToUpdate];
      params.page = 1;
    } else {
      params[queryParamToUpdate] = newDate;
    }
    const query = stringify(params, stringifyOptions);
    // Don't send requests to server if query is the same
    // eslint-disable-next-line react/destructuring-assignment
    if (query === this.state.query) return;
    this.setState({ query });
    getRecords(query);
  }


  render() {
    // eslint-disable-next-line object-curly-newline
    const { floatingLabelText, hint, ranged, name } = this.props;
    const { dob, startDate, endDate } = this.state;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    return (
      <Form.Item
        label={floatingLabelText || 'MM/DD/YYYY'}
        style={{ marginTop: 10 }}
      >
        {ranged
          ? (
            <RangePicker
              allowEmpty={[true, true]}
              format={dateFormat}
              value={[startDate && moment(startDate, dateFormat), endDate && moment(endDate || '', dateFormat)]}
              onChange={(_, dateString) => this.handleDateRangePickerChange(dateString)}
            />
          ) : (
            <DatePicker
              placeholder={hint}
              style={{ width: '100%' }}
              value={dob && moment(dob, dateFormat)}
              onChange={(_, dateString) => this.handleDateInputFieldChange(dateString, name)}
              format={dateFormat}
            />
          )
        }
      </Form.Item>
    );
  }
}

/**
 * DateSettings Prop description:
 * @object {actions & paramTools} Refer to <Sidebar> for description on these props. (See ./../index.jsx)
 * @string {hint & floatingLabelText} hintText & floatingLabelText strings used in the TextField
 * @string {queryType} used to remove dob param from the query if the date is removed by the user in the Sidebar
 */
DateSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  hint: PropTypes.string,
  floatingLabelText: PropTypes.string,
  queryType: PropTypes.string,
  ranged: PropTypes.bool,
  name: PropTypes.string,
};

DateSettings.defaultProps = {
  hint: 'Search here...',
  floatingLabelText: '',
  queryType: 'dob',
  ranged: false,
  name: '',
};

export default DateSettings;
