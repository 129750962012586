import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddTaskProvider from '../../components/modal/AddTaskProvider';
import {
  createTaskProvider,
  updateTaskProvider,
  getTaskProviders,
} from '../../actions/userProfile';

class AddTaskProviderDialog extends Component {
  static propTypes = {
    createTaskProvider: PropTypes.func.isRequired,
    updateTaskProvider: PropTypes.func.isRequired,
    getTaskProviders: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddTaskProvider
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  emaTaskTemplates: state.auth.data,
}),
{
  createTaskProvider,
  updateTaskProvider,
  getTaskProviders,
})(AddTaskProviderDialog);
