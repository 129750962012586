import crud from './helpers/crud';
import { disableReplying } from './auth';

const getRecordResponseById = (url) => {
  return crud({
    dispatch: {
      begin: 'BEGIN_GET_SINGLE_RECORD_RESPONSE',
      fail: 'FAILED_GET_SINGLE_RECORD_RESPONSE',
      end: 'FINISHED_GET_SINGLE_RECORD_RESPONSE',
    },
    method: 'GET',
    url: url,
  });
};

const sendReplyToRecord = (sendToken, replyContent) => {
  return crud({
    dispatch: {
      begin: 'BEGIN_SEND_REPLY_TO_RECORD',
      fail: 'FAILED_SEND_REPLY_TO_RECORD',
      end: 'FINISHED_SEND_REPLY_TO_RECORD',
    },
    method: 'POST',
    data: replyContent,
    url: `/api/v1/receivers/${sendToken}/replies`,
    callback: () => disableReplying(),
  });
};

const getAttachmentUrl = (url) => {
  return crud({
    dispatch: {
      begin: 'BEGIN_GET_ATTACHMENT_URL',
      fail: 'FAILED_GET_ATTACHMENT_URL',
      end: 'FINISHED_GET_ATTACHMENT_URL',
    },
    method: 'GET',
    url: url,
  });
};

export {
  getRecordResponseById,
  sendReplyToRecord,
  getAttachmentUrl,
};
