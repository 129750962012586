let EMRs = [
  'Patagonia Health',
  'Kareo',
  'EMA / Modernizing Medicine',
  'Practice Fusion',
  'NEMO Health / TrakNet',
  'eClinicalWorks (Web App)',
  'eClinicalWorks (Desktop App)',
  'Simple Practice',
  'Epic',
  'Athenahealth',
  'NexTech / MDIntellsys',
  'DocuTAP',
  'TherapyNotes',
  'AdvancedMD',
  'Aprima',
  'Point Click Care',
  'Netsmart',
  'PrimeSUITE',
  'NextGen',
  'Care Cloud',
  'Centricity',
  'Gmed',
  'Theranest',
  'WebPT',
  'Icanotes',
  'Talk EHR',
  'MacPractice',
  'Allscripts',
  'Experity',
  'Practice Mate',
  'Office Ally',
  'DrChrono',
  'Nymbl Systems',
  'PillHelp Works',
  'Method One',
  'Prognocis',
  'Brightree',
  'Sunwave',
  'Medics Cloud',
  'Care Logic',
  'Elation',
  'Care Tracker',
  'OncoEMR',
  'CharmEHR',
];

EMRs.sort();
EMRs.push('Other');

export const SortedEMRs = EMRs;

const AILabels = [
  'Referral',
  'Pharmacy Request',
  'Prior Authorization',
  'Lab Result',
  'Consult Note',
  'Imaging Result',
  'Treatment Consent',
  'Discharge Summary',
];

AILabels.sort();

export const SortedAILabels = AILabels;

const InboundFeature = "Get faxes straight into the patient's chart";
const OutboundFeature = 'Send faxes and emails from your EMR in 1-click';

export const SupportedEMRs = [
  { emr: 'Kareo', supportedFeatures: [OutboundFeature, InboundFeature] },
  { emr: 'eClinicalWorks (Web App)', supportedFeatures: [OutboundFeature, InboundFeature] },
  { emr: 'EMA / Modernizing Medicine', supportedFeatures: [InboundFeature] },
  { emr: 'Athenahealth', supportedFeatures: [OutboundFeature] },
  { emr: 'DrChrono', supportedFeatures: [OutboundFeature, InboundFeature] },
  { emr: 'Practice Fusion', supportedFeatures: [InboundFeature] },
  { emr: 'Nymbl Systems', supportedFeatures: [InboundFeature] },
  { emr: 'AdvancedMD', supportedFeatures: [InboundFeature, OutboundFeature] },
  { emr: 'Simple Practice', supportedFeatures: [InboundFeature, OutboundFeature] },
  { emr: 'WebPT', supportedFeatures: [InboundFeature, OutboundFeature] },
];
