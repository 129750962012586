import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { TagOutlined } from '@ant-design/icons';
import CheckableTag from 'antd/lib/tag/CheckableTag';

import { stringify } from 'qs';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  arrayFormat: 'brackets',
  sort: (a, b) => (a.localeCompare(b)),
};


class SearchSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchLoading: false,
      selectedLabel: [],
      searchLabels: [],
      topLabelsToFilterBy: [],
      orgLabels: [],
    };
  }

  componentWillMount = async () => {
    const { getLabelsByQuery } = this.props;
    const params = {
      sort: 'label_usage',
      sort_dir: 'DESC',
      page: 1,
      page_size: 'max',
    };
    const query = stringify(params, stringifyOptions);
    await getLabelsByQuery(query);
    const { labels, paramTools } = this.props;
    let labelIsSelected = false;
    const searchLabelIsSelected = paramTools.params.tag instanceof Array;
    labels.map((label) => {
      if (paramTools.params.tag === label.name) {
        labelIsSelected = true;
      }
    });
    const labelsSize = labels.length >= 4 ? 5 : labels.length;
    this.setState({
      topLabelsToFilterBy: labels.slice(0, labelsSize),
      orgLabels: labels,
      selectedLabel: labelIsSelected ? paramTools.params.tag : [],
      searchLabels: searchLabelIsSelected ? paramTools.params.tag : [],
    });
  }


  /**
   * updateQuery: This updates the URL query to any Sidebar settings changes.
   * @param  {String} tag
   */
  updateQuery = async (tag) => {
    this.setState({ selectedLabel: [] });
    const { paramTools, inboxActions, queryType } = this.props;
    const { params } = paramTools;
    const { getRecords } = inboxActions;
    const emptySearch = (tag.toString().trim() === '');
    if (params[queryType] === '' || emptySearch) {
      this.setState({ searchLabels: [] });
      delete params[queryType];
      params.page = 1;
    } else {
      this.setState({ searchLabels: tag });
      params[queryType] = tag;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getRecords(query);
    this.setState({
      searchLoading: false,
    });
  }

  handleTagSearchQuery = async (tag, checked) => {
    this.setState({ searchLabels: [] });
    const { paramTools, inboxActions, queryType } = this.props;
    const { params } = paramTools;
    const { getRecords } = inboxActions;
    const clearTag = params[queryType] === tag;
    if (clearTag) {
      this.setState({ selectedLabel: [] });
      delete params[queryType];
      params.page = 1;
    } else {
      this.setState({ selectedLabel: [tag] });
      params[queryType] = tag;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getRecords(query);
    this.setState({
      searchLoading: false,
    });
  }


  render() {
    const { hint } = this.props;
    const {
      searchLoading,
      selectedLabel,
      searchLabels,
      topLabelsToFilterBy,
      orgLabels,
    } = this.state;
    return (
      <div>
        <Select
          placeholder={hint}
          mode="multiple"
          allowClear
          value={searchLabels}
          options={
            orgLabels.map((option, index) => {
              return {
                label: option.name,
                value: option.name,
                key: index,
              };
            })
          }
          prefix={<TagOutlined />}
          onChange={value => this.updateQuery(value)}
          style={{ width: '100%' }}
          loading={searchLoading}
        />
        {topLabelsToFilterBy.length > 0
          && (
          <div style={{ marginTop: '15px', marginBottom: '15px' }}>
            {topLabelsToFilterBy.map(label => (
              <CheckableTag
                key={label.name}
                checked={selectedLabel.indexOf(label.name) > -1}
                style={{
                  margin: '5px',
                  backgroundColor: label.color,
                  color: label.is_dark_text ? 'black' : 'white',
                  border: selectedLabel.indexOf(label.name) > -1 && '2px solid black',
                  boxShadow: selectedLabel.indexOf(label.name) > -1 && `0px 0px 6px ${label.color}`,
                }}
                onClick={checked => this.handleTagSearchQuery(label.name, checked)}
              >
                {label.name}
              </CheckableTag>
            ))}
          </div>
          )
        }
      </div>
    );
  }
}

/**
 * DateSettings Prop description:
 * @object {actions & paramTools} Refer to <Sidebar> for description on these props. (See ./../index.jsx)
 *
 * @string {hint & floatingLabelText} hintText & floatingLabelText strings used in the TextField
 * @string {queryType} used to remove dob param from the query if the date is removed by the user in the Sidebar
 */
SearchSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
  hint: PropTypes.string,
  labels: PropTypes.array,
  queryType: PropTypes.string,
};

SearchSettings.defaultProps = {
  hint: 'Search here...',
  queryType: 'tag',
  labels: [],
};

export default SearchSettings;
