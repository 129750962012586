const welcomePageCopy = {
  pageTitle: 'Welcome to Medsender',
  sections: [
    {
      title: 'Table of Contents',
      id: 'tableOfContents',
      subtitle: `
        A guide to our guide! See what each section covers to quickly find what you’re looking for. 
        Don't see what you're looking for? Go to our FAQ section`,
      cards: [
        {
          title: 'Getting Started',
          sections: [
            'Logging into Medsender',
            'Two Factor Authentication',
            'Chrome Extension',
            'Installing Printer Driver',
          ],
          menuItemIndex: 2,
        },
        {
          title: 'Uploading Records',
          sections: [
            'Using the printer Driver',
            'EMR Integration',
            'Manual Upload',
          ],
          menuItemIndex: 3,
        },
        {
          title: 'Sending Records',
          sections: [
            'How to Send',
            'Sending securely to an Email Address',
            'Sending to a Fax Number',
          ],
          menuItemIndex: 4,
        },
        {
          title: 'Receiving Records',
          sections: [
            'Receiving a Document',
            'Permissions, downloads, and more',
          ],
          menuItemIndex: 5,
        },
        {
          title: 'Singing and Annotating Records',
          sections: [
            'Types of Annotations',
            'Adding Text',
            'Signing a Document',
            'Stamp/Image',
            'Draw/Highlight',
            'Document Editor',
            'Deleting an Annotation',
          ],
          menuItemIndex: 6,
        },
        {
          title: 'Merging Records',
          sections: [
            'How to merge records',
          ],
          menuItemIndex: 7,
        },
        {
          title: 'Labeling Records',
          sections: [
            'Managing your Labels',
            'Labeling a Record',
            'Notifying a User',
          ],
          menuItemIndex: 8,
        },
        {
          title: 'Assigning Records (NEW)',
          sections: [
            'How to Assign a Document',
            'View Assigned Documents',
            'Edit Status and Assignee',
            'Mark assignment as Complete',
          ],
          menuItemIndex: 15,
        },
        {
          title: 'Chrome Extension',
          sections: [
            'List of EMR Integrations',
            'Installing our Chrome Extension',
            'Using the Chrome Extension',
          ],
          menuItemIndex: 9,
        },
        {
          title: 'Printer Driver',
          sections: [
            'Installing the Printer Driver',
            'Using the Printer Driver',
          ],
          menuItemIndex: 10,
        },
        {
          title: 'Admin Tools',
          sections: [
            'Admin Users',
            'Audit Logs',
            'Departments',
            'Fax Numbers',
            'Users',
            'Manage Organizations',
            'Anayltics',
          ],
          menuItemIndex: 11,
        },
        {
          title: 'FAQ',
          sections: [
            'Changing your Password',
            'Managing Email Notifications',
            'Porting a Fax Number',
          ],
          menuItemIndex: 12,
        },
      ],
    },
    {
      title: 'Contact Us',
      id: 'contactUs',
      subtitle: `Can't find what you're looking for or have any suggestions or feedback
      for how we can improve?`,
    },
    {
      subtitle: 'Send an email to our support channel here!',
      link: 'https://medsender.com/support',
    },
  ],
};

export default welcomePageCopy;
