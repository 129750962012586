import crud from './helpers/crud';

const getLabelsByPage = (page = 1, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_LABELS',
      fail: 'FAILED_GET_LABELS',
      end: 'FINISH_GET_LABELS',
    },
    method: 'GET',
    url: `/api/v1/labels?page=${page}&page_size=${pageSize}`,
  })
);

const getLabelsByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_LABELS',
      fail: 'FAILED_GET_LABELS',
      end: 'FINISH_GET_LABELS',
    },
    method: 'GET',
    url: `/api/v1/labels${query}`,
  })
);

const createNewLabel = labelObject => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_LABEL',
      fail: 'FAILED_CREATE_LABEL',
      end: 'FINISH_CREATE_LABEL',
    },
    method: 'POST',
    data: {label: labelObject},
    url: '/api/v1/labels',
  })
);

const deleteLabel = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_LABEL',
      fail: 'FAILED_DELETE_LABEL',
      end: 'FINISH_DELETE_LABEL',
    },
    method: 'DELETE',
    url: `/api/v1/labels/${id}`,
  })
);

const updateLabel = (id, labelObject) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_LABEL',
      fail: 'FAILED_UPDATE_LABEL',
      end: 'FINISH_UPDATE_LABEL',
    },
    method: 'PUT',
    url: `/api/v1/labels/${id}`,
    data: { label: labelObject },
  })
);

export {
  getLabelsByPage,
  createNewLabel,
  deleteLabel,
  updateLabel,
  getLabelsByQuery,
};
