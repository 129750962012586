import crud from './helpers/crud';

/**
 * Calls the search autcomplete endpoint
 * @param {String} query the search parameter
 * @return {Void}
 */
const searchRecipient = (title, query) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_SEARCH_RESULTS',
      fail: 'FAILED_GET_SEARCH_RESULTS',
      end: 'FINISHED_GET_SEARCH_RESULTS',
    },
    method: 'GET',
    url: `/api/v1/search?${title}=${query}`,
  })
);

export default searchRecipient;
