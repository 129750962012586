import React from 'react';
import propTypes from 'prop-types';
import { Button, Tooltip } from 'antd';

/* eslint-disable-next-line object-curly-newline */
const NewFlatButton = ({ disabled, onClick, icon, label, tooltipTitle, style }) => {
  return (
    <Tooltip
      title={tooltipTitle}
      placement="bottom"
    >
      <Button
        type="text"
        size="large"
        disabled={disabled}
        onClick={onClick}
        icon={icon}
        style={style}
      >
        { /* eslint-disable-next-line react/jsx-one-expression-per-line */}
        &nbsp;{label}
      </Button>
    </Tooltip>
  );
};

NewFlatButton.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func.isRequired,
  label: propTypes.string.isRequired,
  icon: propTypes.element,
  tooltipTitle: propTypes.string,
  style: propTypes.object,
};

NewFlatButton.defaultProps = {
  disabled: false,
  icon: null,
  style: {},
  tooltipTitle: null,
};

export {
  NewFlatButton,
};
