import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Link from 'react-router-dom/Link';
import {
  Input,
  message,
} from 'antd';

import ModalWrapper from './ModalWrapper';

class UpdatePhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNum: '',
      otpCode: '',
      verifyingOtp: false,
      phoneNumInputError: false,
      isValidPhoneNum: false,
      invalidOtp: false,
      isValidOtpInput: false,
      phoneNumInputErrorMessage: '',
      verifiedCurrentPhone: false,
      verifyingOtpOldPhone: false,
      recentlyPorted: false,
    };
  }

  handleChange = (number) => {
    if (!number) return;
    const isPhoneValid = isValidPhoneNumber(number);
    this.setState({ isValidPhoneNum: isPhoneValid, phoneNum: number });
  }

  sendCodeOldPhone = async () => {
    const result = await this.props.sendOtpToSms(this.props.modalType.data.phoneNumber);
    if (result && result.error) {
      const err = result.response.data.error;
      this.setState({
        phoneNumInputErrorMessage: err, 
      })
    } else {
      this.setState({
        verifyingOtpOldPhone: true,
      })
    }
  } 

  sendCode = async () => {
    const result = await this.props.sendOtpToSms(this.state.phoneNum);
    if (result && result.response.status === 400) {
      const err = result.response.data.error;
      this.setState({
        phoneNumInputErrorMessage: err,
      })
    } else {
      this.setState({
        verifyingOtp: true,
        phoneNumInputError: false,
      });
    }
  }

  handleSubmit = async () => {
    const { hideModal } = this.props;

    await this.props.verifySmsOtp(this.state.otpCode, this.state.phoneNum);
    const { error } = this.props;
    if (error) {
      this.setState({ invalidOtp: true });
      return;
    }
    hideModal();
    message.success({
      content: 'Cell Phone Number successfully updated',
      style: { marginTop: '70px' },
    });
    window.location.reload();
  }

  handleOldPhoneVerification = async () => {
    await this.props.verifySmsOtp(this.state.otpCode, this.props.modalType.data.phoneNumber);
    const { error } = this.props;
    if (error) {
      this.setState({ invalidOtp: true });
      return;
    }
    this.setState({ verifiedCurrentPhone: true });
    this.setState({ otpCode: '' })
  }
    
  // Skips the old phone number verification step if there is no current phone number in the system
  componentDidMount() {
    if (this.props.modalType.data.phoneNumber === null) {
      this.setState({ verifiedCurrentPhone: true });
    }
  }

  render() {
    const { hideModal } = this.props;
    const {
      phoneNum, verifyingOtp, isValidPhoneNum, invalidOtp, isValidOtpInput, phoneNumInputError, phoneNumInputErrorMessage, verifiedCurrentPhone, verifyingOtpOldPhone,
    } = this.state;
    return (
      <div>
        {!verifiedCurrentPhone
          ? (verifyingOtpOldPhone ? (
          <ModalWrapper
            hideModal={hideModal}
                dismiss="Cancel"
                action={this.handleOldPhoneVerification}
                actionName="Submit"
                modalTitle="Verify old cell phone number"
                disabled={!isValidOtpInput}
              >
                <p>A verification code has been sent to your current cell phone number, please input it below.</p>
                <Input
                  value={this.state.otpCode}
                  onChange={e => this.setState({ invalidOtp: false, otpCode: e.target.value, isValidOtpInput: e.target.value.length === 6 })}
                />
                <br />
                {invalidOtp ? <p style={{ color: 'red' }}>The verification code you inputted is incorrect. Please try again.</p> : <p /> }
                <p onClick={this.sendCode}>
                  <Link to="/app/user">Resend verification code</Link>
                </p>
          </ModalWrapper>
          )
          : (
            <ModalWrapper
              hideModal={hideModal}
              dismiss="Cancel"
              action={this.sendCodeOldPhone}
              actionName="Ok"
              modalTitle="Get your old cell phone ready"
            >
              <p>A verification code will be sent to the phone number currently in our records.</p>
              {
                this.props.error && this.props.message !== ''
                && <p style={{ color: 'red', marginTop: '10px' }}>{phoneNumInputErrorMessage}</p>
              }
            </ModalWrapper>
            )
          )
          : (verifyingOtp
            ? (
              <ModalWrapper
                hideModal={hideModal}
                dismiss="Cancel"
                action={this.handleSubmit}
                actionName="Submit"
                modalTitle="Verify your cell phone number"
                disabled={!isValidOtpInput}
              >
                <p>A verification code has been sent to your cell phone number, please input it below.</p>
                <Input
                  value={this.state.otpCode}
                  onChange={e => this.setState({ invalidOtp: false, otpCode: e.target.value, isValidOtpInput: e.target.value.length === 6 })}
                />
                <br />
                {invalidOtp ? <p style={{ color: 'red' }}>The verification code you inputted is incorrect. Please try again.</p> : <p /> }
                <p onClick={this.sendCode}>
                  <Link to="/app/user">Resend verification code</Link>
                </p>
                <p>
                  <Link to="/app/user" onClick={() => this.setState({ verifyingOtp: false })}>Use different cell phone number</Link>
                </p>
              </ModalWrapper>
            )
            : (
              <ModalWrapper
                hideModal={hideModal}
                dismiss="Cancel"
                action={this.sendCode}
                actionName="Submit"
                modalTitle="Update your cell phone number"
                disabled={!isValidPhoneNum}
              >
                <p>Enter your cell phone number</p>
                <label>
                  <strong>(+1) </strong>
                </label>
                <PhoneInput
                  country="US"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  placeholder="Ex: 555 555 1234"
                  value={phoneNum}
                  onChange={
              (number) => {
                if (!number) return;
                const isPhoneValid = isValidPhoneNumber(number);
                this.setState({ isValidPhoneNum: isPhoneValid, phoneNum: number });
            }}
              />
              <br />
              {
                this.props.error && this.props.message !== ''
                && <p style={{ color: 'red', marginTop: '10px' }}>{phoneNumInputErrorMessage}</p>
              }
            </ModalWrapper>
          )
        )}
      </div>
    );
  }
}

UpdatePhoneNumber.defaultProps = {
  phoneNumber: null,
};

UpdatePhoneNumber.propTypes = {
  updateUserDetails: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
  sendOtpToSms: PropTypes.func.isRequired,
  verifySmsOtp: PropTypes.func.isRequired,
};

export default UpdatePhoneNumber;
