import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntCheckbox } from 'antd';
import './checkbox.scss';

const Checkbox = ({ children, ...props }) => {
  return (
    <AntCheckbox {...props}>
      {children}
    </AntCheckbox>
  );
};

Checkbox.propTypes = {
  children: PropTypes.any,
};

Checkbox.defaultProps = {
  children: {},
};

export default Checkbox;
