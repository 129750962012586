import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { Card, Button, Form } from 'antd';

import { Auth, Footer } from '../components';
import { patientLogin, patientSecurityLogin, patientSetQuestionAndLogin } from '../actions/auth';
import { validPatientRecipient, formatDate } from '../global/validation';

const h2Style = {
  fontWeight: '500',
  marginBottom: '10',
};

const questionsStyle = {
  width: '450px',
  textAlign: 'left',
};

const questionsList = [
  "What is your mother's maiden name?",
  'What was the make and model of your first car?',
  'What school did you attend for sixth grade?',
  'What is your drivers license number?',
  'In what town was your first job?',
];


const questions = questionsList.map((question, index) => (
  <MenuItem key={index + 1} value={index + 1} primaryText={questionsList[index]} />
));

class PatientAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient: undefined,
      dob: undefined,
      patient_id: props.match.params.patient_id,
      record_id: props.match.params.id,
      errorStatus: {
        error: false,
        recipientMsg: '',
        passwordMsg: '',
      },
      selected_question: undefined,
      security_answer: '',
      security_data: {
        recipient: localStorage.getItem('m8_patient_recipient'),
        question: localStorage.getItem('m8_security_question'),
        needs_security: localStorage.getItem('m8_needs_security'),
        file_token: localStorage.getItem('m8_file_token'),
        patient_auth_token: localStorage.getItem('m8_auth_token'),
      },
    };
  }

  /**
   * Remove the stored variables from localStorage once the component is mounted. They're already
   * loaded in the state so no longer needed in localStorage.
   */
  componentDidMount() {
    localStorage.removeItem('m8_patient_recipient');
    localStorage.removeItem('m8_security_question');
    localStorage.removeItem('m8_needs_security');
    localStorage.removeItem('m8_file_token');
    localStorage.removeItem('m8_auth_token');
  }

  gettingValues = (values, allValues) => {
    const recipientInput = String(allValues.recipient).replace(' ', '').toLowerCase();
    this.setState({
      recipient: recipientInput,
      security_answer: allValues.patientSecurity,
    });
  }

  formValues = (field, value) => {
    switch (field) {
      case 'recipient': {
        // Serialize recipient by removing spaces
        const input = String(value).replace(' ', '');
        this.setState({ recipient: input });
        break;
      }
      case 'dob': {
        this.setState({ dob: value });
        break;
      }
      case 'patientSecurity':
        this.setState({ security_answer: value });
        break;
    }
  }

  submit = (event) => {
    event.preventDefault();
    // Validate input before submitting
    const inputValid = this.validateInput();
    // Update child components with errorStatus
    this.setState({ errorStatus: inputValid });
    // If input is okay and 2FA is required make the request
    if (inputValid.error === false && this.state.security_data.needs_security) {
      if (this.state.security_data.question) {
        // If security question was provided, send request with answer.
        this.props.patientSecurityLogin({
          recipient: this.state.security_data.recipient,
          question: this.state.security_data.question,
          answer: this.state.security_answer.trim(),
          auth_token: this.state.security_data.patient_auth_token,
          file_token: this.state.security_data.file_token,
        });
      } else {
        // Otherwise, send request with selected question and answer.
        this.props.patientSetQuestionAndLogin({
          recipient: this.state.security_data.recipient,
          question: questionsList[this.state.selected_question - 1],
          answer: this.state.security_answer.trim(),
          auth_token: this.state.security_data.patient_auth_token,
          file_token: this.state.security_data.file_token,
        });
      }
      this.state.security_data.needs_security = false;
    } else if (inputValid.error === false) {
      this.props.patientLogin({
        recipient: this.state.recipient.trim(),
        dob: formatDate(this.state.dob),
        token: this.state.patient_id,
        file_token: this.state.record_id,
      });
    }
  }

  handleQuestionChange = (event, index, value) => {
    event.preventDefault();
    this.setState({
      selected_question: value,
    });
  }

  handleDobFieldChange = (selectedDate) => {
    this.setState({
      dob: selectedDate,
    });
  };

  validateInput() {
    let errorStatus = {
      error: false,
      recipientMsg: '',
      dobMsg: '',
      securityErrorMsg: '',
    };
    // Sets the correct form validation error message
    // => Checks if an otp code was entered, gives an error for an empty string
    // or a string of only white spaces
    if (this.state.security_data.needs_security && (!this.state.security_answer || !this.state.security_answer.trim().length)) {
      errorStatus = {
        ...errorStatus,
        error: true,
        securityErrorMsg: 'The answer to the security question cannot be empty.',
      };
    } else if (!this.state.security_data.needs_security) {
      if (!validPatientRecipient(this.state.recipient)) {
        errorStatus = {
          ...errorStatus,
          error: true,
          recipientMsg: 'A valid email or cell phone number is required',
        };
      }
      if (!this.state.dob) {
        errorStatus = {
          ...errorStatus,
          error: true,
          dobMsg: 'Date of Birth cannot be empty',
        };
      }
    }
    return errorStatus;
  }

  renderSecurityLogin() {
    if (this.state.security_data.question) {
      // If security question was returned, ask patient for the answer.
      return (
        <div>
          <h2 style={h2Style}> One Last Step </h2>
          <p>
            Please enter the answer to your security question below.<br />
            If you have any issues, please contact Medsender Support
            for further assistance.
          </p>
          {this.props.error && this.props.message !== '' && <p style={{ color: 'red' }}>{this.props.message}</p>}
          <h4 className="security-question">{this.state.security_data.question}</h4>
          <Auth
            form="patientSecurity"
            update={this.formValues}
            msg={this.props.message}
            error={this.state.errorStatus}
            needs_2FA={true}
          />
        </div>
      );
    }
    // Otherwise, ask patient to set security question.
    const { handleQuestionChange } = this;
    return (
      <div>
        <h2 style={h2Style}> One Last Step </h2>
        <p>
          For your security, please choose and answer a security question from below.<br />
          Please remember your answer for the next time you view a record.
        </p>
        <SelectField
          className="login-field" 
          onChange={handleQuestionChange.bind(this)}
          floatingLabelText="Security Question"
          hintText="Please select a security question:"
          style={questionsStyle}
          value={this.state.selected_question}
          floatingLabelStyle={{ color: 'rgba(81, 46, 168, 1)' }}
          floatingLabelFixed={true}
        >
          {questions}
        </SelectField>
        <br />
        <Form
          name="patientSecurity"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={this.submit}
          onValuesChange={this.gettingValues}
          layout="vertical"
        >
          <Auth
            form="patientSecurity"
            update={this.formValues}
            msg={this.props.message}
            error={this.state.errorStatus}
            needs_2FA={true}
          />
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div
        className="login"
        role="presentation"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            this.submit(e);
          }
        }}
      >
        <Form
          name="patient"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={this.submit}
          onValuesChange={this.gettingValues}
          layout="vertical"
        >
          { // If 2FA is required render the correct message and pass correct props
            // to the Auth component
            this.state.security_data.needs_security === 'true'
              ? this.renderSecurityLogin()
              : (
                <div>
                  <Card className="login-card">
                    <h2 style={h2Style}>Secure Patient Access</h2>
                    <p style={{ marginBottom: '20px' }}>
                      To ensure you are the intended recipient, please verify your email/cell phone number and date of birth.
                    </p>
                    {
                      this.props.error && this.props.message !== '' &&
                      <p style={{ color: 'red' }}>{this.props.message}</p>
                    }
                    <Auth
                      form="patient"
                      update={this.formValues}
                      msg={this.props.message}
                      error={this.state.errorStatus}
                      needs_2FA={false}
                    />
                  </Card>
                </div>
              )
          }
        <br />
          <Form.Item>
            <Button
              style={{ width: '25rem', marginTop: 30 }}
              shape="round"
              type="primary"
              onClick={this.submit}
              size="large"
              htmlType="submit"
            >
            Submit
            </Button>
          </Form.Item>
        </Form>
        <br />
        <br />
        <Footer />
      </div>
    );
  }
}

PatientAccess.defaultProps = {
  data: {
    error: '',
  },
  message: '',
  loading: false,
};

PatientAccess.propTypes = {
  message: PropTypes.string,
  patientLogin: PropTypes.func.isRequired,
  patientSecurityLogin: PropTypes.func.isRequired,
  patientSetQuestionAndLogin: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    error: state.auth.error,
    data: state.auth.data,
    message: state.auth.patientErrorMessage,
    recipient: state.auth.otp_auth && state.auth.otp_auth.recipient,
  }),
  { patientLogin, patientSecurityLogin, patientSetQuestionAndLogin },
)(PatientAccess);

export {
  PatientAccess,
};
