import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Paper from 'material-ui/Paper';
import Dialog from 'material-ui/Dialog';
import FontIcon from 'material-ui/FontIcon';

import {
  BILLING_ENUMS,
  billingPlans,
  planLevels,
  chargebeePlans,
  chargebeeBasicPlans,
} from '../constants';
import './billing.scss';

class PlanSelector extends Component {
  static propTypes = {
    changeModal: PropTypes.func.isRequired,
    validBilling: PropTypes.bool.isRequired,
    isUpgradeInProgress: PropTypes.bool,
    planType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isUpgradeInProgress: false,
  };

  state = {
    open: true,
    // eslint-disable-next-line react/destructuring-assignment
    selectedPlanIndex: planLevels[this.props.planType] === undefined ? 1 : planLevels[this.props.planType],
  }

  checkout = async () => {
    const { selectedPlanIndex } = this.state;
    const { changeModal, isUpgradeInProgress } = this.props;
    const selectedPlan = chargebeePlans[selectedPlanIndex];
    await this.setState({ open: false });
    const { PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT, PROCESS_NEW_SUBSCRIPTION_PAYMENT } = BILLING_ENUMS;
    const modalState = isUpgradeInProgress ? PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT : PROCESS_NEW_SUBSCRIPTION_PAYMENT;
    changeModal(modalState, selectedPlan, true);
  }

  handleSelectedPlan = (selectedPlanIndex) => {
    const { planType } = this.props;
    // Focus on plan and highlight with a purple border, if plan is higher than selected plan
    if (selectedPlanIndex >= planLevels[planType] || !planLevels[planType]) {
      return this.setState({ selectedPlanIndex });
    }
  }

  renderPlans = () => {
    const { selectedPlanIndex } = this.state;
    const { isUpgradeInProgress } = this.props;
    const { planType } = this.props;
    const isUserOnStarterOrFreePlan = chargebeeBasicPlans.concat('free').includes(planType);
    return billingPlans.map((plan, index) => {
      const {
        name,
        pricing,
        subtext,
        features,
        popular,
      } = plan;
      // Show header when user has their current plan selected
      const showCurrentPlanHeader = index === planLevels[planType] && isUpgradeInProgress;
      // Show Ribbon on the next higher plan or recommend group when user is on a starter plan
      const showRibbon = (index === (planLevels[planType] + 1)) || (isUserOnStarterOrFreePlan && popular);
      // If current plan is lower than the current plan
      const isLowerThanCurrentPlan = index <= planLevels[planType];
      const isHigherOrEqualToCurrentPlan = this.props;
      // Show 0 border radius when the current plan container has not been selected.
      const borderRadius = !showCurrentPlanHeader ? '10px' : '0';
      const planClassName = selectedPlanIndex === index && isHigherOrEqualToCurrentPlan ? 'plan-selected' : 'plan-select';
      return (
        <div key={name} className={`plan-container ${popular && 'plan-box'}`}>
          {!chargebeeBasicPlans.includes(planType) && (
            <div className={showCurrentPlanHeader ? 'current-plan' : 'header'}>
              {showCurrentPlanHeader && 'Current Plan'}
            </div>
          )}
          <Paper
            onClick={() => this.handleSelectedPlan(index)}
            className={`${planClassName} ${isLowerThanCurrentPlan ? 'disable-plan' : ''}`}
            style={{ borderRadius, padding: '20px', position: 'relative' }}
          >
            {showRibbon && <div className="billing-ribbon"><span>POPULAR</span></div>}
            <div className="plan-header">
              <h3>{name}</h3>
              <h1>{pricing}</h1>
            </div>
            {subtext && <div className="plan-subtext">{subtext}</div>}
            {features.map(feature => (
              <div key={feature} className="plan-feature">
                <FontIcon className="material-icons">check</FontIcon>
                &nbsp;
                {feature}
              </div>
            ))}
          </Paper>
        </div>
      );
    });
  }

  render() {
    const { open, selectedPlanIndex } = this.state;
    const {
      validBilling,
      changeModal,
      isUpgradeInProgress,
      planType,
    } = this.props;
    const isLowerPlan = selectedPlanIndex <= planLevels[planType];

    return (
      <Dialog
        title="Select a plan"
        titleStyle={{ textAlign: 'center' }}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label={!validBilling ? 'Back' : 'Later'}
            onClick={() => {
              if (!validBilling) changeModal(BILLING_ENUMS.DISPLAY_TRIAL_ENDING);
              else if (isUpgradeInProgress) changeModal('');
              else {
                changeModal('');
                this.setState({ open: false });
              }
            }}
          />,
          <FlatButton
            label="Subscribe"
            disabled={selectedPlanIndex === -1 || isLowerPlan}
            primary={true}
            onClick={() => this.checkout()}
          />,
        ]}
        open={open}
      >
        <div>
          All Medsender plans include HIPAA compliant email, SMS, and fax, with a never-busy fax line.
        </div>
        {(chargebeeBasicPlans.includes(planType)) && (
          <div className="essentials-plan-header">
            <p>Current Plan: Essentials</p>
          </div>
        )}
        <div className="plan-selection-wrapper">
          { this.renderPlans() }
        </div>
        {/* Do not show this link if user is perfoming an upgrade */}
        {!isUpgradeInProgress && (
          <p
            className="essentials-anchor"
            onClick={() => changeModal(BILLING_ENUMS.BASIC_PLAN, null, true)}
          >
            Looking for something more basic?
          </p>
        )}
      </Dialog>
    );
  }
}

export default PlanSelector;
