// Display login via modal component
import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';

const pStyle = {
  fontWeight: '600',
  textAlign: 'center',
};

const loginFieldStyle = {
  width: '330px',
};

const error = {
  color: 'red',
  fontSize: '13px',
};

const emailFieldDetails = {
  header: 'Enter Your Email and Password',
  name: 'email',
  hintText: 'user@medical.com',
  floatingLabelText: 'Email Address',
};

const verificationFieldDetails = {
  header: 'Verification Code',
  name: 'otp',
  hintText: 'Enter the Verification Code Here',
  floatingLabelText: 'Verification Code',
};

class LoginModal extends Component {
  render() {
    const { needs2FA, errorStatus, loginError } = this.props;
    const fieldDetails = needs2FA ? verificationFieldDetails : emailFieldDetails;
    return (
      <div>
        <p style={pStyle}>
          {fieldDetails.header}
        </p>
        {needs2FA &&
          <p>
            We have detected that you are signing in from a new browser. For your security,
            a verification code has been sent to your email.
            Please enter it below.
          </p>
        }
        <TextField
          name={fieldDetails.name}
          style={loginFieldStyle}
          onChange={this.props.handleChange}
          hintText={fieldDetails.hintText}
          floatingLabelText={fieldDetails.floatingLabelText}
          type="text"
          errorText={needs2FA ? errorStatus.otpMsg : errorStatus.usernameMsg}
        />
        <br />
        {needs2FA ?
          <div>
            <p style={{ fontSize: '13px'}}>
              Having trouble ? <Link to="" onClick={this.props.resendModalOtp} >Resend security code</Link>&nbsp;or&nbsp;
              <Link to="" onClick={this.props.startOver}>Start over</Link>
            </p>
          </div> :
          <TextField
            name="password"
            style={loginFieldStyle}
            onChange={this.props.handleChange}
            hintText="password"
            floatingLabelText="password"
            type="password"
            errorText={errorStatus.passwordMsg}
          />
        }
        {loginError.error && !errorStatus.error &&
          <p style={error}>{loginError.errorMessage}</p>
        }
      </div>
    );
  }
}

LoginModal.propTypes = {
  errorStatus: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  resendModalOtp: PropTypes.func.isRequired,
  startOver: PropTypes.func.isRequired,
  loginError: PropTypes.object.isRequired,
  needs2FA: PropTypes.bool.isRequired,
};

export default LoginModal;
