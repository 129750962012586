// Display the cell rows on the Sent Record Status Page
import React from 'react';
import PropTypes from 'prop-types';

const RecordDetailHeader = ({ title, content }) => {
  const statusTitle = `${title}:`;
  return (
    <div className="status-header">
      <h2>
        <b style={{ color: 'white' }}>{statusTitle}</b>
        &nbsp;
        <span style={{ fontWeight: '200', color: 'white' }}>
          {content}
        </span>
      </h2>
    </div>
  );
};

RecordDetailHeader.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default RecordDetailHeader;
