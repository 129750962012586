// Raise flag component displays the modal content.
// Mainly provide "usage" or subscription info to the user

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

const customContentStyle = {
  width: '45%',
};

const header = {
  textAlign: 'center',
  fontSize: '20px',
};

class RaiseFlag extends Component {
  render() {
    const { featureFlag } = this.props.modalType;
    const email = 'support@medsender.com';
    const emailSubject = featureFlag === 'fwd_to_emr' ? 'Forward to EMR feature' : 'Upgrade your account';
    const upgradeDialog = featureFlag === 'fwd_to_emr' ? 'to an eligible plan' : 'your account';
    const emailBody = encodeURI("Hi, I'm interested in learning more about this feature.");
    const mailTo = `mailto:${email}?subject=${encodeURI(emailSubject)}&body=${emailBody}`;
    return (
      <ModalWrapper
        hideModal={this.props.hideModal}
        customContentStyle={customContentStyle}
        dismiss={'Close'}
        actionName={'Learn more!'}
        action={() => {
          const learnMoreWindow = window.open();
          learnMoreWindow.location.href = `https://meetings.hubspot.com/zain3/15-minute-newfeature-demo?feature=${featureFlag}`;
          learnMoreWindow.opener = null;
        }}
        modalTitle="Upgrade Required"
      >
        <b>Oh snap!&nbsp;</b>
        To access this feature, you need to upgrade&nbsp;
        {upgradeDialog}
        . We can get you set up
        pretty quickly. Click&nbsp;
        <a href={mailTo}>here</a> to get in touch with our team.
        <br />
        <br />
        If you want to learn more about this feature, click the button below.
      </ModalWrapper>
    );
  }
}

RaiseFlag.propTypes = {
  hideModal: PropTypes.func.isRequired,
  trialUser: PropTypes.bool.isRequired,
};

export default RaiseFlag;
