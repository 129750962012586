import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CreateTask from '../../components/modal/CreateTask';
import { triggerTaskCreation } from '../../actions/records';
import { getEmaTaskProviders, getTaskTemplatesByQuery } from '../../actions/userProfile';

class CreateTaskDialog extends Component {
  static propTypes = {
    editReceivedRecordLabels: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <CreateTask
          {...this.props}
        />
      </div>
    );
  }
}

CreateTaskDialog.propTypes = {
  triggerTaskCreation: PropTypes.func.isRequired,
};


export default connect(state => ({
  sendToken: state.auth.data.sendToken,
}), { triggerTaskCreation, getEmaTaskProviders, getTaskTemplatesByQuery })(CreateTaskDialog);
