import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Checkbox, Input, Select } from 'antd';

import isOnChrome from '../../global/browser';
import './walkthrough.scss';

class WalkthroughStepTemplate extends Component {
  showChromeExtensionInformation() {
    const { userInformation, supportedFeatures, chromeExtensionInstalled } = this.props;
    const { value } = userInformation;
    const chromeExtLink = 'https://chrome.google.com/webstore/detail/medsender/onpcajmldffklnfccjolcccoijaogggl';

    if (isOnChrome()) {
      return (
        <Card className="chrome-information">
          <h3 style={{ marginBottom: '0px', fontWeight: 200, lineHeight: '40px', fontSize: '18px' }}>
            {`${value} has first class support Chrome support within Medsender!`}
            <ul>
              {supportedFeatures.map(feature => (
                <li key={`${feature}`} style={{ fontWeight: 400, textAlign: 'left', fontSize: 16 }}>
                  {feature}
                </li>
              ))}
            </ul>
          </h3>
          {chromeExtensionInstalled
            ? (
              <h3 style={{ color: 'grey', fontSize: '16px', fontWeight: 200, fontStyle: 'italic' }}>
                Looks like you've already installed our Chrome Extension. You're good to go!
              </h3>
            )
            : (
              <Button
                style={{ marginTop: '5px' }}
                label="Install Chrome Extension"
                backgroundColor="rgb(163,237,255)"
                onClick={() => window.open(chromeExtLink, 'chrome link', 'width=880, height=600')}
                type="primary"
              >
                Install Chrome Extension
              </Button>
            )
          }
        </Card>
      );
    }
    return (
      <h3 style={{ paddingTop: '30px', fontWeight: 200, lineHeight: '30px' }}>
      Medsender offers a chrome extension that will allow you to upload to and from your EMR directly to Medsender.
      To use our extension,
        {<a href="https://www.google.com/chrome/"> please download and install the chrome browser. </a>}
      Then open up Medesender in a new chrome browser window and follow the prompts to download the chrome extension.
      </h3>
    );
  }

  renderTextFieldInput() {
    const { handleFieldChange, userInformation } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { label, value, name, otherFieldHintText } = userInformation;
    return (
      <div className="text-input">
        <h3 className="label">{label}</h3>
        <Input
          placeholder={otherFieldHintText}
          value={value}
          name={name}
          onChange={handleFieldChange}
        />
      </div>
    );
  }

  installWindowsClient = () => {
    const win = window.open(process.env.DOWNLOAD_APP_LINK, '_blank');
    win.opener = null;
  }

  // Handler for the Add Fax Number button. Shows the modal to upload new record.
  openAddFaxNumberModal = () => {
    const { openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'ADD_FAX_NUMBER',
    };
    return handleOpenModal(modalType);
  }

  renderDropDownSelector() {
    const {
      handleFieldChange,
      userInformation,
      handleChange,
      selectedOtherOption,
      checkbox,
      checked,
      toggleChecked,
    } = this.props;
    const {
      label,
      value,
      options,
      otherFieldHintText,
      dropDownDefault,
      otherFieldName,
      otherFieldValue,
    } = userInformation;
    return (
      <>
        <div className="dropdown-container">
          <h3 className="label">{label}</h3>
          <Select
            style={{ width: 250, marginTop: 20 }}
            onChange={handleChange}
            value={value}
            placeholder="Select your EMR"
          >
            {options.map(emr => <Option key={emr} value={emr}>{emr}</Option>)}
          </Select>
        </div>
        {selectedOtherOption
          && (
            <Input
              placeholder={otherFieldHintText}
              value={otherFieldValue}
              name={otherFieldName}
              onChange={handleFieldChange}
              style={{ marginTop: 15 }}
            />
          )}
        {checkbox
          && (
          <Checkbox
            style={{ margin: '20px 0 0px 0', fontSize: '14px' }}
            checked={checked}
            onChange={(event) => {
              toggleChecked(event.target.checked);
            }}
          >
            I have no EMR
          </Checkbox>
          )}
      </>
    );
  }


  render() {
    const {
      title,
      subtitle,
      textField,
      img,
      dropDown,
      button,
      hasSupportedChromeExtension,
      showGetFaxNumberButton,
      openAddFaxNumberModal,
    } = this.props;

    return (
      <div className="stepper-container-content">
        {img && <img src={img} alt="medsender bear" />}
        <h2>{title}</h2>
        <p>{subtitle}</p>
        <div className="user-input-container">
          {textField && this.renderTextFieldInput()}
          {dropDown && this.renderDropDownSelector() }
        </div>
        {hasSupportedChromeExtension && this.showChromeExtensionInformation()}
        {showGetFaxNumberButton
          && (
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              backgroundColor="rgb(163,237,255)"
              onClick={openAddFaxNumberModal}
            >
              Get A Fax Number
            </Button>
          )
        }
        {button
          && (
            <Button
              type="primary"
              style={{ marginTop: '10px' }}
              backgroundColor="rgb(163,237,255)"
              onClick={this.installWindowsClient}
            >
              Install Printer Driver
            </Button>
          )
        }
      </div>
    );
  }
}

WalkthroughStepTemplate.defaultProps = {
  title: null,
  subtitle: null,
  textField: false,
  dropDown: false,
  checked: false,
  button: false,
  checkbox: false,
  selectedOtherOption: false,
  hasSupportedChromeExtension: false,
  supportedFeatures: [],
  userInformation: {},
  handleFieldChange: null,
  toggleChecked: null,
  handleChange: null,
  chromeExtensionInstalled: false,
  img: null,
  showGetFaxNumberButton: false,
  openAddFaxNumberModal: () => {},
};

WalkthroughStepTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textField: PropTypes.bool,
  handleFieldChange: PropTypes.func,
  img: PropTypes.node,
  dropDown: PropTypes.bool,
  checked: PropTypes.bool,
  toggleChecked: PropTypes.func,
  button: PropTypes.bool,
  handleChange: PropTypes.func,
  checkbox: PropTypes.bool,
  selectedOtherOption: PropTypes.bool,
  hasSupportedChromeExtension: PropTypes.bool,
  showGetFaxNumberButton: PropTypes.bool,
  openAddFaxNumberModal: PropTypes.func,
  supportedFeatures: PropTypes.array,
  chromeExtensionInstalled: PropTypes.bool,
  userInformation: PropTypes.shape({
    options: PropTypes.array,
    dropDownDefault: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    otherFieldHintText: PropTypes.string,
    otherFieldName: PropTypes.string,
    otherFieldValue: PropTypes.string,
  }),
};

export default WalkthroughStepTemplate;
