import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddCategory from '../../components/modal/AddCategory';
import { createNewCategory, updateCategory, getCategoriesByPage } from '../../actions/categories';

class AddCategoryDialog extends Component {
  static propTypes = {
    createNewCategory: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    getCategoriesByPage: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddCategory
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  aiTag: state.auth.data.aiTag,
}),
{
  createNewCategory,
  updateCategory,
  getCategoriesByPage,
})(AddCategoryDialog);
