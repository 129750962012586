import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProcessPayment from './index';

class ProcessNewSubscriptionPayment extends Component {
  enrollNewSubscription = async (cbInstance, chargebeeCallbacks) => {
    const callbacks = chargebeeCallbacks();
    // Documentation: https://www.chargebee.com/checkout-portal-docs/dropIn.html#chargebee-instance-object
    cbInstance.setCheckoutCallbacks(() => {
      return callbacks;
    });

    const { getUserInfo } = this.props;
    await getUserInfo();

    const { userBillingInfo, modalPlan } = this.props;
    const { name: fullName, email, organization } = userBillingInfo;
    // We store the full name but Chargebee likes first and last name,
    // so we'll make our best attempt
    const splitName = fullName.split(' ');
    const firstName = splitName[0];
    const lastName = splitName[splitName.length - 1];

    // By setting these params, Chargebee will automatically fill these fields in the payment form
    // Full list of available params: https://www.chargebee.com/checkout-portal-docs/dropIn.html#cart-object
    const customer = {
      first_name: firstName,
      last_name: lastName,
      email,
      company: organization,
      billing_address: {
        first_name: firstName,
        last_name: lastName,
        company: organization,
        email,
      },
    };

    const cart = cbInstance.getCart();
    const product = cbInstance.initializeProduct(modalPlan);
    // TODO Change Chargebee settings for add-on plans to support fax numbers
    // The line below doesn't work. Source: https://www.chargebee.com/docs/addons.html#addon-types_recurring-addon
    // product.addAddon('new-fax-number-annual-charge-$18');
    cart.setCustomer(customer);
    cart.replaceProduct(product);
    cart.proceedToCheckout();
  }


  render() {
    return (
      <ProcessPayment
        handlePayment={this.enrollNewSubscription}
        {...this.props}
      />
    );
  }
}

ProcessNewSubscriptionPayment.propTypes = {
  userBillingInfo: PropTypes.object.isRequired,
  billingTrialDetails: PropTypes.shape({
    billing_on_file: PropTypes.bool.isRequired,
    trial_end_date: PropTypes.string.isRequired,
    trial_days_remaining: PropTypes.string.isRequired,
  }).isRequired,
  getUserInfo: PropTypes.func.isRequired,
  isUpgradeInProgress: PropTypes.bool.isRequired,
  modalPlan: PropTypes.string.isRequired,
};

export default ProcessNewSubscriptionPayment;
