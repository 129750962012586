import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Table } from 'antd';

import ErrorDisplay from '../viewer/errorDisplay';
import './replyMessageSidebar.scss';

class smsMessageSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  render() {
    const { error, data } = this.props;
    const {
      sms_messages: smsMessages,
    } = data;

    const columns = [
      {
        title: 'To Number',
        dataIndex: 'to_number',
        key: 'slug',
        width: '25%',
      },
      {
        title: 'Message',
        dataIndex: 'message',
      },
      {
        title: 'Status',
        dataIndex: 'sms_delivery_status',
      },
      {
        title: 'Transmission Date',
        dataIndex: 'sms_delivered_at',
        width: '25%',
      },
    ];

    return (
      <Drawer
        title="Sms Message(s)"
        placement="right"
        closable={true}
        onClose={this.props.toggleSmsSidebar}
        visible={this.state.visible}
        width={730}
        style={{ marginTop: 64, padding: 0 }}
      >
        <div>
          {error ? <ErrorDisplay sidebar={true} message="Error retrieving reply. Please try again" />
            : (
              <>
                <div style={{ borderTop: '1px solid lightgrey', padding: '25px' }}>
                  <Table dataSource={smsMessages} columns={columns} />
                </div>
              </>
            )
          }
        </div>
      </Drawer>
    );
  }
}

smsMessageSidebar.defaultProps = {
  data: {},
  error: false,
};

smsMessageSidebar.propTypes = {
  error: PropTypes.bool,
  data: PropTypes.shape({
    sms_messages: PropTypes.object.isRequired,
  }),
};

export default smsMessageSidebar;
