const initialState = {
  name: '',
  email: '',
  phone_number: '',
  job_title: '',
  organization: '',
  emr: '',
  department: '',
  departments: [],
  caller_id: '',
  associated_fax_numbers: [],
  isAdmin: false,
  billing: {
    plan: 'unknown',
    total_pgs: 0,
  },
  loginSuccess: false,
  loginErrorMessage: '',
  notificationErrorMessage: '',
  notification_fax_email: true,
  email_notification_emailed_records: false,
  email_notification_assigned_records: false,
  modalOpen: false,
  allowed_user_domains: [],
  allOrganizationDepartments: [],
  allDocumentCategories: {},
  allActiveFeatures: [],
  allReferredStatuses: [],
  allDocumentStatuses: {},
  allReferralStatuses: {},
  allEmaTaskTemplates: {},
  usersDepartments: {},
  allEmaTaskProviders: [],
  error: false,
  errorMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_CHANGE_EMAIL_PREFERENCE':
      return {
        ...state,
        notificationErrorMessage: '',
      };
    case 'FAILED_CHANGE_EMAIL_PREFERENCE':
      return {
        ...state,
        notificationErrorMessage: 'An error has occured, please try again later.',
      };
    case 'FINISHED_CHANGE_EMAIL_PREFERENCE':
      return {
        ...state,
        notification_fax_email: action.payload.data.notification_fax_email,
        notificationErrorMessage: '',
      };
    case 'BEGIN_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS':
      return {
        ...state,
        notificationErrorMessage: '',
      };
    case 'FAILED_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS':
      return {
        ...state,
        notificationErrorMessage: 'An error has occured, please try again later.',
      };
    case 'FINISHED_CHANGE_EMAIL_PREFERENCE_FOR_EMAILED_RECORDS':
      return {
        ...state,
        email_notification_emailed_records: action.payload.data.email_notification_emailed_records,
        notificationErrorMessage: '',
      };
    case 'BEGIN_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS':
      return {
        ...state,
        notificationErrorMessage: '',
      };
    case 'FAILED_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS':
      return {
        ...state,
        notificationErrorMessage: 'An error has occured, please try again later.',
      };
    case 'FINISHED_CHANGE_EMAIL_PREFERENCE_FOR_ASSIGNED_RECORDS':
      return {
        ...state,
        email_notification_assigned_records: action.payload.data.email_notification_assigned_records,
        notificationErrorMessage: '',
      };
    case 'BEGIN_GET_USER_INFO':
      return {
        ...state,
      };
    case 'FAILED_GET_USER_INFO':
      return {
        ...state,
      };
    case 'FINISHED_GET_USER_INFO': {
      const { data } = action.payload;
      data.emr = data.emr || null;
      data.job_title = data.job_title || '';
      return {
        ...state,
        ...data,
        isAdmin: data.is_admin,
      };
    }
    case 'BEGIN_GET_USER_DEPARTMENTS':
      return {
        ...state,
      };
    case 'FAILED_GET_USER_DEPARTMENTS':
      return {
        ...state,
      };
    case 'FINISHED_GET_USER_DEPARTMENTS': {
      return {
        ...state,
        isLoading: false,
        departmentUsers: action.payload.data.users,
        headers: action.payload.data.meta,
      };
    }
    case 'BEGIN_GET_BILLING_USAGE':
      return {
        ...state,
        loading: true,
      };
    case 'FAILED_GET_BILLING_USAGE':
      return {
        ...state,
        loading: false,
      };
    case 'FINISHED_GET_BILLING_USAGE':
      return {
        ...state,
        billing: action.payload.data,
      };
    case 'BEGIN_LOGIN_ADMIN':
      return {
        ...state,
        loginErrorMessage: '',
        loginSuccess: false,
      };
    case 'FAILED_LOGIN_ADMIN': {
      const forceRefresh = action.payload.response.data.force || false;
      let message = 'You do not have admin privileges. Please contact your admin if you would like admin privileges.';
      if (forceRefresh) {
        setTimeout(() => {
          window.location.reload();
        }, 200);
        message = 'You have logged out of the admin dashboard. Please refresh the page and sign in.';
      }

      return {
        ...state,
        loginSuccess: false,
        loginErrorMessage: message,
      };
    }
    case 'FINISHED_LOGIN_ADMIN':
      return {
        ...state,
        loginSuccess: true,
        loginErrorMessage: '',
      };
    case 'BEGIN_UPDATE_USER_DETAILS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_UPDATE_USER_DETAILS': {
      localStorage.setItem('m8_emr', action.payload.data.emr);
      localStorage.setItem('m8_job_title', action.payload.data.job_title);
      return {
        ...state,
        isLoading: false,
      };
    }
    case 'FAILED_UPDATE_USER_DETAILS': {
      localStorage.removeItem('m8_emr');
      localStorage.removeItem('m8_job_title');
      return {
        ...failedState,
        errorCode: action.payload.response.status,
        errorMessage: handlingErrors(action.payload.response.error, 'Unable to update your information. Please try again.'),
        error: true,
      };
    }
    case 'BEGIN_SEND_OTP_TO_SMS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FAILED_SEND_OTP_TO_SMS': {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: 'error',
      };
    }
    case 'FINISHED_SEND_OTP_TO_SMS': {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: false,
      };
    }
    case 'BEGIN_VERIFY_SMS_OTP': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FAILED_VERIFY_SMS_OTP': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_VERIFY_SMS_OTP': {
      return {
        ...state,
        isLoading: true,
        error: action.payload.data.message !== 'success',
        errorMessage: action.payload.data.message !== 'success' ? action.payload.data.message : '',
      };
    }
    case 'BEGIN_GET_ALL_DEPARTMENTS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_ALL_DEPARTMENTS': {
      return {
        ...state,
        isLoading: false,
        allOrganizationDepartments: action.payload.data.departments,
      };
    }
    case 'FAILED_GET_ALL_DEPARTMENTS': {
      return {
        ...failedState,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_DOCUMENT_CATEGORIES': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_DOCUMENT_CATEGORIES': {
      return {
        ...state,
        isLoading: false,
        allDocumentCategories: action.payload.data,
      };
    }
    case 'FAILED_GET_EMA_DOCUMENT_CATEGORIES': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_REFERRED_STATUSES': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_REFERRED_STATUSES': {
      return {
        ...state,
        isLoading: false,
        allReferredStatuses: action.payload.data.referral_statuses,
      };
    }
    case 'FAILED_GET_REFERRED_STATUSES': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_DOCUMENT_STATUSES': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_DOCUMENT_STATUSES': {
      return {
        ...state,
        isLoading: false,
        allDocumentStatuses: action.payload.data,
        headers: action.payload.data.meta,
      };
    }
    case 'FAILED_GET_DOCUMENT_STATUSES': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_SUBSCRIPTIONS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_SUBSCRIPTIONS': {
      return {
        ...state,
        isLoading: false,
        allActiveFeatures: action.payload.data.features,
        headers: action.payload.data.meta,
      };
    }
    case 'FAILED_GET_SUBSCRIPTIONS': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_EMA_TASK_TEMPLATES': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_EMA_TASK_TEMPLATES': {
      return {
        ...state,
        isLoading: false,
        allEmaTaskTemplates: action.payload.data,
        headers: action.payload.data.meta,
      };
    }
    case 'FAILED_GET_EMA_TASK_TEMPLATES': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_EMA_TASK_PROVIDERS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_EMA_TASK_PROVIDERS': {
      return {
        ...state,
        isLoading: false,
        allEmaTaskProviders: action.payload.data,
        headers: action.payload.data.meta,
      };
    }
    case 'FAILED_GET_EMA_TASK_PROVIDERS': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'BEGIN_GET_TASK_PROVIDERS': {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: false,
      };
    }
    case 'FINISHED_GET_TASK_PROVIDERS': {
      return {
        ...state,
        isLoading: false,
        allEmaTaskProviders: action.payload.data,
        headers: action.payload.data.meta,
      };
    }
    case 'FAILED_UPDATE_EMA_TASK_PROVIDER': {
      return {
        ...state,
        error: true,
        errorCode: action.payload.response.status,
      };
    }
    case 'FAILED_DELETE_EMA_TASK_PROVIDER': {
      return {
        ...state,
        error: true,
        errorCode: action.payload.response.status,
      };
    }
    case 'FAILED_CREATE_EMA_TASK_PROVIDER': {
      return {
        ...state,
        error: true,
        errorCode: action.payload.response.status,
      };
    }
    case 'FAILED_GET_TASK_PROVIDERS': {
      return {
        ...state,
        error: true,
        errorMessage: action.payload.response,
      };
    }
    case 'CHANGE_BILLING_MODAL_STATE':
      return {
        ...state,
        modalState: action.state,
        modalPlan: action.plan,
        modalOpen: action.open,
      };
    default:
      return state;
  }
};
