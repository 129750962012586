import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BILLING_ENUMS } from '../../constants';

class ProcessPayment extends Component {
  componentDidMount() {
    const cb = document.createElement('script');
    cb.innerHTML = `Chargebee.init({
          site: "${process.env.CB_SITE}",
          publishableKey: "${process.env.CB_KEY}"
        })`;
    document.body.appendChild(cb);
    return this.beginPayment();
  }

  beginPayment = () => {
    /* global Chargebee */
    const cbInstance = Chargebee.getInstance();
    let _this = this;
    const callbacks = () => this.chargebeeCallbacks(_this);
    const { handlePayment } = this.props;
    return handlePayment(cbInstance, callbacks);
  }

  handleOpenFaxNumberInstructionsDialog = async () => {
    const { openModal } = this.props;
    const modalType = {
      type: 'FAX_NUMBER_INSTRUCTIONS',
    };
    return openModal(modalType);
  }

  chargebeeCallbacks(_this) {
    return {
      loaded: () => {
        _this.setState({ loading: false, open: false });
      },
      close: () => {
        _this.props.changeModal(BILLING_ENUMS.MODAL_CLOSE, null, false);
        const { billingTrialDetails: { billing_on_file: billingOnFile } } = this.props;
        if (billingOnFile) return _this.handleOpenFaxNumberInstructionsDialog();
      },
      success: (hostedPageId) => {
        const data = {
          page_id: hostedPageId,
        };
        if (_this.props.isUpgradeInProgress) _this.props.closeModal();
        return _this.props.addSubscription(data);
      },
      error: () => {
        _this.setState({ loading: false, open: true });
      },
    };
  }

  render() {
    return null;
  }
}

ProcessPayment.propTypes = {
  billingTrialDetails: PropTypes.shape({
    billing_on_file: PropTypes.bool.isRequired,
    trial_end_date: PropTypes.string.isRequired,
    trial_days_remaining: PropTypes.string.isRequired,
  }).isRequired,
  handlePayment: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default ProcessPayment;
