import React, {
  Component,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseFullName } from 'parse-full-name';
import {
  message,
  Form,
  Input,
  Tooltip,
  Alert,
  AutoComplete,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { validPhoneNumber } from '../../global/validation';
import { triggerSmsMessage } from '../../actions/records';
import { loginAdmin } from '../../actions/userProfile';
import ModalWrapper from './ModalWrapper';

const { TextArea } = Input;

class SmsMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiverType: this.props.modalType.data.receiverType,
      patientName: this.props.modalType.data.patientName,
      messageText: this.props.modalType.data.messageText,
      patientPhoneNumber: this.props.modalType.data.patientPhoneNumber,
      url: this.props.modalType.data.url,
      originalUrl: this.props.modalType.data.originalUrl,
      sendSmsFeatureEnabled: this.props.modalType.data.smsMessageFeature,
      messageTemplates: this.props.modalType.data.messageTemplates,
      searchedSMSTemplates: this.props.modalType.data.messageTemplates,
    };
  }

  send = async () => {
    const {
      hideModal, modalType: { data: { sendToken } },
    } = this.props;
    const {
      receiverType,
      patientName,
      patientPhoneNumber,
      url,
    } = this.state;

    let { messageText } = this.state;
    
    if (url) {
      messageText = `${messageText} \n\n${url} \n\nReply STOP To Opt Out`;
    } else {
      messageText = `${messageText} \n\nReply STOP To Opt Out`;
    }

    await this.props.triggerSmsMessage({
      sendToken,
      receiverType,
      patientName,
      messageText,
      patientPhoneNumber,
    });
    hideModal();
    message.success({
      content: 'SMS Referral Message will be sent to Patient.',
      style: { marginTop: '70px' },
    });
  }

  handleMessageChange = (e) => {
    this.setState({ messageText: e.target.value });
  }

  handleNumberChange = (e) => {
    this.setState({ patientPhoneNumber: e.target.value });
  }

  loginAsAdmin = async () => {
    await this.props.loginAdmin();
    const { adminLoginSuccess, loginErrorMessage } = this.props;
    if (adminLoginSuccess) {
      const win = window.open(`${window.location.origin}/admin/sms_templates`, '_blank');
      win.opener = null;
    } else {
      /* global toastr */
      toastr.error(loginErrorMessage);
    }
  }


  render() {
    const { isLoading, hideModal } = this.props;
    const {
      patientPhoneNumber,
      originalUrl,
      sendSmsFeatureEnabled,
      messageTemplates,
      searchedSMSTemplates,
      patientName,
    } = this.state;

    const parsedName = parseFullName(patientName)?.first;
    const firstName = parsedName ? parsedName : patientName;

    let { messageText } = this.state
    const { isAdmin } = this.props;
    const smsTemplateMessage = isAdmin ? 'Click here to modify SMS template' : 'Contact your admin to edit SMS template';

    const validatePhoneNumber = (rule, value) => {
      if (!value) {
        return Promise.reject('Please input your phone number!');
      } if (!validPhoneNumber(value)) {
        return Promise.reject('Please enter a valid phone number!');
      }
      return Promise.resolve();
    };

    const disableSubmitButton = () => {
      return (
        messageText === null
        || !sendSmsFeatureEnabled
        || patientPhoneNumber === null
        || !validPhoneNumber(patientPhoneNumber)
      );
    };

    const handleSearchTemplates = (e) => {
      this.setState({ messageText: e });
      const filteredOptions = this.state.messageTemplates.filter(option => (
        e.toLowerCase().includes(option.message.toLowerCase())));
      
      this.setState({ searchedSMSTemplates: filteredOptions });
    };

    const handleTemplateSelection = (e) => {
      const filteredOptions = this.state.messageTemplates.filter(option => (
        e.toLowerCase().includes(option.message.toLowerCase())));
      let url = filteredOptions[0].url;
      this.setState({ messageText: e,  originalUrl: url, url: filteredOptions[0].short_url });
    }

    const customContentStyle = {
      width: '270px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.send}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableSubmitButton()}
        modalTitle="Send Referral SMS"
        form="smsTemplate"
        isDraggable={true}
      >
        <Form
          layout="vertical"
          onFinish={this.send}
          id="messageText"
          initialValues={{
            messageText,
          }}
          fields={[
            {
              name: 'messageLink',
              value: originalUrl,
            }
          ]}
        >

          {!sendSmsFeatureEnabled && (
            <div style={{ marginBottom: '25px' }}>
              <Alert
                message="Your plan doesn't include access to send sms messages. Please upgrade your plan to make any changes."
                type="info"
                showIcon
              />
            </div>
          )}

          <div>
            <div>
              <p><i>Please confirm the information below is correct</i></p>
            </div>
            <div style={{ width: '200px', gap: '10px' }}>
              <Form.Item
                label="Patient Phone Number"
                rules={[
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
                onChange={this.handleNumberChange}
                style={{ flex: 1 }}
              >
                <Input
                  placeholder="Enter phone number"
                  name="patientPhoneNumber"
                  defaultValue={patientPhoneNumber}
                  style={{ flex: 1 }}
                />
              </Form.Item>
            </div>
            <div>
            <Form.Item
              label={isAdmin
                ? (
                  <div>
                    Message
                    <Tooltip title={smsTemplateMessage}>
                      <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} onClick={this.loginAsAdmin} />
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    Message
                    <Tooltip title={smsTemplateMessage}>
                      <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                    </Tooltip>
                  </div>
                )}
              name="messageText"
            >
              <AutoComplete
                placeholder="SMS Templates"
                options={searchedSMSTemplates.map(template => ({ value: `Hello ${firstName}, ${template.message}`, label: template.message }))}
                onSearch={handleSearchTemplates}
                onSelect={handleTemplateSelection}
                value={this.state.messageText}
              >
                <TextArea
                  style={{ height: 100 }}
                  value={this.state.messageText}
                  onChange={this.handleMessageChange}
                />
              </AutoComplete>
            </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Message Link"
                name="messageLink"
              >
                <Input.TextArea
                  onChange={(e) => this.setState({ url: e.target.value, originalUrl:  e.target.value })}
                  rows={1}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </ModalWrapper>
    );
  }
}

SmsMessage.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  triggerSmsMessage: PropTypes.func.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  adminLoginSuccess: PropTypes.bool.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  isAdmin: state.auth.isAdmin,
  loginErrorMessage: state.userProfile.loginErrorMessage,
  adminLoginSuccess: state.userProfile.loginSuccess,
}), {
  triggerSmsMessage,
  loginAdmin,
})(SmsMessage);
