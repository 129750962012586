export const MODAL_ENUMS = {
  LOADING: 0,
  ENTER_DIRECT_ADDRESS: 1,
  VERIFY_DIRECT_ADDRESS: 2,
  CONFIRM_FORWARDING: 3,
  RECORD_FORWARDED: 4,
};

const initialState = {
  inputDirectAddress: '',
  directAddressVerified: false,
  verificationCodeTimeout: false,
  documentSent: false,
  isLoading: false,
  error: false,
  errorMessage: null,
  addresses: [],
  hasVerifiedAddress: false,
  modalState: MODAL_ENUMS.LOADING,
  indexSelectedDirectAddress: 0,
};

const failedState = {
  isLoading: false,
  error: true,
};

function directMessaging(state = initialState, action) {
  switch (action.type) {
    case 'BEGIN_CHECK_FOR_DIRECT':
      return {
        ...state,
        isLoading: true,
        documentSent: false,
        errorCode: null,
      };
    case 'FINISH_CHECK_FOR_DIRECT': {
      const { addresses } = action.payload.data;
      const hasVerifiedAddress = addresses.filter(address => address.verified).length;
      const prevState = state.modalState;
      let modalState = MODAL_ENUMS.INITIAL_DIRECT_ADDRESS;
      if (hasVerifiedAddress && prevState !== MODAL_ENUMS.VERIFY_DIRECT_ADDRESS) {
        modalState = MODAL_ENUMS.CONFIRM_FORWARDING;
      } else if (addresses.length) {
        modalState = MODAL_ENUMS.VERIFY_DIRECT_ADDRESS;
      } else {
        modalState = MODAL_ENUMS.ENTER_DIRECT_ADDRESS;
      }
      return {
        ...state,
        isLoading: false,
        addresses: action.payload.data.addresses,
        modalState,
      };
    }
    case 'FAILED_CHECK_FOR_DIRECT':
      return {
        ...failedState,
        errorMessage: action.payload.message,
        errorCode: action.payload.response.status,
        modalState: MODAL_ENUMS.ENTER_DIRECT_ADDRESS,
      };
    case 'BEGIN_CONFIRM_FORWARDING':
      return {
        ...state,
        isLoading: true,
      };
    case 'FINISH_CONFIRM_FORWARDING':
      return {
        ...state,
        isLoading: false,
        documentSent: true,
        directAddressVerified: true,
        modalState: MODAL_ENUMS.RECORD_FORWARDED,
      };
    case 'FAILED_CONFIRM_FORWARDING':
      return {
        ...failedState,
        directAddressVerified: true,
        errorMessage: action.payload.message,
        errorCode: action.payload.response.status,
        modalState: state.modalState,
      };
    case 'BEGIN_VERIFY_ADDRESS':
      return {
        ...state,
        isLoading: true,
      };
    case 'FINISH_VERIFY_ADDRESS':
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.error,
        addresses: state.addresses,
        indexSelectedDirectAddress: state.addresses.length,
        modalState: MODAL_ENUMS.VERIFY_DIRECT_ADDRESS,
      };
    case 'FAILED_VERIFY_ADDRESS':
      return {
        ...failedState,
        addresses: state.addresses,
        errorMessage: action.payload.error,
        errorCode: action.payload.response.status,
        modalState: state.modalState,
      };
    case 'BEGIN_RESEND_VERIFICATION_CODE':
      return {
        ...state,
        isLoading: true,
      };
    case 'FINISH_RESEND_VERIFICATION_CODE':
      return {
        ...state,
        isLoading: false,
        modalState: MODAL_ENUMS.VERIFY_DIRECT_ADDRESS,
      };
    case 'FAILED_RESEND_VERIFICATION_CODE':
      return {
        ...failedState,
        errorCode: action.payload.response.status,
        addresses: state.addresses,
        errorMessage: action.payload.error,

        modalState: state.modalState,
      };
    case 'BEGIN_CONFIRM_VERIFICATION_CODE':
      return {
        ...state,
        directAddressVerified: false,
        isLoading: true,
      };
    case 'FINISH_CONFIRM_VERIFICATION_CODE': {
      const allAddresses = state.addresses.map(address => address.address);
      const index = allAddresses.indexOf(action.payload.data.direct_address);
      const newUpdatedAddresses = state.addresses;
      newUpdatedAddresses[index].verified = true;
      return {
        ...state,
        errorCode: null,
        errorMessage: '',
        isLoading: false,
        addresses: newUpdatedAddresses,
        directAddressVerified: action.payload.data.verified,
        inputDirectAddress: action.payload.data.direct_address,
        modalState: MODAL_ENUMS.CONFIRM_FORWARDING,
      };
    }
    case 'FAILED_CONFIRM_VERIFICATION_CODE': {
      return {
        ...failedState,
        addresses: state.addresses,
        directAddressVerified: false,
        errorMessage: action.payload.response.data.error,
        verificationCodeTimeout: action.payload.response.data.verification_expired,
        modalState: state.modalState,
        indexSelectedDirectAddress: state.indexSelectedDirectAddress,
      };
    }
    case 'CHANGE_MODAL_STATE': {
      return {
        ...state,
        modalState: action.value,
      };
    }

    case 'CHANGE_SELECTED_DIRECT_ADDRESS': {
      return {
        ...state,
        indexSelectedDirectAddress: action.value,
        modalState: state.modalState,
      };
    }
    default:
      return state;
  }
}

export default directMessaging;
