const initialState = {
  CHECK_STATUS: false,
  DATE_STATUS: null,
  MENU_STATUS: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SIDEBAR_CHECKBOX_TRIGGER': {
      return {
        ...state,
        CHECK_STATUS: !state.CHECK_STATUS,
      };
    }
    case 'SIDEBAR_DATE_CHANGE': {
      return {
        ...state,
        DATE_STATUS: action.newDate,
      };
    }
    case 'SIDEBAR_SORT_MENU_CHANGE': {
      return {
        ...state,
        MENU_STATUS: action.newMenuItem,
      };
    }
  }
  return state;
};
