import axios from 'axios';

const downloadToBrowser = (response, customFileName, fileExt = 'pdf') => {
  try {
    // Explicitly set mime-type so all browsers can download properly
    const downloadBlob = new Blob([response.data], { type: `application/${fileExt}` });

    // For IE, call native method to download file
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(downloadBlob, `${customFileName}.${fileExt}`);
      return
    }
    // For other browsers, we'll create an invisible link pointing to
    // the URL of the blob, using the download property for <a> tags
    // supported natively in HTML5
    // More here: https://caniuse.com/#feat=download
    const url = window.URL.createObjectURL(downloadBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${customFileName}.${fileExt}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    window.raven.captureException(e);
  }
}

/**
 * Given a signedUrl, download the PDF from the URL to the user's computer
 * @param  {String} signedUrl
 * @return {Void}
 */
const download = (signedUrl, customFileName = 'file') => {
  axios.get(signedUrl, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    }
  }).then((response) => {
    downloadToBrowser(response, customFileName);
  }).catch((error) => window.raven.captureException(error));
};

export default download;
export { downloadToBrowser };
