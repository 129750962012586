const initialState = {
  error: false,
  isLoading: false,
  notifications: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_GET_NOTIFICATIONS':
      return {
        ...state,
        error: false,
        isLoading: true,
      };
    case 'FAILED_GET_NOTIFICATIONS':
      return {
        ...initialState,
        error: true,
        isLoading: false,
      };
    case 'FINISHED_GET_NOTIFICATIONS':
      return {
        ...state,
        isLoading: false,
        notifications: action.payload.data,
      };
    default:
      return state;
  }
};
