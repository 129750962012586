import crud from './helpers/crud';

const getCategoriesByPage = (page = 1, pageSize = 10) => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_CATEGORIES',
      fail: 'FAILED_GET_CATEGORIES',
      end: 'FINISH_GET_CATEGORIES',
    },
    method: 'GET',
    url: `/api/v1/document_categories?page=${page}&page_size=${pageSize}`,
  })
);

const getCategoriesByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_CATEGORIES',
      fail: 'FAILED_GET_CATEGORIES',
      end: 'FINISH_GET_CATEGORIES',
    },
    method: 'GET',
    url: `/api/v1/document_categories${query}`,
  })
);

const createNewCategory = categoryObject => (
  crud({
    dispatch: {
      begin: 'BEGIN_CREATE_CATEGORY',
      fail: 'FAILED_CREATE_CATEGORY',
      end: 'FINISH_CREATE_CATEGORY',
    },
    method: 'POST',
    data: { category: categoryObject },
    url: '/api/v1/document_categories',
  })
);

const deleteCategory = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_CATEGORY',
      fail: 'FAILED_DELETE_CATEGORY',
      end: 'FINISH_DELETE_CATEGORY',
    },
    method: 'DELETE',
    url: `/api/v1/document_categories/${id}`,
  })
);

const updateCategory = (id, categoryObject) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_CATEGORY',
      fail: 'FAILED_UPDATE_CATEGORY',
      end: 'FINISH_UPDATE_CATEGORY',
    },
    method: 'PUT',
    url: `/api/v1/document_categories/${id}`,
    data: { category: categoryObject },
  })
);

const getUsedOrganizationCategories = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_USED_ORGANIZATION_CATEGORIES',
      fail: 'FAILED_GET_USED_ORGANIZATION_CATEGORIES',
      end: 'FINISH_GET_USED_ORGANIZATION_CATEGORIES',
    },
    method: 'GET',
    url: '/api/v1/document_categories/used_organization_category_ids',
  })
);

const getUsedDepartmentCategories = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_USED_DEPARTMENT_CATEGORIES',
      fail: 'FAILED_GET_USED_DEPARTMENT_CATEGORIES',
      end: 'FINISH_GET_USED_DEPARTMENT_CATEGORIES',
    },
    method: 'GET',
    url: '/api/v1/document_categories/used_department_category_ids',
  })
);

export {
  getCategoriesByPage,
  createNewCategory,
  deleteCategory,
  updateCategory,
  getCategoriesByQuery,
  getUsedOrganizationCategories,
  getUsedDepartmentCategories,
};
