import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Alert,
} from 'antd';

import {
  validPassword,
  InvalidPasswordMessage,
} from '../../../global/validation';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 36 },
};

const tailLayout = {
  wrapperCol: { offset: 10, span: 14 },
};

class ChangePassword extends Component {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    passwordChangeMessage: PropTypes.string,
    changeStatus: PropTypes.bool,
    resetPasswordToken: PropTypes.string,
    resetPasswordEmail: PropTypes.string,
  };

  static defaultProps = {
    resetPasswordToken: null,
    resetPasswordEmail: null,
    changeStatus: false,
    passwordChangeMessage: '',
  };

  state = {
    sending: false,
  };

  handleSubmit = async (values) => {
    const { resetPasswordToken } = this.props;
    const { submit } = this.props;
    this.setState({ sending: true });

    if (resetPasswordToken) {
      values.reset_password_token = resetPasswordToken;
    }

    await submit(values);
    this.setState({ sending: false });
  }

  render() {
    const { sending } = this.state;
    const {
      resetPasswordToken,
      resetPasswordEmail,
      passwordChangeMessage,
      changeStatus,
    } = this.props;
    return (
      <div className="reset-password-container">
        <h3 className="header" style={{ margin: '50px 0 60px' }}>
          Create a new password for account: &nbsp;
          <i style={{ fontWeight: 100 }}>{resetPasswordEmail}</i>
        </h3>
        <Form {...layout} size="large" id="passwordForm" onFinish={this.handleSubmit}>
          {
            resetPasswordToken === null
            && (
              <Form.Item
                hasFeedback
                name="current_password"
                label="Current Password"
                rules={[
                  {
                    required: true,
                    message: 'Current password is required',
                  },
                  {
                    min: 8,
                    message: 'must be at least 8 characters',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            )
          }
          <Form.Item
            hasFeedback
            name="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'A new password is required',
              },
              {
                min: 8,
                message: 'must be at least 8 characters',
              },
              {
                pattern: validPassword,
                message: InvalidPasswordMessage,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="password_confirmation"
            label="Confirm New Password"
            rules={[
              {
                required: true,
                message: 'Password confirmation is required',
              },
              {
                min: 8,
                message: 'must be at least 8 characters',
              },
              {
                pattern: validPassword,
                message: InvalidPasswordMessage,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) return Promise.resolve();
                  return Promise.reject('The two passwords that you entered do not match');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ marginTop: '15px' }}>
            <Button
              form="passwordForm"
              key="submit"
              htmlType="submit"
              size="default"
              type="primary"
              loading={sending}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        { passwordChangeMessage
          && (
            <Alert
              style={{ marginTop: '20px', maxWidth: '400px' }}
              message={passwordChangeMessage}
              type={changeStatus === true ? 'success' : 'error'}
              showIcon
            />
          )
        }
      </div>
    );
  }
}

export default ChangePassword;
