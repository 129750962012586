import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import {
  Select,
} from 'antd';
import { getReferredStatuses } from '../../../actions/userProfile';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ReferredSearchSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: props.paramTools.params.referred,
      referredStatuses: [],
    };
  }

  componentDidMount = async () => {
    await this.props.getReferredStatuses();
    this.setState({ referredStatuses: this.props.allReferredStatuses });
  }

  updateQuery = async (referred) => {
    this.setState({ selectedStatus: referred });
    const { paramTools, inboxActions, queryType } = this.props;
    const { params } = paramTools;
    const { getRecords } = inboxActions;
    const emptySearch = (!referred || referred.trim() === '');
    if (params[queryType] === '' || emptySearch) {
      delete params[queryType];
      params.page = 1;
    } else {
      params[queryType] = referred;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getRecords(query);
  }

  render() {
    const { referredStatuses, selectedStatus } = this.state;
    return (
      <div>
        <Select
          showSearch
          style={{ width: '220px', marginTop: 20 }}
          label="Referred Status"
          placeholder="Search by Status"
          allowClear
          value={selectedStatus}
          onChange={option => this.updateQuery(option)}
        >
          {referredStatuses.map(referredStatus => <Select.Option key={referredStatus.id} value={referredStatus.name}>{referredStatus.name}</Select.Option>)}
        </Select>
      </div>
    );
  }
}

ReferredSearchSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  queryType: PropTypes.string,
  allReferredStatuses: PropTypes.object.isRequired,
  getReferredStatuses: PropTypes.func.isRequired,
};

ReferredSearchSettings.defaultProps = {
  queryType: 'referred',
};

export default connect(state => ({
  //referredStatuses: state.auth,
  allReferredStatuses: state.userProfile.allReferredStatuses,
}), {
  getReferredStatuses,
})(ReferredSearchSettings);
