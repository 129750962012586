// Shows modal for asking the user to logout
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

class LogoutUser extends Component {

  render() {
    const customContentStyle = {
      width: '37%',
    };

    return (
      <ModalWrapper
        hideModal={this.props.hide}
        primary={true}
        customContentStyle={customContentStyle}
        dismiss={'No'}
        action={() => {
          this.props.redirect();
        }}
        actionName={'Yes'}
        modalTitle="Free Medsender Account"
      >
        <p><b>Would you like to create a free Medsender account before signing out?</b></p>
        <p>Creating an account makes things even easier next time you receive medical records</p>
      </ModalWrapper>
    );
  }
}

LogoutUser.propTypes = {
  hide: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default LogoutUser;
