import {
  AddingStamp,
  DocEditor,
  DrawAnnotation,
  SignDocument,
  TextAnnotation,
  DeleteAnnotation,
  RecordViewerToolbar,
} from '../../../assets/img/guide';

const signingAndAnnotating = {
  pageTitle: 'Signing and Annotating Records',
  pageSubtitle: 'Medsender includes a powerful document editor and suite of annotation tools.',
  sections: [
    {
      title: 'Types of annotations',
      id: 'typesOfAnnotations',
      subtitle: `Within the record viewer, the toolbar provides several options for editing and 
      annotating a record.`,
      img: RecordViewerToolbar,
      divider: true,
    },
    {
      title: '1. Adding text',
      id: 'addingText',
      subtitle: `To type on the document, select the text box “T” and click anywhere on the page. 
      You can change the font and text color with the toolbar on the left.`,
      img: TextAnnotation,
      divider: true,
    },
    {
      title: '2. Signing a Document',
      id: 'signingDoc',
      subtitle: `To sign a document, click on the “Sign Document” button. Sign with your mouse 
      (or on a touchscreen). You can store your signature on your account by leaving the “Store 
      Signature” box checked in the lower left corner. When you click done, the signature will be 
      added to the page, and you can drag and resize as needed.`,
      img: SignDocument,
      divider: true,
    },
    {
      title: '3. Stamp/Image',
      id: 'stamp',
      subtitle: `To add a stamp or attach an image from your computer, click the dropdown arrow next 
      to the image icon:`,
      img: AddingStamp,
      divider: true,
    },
    {
      title: '4. Draw/Highlight',
      id: 'drawHighlight',
      subtitle: `To draw, freeform highlight, highlight text, or erase an annotation, 
      click the dropdown arrow next to the pen icon:`,
      img: DrawAnnotation,
      divider: true,
    },
    {
      title: '5. Document Editor (Delete/Rotate/Add Pages)',
      id: 'docEditor',
      subtitle: `
        To delete, duplicate, rotate, or add pages, select the document editor icon in the toolbar.
        This will open the editor popup. Here, you can select a page or multiple pages and then delete, 
        duplicate, rotate, re-order them, or even import another document from your computer to append to that record.
      `,
      img: DocEditor,
      divider: true,
    },
    {
      title: '6. Deleting an Annotation',
      id: 'deleteAnnotation',
      subtitle: `
        To delete an annotation, select the annotation and then either click the trash can icon in the 
        top right or press delete on your keyboard. The following confirmation will ask you to confirm 
        to delete the annotation.
      `,
      img: DeleteAnnotation,
    },
    {
      subtitle: `
        To save any changes, click “Save Changes” in the top right corner.
      `,
      divider: true,
    },
  ],
};

export default signingAndAnnotating;
