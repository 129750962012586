import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalWrapper from './ModalWrapper';
import LinearProgress from 'material-ui/LinearProgress';

class DownloadZip extends Component {
    downloadErrorMessage = () => (
        <React.Fragment>
            <p style={{ fontWeight: '700', textAlign: 'center', color: 'red' }}>
                Download failed
            </p>
            <span>{this.props.downloadError}</span>
        </React.Fragment>
    )

    downloadStartMessage = () => (
        <React.Fragment>
            <p style={{ fontWeight: '700', textAlign: 'center' }}>
                Starting download...
            </p>
            <span>Your download will begin momentarily.</span>
        </React.Fragment>
    )

    modalMessage = () => {
        if (this.props.downloadError) return this.downloadErrorMessage();      
        if (!this.props.total || !this.props.progress) return this.downloadStartMessage();
        
        const percentDownloaded = Math.round((this.props.progress / this.props.total) * 100);
        const downloadComplete = percentDownloaded == 100;

        // Automatically close download window after 4 seconds
        if (downloadComplete) setTimeout(() => this.props.hideModal(), 4000);
        return (
            <div>
                <span>
                    { downloadComplete
                        ? 'Your download is complete. This window will close automatically'
                        : `Downloading, ${percentDownloaded}% complete`
                    }
                </span>
                <LinearProgress
                    mode={'determinate'}
                    value={percentDownloaded}
                    style={{ marginTop: '30px' }}
                /> <span style={{ float: 'right' }}>{percentDownloaded}%</span>
            </div>
        )
    }
    render() {
        return (
            <ModalWrapper
                hideModal={this.props.hideModal}
                action={this.props.hideModal}
                customContentStyle={{ width: '30%' }}
                actionName={'Close'}
                dismiss={''}
                modalTitle="Download Records"
            >
                {this.modalMessage()}
            </ModalWrapper>
        );
    }
}

DownloadZip.contextTypes = {
    router: PropTypes.object.isRequired,
};

DownloadZip.propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.object.isRequired,
    error: PropTypes.bool.isRequired,
};


export default connect(state => ({
    error: state.records.error,
    total: state.records.total,
    progress: state.records.progress,
    downloadError: state.records.downloadError,
}))(DownloadZip);
