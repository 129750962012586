// Connects the Archive  record modal action

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ArchiveRecord from '../../components/modal/ArchiveRecord';
import { archiveRecords, archiveReceivedRecords } from '../../actions/records';

class ArchiveRecordDialog extends Component {
  render() {
    return (
      <div>
        <ArchiveRecord
          {...this.props}
        />
      </div>
    );
  }
}

ArchiveRecordDialog.defaultProps = {
  archiveErrorMessage: '',
};

ArchiveRecordDialog.propTypes = {
  archiveRecords: PropTypes.func.isRequired,
  archiveReceivedRecords: PropTypes.func.isRequired,
  archiveErrorMessage: PropTypes.string,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  archiveErrorMessage: state.records.archiveErrorMessage,
}), { archiveRecords, archiveReceivedRecords })(ArchiveRecordDialog);
