/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Alert,
  Input,
  Form,
  Tag,
  Tooltip,
  Checkbox,
  message,
  Radio,
  Space,
} from 'antd';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import ModalWrapper from './ModalWrapper';
import { verifyFeatureAccess } from '../../global/featureFlags';

const labelColors = [
  { color: '#B01E8A', darkText: false },
  { color: '#C3F6E9', darkText: true },
  { color: '#2EC0FC', darkText: false },
  { color: '#CCB2FD', darkText: true },
  { color: '#D2F7C6', darkText: true },
  { color: '#FDD576', darkText: true },
  { color: '#9DC9FF', darkText: true },
  { color: '#D44D2D', darkText: false },
  { color: '#FEDCE5', darkText: true },
  { color: '#F69FE0', darkText: true },
  { color: '#3282F5', darkText: false },
  { color: '#FDE9B8', darkText: true },
  { color: '#000000', darkText: false },
  { color: '#666666', darkText: false },
  { color: '#31D8D1', darkText: false },
  { color: '#CCCCCC', darkText: true },
  { color: '#8A4FFB', darkText: false },
  { color: '#388822', darkText: false },
];

class AddLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positiveKeywords: [],
      negativeKeywords: [],
      newPositiveKeywordValue: '',
      newNegativeKeywordValue: '',
      positiveInputVisible: false,
      negativeInputVisible: false,
      labelName: props.modalType.data.label.name,
      selectedColorIndex: 0,
      autoLabel: false,
      labelsCanBeAssignedDepartment: props.modalType.data.label.department_id,
      assignedDepartment: props.modalType.data.label.department_id || null,
      editingLabelID: '',
      showEditConfirmation: false,
      canManageLabel: true,
    };
  }

  componentDidMount = async () => {
    const { modalType } = this.props;
    const { label } = modalType.data;
    const canManageLabel = await verifyFeatureAccess('manage_label');
    if (label.name) {
      const {
        name,
        color,
        positive_keywords: positiveKeywords,
        negative_keywords: negativeKeywords,
        id,
        auto_label: autoLabel,
      } = label;
      this.setState({
        editingLabelID: id,
        labelName: name,
        positiveKeywords,
        negativeKeywords,
        autoLabel,
        selectedColorIndex: labelColors.findIndex(colorType => colorType.color === color) === -1 ? 0 : labelColors.findIndex(colorType => colorType.color === color),
        canManageLabel,
      });
    } else {
      this.setState({ canManageLabel });
    }
  }

  removeKeyword = (removedKeyword, type) => {
    // eslint-disable-next-line react/destructuring-assignment
    const editedArray = this.state[type];
    // eslint-disable-next-line react/destructuring-assignment
    const index = this.state[type].indexOf(removedKeyword);
    editedArray.splice(index, 1);
    this.setState({
      [type]: [...editedArray],
    });
  }

  addNewKeyword = (type) => {
    const {
      positiveKeywords,
      negativeKeywords,
      newNegativeKeywordValue,
      newPositiveKeywordValue,
    } = this.state;
    const isPositiveKeyword = type === 'positiveKeywords';
    const inputToClear = isPositiveKeyword ? 'newPositiveKeywordValue' : 'newNegativeKeywordValue';
    const inputFieldToHide = isPositiveKeyword ? 'positiveInputVisible' : 'negativeInputVisible';
    const newKeywordArray = isPositiveKeyword ? [...positiveKeywords, newPositiveKeywordValue] : [...negativeKeywords, newNegativeKeywordValue];
    this.setState({
      [type]: newKeywordArray,
      [inputToClear]: '',
      [inputFieldToHide]: false,
    });
  }

  addLabel = async () => {
    const { createNewLabel, getLabelsByPage } = this.props;
    const {
      labelName,
      selectedColorIndex,
      autoLabel,
      positiveKeywords,
      negativeKeywords,
      newPositiveKeywordValue,
      newNegativeKeywordValue,
      assignedDepartment,
    } = this.state;
    const selectedColor = labelColors[selectedColorIndex];
    if (!labelName || labelName.trim() === '') return;
    // allowing keywords to be submitted even if the user hasn't pressed enter to add them to the keyword array yet
    let allPositiveKeywords = [...positiveKeywords];
    let allNegativeKeywords = [...negativeKeywords];
    if (newPositiveKeywordValue) allPositiveKeywords = [...positiveKeywords, newPositiveKeywordValue];
    if (newNegativeKeywordValue) allNegativeKeywords = [...negativeKeywords, newNegativeKeywordValue];
    const newLabel = {
      name: labelName.trim(),
      color: selectedColor.color,
      is_dark_text: selectedColor.darkText,
      auto_label: autoLabel,
      positive_keywords: allPositiveKeywords,
      negative_keywords: allNegativeKeywords,
      department_id: assignedDepartment,
    };
    await createNewLabel(newLabel);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error adding label. Duplicate label name already exists.' : 'Error adding label. Please try again.';
    if (!error) {
      hideModal();
      message.success({
        content: 'Label successfully added',
        style: { marginTop: '70px' },
      });
      getLabelsByPage();
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
      getLabelsByPage();
    }
  }

  editLabel = async () => {
    const { updateLabel, getLabelsByPage } = this.props;
    const {
      labelName,
      selectedColorIndex,
      autoLabel,
      positiveKeywords,
      negativeKeywords,
      editingLabelID,
      newPositiveKeywordValue,
      newNegativeKeywordValue,
      assignedDepartment,
    } = this.state;
    const selectedColor = labelColors[selectedColorIndex];
    // allowing keywords to be submitted even if the user hasn't pressed enter to add them to the keyword array yet
    let allPositiveKeywords = [...positiveKeywords];
    let allNegativeKeywords = [...negativeKeywords];
    if (newPositiveKeywordValue) allPositiveKeywords = [...positiveKeywords, newPositiveKeywordValue];
    if (newNegativeKeywordValue) allNegativeKeywords = [...negativeKeywords, newNegativeKeywordValue];
    if (!labelName) return;
    const newLabel = {
      name: labelName,
      color: selectedColor.color,
      is_dark_text: selectedColor.darkText,
      auto_label: autoLabel,
      positive_keywords: allPositiveKeywords,
      negative_keywords: allNegativeKeywords,
      department_id: assignedDepartment,
    };
    await updateLabel(editingLabelID, newLabel);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error updating label. Duplicate label name already exists.' : 'Error updating label. Please try again.';
    hideModal();
    if (!error) {
      message.success({
        content: 'Label successfully updated',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
    }
    getLabelsByPage();
  }

  isColorSelected = (colorObject) => {
    const { selectedColorIndex, canManageLabel } = this.state;
    const { modalType: { data: { isAdmin } } } = this.props;
    const canSelectColor = (canManageLabel && isAdmin) || !canManageLabel;
    return colorObject.color === labelColors[selectedColorIndex].color && canSelectColor;
  }

  renderModal = () => {
    const { isLoading, modalType } = this.props;
    const { isAutoLabelingEnabled, isAdmin, userDepartments, isDepartmentLabelsEnabled } = modalType.data;
    const {
      newPositiveKeywordValue,
      newNegativeKeywordValue,
      negativeInputVisible,
      positiveInputVisible,
      autoLabel,
      labelsCanBeAssignedDepartment,
      assignedDepartment,
      labelName,
      positiveKeywords,
      negativeKeywords,
      editingLabelID,
      canManageLabel,
    } = this.state;
    // Disable button if manage_label is on and user is not admin
    const disableButton = canManageLabel && !isAdmin;
    if (isLoading) {
      return (<span> Adding new label... </span>);
    }
    return (
      <React.Fragment>
        <Form
          layout="vertical"
          onFinish={!editingLabelID && this.addLabel}
          id="addLabel"
        >
          <Form.Item
            hasFeedback
            name="label"
            label="Label Name"
            rules={[
              {
                required: true,
                message: 'Must provide label name',
              },
            ]}
          >
            <Input
              value={labelName}
              defaultValue={labelName}
              onChange={event => this.setState({ labelName: event.target.value })}
              disabled={disableButton}
            />
          </Form.Item>
          <Form.Item
            name="Color"
            label="Color"
          >
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {labelColors.map((colorObject, index) => (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  onClick={() => this.setState({ selectedColorIndex: index })}
                  style={{
                    height: 30,
                    width: 30,
                    borderRadius: 100,
                    borderStyle: 'inset',
                    border: this.isColorSelected(colorObject) ? '3px solid white' : '',
                    backgroundColor: colorObject.color,
                    margin: '10px',
                    boxShadow: this.isColorSelected(colorObject) ? '0px 0px 20px #b3b3b3' : '',
                    cursor: disableButton ? 'not-allowed' : 'pointer',
                  }}
                />
              ))}
            </div>
          </Form.Item>
          <Form.Item
            name="OCR"
            label={(
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Tooltip placement="left" title="Use these labels to autoscan received documents and have them labeled automatically">
                  Autolabel
                  <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                </Tooltip>
                {!isAutoLabelingEnabled
                  && (
                    // eslint-disable-next-line object-curly-newline
                    <p style={{ color: 'red', fontSize: 12, marginLeft: 10, marginBottom: 0 }}>
                      You don't have access to this feature. Contact your admin to upgrade.
                    </p>
                  )}
              </div>
            )}
          >
            <Checkbox
              checked={autoLabel}
              onChange={event => this.setState({ autoLabel: event.target.checked })}
              disabled={(disableButton) || !isAutoLabelingEnabled}
            >
              Use label to autolabel documents
            </Checkbox>
          </Form.Item>
          {/* only show positive and negative keyword fields if user has autolabel checked */}
          {autoLabel && isAutoLabelingEnabled && (
          <div>
            <Form.Item
              name="positiveKeywords"
              label={(
                <Tooltip
                  placement="left"
                  title="Associate additional words with this label so documents containing these words will also be labeled with this when autolabeled."
                >
                  Positive Keywords
                  <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px'}}/>
                </Tooltip>
              )}
            >
              {positiveKeywords.map((keyword, index) => (
                <Tag
                  color="blue"
                  key={`${keyword}${index}`}
                  style={{ marginTop: '10px' }}
                  closable={true}
                  onClose={() => this.removeKeyword(keyword, 'positiveKeywords')}
                >
                  {keyword}
                </Tag>
              ))}
              {positiveInputVisible
                && (
                  <Input
                    ref={(positiveInput) => { if (positiveInput != null) { positiveInput.focus();} }}
                    name="positiveKeywordInput"
                    type="text"
                    size="small"
                    style={{ width: '20%', marginTop: '5px' }}
                    value={newPositiveKeywordValue}
                    onChange={e => this.setState({ newPositiveKeywordValue: e.target.value })}
                    onPressEnter={() => this.addNewKeyword('positiveKeywords')}
                    disabled={disableButton}
                  />
                )
              }
              {!positiveInputVisible && (
                <Tag style={{ marginTop: '10px' }} onClick={() => this.setState({ positiveInputVisible: true })}>
                  <PlusOutlined />
                  {newPositiveKeywordValue || 'New Positive Keyword'}
                </Tag>
              )}
            </Form.Item>
            <Form.Item
              name="negativeKeywords"
              label={(
                <Tooltip placement="left" title="Use negative key words to prevent documents containing these words from being automatically labeled with this label. Helpful to prevent mislabeling records.">
                  Negative Keywords
                  <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }}/>
                </Tooltip>
              )}
            >
              {negativeKeywords.map((keyword, index) => (
                <Tag
                  color="red"
                  key={`${keyword}${index}`} 
                  style={{ marginTop: '10px' }}
                  closable={true}
                  onClose={() => this.removeKeyword(keyword, 'negativeKeywords')}
                >
                  {keyword}
                </Tag>
              ))}
              {negativeInputVisible
                && (
                  <Input
                    ref={(negativeInput) => { if (negativeInput != null) { negativeInput.focus(); }}}
                    name="negativeKeywordInput"
                    type="text"
                    size="small"
                    style={{ width: '20%', marginTop: '10px'}}
                    value={newNegativeKeywordValue}
                    onChange={e => this.setState({ newNegativeKeywordValue: e.target.value })}
                    onPressEnter={() => this.addNewKeyword('negativeKeywords')}
                    disabled={disableButton}
                  />
                )
              }
              {!negativeInputVisible && (
                <Tag style={{ marginTop: '10px' }} onClick={() => this.setState({ negativeInputVisible: true })}>
                  <PlusOutlined />
                  {newNegativeKeywordValue || 'New Negative Keyword'}
                </Tag>
              )}
            </Form.Item>
          </div>
          )}
          {isDepartmentLabelsEnabled && (
            <Form.Item
              name="assign labels"
              label={(
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Tooltip placement="left" title="Labels will be assigned to a department otherwise labels will be assigned to the organization by default">
                    Department Labels
                    <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                  </Tooltip>
                </div>
              )}
            >
              <Checkbox
                checked={labelsCanBeAssignedDepartment}
                disabled={disableButton}
                onChange={event => this.setState(prevState => ({
                  labelsCanBeAssignedDepartment: event.target.checked,
                  assignedDepartment: event.target.checked ? prevState.assignedDepartment : null,
                }))}
              >
                Assign label to a department
              </Checkbox>
              <br />
              <br />
              {labelsCanBeAssignedDepartment && userDepartments.length > 0 && (
                <>
                  <Radio.Group onChange={event => this.setState({ assignedDepartment: event.target.value })} value={assignedDepartment}>
                    <Space direction="vertical">
                      {userDepartments.map(department => <Radio value={department.id}>{department.name}</Radio>)}
                    </Space>
                  </Radio.Group>
                </>
              )}
              {labelsCanBeAssignedDepartment && userDepartments.length === 0 && (
                <p><i>Currently you don&apos;t have any departments assigned to you.</i></p>
              )}
            </Form.Item>
          )}
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal, modalType: { data: { isAdmin } } } = this.props;
    const { editingLabelID, canManageLabel } = this.state;
    const disableButton = canManageLabel && !isAdmin;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={!editingLabelID ? this.addLabel : this.editLabel}
        actionName={editingLabelID ? 'Edit' : 'Add'}
        dismiss={isLoading ? '' : 'Cancel'}
        showConfirmation={!!editingLabelID}
        disabled={disableButton || isLoading}
        form="addLabel"
        modalTitle={editingLabelID ? 'Edit Label' : 'Add New Label'}
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        >
          {disableButton && (
            <div style={{ marginBottom: '25px' }}>
              <Alert
                message="You do not have permissions to add/edit/delete labels. Please contact your admin to make any changes."
                type="info"
                showIcon
              />
            </div>
          )}
          {this.renderModal()}
        </div>
      </ModalWrapper>
    );
  }
}

AddLabel.defaultProps = {
  error: false,
  errorCode: null,
};

AddLabel.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getLabelsByPage: PropTypes.func.isRequired,
  updateLabel: PropTypes.func.isRequired,
  createNewLabel: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorCode: PropTypes.string,
};

export default connect(state => ({
  isLoading: state.labels.isLoading,
  error: state.labels.error,
  errorCode: state.labels.errorCode,
}))(AddLabel);
