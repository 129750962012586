import moment from 'moment-timezone';

export const validEmail = (email) => {
  const emailRegex = new RegExp(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
  return emailRegex.test(email);
};

/**
 * Rather than refactor all instances that call validEmail, we created another method specifically
 * for checking if emails for sending to provider or patient are valid.
 * @param {String} email
 */
export const validSendEmail = (email) => {
  const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return emailRegex.test(email);
};

/**
 * Check if an input string is a valid fax number
 * @param  {String}
 * @return {Boolean}
 */
export const validFaxNumber = (input) => {
  if (!input) return false;
  // Remove all non-numerical characters from input
  let sanitizedInput = input.replace(/\D/g, '');
  // If they prefix with 1 (for USA country code), sanitize that
  if (sanitizedInput.length === 11 && sanitizedInput[0] === '1') {
    sanitizedInput = sanitizedInput.substring(1);
  }
  // Number needs to be 10 digits
  const faxNumber = /^\d{10}$/;
  return { valid: faxNumber.test(sanitizedInput), rawNumber: sanitizedInput };
};

export const validPhoneNumber = (input) => {
  if (!input) return false;
  // Remove all non-numerical characters from input
  let sanitizedInput = input.replace(/\D/g, '');
  // If they prefix with 1 (for USA country code), sanitize that
  if (sanitizedInput.length === 11 && sanitizedInput[0] === '1') {
    sanitizedInput = sanitizedInput.substring(1);
  }
  // Number needs to be 10 digits
  const faxNumber = /^\d{10}$/;
  return faxNumber.test(sanitizedInput);
};

export const validZipCode = (input) => {
  if (!input) return false;
  // Remove all non-numerical characters from input
  const sanitizedInput = input.replace(/\D/g, '');
  // Number needs to be 5 digits
  const zipCode = /^\d{5}$/;
  return zipCode.test(sanitizedInput);
};

/**
 * Check if recipient is a valid email or phone number.
 * @param {String} recipient The patient recipient's email or phone number.
 */
export const validPatientRecipient = (recipient) => {
  return validSendEmail(recipient) || validPhoneNumber(recipient);
};

/**
 * Check if an input string is a valid partial fax number
 * @param  {String}
 * @return {Boolean}
 */
export const validPartialNumber = (input) => {
  if (!input) return false;
  // Remove all special characters from input
  const sanitizedInput = input.replace(/[^a-zA-Z0-9]/g, '');
  // Number needs to between 1-10 digits
  const faxNumber = /^\d{1,10}$/;
  return { valid: faxNumber.test(sanitizedInput), rawNumber: sanitizedInput };
};

/**
 *
 * @param {String} input any 10 digit fax number format
 * @return {String} fax number string in XXX-YYY-ZZZZ format
 */
export const formatValidFaxNumber = (input, format = 'local') => {
  if (!input) return;
  const { rawNumber } = validFaxNumber(input);
  const firstThree = rawNumber.substring(0, 3);
  const secondThree = rawNumber.substring(3, 6);
  const lastFour = rawNumber.substring(6, 10);
  if (format === 'Domestic') {
    return `(${firstThree}) ${secondThree}-${lastFour}`;
  }
  return `${firstThree}-${secondThree}-${lastFour}`;
};

/**
 * Check if an input string is a valid date
 * @param {String}
 * @return {Boolean}
 */
export const validDOB = (input) => {
  if (input) {
    const regEx = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!input.match(regEx)) return false;  // Invalid format
    const d = new Date(input);
    if (!d.getTime() && d.getTime() !== 0) return false; // Invalid date
    return true;
  } return false;
};

/**
 * Converts date string from format MM/DD/YYYY to YYYY-MM-DD.
 * @param {String} dateString
 * @return {String}
 */
export const formatDate = (dateString) => {
  if (dateString && validDOB(dateString)) {
    const date = dateString.split('/').reverse();
    const month = date[2];
    date[2] = date[1];
    date[1] = month;
    return date.join('-');
  }
  return dateString || '';
};

export const formatYearToMonth = (dateString) => {
  if (dateString && moment(dateString, 'YYYY-MM-DD').isValid()) {
    const date = dateString.split('-').reverse();
    const month = date[1];
    date[1] = date[0];
    date[0] = month;
    return date.join('-');
  }
  return '';
};

// Validates Record Page Splitting
export const validPageSplit = /^\s*(\d+\s*,\s*)*\d+\s*$/;

export const invalidPageSplit = 'Invalid page range, use e.g. 3, 5, 7';

export const invalidPageRange = (numPages, pageToSplit) => {
  if (numPages === null) return false;
  const pageRanges = pageToSplit.split(',');
  let outOfRange = false;
  pageRanges.forEach((range) => {
    const numbers = range.split('-');
    numbers.forEach((number) => {
      if (Number(number) > numPages) {
        outOfRange = true;
      }
    });
  });
  if (outOfRange) {
    return true;
  }
  return false;
};

// Validates passwords
// It Should be 8 or more characters, at least 1 capital letter and and one digit
export const validPassword = /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~ ]{8,}$/;

export const InvalidPasswordMessage = 'Your password must consist of at least one capital letter and one number.';

// Checks password validity
// Check if password eight or more characters
// TODO: Enforce strong passwords
export const checkPasswordValidity = password => (
  validPassword.test(password)
    ? ''
    : InvalidPasswordMessage
);

// Ensures that the password confirmation is correct
export const checkPasswordConfirmation = (password, confirmPassword) => (
  (password === confirmPassword) ? '' : 'Passwords are not matching'
);

// Check if field is not empty and an return error
export const checkEmptyField = (field) => {
  return (field === '') && 'This field cannot be blank.';
};
