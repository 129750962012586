const initialState = {
  isLoading: false,
  data: [],
  error: false,
  errorMessage: null,
  headers: null,
  errorCode: '',
};

const failedState = {
  ...initialState,
  isLoading: false,
  error: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_GET_LABELS': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_GET_LABELS': {
      return {
        ...failedState,
        errorMessage: action.payload.data.message,
      };
    }
    case 'FINISH_GET_LABELS': {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data.labels,
        headers: action.payload.data.meta,
      };
    }
    case 'BEGIN_CREATE_LABEL': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_CREATE_LABEL': {
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_CREATE_LABEL': {
      return {
        isLoading: false,
        error: false,
      };
    }
    case 'BEGIN_DELETE_LABEL': {
      return {
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_DELETE_LABEL': {
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_DELETE_LABEL': {
      return {
        isLoading: false,
        error: false,
      };
    }
    case 'BEGIN_UPDATE_LABEL': {
      return {
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_UPDATE_LABEL': {
      return {
        ...failedState,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_UPDATE_LABEL': {
      return {
        isLoading: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
