import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Button } from 'antd';
import { SupportedEMRs } from '../../../containers/walkthrough/constants';

import { formatValidFaxNumber } from '../../../global/validation';
import { EditFilled } from '@ant-design/icons';

const descStyle = {
  paddingLeft: '40px',
  paddingRight: '40px',
  marginTop: '30px',
  textAlign: 'left',
};

const chromeExtLink = `https://chrome.google.com/webstore/detail/medsender/${process.env.CHROME_EXT_ID}`;

class userInformation extends Component {
  static propTypes = {
    getUserInfo: PropTypes.func.isRequired,
    changeEmailPreference: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    jobTitle: PropTypes.string,
    emr: PropTypes.string,
    departments: PropTypes.array,
    associated_fax_numbers: PropTypes.array,
    adminLoginSuccess: PropTypes.bool.isRequired,
    loginErrorMessage: PropTypes.string.isRequired,
    loginAdmin: PropTypes.func.isRequired,
    notificationErrorMessage: PropTypes.string.isRequired,
    allowedUserDomains: PropTypes.array.isRequired,
    defaultFaxNumber: PropTypes.string,
    organizationDetails: PropTypes.shape({
      multiple_organization_login_enabled: PropTypes.bool.isRequired,
      show_multiple_organization_login_modal: PropTypes.bool.isRequired,
      organizations: PropTypes.array.isRequired,
      current_organization: PropTypes.string,
    }),
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: null,
    email: null,
    phoneNumber: null,
    jobTitle: null,
    emr: null,
    departments: null,
    associated_fax_numbers: [],
    defaultFaxNumber: null,
    organizationDetails: null,
  };

  state = {
    name: '',
    email: '',
    phoneNumber: '',
    jobTitle: '',
    emr: '',
    departments: [],
    defaultFaxNumber: '',
    associatedFaxNum: [],
    isAdmin: false,
    loginError: false,
    loginErrorMessage: '',
    checked: true,
    loading: false,
    chromeExtensionInstalled: false,
    userEMR: localStorage.getItem('m8_emr') || '',
    organizationDetails: {
      multiple_organization_login_enabled: false,
      currentOrganization: '',
    },
  };

  componentDidMount() {
    this.setInformation();
    this.showChromeExtensionInformation();
  }

  openAddUserModal = () => {
    const { openModal } = this.props;
    const modalType = {
      type: 'ADD_NEW_USER',
    };
    openModal(modalType);
  }

  openUpdatePhoneNumber = () => {
    const { openModal, phoneNumber } = this.props;
    const modalType = {
      data: { phoneNumber },
      type: 'UPDATE_PHONE_NUMBER',
    };
    openModal(modalType);
  }

  openChangeEMR = () => {
    const { openModal, emr } = this.props;
    const modalType = {
      data: { emr },
      type: 'CHANGE_EMR',
    };
    openModal(modalType);
  }

  setInformation = async () => {
    this.setState({ loading: true });
    const { getUserInfo } = this.props;
    await getUserInfo();
    this.setState({ loading: false });
    const {
      name,
      email,
      jobTitle,
      phoneNumber,
      emr,
      departments,
      associated_fax_numbers: associatedFaxNum,
      defaultFaxNumber,
      notification_fax_email,
      isAdmin,
      allowedUserDomains,
      organizationDetails,
    } = this.props;

    localStorage.setItem('allowed_user_domains', JSON.stringify(allowedUserDomains));
    this.setState({
      name,
      email,
      phoneNumber,
      jobTitle,
      emr,
      departments,
      associatedFaxNum,
      isAdmin,
      checked: notification_fax_email,
      userEMR: localStorage.getItem('m8_emr') || '',
      defaultFaxNumber,
      organizationDetails,
    });
  }

  onCheck = async (event, isInputChecked) => {
    const { changeEmailPreference } = this.props;
    this.setState({
      checked: isInputChecked,
    });
    await changeEmailPreference(isInputChecked);
    const { notificationErrorMessage } = this.props;
    if (notificationErrorMessage !== '') {
      /* global toastr */
      toastr.error(notificationErrorMessage);
    } else {
      const toastrMessage = isInputChecked
        ? 'You will now receive email notifications when receiving records'
        : 'You will no longer receive email notifications when receiving records';
      toastr.success(toastrMessage);
    }
  }

  checkChromeExtensionInstallation = () => {
    const ping = { message: 'ping' };
    const chromeExtId = process.env.CHROME_EXT_ID;
    return new Promise((resolve, reject) => {
      /* global chrome */
      chrome.runtime.sendMessage(chromeExtId, ping, (response) => {
        if (response && response.message) return resolve();
        return reject(chrome.runtime.lastError.message || 'Error');
      });
    });
  }

  showChromeExtensionInformation = () => {
    this.checkChromeExtensionInstallation()
      .then(() => {
        this.setState({ chromeExtensionInstalled: true });
      })
      .catch(() => {
        this.setState({ chromeExtensionInstalled: false });
      });
  }

  installWindowsClient = () => {
    const win = window.open(process.env.DOWNLOAD_APP_LINK, '_blank');
    win.opener = null;
  }

  changeDefaultOrganization = () => {
    const { openModal: handleOpenModal } = this.props;
    const { organizationDetails } = this.state;
    const modalType = {
      type: 'SELECT_ORGANIZATION',
      data: organizationDetails,
    };
    handleOpenModal(modalType);
  }

  render() {
    const {
      name,
      email,
      phoneNumber,
      jobTitle,
      emr,
      departments,
      defaultFaxNumber,
      associatedFaxNum,
      userEMR,
      chromeExtensionInstalled,
      organizationDetails,
    } = this.state;
    const { multiple_organization_login_enabled: isMultiOrgEnabled, current_organization: currentOrganization } = organizationDetails;
    const departmentNames = departments.map(department => department.name);

    const contents = [
      { label: 'Name', content: name },
      { label: 'Email', content: email },
      { label: 'Cell Phone Number', content: phoneNumber, button: { onClick: this.openUpdatePhoneNumber } },
      { label: 'Job Title', content: jobTitle },
      { label: 'EMR', content: emr, button: { onClick: this.openChangeEMR } },
      { label: 'Default Fax Number', content: formatValidFaxNumber(defaultFaxNumber) },
      { label: 'Departments', content: departments && departmentNames.join([', ']) },
      {
        label: 'Current Location/Organization/Branch',
        content: currentOrganization,
        hide: !isMultiOrgEnabled,
        button: { onClick: this.changeDefaultOrganization },
      },
    ];

    const platform = window.navigator.platform;
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const onWindows = windowsPlatforms.indexOf(platform) !== -1 && navigator.userAgent.indexOf('Windows NT 5.1') === -1;
    return (
      <>
        <Descriptions title="User Information" bordered style={descStyle}>
          {contents.map(
            ({ label, content, button, hide }) => {
              return (
                !hide && (
                  <Descriptions.Item
                    key={`userInfo-${label}`}
                    span={3}
                    label={label}
                    style={{ textAlign: "left" }}
                  >
                    {content || "N/A"}
                    {button && (
                      <Button
                        type="link"
                        style={{ marginLeft: "5px" }}
                        icon={<EditFilled />}
                        onClick={button.onClick}
                      />
                    )}
                  </Descriptions.Item>
                )
              );
            },
          )}
        </Descriptions>
        {
          associatedFaxNum && associatedFaxNum.length > 0 ? (
            <Descriptions title="Associated Fax Numbers" bordered style={{ marginTop: '20px', ...descStyle }}>
              {
                associatedFaxNum.map((faxNumber, index) => (
                  <Descriptions.Item key={`faxNumbs-${index}`} label={faxNumber[1]} span={3}>
                    {formatValidFaxNumber(faxNumber[0])}
                  </Descriptions.Item>
                ))
              }
            </Descriptions>
          )
            : (<div style={{ paddingTop: '50px', fontStyle: 'italic' }}>There are no fax numbers associated with this account</div>)
        }
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', paddingRight: '40px' }}>
          {
            onWindows && (
              <Button
                style={{ marginRight: '20px '}}
                backgroundColor="rgb(163,237,255)"
                onClick={this.installWindowsClient}
              >
                Install Printer Driver
              </Button>
            )
          }
          {
            !chromeExtensionInstalled && (
              <Button
              type="primary"
              backgroundcolor="rgb(163,237,255)"
              onClick={() => window.open(chromeExtLink, 'chrome link', 'width=1000, height=600')}
              >
                Install Chrome Extension
              </Button>
            )
          }
        </div>
      </>
    );
  }
}

export default userInformation;
