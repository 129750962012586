import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Steps, Button } from 'antd';
import { getLoginInfo } from '../../actions/auth';

import WalkthroughStepTemplate from './WalkthroughStepTemplate';
import {
  BearOnComputer,
  BearEMR,
  BearCompletedWalkthrough,
  WelcomeBear,
} from '../../assets/img/redesign';
import { SortedEMRs, SupportedEMRs } from './constants';
import { updateUserDetails } from '../../actions/userProfile';
import { openModal } from '../../actions/modal';

const { Step } = Steps;
// eslint-disable-next-line prefer-destructuring
const platform = window.navigator.platform;
const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
const walkththroughSteps = [
  'Welcome to Medsender!',
  'About you',
  'Your EMR',
  'Fax Number',
  'Printer Driver',
  'Done',
];
const onWindows = windowsPlatforms.indexOf(platform) !== -1 && navigator.userAgent.indexOf('Windows NT 5.1') === -1;

class IntroToMedsender extends Component {
  state = {
    stepIndex: 0,
    steps: walkththroughSteps,
    nextStepDisabled: true,
    checked: false,
    userEMR: '',
    jobTitle: null,
    hasSupportedChromeExtension: false,
    // tracking the features that an EMR with chrome extension supports
    supportedFeatures: [],
    otherEMR: localStorage.getItem('m8_emr'),
    chromeExtensionInstalled: false,
  };

  componentDidMount() {
    this.props.getLoginInfo();
    this.defineWalkthroughSteps();
    this.showChromeExtensionInformation();
    const { emr, jobTitle, stepOnWalkthrough } = this.props;
    const { steps: currentSteps } = this.state;
    const userEMR = emr;
    if (userEMR || stepOnWalkthrough) {
      const userHasValidEMR = SortedEMRs.filter(singleEMR => singleEMR.includes(userEMR));
      this.setState({
        userEMR: userHasValidEMR[0],
        jobTitle,
        stepIndex: currentSteps.indexOf(stepOnWalkthrough),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { stepOnWalkthrough } = this.props;
    const { steps } = this.state;
    if (stepOnWalkthrough !== nextProps.stepOnWalkthrough) {
      this.setState({
        stepIndex: steps.indexOf(nextProps.stepOnWalkthrough),
      });
    }
  }

  getStepContent(stepIndex) {
    const {
      userEMR,
      jobTitle,
      checked,
      hasSupportedChromeExtension,
      supportedFeatures,
      otherEMR,
      chromeExtensionInstalled,
    } = this.state;

    const userName = this.props.fullName;

    switch (stepIndex) {
      case 'Welcome to Medsender!':
        return (
          <WalkthroughStepTemplate
            title={`Welcome to Medsender${userName ? ` ${userName}` : ''}!`}
            img={WelcomeBear}
            subtitle="Thanks for joining us! We’re excited to show you how to get started with Medsender"
          />
        );
      case 'About you':
        return (
          <WalkthroughStepTemplate
            title="First off, we want to learn a bit about you!"
            img={BearCompletedWalkthrough}
            subtitle="This information will help us customize your Medsender experience to best fit your needs"
            userInformation={{
              dropDownDefault: 'Pick your position',
              label: 'What is your job title?',
              name: 'jobTitle',
              value: jobTitle,
              otherFieldHintText: 'Your job title',
            }}
            textField={true}
            handleChange={(value) => this.handleChange(value, 'jobTitle')}
            handleFieldChange={this.handleFieldChange}
          />
        );
      case 'Your EMR':
        return (
          <WalkthroughStepTemplate
            title="What EMR are you using?"
            img={null}
            subtitle="An EMR (or EHR) is the system you use to store electronic patient records"
            dropDown={true}
            userInformation={{
              options: SortedEMRs,
              dropDownDefault: 'Select your EMR',
              label: 'Your EMR',
              name: 'userEMR',
              value: userEMR,
              otherFieldHintText: 'Type the name of your EMR',
              otherFieldName: 'otherEMR',
              otherFieldValue: otherEMR,
            }}
            toggleChecked={this.toggleChecked}
            checked={checked}
            checkbox={true}
            handleChange={(value) => this.handleChange(value, 'userEMR')}
            handleFieldChange={this.handleFieldChange}
            selectedOtherOption={userEMR === 'Other'}
            hasSupportedChromeExtension={hasSupportedChromeExtension}
            supportedFeatures={supportedFeatures}
            chromeExtensionInstalled={chromeExtensionInstalled}
          />
        );
      case 'Fax Number':
        return (
          <WalkthroughStepTemplate
            title="Get A Fax Number"
            subtitle={
              `Having a fax number enables you to receive and send
               faxes straight into and out of Medsender.`
            }
            openAddFaxNumberModal={this.openAddFaxNumberModal}
            showGetFaxNumberButton
          />
        );
      case 'Printer Driver':
        return (
          (
            <WalkthroughStepTemplate
              title="We noticed you’re using a Windows computer."
              img={BearEMR}
              subtitle={
                `Use our Printer Driver for Windows for easy uploading. Once installed, 
                you can "print" a document to Medsender from any application.`
              }
              button={true}
            />
          )
        );
      case 'Done':
        return (
          <WalkthroughStepTemplate
            title="Enjoy Medsender!"
            img={BearOnComputer}
            subtitle={
              `Reach out to our team if we can help with anything. We're always looking to 
              improve so let us know how we can make your experience better.`
            }
          />
        );
      default:
        return 'Welcome to Medsender!';
    }
  }

  handleNext = () => {
    // eslint-disable-next-line object-curly-newline
    const { stepIndex, jobTitle, userEMR, otherEMR } = this.state;
    // eslint-disable-next-line
    const { updateUserDetails } = this.props;
    const selectedOtherOption = userEMR === 'Other';
    const userEMRSelection = selectedOtherOption ? otherEMR : userEMR;
    if (stepIndex === 1) {
      localStorage.setItem('m8_job_title', jobTitle);
      updateUserDetails({ job_title: jobTitle });
      // Check to see if their prepopulated emr from user details (if applicable)  has chrome support
      const emrThatHasExtension = SupportedEMRs.filter(emr => emr.emr === userEMR);
      if (emrThatHasExtension.length) {
        this.setState({
          hasSupportedChromeExtension: true,
          supportedFeatures: emrThatHasExtension[0].supportedFeatures,
        });
      }
    }
    if (stepIndex === 2) {
      localStorage.setItem('m8_emr', userEMRSelection);
      updateUserDetails({ emr: userEMRSelection });
    }
    this.setState({
      stepIndex: stepIndex + 1,
    });
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    }
  };

  defineWalkthroughSteps = () => {
    const { isTrialUser } = this.props;
    if (!onWindows) delete walkththroughSteps[walkththroughSteps.indexOf('Printer Driver')];
    if (!isTrialUser) delete walkththroughSteps[walkththroughSteps.indexOf('Fax Number')];
    const steps = walkththroughSteps.filter(step => step);

    this.setState({
      steps,
    });
  }

  // Handler for the 'I have no EMR' checkbox
  toggleChecked = (isChecked) => {
    this.setState({
      checked: isChecked,
      nextStepDisabled: !isChecked,
      userEMR: null,
      hasSupportedChromeExtension: false,
    });
  }

  handleFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      checked: false,
      nextStepDisabled: !e.target.value,
    });
  }

  checkChromeExtensionInstallation = () => {
    const ping = { message: 'ping' };
    const chromeExtId = process.env.CHROME_EXT_ID;
    return new Promise((resolve, reject) => {
      /* global chrome */
      chrome.runtime.sendMessage(chromeExtId, ping, (response) => {
        if (response && response.message) return resolve();
        return reject(chrome.runtime.lastError.message || 'Error');
      });
    });
  }

  showChromeExtensionInformation = () => {
    this.checkChromeExtensionInstallation()
      .then(() => {
        this.setState({ chromeExtensionInstalled: true });
      })
      .catch(() => {
        this.setState({ chromeExtensionInstalled: false });
      });
  }

  handleChange = (value, name) => {
    this.setState({
      [name]: value,
      checked: false,
      nextStepDisabled: false,
      hasSupportedChromeExtension: false,
    });
    const emrThatHasExtension = SupportedEMRs.filter(emr => emr.emr === value);
    if (emrThatHasExtension.length) {
      this.setState({
        hasSupportedChromeExtension: true,
        supportedFeatures: emrThatHasExtension[0].supportedFeatures,
      });
    }
  }

  setWalkthroughAsComplete = async () => {
    const { updateUserDetails, history } = this.props;
    await updateUserDetails({ has_completed_walkthrough: true });
    // eslint-disable-next-line
    history.push('/app');
    const { openModal: handleOpenModal, organizationDetails } = this.props;

    if (!organizationDetails.show_multiple_organization_login_modal) return;
    const modalType = {
      type: 'SELECT_ORGANIZATION',
      data: organizationDetails,
    };
    handleOpenModal(modalType);
  }

  // Handler for the Add Fax Number button. Shows the modal to upload new record.
  openAddFaxNumberModal = () => {
    const { openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'ADD_FAX_NUMBER',
      data: {
        userIsOnWalkthrough: true,
      },
    };
    return handleOpenModal(modalType);
  }

  render() {
    // eslint-disable-next-line object-curly-newline
    const { stepIndex, nextStepDisabled, jobTitle, steps: allSteps, userEMR } = this.state;
    const contentStyle = { margin: '0 16px' };
    const steps = allSteps.filter(step => step);
    const preventUsersFromNextStep = (stepIndex === 1 && !jobTitle) || (stepIndex === 2 && nextStepDisabled && !userEMR);
    const onLastSlide = stepIndex === steps.length - 1;

    return (
      <div style={{ width: '100%', maxWidth: 900, margin: 'auto' }}>
        <Helmet>
          <title>Getting Started - Medsender</title>
        </Helmet>
        <Steps current={stepIndex} size={'small'} style={{ marginTop: 60 }}>
          {steps.map(step => (
            <Step key={`${step}`} title={step} />
          ))}
        </Steps>
        <div style={contentStyle}>
          <div>
            <div>{this.getStepContent(steps[stepIndex])}</div>
            <div style={{ marginTop: 12 }}>
              <Button
                type="text"
                disabled={stepIndex === 0}
                onClick={this.handlePrev}
                style={{ marginRight: 12 }}
              >
                Back
              </Button>
              <Button
                type="primary"
                disabled={preventUsersFromNextStep ? nextStepDisabled : false}
                primary={true}
                onClick={() => (onLastSlide ? this.setWalkthroughAsComplete() : this.handleNext())}
              >
                {onLastSlide ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

IntroToMedsender.defaultProps = {
  jobTitle: null,
  emr: null,
};

IntroToMedsender.propTypes = {
  emr: PropTypes.string,
  jobTitle: PropTypes.string,
  updateUserDetails: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  isTrialUser: PropTypes.bool.isRequired,
  stepOnWalkthrough: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  organizationDetails: PropTypes.object.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
};

export default connect(state => ({
  emr: state.auth.data.emr,
  jobTitle: state.auth.data.jobTitle,
  isTrialUser: state.auth.trialUser,
  stepOnWalkthrough: state.auth.stepOnWalkthrough,
  fullName: state.auth.data.fullName,
  organizationDetails: state.auth.organizationDetails,
}), { updateUserDetails, openModal, getLoginInfo })(IntroToMedsender);
