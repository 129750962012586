import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Raven from 'raven-js';
import React from 'react';
import { render } from 'react-dom';
import Medsender from '../src/app';
import toastr from '../../assets/javascripts/toastr';

import '../src/assets/stylesheets/main.scss';
import '../../assets/stylesheets/toastr.css';

Raven.config(process.env.SENTRY_REACT_DSN, {
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
}).install();

window.raven = Raven;
global.toastr = toastr;
const root = document.getElementById('root');
document.addEventListener('DOMContentLoaded', () => {
  render(
    <Medsender />, root,
  );
});
