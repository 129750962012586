import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddFaxNumber from '../../components/modal/AddFaxNumber';
import { fetchAreaCodes, addNewFaxNumber } from '../../actions/modal';
import { updateAssociatedFaxNumber, updateWalkthroughStep } from '../../actions/auth';

class AddFaxNumberDialog extends Component {
  static propTypes = {
    fetchAreaCodes: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddFaxNumber
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  areaCodes: state.modal.areaCodes,
  billingOnFile: state.auth.billing.billing_on_file,
  isLoading: state.modal.isCreatingFaxNumber,
  newFaxNumber: state.modal.newFaxNumber,
  errorStatus: state.modal.errorStatus,
  isTrialUser: state.auth.trialUser,
  stepOnWalkthrough: state.auth.stepOnWalkthrough,
}), { fetchAreaCodes, addNewFaxNumber, updateAssociatedFaxNumber, updateWalkthroughStep })(AddFaxNumberDialog);
