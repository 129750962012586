import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ChangeEMR from '../../components/modal/ChangeEMR';
import { updateUserDetails } from '../../actions/userProfile';

class ChangeEMRDialog extends Component {
  render() {
    return (
      <div>
        <ChangeEMR
          {...this.props}
        />
      </div>
    );
  }
}

ChangeEMRDialog.propTypes = {
  emr: PropTypes.string.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
};

export default connect(state => ({
  emr: state.auth.data.emr,
}), { updateUserDetails })(ChangeEMRDialog);
