import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CreateNewChart from '../../components/modal/CreateNewChart';
import { triggerAutoUpload } from '../../actions/records';
import { getDocumentCategories } from '../../actions/userProfile';

class CreateNewChartDialog extends Component {
  render() {
    return (
      <div>
        <CreateNewChart
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  fileName: state.auth.data.fileName,
  sendToken: state.auth.data.sendToken,
}), {
  triggerAutoUpload,
  getDocumentCategories,
})(CreateNewChartDialog);
