import React from 'react';
import Link from 'react-router-dom/Link';

const Error404 = () => (
  <div style={{ textAlign: 'center', marginTop: '40px' }}>
    <h1>404 Not Found </h1>
    <p>These aren&rsquo;t the medical records you&rsquo;re looking for</p>
    <h5><Link to="/">Return back to Medsender</Link></h5>
  </div>
);

export default Error404;
