import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EnterDirectAddress from '../../components/modal/DirectMessaging/EnterDirectAddress';
import VerifyDirectAddress from '../../components/modal/DirectMessaging/VerifyDirectAddress';
import ConfirmForwarding from '../../components/modal/DirectMessaging/ConfirmForwarding';
import RecordForwarded from '../../components/modal/DirectMessaging/RecordForwarded';
import DownloadChromeExtension from '../../components/modal/DirectMessaging/DownloadChromeExtension';
import ModalLoading from '../../components/modal/ModalLoading';
import ModalWrapper from '../../components/modal/ModalWrapper';

import { MODAL_ENUMS } from '../../reducers/directMessaging';
import {
  checkForDirectAddress,
  confirmForwardRecord,
  verifyDirectAddress,
  resendVerificationCode,
  confirmVerificationCode,
  changeModalState,
  changeSelectedDirectAddress,
} from '../../actions/directMessaging';
import { rowSelected } from '../../actions/inboxTableActions';
import { SupportedEMRs } from '../../containers/walkthrough/constants';

class DirectMessagingDialog extends Component {
  state = {
    userEMR: localStorage.getItem('m8_emr') || '',
  }

  componentDidMount() {    
    this.props.checkForDirectAddress();
  }

  render() {
    const { userEMR } = this.state;
    const userEMRHasChromeSupport = SupportedEMRs.some(({ emr }) => emr === userEMR);
    if (userEMRHasChromeSupport) return <DownloadChromeExtension {...this.props}/>
    else {
      const { modalState } = this.props;
      switch (modalState) {
        case MODAL_ENUMS.LOADING:
          return <ModalWrapper children={<ModalLoading {...this.props} />} />;
        case MODAL_ENUMS.ENTER_DIRECT_ADDRESS:
          return <EnterDirectAddress {...this.props} />;
        case MODAL_ENUMS.VERIFY_DIRECT_ADDRESS:
          return <VerifyDirectAddress {...this.props} />;
        case MODAL_ENUMS.CONFIRM_FORWARDING:
          return <ConfirmForwarding {...this.props} />;
        case MODAL_ENUMS.RECORD_FORWARDED:
          return <RecordForwarded {...this.props} />;
        default:
          return null;
      }
    }
  }
}

DirectMessagingDialog.defaultProps = {
  directAddressVerified: false,
  verificationCodeTimeout: false,
  verificationCodeExpired: false,
  indexSelectedDirectAddress: 0,
}

DirectMessagingDialog.propTypes = {
  checkForDirectAddress: PropTypes.func.isRequired,
  confirmForwardRecord: PropTypes.func.isRequired,
  verifyDirectAddress: PropTypes.func.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  confirmVerificationCode: PropTypes.func.isRequired,
  changeModalState: PropTypes.func.isRequired,
  changeSelectedDirectAddress: PropTypes.func.isRequired,
  directAddressVerified: PropTypes.bool,
  verificationCodeTimeout: PropTypes.bool,
  documentSent: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  addresses: PropTypes.array.isRequired,
  verificationCodeExpired: PropTypes.bool,
  modalState: PropTypes.number.isRequired,
  indexSelectedDirectAddress: PropTypes.number,
};

export default connect(
  state => ({
    directAddressVerified: state.directMessaging.directAddressVerified,
    verificationCodeTimeout: state.directMessaging.verificationCodeTimeout,
    documentSent: state.directMessaging.documentSent,
    loading: state.directMessaging.isLoading,
    error: state.directMessaging.error,
    errorMessage: state.directMessaging.errorMessage,
    errorCode: state.directMessaging.errorCode,
    addresses: state.directMessaging.addresses,
    verificationCodeExpired: state.directMessaging.verificationCodeExpired,
    modalState: state.directMessaging.modalState,
    indexSelectedDirectAddress: state.directMessaging.indexSelectedDirectAddress,
  }), {
    checkForDirectAddress,
    confirmForwardRecord,
    verifyDirectAddress,
    resendVerificationCode,
    confirmVerificationCode,
    rowSelected,
    changeModalState,
    changeSelectedDirectAddress,
  },
)(DirectMessagingDialog);
