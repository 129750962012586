import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UpdatePhoneNumber from '../../components/modal/UpdatePhoneNumber';
import { sendOtpToSms, verifySmsOtp, updateUserDetails } from '../../actions/userProfile';

class UpdatePhoneNumberDialog extends Component {
  render() {
    return (
      <div>
        <UpdatePhoneNumber
          {...this.props}
        />
      </div>
    );
  }
}

UpdatePhoneNumber.propTypes = {
  updateUserDetails: PropTypes.func.isRequired,
};

export default connect(state => ({
  error: state.userProfile.error,
  errorMessage: state.userProfile.erroMesage,
}), { updateUserDetails, sendOtpToSms, verifySmsOtp })(UpdatePhoneNumber);
