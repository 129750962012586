const initialState = {
  newReferralsCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEW_REFERRALS_COUNT': {
      return {
        ...initialState,
        newReferralsCount: action.payload.newReferralsCount,
      };
    }
    case 'INCREMENT_NEW_REFERRALS_COUNT': {
      return {
        newReferralsCount: state.newReferralsCount + 1,
      };
    }
    case 'DECREMENT_NEW_REFERRALS_COUNT': {
      return {
        newReferralsCount: state.newReferralsCount - 1,
      };
    }
    default:
      return state;
  }
};
