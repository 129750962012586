import crud from './helpers/crud';

const getNotifications = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_NOTIFICATIONS',
      fail: 'FAILED_GET_NOTIFICATIONS',
      end: 'FINISHED_GET_NOTIFICATIONS',
    },
    method: 'GET',
    url: '/api/v1/notifications',
  })
);

export {
  getNotifications,
};
