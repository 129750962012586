import React, { PureComponent } from 'react';
import { Spin } from 'antd';

class PageLoading extends PureComponent {
  render() {
    return (
      <div style={{ textAlign: 'center', marginTop: '200px' }}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }
}

export default PageLoading;
