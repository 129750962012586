// Failed SSO component displays the modal content notifying user they aren't signed up with Medsender.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

const pStyle = {
  textAlign: 'center',
  fontWeight: '600',
};

class ProviderSwitched extends Component {
  render() {
    const { hideModal } = this.props;
    const customContentStyle = {
      width: '400px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        dismiss="Close"
      >
        <p style={pStyle}>We've enabled SSO on your account</p>
        <p>
          We noticed this was the first you've logged in using SSO on your Medsender account.
          We've automatically migrated your account to support using SSO and disabled logging in via
           email and password. If you'd like to go back to using email and password to login please
           contact&nbsp;
          <a href="https://medsender.com/support">Medsender Support</a>
        </p>
      </ModalWrapper>
    );
  }
}

ProviderSwitched.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default ProviderSwitched;
