import {
  ManageTaskProviders,
  AddTaskProvider,
} from '../../../assets/img/guide';

const addingTaskProviderCopy = {
  pageTitle: 'Manage Task Providers',
  pageSubtitle: `
    Medsender allows you to create custom Task Providers. Users will be manage their providers listing that will
    display on the Send to Chart and Create Task Modals.`,
  sections: [
    {
      title: 'Managing your Task Providers',
      id: 'manageTaskProviders',
      subtitle: `Navigate to settings and find the "Manage Task Providers" tab. 
      From here, you can edit, add, or delete providers. Only an admin will be able to create a provider.`,
      img: ManageTaskProviders,
    },
    {
      title: 'Adding a Task Provider',
      id: 'addingTaskProvider',
      subtitle: `Provider's name will be visible as a dropdown choice from the Send to Chart and
      Create Task Modals. All other fields are required to create a Task Provider.`,
      img: AddTaskProvider,
    },
  ],
};

export default addingTaskProviderCopy;
