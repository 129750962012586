import { Upload } from 'antd';
import styled from 'styled-components';

const { Dragger: antdDragger } = Upload;

const Dragger = styled(antdDragger)`
  min-height: 100vh;
  border: none !important;
  cursor: inherit !important;

  &.ant-upload-drag-hover {
    filter: ${({ disabled }) => !disabled && 'brightness(0.5)'};
  }
  & .ant-upload.ant-upload-disabled {
    cursor: inherit;
  }
  & .ant-upload {
    padding: 0 !important;
  }
`;

export default Dragger;
