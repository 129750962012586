import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

class PrivacyLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: props.display,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ display: nextProps.display });
  }

  onRadioChange = (e) => {
    const { handleChange } = this.props;
    const newPrivacyLevel = e.target.value;
    handleChange(newPrivacyLevel);
  };

  render() {
    const { display } = this.state;
    const { valueSelected } = this.props;
    if (!display) return null;
    const privacyLevels = [
      { text: 'Me only', option: 'owner' },
      { text: 'My department', option: 'department' },
      { text: 'My organization', option: 'organization' },
    ];
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    return (
      <div>
        <Radio.Group onChange={this.onRadioChange} value={valueSelected}>
          {
            privacyLevels.map(({ text, option }) => (
              <Radio style={radioStyle} value={option} key={option}>
                {text}
              </Radio>
            ))
          }
        </Radio.Group>
      </div>
    );
  }
}

PrivacyLevel.propTypes = {
  valueSelected: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
};

export default PrivacyLevel;
