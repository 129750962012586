import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import { NewFlatButton } from './redesign/Migration';
import ResendRecordsMenu from './resendRecordsMenu';
import FiltersMenu from './filtersMenu';

class HeaderButtons extends Component {
  render() {
    const { buttons } = this.props;
    return (
      <>
        {
          // Header buttons are mapped in an array and rendered
          buttons.map((button, i) => {
            if (button.hide) {
              return null;
            }

            if (button.type === 'dropdown') {
              return (
                <Dropdown key={i} overlay={button.menu} disabled={button.disabled}>
                  <a
                    style={{
                      color: button.disabled ? 'rgba(0, 0, 0, .25)' : 'rgba(0, 0, 0, .65)', height: '20px', fontSize: '16px', marginLeft: '24px',
                    }}
                    disabled
                    onClick={e => e.preventDefault()}
                  >
                    {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                    {button.icon}  {button.text}
                  </a>
                </Dropdown>
              );
            }
            return (
              <div
                key={i}
                className="header-buttons"
              >
                {
                  button.resendRecords
                    && (
                      <ResendRecordsMenu
                        openMenu={button.openMenu}
                        disableSingleResend={button.disableSingleResend}
                        handleResendRecord={button.handleResendRecord}
                        handleRequestChange={button.handleRequestChange}
                      />
                    )
                }
                {
                  button.filterRecords
                    && (
                      <FiltersMenu
                        openFilterMenu={button.openFilterMenu}
                        handleFilter={button.handleFilter}
                        filterOptions={button.filterOptions}
                        handleRequestChange={button.handleRequestChange}
                      />
                    )
                }
                <NewFlatButton
                  label={button.text}
                  disabled={button.disabled}
                  icon={button.icon}
                  onClick={button.func}
                  secondary={button.secondary}
                  tooltipTitle={button.toolTiptitle}
                  style={{
                    marginLeft: button.resendRecords || button.filterRecords ? '0' : '24px',
                    ...button.style || {},
                  }}
                />
              </div>
            );
          })
        }
      </>
    );
  }
}

HeaderButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    func: PropTypes.func,
    icon: PropTypes.object.isRequired,
    hide: PropTypes.bool,
    passedFuncProps: PropTypes.Object,
  })).isRequired,
};

export default HeaderButtons;
