import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import {
  CloudDownloadOutlined,
  PrinterOutlined,
  FormOutlined,
} from '@ant-design/icons';

import ModalWrapper from '../components/modal/ModalWrapper';
import { Header, PageLoading, Viewer } from '../components';
import { hideModal, openModal } from '../actions/modal';
import download from '../actions/helpers/download';
import { getSecureSignedURL, printOrDownloadSecureLink, setUnsavedAnnotationsOnRecord } from '../actions/records';
import { sendReplyToRecord } from '../actions/replies';

/* global toastr */

const customContentStyle = {
  width: '26%',
  textAlign: 'center',
  disabled: 'true',
};

const parseOptions = {
  ignoreQueryPrefix: true,
};

// See comment in app/containers/RecordViewerPatient.jsx for more explanation
let printedPdfWindow;

class RecordViewer extends Component {

  constructor(props) {
    super(props);
    const { match: { params: { id, record_id: recordId } }, location } = this.props;
    const params = parse(location.search, parseOptions);

    this.state = {
      recordId,
      id,
      source: params.source,
      recordBlobObject: null,
      recordHasReply: false,
      unsavedSignatures: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    const { secureSignedURL: newSignedUrl, error: newError } = nextProps;
    const { secureSignedURL: oldSignedUrl, error: oldError } = this.props;
    if (newSignedUrl !== oldSignedUrl) return true;
    if (nextProps.loading !== this.props.loading) return true;
    if (newError !== oldError) return true;
    return false;
  }

  printRecord = async () => {
    await this.props.printOrDownloadSecureLink(this.state.recordId, this.state.id);
    printedPdfWindow.location.href = this.props.printUrl;
    printedPdfWindow.opener = null;
  }

  downloadRecord = async () => {
    await this.props.printOrDownloadSecureLink(this.state.recordId, this.state.id);
    download(this.props.printUrl);
  }

  setUnsavedSignatures = (unsavedSignatures) => {
    this.setState({ unsavedSignatures });
  };

  openReplyToModal = async () => {
    const {
      subject, 
      recipientEmail,
      recipientName,
      sendToken,
      hasResponse,
      signatureRequired,
      recordHasUnsavedEdits,
      openModal: openReplyToModal,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(false);
    }

    const { recordBlobObject } = this.state;

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    const data = {
      subject,
      recipientEmail,
      recipientName,
      sendToken,
      hasResponse,
      recordBlobObject,
    };
    const modalType = {
      type: 'REPLY_TO_EMAIL',
      data,
    };

    // Make request to BE to securely reply to the record.
    this.setState({
      recordHasReply: true,
    });
    openReplyToModal(modalType);
  }

  signAndSubmitForm = (file) => {
    const { openModal: openSubmitFormModal, match: { params: { record_id: recordId, id } } } = this.props;

    const modalType = {
      type: 'SUBMIT_FORM',
      data: {
        file: file.get('file'),
        fileToken: recordId,
        accessCode: id,
      },
    };
    return openSubmitFormModal(modalType);
  }

  // This function allows guest users to quickly submit their signature/edits without requiring them
  // to input any additional information like name and email/fax number.
  guestSignAndSubmitRecord = async (file) => {
    const {
      recipientEmail,
      hasResponse,
      signatureRequired,
      recordHasUnsavedEdits,
      sendToken,
      sendReplyToRecord,
    } = this.props;

    if (this.state.unsavedSignatures) {
      this.setState({ unsavedSignatures: false });
      await this.flattenAnnotations(true);
      return;
    }

    // If signature is required and record edits is not present.
    if (signatureRequired && !recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Signature is required before making a reply');
    }

    // If signature is not required and record edits is not present.
    if (!recordHasUnsavedEdits && !hasResponse) {
      return toastr.warning('Please make changes to the document before hitting finish');
    }

    if (hasResponse) {
      return toastr.error("You've already replied to this record.");
    }

    const bodyFormData = new FormData();
    bodyFormData.append('subject', 'Signed Reply');
    bodyFormData.append('from', recipientEmail);
    bodyFormData.append('edited_record', file.get('file'), 'signed_record.pdf');

    await sendReplyToRecord(sendToken, bodyFormData);

    const { replyError, replyErrorMessage } = this.props;
    if (replyError && replyErrorMessage === 'Record already has an associated reply') {
      toastr.error('You have already replied to this record.');
      return;
    }

    if (replyError) {
      toastr.error('There was an issue saving your edits, please try again.');
      return;
    }

    // Make request to BE to securely reply to the record.
    this.setState({
      recordHasReply: true,
    });

    toastr.success('Your edits have been submitted.');
  }

  // Save file to state when `Save Changes` button is hit.
  beginSaveAnnotationsOnRecord = async (file) => {
    const { error, openModal: openMessageModal } = this.props;

    // If saving annonations/signature on record has no error the save the file blob in local state.
    if (!error) {
      const { recordHasReply } = this.state;

      if (recordHasReply) {
        const modalType = {
          type: 'MESSAGE_MODAL',
          message: 'You have already sent a secure reply for this record therefore you cannot make any further changes.',
        };
        return openMessageModal(modalType);
      }
      toastr.success('Changes saved');
      return this.setState({
        recordBlobObject: file,
      });
    }
    toastr.error('Unable to save changes. Please try again');
  }

  updateRecordBlob = (file) => {
    // Updates the file blob saved in state,
    this.setState({
      recordBlobObject: file,
    });
  }

  displayRecord() {
    const {
      recordHasUnsavedEdits,
      setUnsavedAnnotationsOnRecord,
      documentType,
      canEditRecord,
    } = this.props;
    const { source } = this.state;
    let canEdit = canEditRecord;

    if (source === 'api') {
      canEdit = false;
    }
    const buttonName = documentType === 'form' ? 'SUBMIT FORM' : 'FINISH';

    if (this.props.error) {
      let message = 'There was an issue processing your request. Please try again later.';

      if (this.props.errorMessage) {
        message = this.props.errorMessage;
      }

      return (
        <ModalWrapper
          hideModal={this.props.hideModal}
          customContentStyle={customContentStyle}
          primary={true}
          actionName={'OK'}
          action={() => {
            this.context.router.history.push('/');
          }}
        >
          <p><b>{message}</b></p>
        </ModalWrapper>
      );
    }

    return (
      <div>
        <Helmet>
          <title>Record Viewer - Medsender</title>
        </Helmet>
        <div
          style={{ marginLeft: '0' }}
          className="record-viewer"
        >
          <Header
            main="Record Viewer"
            subtext="Search and navigate your guest record"
            locationPath={[
              { icon: 'home', text: 'Home', link: '/login' },
              { icon: 'list', text: 'View record (guest)', link: '' },
            ]}
            buttons={[
              {
                text: 'Download Record',
                icon: <CloudDownloadOutlined />,
                disabled: false,
                func: () => this.downloadRecord(),
              },
              {
                text: 'Print Record',
                icon: <PrinterOutlined />,
                disabled: false,
                func: () => {
                  printedPdfWindow = window.open();
                  this.printRecord();
                },
              },
              {
                text: 'Secure Reply',
                icon: <FormOutlined />,
                disabled: false,
                hide: documentType === 'form' || source === 'api',
                func: () => this.openReplyToModal(),
              },
            ]}
          />
          <Viewer
            documentUrl={this.props.secureSignedURL}
            recordId={this.props.match.params.id}
            canEditRecord={documentType === 'form_submission' ? false : canEdit}
            recordNeedsToBeSaved={recordHasUnsavedEdits}
            setUnsavedEdits={setUnsavedAnnotationsOnRecord}
            updateRecordBlob={this.updateRecordBlob}
            saveAnnotationsOnRecord={this.beginSaveAnnotationsOnRecord}
            guestSignAndSubmitRecord={documentType === 'form' ? this.signAndSubmitForm : this.guestSignAndSubmitRecord}
            saveLoading={this.props.loading}
            saveChangesButtonName={buttonName}
            setClick={click => this.flattenAnnotations = click}
            unsavedSignatures={this.state.unsavedSignatures}
            setUnsavedSignatures={this.setUnsavedSignatures}
            shouldFlatten={this.props.shouldFlatten}
            recipientEmail={this.props.recipientEmail}
            recipientName={this.props.recipientName}
            nameToSignatureRequired={this.props.nameToSignatureRequired}
          />
        </div>
      </div>
    );
  }

  render() {
    const { id, recordId, source } = this.state;
    const {
      secureSignedURL,
      error,
      isLoading,
      getSecureSignedURL: getRecordUrl,
    } = this.props;

    if (secureSignedURL === '' && !error && !isLoading) {
      // Runs only when the component loads for first time.
      getRecordUrl(recordId, id, source);
      // If there is no signed URL and no error, then make a request to get one and display loading screen.
      return (<PageLoading />);
    }

    if (isLoading) {
      return (<PageLoading />);
    }

    return this.displayRecord();
  }
}

RecordViewer.contextTypes = {
  router: PropTypes.object.isRequired,
};

RecordViewer.defaultProps = {
  errorMessage: '',
  canEditRecord: true,
};

RecordViewer.propTypes = {
  secureSignedURL: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  getSecureSignedURL: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  recordHasUnsavedEdits: PropTypes.bool.isRequired,
  signatureRequired: PropTypes.bool.isRequired,
  setUnsavedAnnotationsOnRecord: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  hasResponse: PropTypes.bool.isRequired,
  sendReplyToRecord: PropTypes.func.isRequired,
  replyError: PropTypes.bool.isRequired,
  replyErrorMessage: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
  canEditRecord: PropTypes.bool,
  shouldFlatten: PropTypes.bool.isRequired,
  nameToSignatureRequired: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  loading: state.replies.isLoading,
  secureSignedURL: state.records.secure_signed_url,
  documentType: state.records.documentType,
  subject: state.records.subject,
  recipientEmail: state.records.recipient_email,
  recipientName: state.records.recipient_name,
  sendToken: state.records.send_token,
  error: state.records.error,
  replyError: state.replies.error,
  printUrl: state.records.printUrl,
  errorMessage: state.records.errorMessage,
  replyErrorMessage: state.replies.errorMessage,
  hasResponse: state.records.hasResponse,
  canEditRecord: state.records.canEditRecord,
  recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
  signatureRequired: state.records.signatureRequired,
  shouldFlatten: state.records.shouldFlatten,
  nameToSignatureRequired: state.records.nameToSignatureRequired,
}), {
  getSecureSignedURL,
  hideModal,
  printOrDownloadSecureLink,
  openModal,
  setUnsavedAnnotationsOnRecord,
  sendReplyToRecord,
})(RecordViewer);

export {
  RecordViewer,
};
