const changeInboxPage = newPage => (dispatch) => {
  dispatch({
    type: 'INBOX_PAGE_CHANGE',
    newPage,
  });
};

/* eslint import/prefer-default-export: 0 */
export {
  changeInboxPage,
};
