import React, { Component } from 'react';

import ProviderSwitched from '../../components/modal/ProviderSwitched';

class ProviderSwitchedDialog extends Component {
  render() {
    return (
      <div>
        <ProviderSwitched
          {...this.props}
        />
      </div>
    );
  }
}

export default ProviderSwitchedDialog;
