import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import Divider from 'material-ui/Divider';
import { connect } from 'react-redux';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import { Redirect } from 'react-router-dom';

import { PageLoading } from '../components';
import { createGuestAccount, verifyGuestToken } from '../actions/auth';
import { checkPasswordConfirmation, checkPasswordValidity, validSendEmail } from '../global/validation';

const cardStyle = {
  width: '600px',
  height: '700px',
  position: 'relative',
  left: '50%',
  top: '50%',
  marginLeft: '-300px', // Needs to be half width to center.
  marginTop: '10px',
};

const buttonStyle = {
  width: '260px',
};

class SignUpGuest extends Component {
  constructor(props) {
    super();
    this.state = {
      name: props.receiver_name,
      nameError: '',
      email: localStorage.getItem('m8_guest_email') || '',
      emailError: '',
      currentPassword: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      errorMessage: '',
    };
  }

  componentDidMount() {
    if (this.props.guestAuthenticated !== true && !this.props.loading) {
      if (localStorage.getItem('m8_guest_token')) {
        this.props.verifyGuestToken();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.receiver_name !== '' && this.state.name === '') {
      this.setState({ name: nextProps.receiver_name });
    }

    if (nextProps.errorMessage === 'This email has already been taken.') {
      this.setState({
        emailError: nextProps.errorMessage,
        password: '',
        confirmPassword: '',
      });
    } else if (nextProps.errorMessage !== '') {
      this.setState({
        errorMessage: nextProps.errorMessage,
        password: '',
        confirmPassword: '',
      });
    }
  }

  // Checks if all input is valid. Returns true if they are, false otherwise.
  validInput() {
    const {
      name,
      nameError,
      email,
      emailError,
      password,
      passwordError,
      confirmPassword,
      confirmPasswordError,
    } = this.state;

    // If all inputs have a value.
    if (name !== '' && email !== '' && password !== '' && confirmPassword !== '') {
      return nameError === '' && emailError === '' && passwordError === '' && confirmPasswordError === '';
    }
    return false;
  }

  handleChange = (event) => {
    let nameError = '';
    let emailError = '';
    let passwordError = '';
    let confirmPasswordError = '';

    // Validate full name field
    if (event.target.name === 'fullName') {
      const name = event.target.value;
      if (name.trim() === '') {
        nameError = 'Name cannot be blank';
      }
      this.setState({ nameError, name });
      return;
    }

    // Validate email field
    if (event.target.name === 'email') {
      const isEmailValid = validSendEmail(event.target.value);
      if (!isEmailValid) {
        emailError = 'Email needs to be in proper format';
      }
      this.setState({
        emailError,
        email: event.target.value,
      });
      return;
    }

    // Validate password if it is more than eight characters
    if (event.target.name === 'password') {
      passwordError = checkPasswordValidity(event.target.value);
      confirmPasswordError = checkPasswordConfirmation(event.target.value, this.state.confirmPassword);
    }

    // Validate password if it is equal to the confirmation
    if (event.target.name === 'confirmPassword') {
      confirmPasswordError = checkPasswordConfirmation(this.state.password, event.target.value);
    }

    this.setState({
      [event.target.name]: event.target.value,
      passwordError,
      confirmPasswordError,
    });
  }

  submit = () => {
    const { name, email, password, confirmPassword } = this.state;
    this.props.createGuestAccount(name.trim(), email, password, confirmPassword);
  }

  render() {
    const { loading } = this.props;
    const { nameError, emailError, passwordError, confirmPasswordError, errorMessage } = this.state;

    if (this.props.guestAuthenticated !== true && !loading && !localStorage.getItem('m8_guest_token')) {
      return (<Redirect to="/app/access_code" />);
    }

    return (
      <div
        role="presentation"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            this.submit(e);
          }
        }}
      >
        <Helmet>
          <title>Sign Up - Medsender</title>
        </Helmet>
        { loading ? <PageLoading /> :
        <div>
          <br />
          <Card style={cardStyle}>
            <CardHeader
              title="Create Your Free Medsender Account"
              actAsExpander={false}
              showExpandableButton={false}
              titleStyle={{ fontSize: '25px' }}
            />
            <Divider />
            <CardText expandable={false} style={{ fontSize: '15px' }}>
              Get email notifications when you receive new medical records.
              <br />
              <p style={{ fontSize: '19px' }}>No more access codes. <br /> Sign up below in 30 seconds to get started!</p>
              <div style={{ textAlign: 'center' }}>
                <TextField
                  floatingLabelText="Full Name"
                  name="fullName"
                  hintText="Mary Seacole"
                  value={this.state.name}
                  onChange={this.handleChange}
                  errorText={nameError}
                />
                <br />
                <TextField
                  floatingLabelText="Email Address"
                  value={this.state.email}
                  name="email"
                  hintText="mseacole@bh.com"
                  onChange={this.handleChange}
                  errorText={emailError}
                />
                <br />
                <TextField
                  floatingLabelText="Password"
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  errorText={passwordError}
                />
                <br />
                <TextField
                  floatingLabelText="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  onChange={this.handleChange}
                  errorText={confirmPasswordError}
                />
                <br />
                { errorMessage !== '' ?
                  <p style={{ color: 'red' }}>{errorMessage}</p> : null
                }
                <br />
                <RaisedButton
                  label="Create Your Account"
                  style={buttonStyle}
                  backgroundColor="#15CD72"
                  disabled={!this.validInput()}
                  onClick={this.submit}
                />
                <br />
                <br />
                <RaisedButton
                  label="Cancel"
                  style={buttonStyle}
                  backgroundColor="red"
                  onClick={() => window.history.back()}
                />
              </div>
            </CardText>
          </Card>
        </div>
        }
      </div>
    );
  }
}

SignUpGuest.defaultProps = {
  loading: false,
  errorMessage: '',
};

SignUpGuest.propTypes = {
  createGuestAccount: PropTypes.func.isRequired,
  guestAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  receiver_name: PropTypes.string.isRequired,
  verifyGuestToken: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    guestAuthenticated: state.auth.guestAuthenticated,
    loading: state.auth.isVerifyingLoading,
    errorMessage: state.auth.createAccountErrorMessage,
    receiver_name: state.auth.receiver_name,
  }), { createGuestAccount, verifyGuestToken },
)(SignUpGuest);

export { SignUpGuest };
