import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Notify from '../../components/modal/Notify';
import { editReceivedRecordTag } from '../../actions/records';
import { getLabelsByPage } from '../../actions/labels';
import { rowSelected } from '../../actions/inboxTableActions';
import { getOrganizationDepartments } from '../../actions/userProfile';

class NotifyDialog extends Component {
  static propTypes = {
    editReceivedRecordTag: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <Notify
          {...this.props}
          editTag={this.props.editReceivedRecordTag}
        />
      </div>
    );
  }
}

export default connect(null, {
  rowSelected,
  editReceivedRecordTag,
  getLabelsByPage,
  getOrganizationDepartments,
})(NotifyDialog);
