import React, { Component } from 'react';
import { connect } from 'react-redux';

import UpdateReferral from '../../components/modal/UpdateReferral';
import { getReferredStatuses } from '../../actions/userProfile';

class UpdateReferralDialog extends Component {
  render() {
    return (
      <div>
        <UpdateReferral
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null, {
  getReferredStatuses,
})(UpdateReferralDialog);
