import {
  ChromeExtensionButton,
  ChromeInstallWindow,
} from '../../../assets/img/guide';

const chromeExtensionCopy = {
  page: 'chrome_extensions',
  pageTitle: 'Medsender Chrome Extensions',
  pageSubtitle: `
    Medsender currently integrates with 9 major EMRs. Install our chrome extension to facilitate easy sending and 
    uploading directly from Medsender to your EMR. No more going back and forth or worrying about if you've received 
    your faxes. Here's a list of EMRs we currently integrate with.`,
  sections: [
    {
      subtitle: `Don't see yours on the list? Email us at support@medsender.com to let us know which EMR we should 
      integrate with next!`,
    },
    {
      title: 'Installing our Chrome Extension',
      id: 'installExtension',
      subtitle: `When you sign into Medsender for the first time on a new computer, you need to install the 
      Medsender Chrome extension to see the Medsender options inside of your EMR system.
      `,
      img: ChromeExtensionButton,
    },
    {
      subtitle: `To download the Medsender Chrome Extension, ensure you're on the Chrome Browser and then 
      navigate to the Settings page. From here, click the blue button on the lower right corner that says "Install Chrome Extension"`,
      img: ChromeInstallWindow,
    },
    {
      subtitle: `A popup will open. Click "Add to Chrome" and the extension will be installed on your browser. 
      If the blue button is not present on the page, check (1) if you are currently in Chrome, or (2) if you already have the extension installed. 
      You can click the puzzle icon on the top right corner of Chrome to see what extensions you have installed.`,
    },
    {
      title: 'Using our Chrome Extension',
      id: 'usingExtension',
      subtitle: `From here, navigate to your EMR and try sending and/or uploading documents as usual. Our integrations vary depending on the 
      EMR so if you need help finding where Medsender is integrated within your EMR please reach out to us!`,
    },
  ],
};

export default chromeExtensionCopy;
