import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import ModalWrapper from './ModalWrapper';
import Loading from './ModalLoading';
import { formatValidFaxNumber } from '../../global/validation';

const customContentStyle = {
  width: '50%',
  textAlign: 'left',
  disabled: 'true',
};

class AddFaxNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaCodes: [],
      value: 202,
    };
  }

  componentDidMount() {
    const { fetchAreaCodes } = this.props;
    fetchAreaCodes();
  }

  componentWillReceiveProps(nextProps) {
    const { areaCodes } = this.props;
    if (nextProps.areaCodes !== areaCodes) {
      this.setState({
        areaCodes: nextProps.areaCodes,
        value: nextProps.areaCodes[0].code,
      });
    }
  }

  handleChange = (event, index, value) => this.setState({ value });

  handleSubmit = async () => {
    const { value } = this.state;
    const { addNewFaxNumber } = this.props;
    const number = {
      fax_number: { area_code: value },
    };
    await addNewFaxNumber(number);
    const { newFaxNumber, updateAssociatedFaxNumber, errorStatus } = this.props;
    if (!errorStatus.error) {
      const faxNumber = {
        organization: localStorage.getItem('m8_org'),
        number: newFaxNumber.number,
      };
      const currentAssociatedFaxNumbers = JSON.parse(localStorage.getItem('associated_fax_numbers'));

      localStorage.setItem('associated_fax_numbers', JSON.stringify([...currentAssociatedFaxNumbers, [faxNumber.number, faxNumber.organization]]));
      updateAssociatedFaxNumber(faxNumber);
    }
  }

  renderDismissButton = (isLoading, newGeneratedFaxNumber) => {
    if (isLoading) return '';
    // If fax number has been created display ok button otherwise show Cancel Button
    return newGeneratedFaxNumber ? 'OK' : 'Cancel';
  }

  hideAddFaxNumberModal = async () => {
    const { hideModal, modalType, updateWalkthroughStep } = this.props;
    if (modalType.data) {
      const stepOnWalkthrough = modalType.data.userIsOnWalkthrough ? 'Done' : 'Welcome to Medsender!';
      await updateWalkthroughStep(stepOnWalkthrough);
    }
    return hideModal();
  }

  getErrorMessage = (errorMessage) => {
    if (errorMessage?.includes('unavailable')) {
      return (
        <>
          The selected area code is currently unavailable, please try again with another area code or
          {' '}
          <a href="https://www.medsender.com/support">contact us</a>
          .
        </>
      );
    }
    return 'Something went wrong and we weren\'t able to assign a fax number. Please try again or contact support for help if the issue persists.';
  }

  renderModalText() {
    const { areaCodes, value } = this.state;
    const {
      isLoading,
      newFaxNumber,
      errorStatus,
    } = this.props;

    const { number: newGeneratedFaxNumber } = newFaxNumber;
    const status = 'Getting a new fax number for you! Please wait...';
    if (isLoading) return (<Loading status={status} />);
    if (newGeneratedFaxNumber) {
      return (
        <>
          <br />
          <p>
            We&apos;ve successfully created and linked a new fax number to your account. Your
            new number is&nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {formatValidFaxNumber(newFaxNumber.number)}
              { /* eslint-disable-next-line react/jsx-one-expression-per-line */}
            </span>. All faxes sent to this number will be available in your received records.
          </p>
        </>
      );
    } return (
      <>
        Your Medsender plan includes one fax number. Pick an area code
        that works for you. This number will then be yours and all faxes
        sent to this number will be available in Medsender.
        <br />
        <br />
        The number will be linked to your organization, so all users will
        be able to access faxes by default.
        <SelectField
          floatingLabelText="Select Area Code"
          value={value}
          onChange={this.handleChange}
          autoWidth={true}
          fullWidth={true}
        >
          {
            areaCodes.map(({ code, area }) => (
              <MenuItem
                key={code}
                value={code}
                primaryText={area}
              />
            ))
          }
        </SelectField>
        <span style={{ fontSize: '.8em' }}>
          Fax numbers can also be linked to a particular department or specific user.
          We can help set this up for you, just&nbsp;
          <a href="mailto:support@medsender.com?subject=Link my fax number">
            contact us.
          </a>
        </span>
        {
          errorStatus.error && (
          <p style={{ color: 'red' }}>
            {this.getErrorMessage(errorStatus.errorMessage)}
          </p>
          )
        }
      </>
    );
  }

  renderGetFaxNumber(modalProps) {
    return (
      <ModalWrapper {...modalProps}>
        {this.renderModalText()}
      </ModalWrapper>
    );
  }

  renderError(modalProps) {
    const href = 'mailto:support@medsender.com?subject=Provision a new fax number';
    return (
      <ModalWrapper {...modalProps}>
        <p>
          It looks we can&apos;t help you automatically add a new fax number to your account&nbsp;
          { /* eslint-disable-next-line react/jsx-one-expression-per-line */}
          at this time. Contact us at <a href={href}>support@medsender.com</a> so we can
          help you with this manually.
          <br />
          <br />
          Our sincere apologies for any inconvenience this may cause.
        </p>
      </ModalWrapper>
    );
  }

  render() {
    const {
      billingOnFile,
      isLoading,
      newFaxNumber,
      errorStatus,
      isTrialUser,
    } = this.props;
    const { number: newGeneratedFaxNumber } = newFaxNumber;
    const displayContent = !isLoading && !newGeneratedFaxNumber && !errorStatus.error;
    const notEligibleForFaxNumber = isTrialUser ? false : !billingOnFile;
    // Do not allow users to submit form while these conditions are true
    const hideActionButton = newGeneratedFaxNumber || notEligibleForFaxNumber;

    const modalProps = {
      hideModal: this.hideAddFaxNumberModal,
      customContentStyle,
      action: this.handleSubmit,
      dismiss: this.renderDismissButton(isLoading, newGeneratedFaxNumber),
      actionName: hideActionButton ? '' : 'Add Fax Number',
      modalTitle: 'Get a New Fax Number',
      disabled: isLoading,
    };

    const cannotAddFaxNumber = displayContent && notEligibleForFaxNumber && !isTrialUser;
    if (cannotAddFaxNumber) return this.renderError(modalProps);

    return this.renderGetFaxNumber(modalProps);
  }
}

AddFaxNumber.propTypes = {
  addNewFaxNumber: PropTypes.func.isRequired,
  areaCodes: PropTypes.array.isRequired,
  billingOnFile: PropTypes.bool.isRequired,
  isTrialUser: PropTypes.bool.isRequired,
  errorStatus: PropTypes.shape({
    errorMessage: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  fetchAreaCodes: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  newFaxNumber: PropTypes.shape({
    number: PropTypes.string.isRequired,
  }).isRequired,
  modalType: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  updateAssociatedFaxNumber: PropTypes.func.isRequired,
  updateWalkthroughStep: PropTypes.func.isRequired,
};

export default AddFaxNumber;
