/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';
import {
  Table,
  Button,
  Tooltip,
  Input,
  Popconfirm,
  Spin,
  message,
} from 'antd';
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { verifyFeatureAccess } from '../../../global/featureFlags';

const { Search } = Input;

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ManageProviders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emaTaskProviders: null,
      currentPage: 1,
      selectedProvider: '',
      searchValue: '',
      canManageProvider: false,
    };
  }

  componentDidMount = async (page = 1) => {
    const { getTaskProviders, getUserInfo } = this.props;
    await getUserInfo();
    const { availableEmrs } = this.props;
    this.setState({ availableEmrs });
    const params = {};
    params.page = page;
    const query = stringify(params, stringifyOptions);
    const canManageProvider = await verifyFeatureAccess('manage_providers');
    await getTaskProviders(query);
    this.setState({
      emaTaskProviders: this.props.allEmaTaskProviders.providers,
      canManageProvider,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      emaTaskProviders: nextProps.allEmaTaskProviders.providers,
    });
  }

  getQueryData = async (page = 1) => {
    const { location, headers, getTaskProviders } = this.props;
    const totalPages = (headers ? parseInt(headers.total_count, 10) : 1);
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
      // eslint-disable-next-line no-param-reassign
      page = parseInt((params.page <= totalPages ? params.page : 1), 10);
    }
    params.page = page;
    const query = stringify(params, stringifyOptions);
    await getTaskProviders(query);
    this.setState({
      emaTaskProviders: this.props.allEmaTaskProviders.providers,
    });
  }

  openEditProviderModal = (provider) => {
    const {
      openModal,
      isAdmin,
    } = this.props;
    const { availableEmrs } = this.state;
    availableEmrs.sort((a, b) => (a.name > b.name ? 1 : -1));
    const modalType = {
      type: 'ADD_TASK_PROVIDER',
      data: {
        provider,
        isAdmin,
        availableEmrs,
      },
    };
    return openModal(modalType);
  }

  handleTableChange = (pagination) => {
    const { current: page } = pagination;
    const searchValue = '';
    this.setState({
      searchValue,
    });
    this.setState({
      currentPage: page,
    });
    const { getTaskProvidersByQuery: fetchProviders } = this.props;
    const query = `?page=${page}&contains=${searchValue}`;
    fetchProviders(query);
  };

  searchProviders = (value) => {
    const { paramTools, getTaskProvidersByQuery } = this.props;
    const { params } = paramTools;
    if (params.contains === '' || value === '') {
      delete params.contains;
      params.page = 1;
    } else {
      params.contains = value;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    this.setState({ searchValue: value, currentPage: 1 });
    const emptySearch = (value.trim() === '');
    if (emptySearch) return getTaskProvidersByQuery('');
    getTaskProvidersByQuery(query);
  }

  deleteTaskProvider = async (provider) => {
    const { deleteTaskProvider } = this.props;
    await deleteTaskProvider(provider.id);
    const { error, errorCode } = this.props;
    if (error && errorCode === 403) {
      message.error('Cannot delete Task Provider. You need permission to perform this action');
    }

    this.getQueryData();
    window.location.reload();
  }

  handlePageChange = async (page) => {
    const { getTaskProvidersByQuery: fetchProviders } = this.props;
    const { searchValue } = this.state;
    this.setState({
      currentPage: page,
    });
    const query = `?page=${page}&contains=${searchValue}`;
    await fetchProviders(query);
  };

  render() {
    const {
      emaTaskProviders,
      currentPage,
      selectedProvider,
      searchValue,
      canManageProvider,
      availableEmrs,
    } = this.state;
    const isEditing = provider => provider.task_name === selectedProvider;
    const {
      headers,
      isLoading,
      isAdmin,
    } = this.props;
    const totalResults = headers ? headers.total_count : 1;
    const columns = [
      {
        title: (
          <Tooltip title="Name of your provider that displays on Send to Chart and Create Task Modals.">
            Task Provider Name
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'name',
        dataIndex: 'name',
      },
      {
        title: (
          <Tooltip title="Designation of provider as part of Staff Group or User Group.">
            Task Provider Type
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'provider_type',
        dataIndex: 'provider_type',
      },
      {
        title: (
          <Tooltip title="Active Providers only display on Send to Chart and Create Task Modals.">
            Status
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'is_active',
        dataIndex: 'is_active',
      },
      {
        title: (
          <Tooltip title="Provider that is assigned to this EMR Credential Name. Providers assigned at the Organization level are available when no Emr Credential is assigned to the Organization.">
            Emr Credential
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'emr_credential_name',
        dataIndex: 'emr_credential_name',
        hidden: availableEmrs ? availableEmrs.length <= 2 : false,
      },
      {
        title: '',
        key: 'edit',
        align: 'left',
        render: (_, provider) => {
          const editable = isEditing(provider);
          return editable ? (
            <span>
              <Button
                // eslint-disable-next-line no-script-url
                href="javascript:;"
                onClick={() => this.save(provider)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Button>
              <Popconfirm title="Cancel edit without saving?" onConfirm={() => this.setState({ selectedProvider: '' })}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              {}
              {
                ((isAdmin && canManageProvider))
                && (
                <Button disabled={selectedProvider !== ''} onClick={() => this.openEditProviderModal(provider)} style={{ marginRight: 10 }}>
                  Edit
                </Button>
                )
              }
              {}
              {
              ((isAdmin))
                && (
                <Popconfirm title="Delete this Provider? (This action is permanent and cannot be reversed. " onConfirm={() => this.deleteTaskProvider(provider)}>
                  <a>Delete</a>
                </Popconfirm>
                )
              }
            </span>
          );
        },
      },
    ].filter(column => !column.hidden);

    if (isLoading) return (<Spin size="large" />);

    return (
      // eslint-disable-next-line object-curly-newline
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <h2>Manage Task Providers</h2>
            {((canManageProvider && isAdmin) || !canManageProvider)
              ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={this.openEditProviderModal}
                >
                  Add Task Provider
                </Button>
              )
              : (
                <p />
              )
            }
          </div>
          <div>
            <Search
              placeholder="search providers"
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
              onSearch={this.searchProviders}
              style={{ width: 200, height: '32px', marginRight: '10px' }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            dataSource={emaTaskProviders}
            columns={columns}
            rowKey={record => record.id}
            bordered
            onChange={this.handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              total: totalResults,
              current: currentPage,
            }}
          />
        </div>
      </div>
    );
  }
}

ManageProviders.defaultProps = {
  isLoading: false,
  allEmaTaskProviders: {},
  availableEmrs: [],
  headers: {
    page: 1,
    'per-page': '10',
    total_count: 1,
  },
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  errorCode: '',
};

ManageProviders.propTypes = {
  isLoading: PropTypes.bool,
  headers: PropTypes.shape({
    page: PropTypes.number,
    'per-page': PropTypes.string,
    total_count: PropTypes.number,
  }),
  getTaskProviders: PropTypes.func.isRequired,
  paramTools: PropTypes.shape({
    params: PropTypes.object,
    stringify: PropTypes.func,
    stringifyOptions: PropTypes.object,
    push: PropTypes.func,
  }),
  location: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  deleteTaskProvider: PropTypes.func.isRequired,
  getTaskProvidersByQuery: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  allEmaTaskProviders: PropTypes.object,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  availableEmrs: PropTypes.array,
};

export default connect(state => ({
  allEmaTaskProviders: state.userProfile.allEmaTaskProviders,
  emaTaskProviders: state.auth.data,
  isLoading: state.userProfile.isLoading,
  isAdmin: state.userProfile.isAdmin,
  headers: state.userProfile.headers,
  error: state.userProfile.error,
}))(ManageProviders);
