// Shows modal for asking the user to logout
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

class MessageModal extends Component {
  render() {
    const customContentStyle = {
      width: '37%',
    };

    const { hideModal, modalType: { message } } = this.props;

    return (
      <ModalWrapper
        hideModal={hideModal}
        primary={true}
        customContentStyle={customContentStyle}
        dismiss="OK"
      >
        <p><b>{message}</b></p>
      </ModalWrapper>
    );
  }
}
MessageModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
};

export default MessageModal;
