import {
  ManageLabels,
  AddLabel,
} from '../../../assets/img/guide';

const addingTaggingCopy = {
  pageTitle: 'Labeling a Record',
  pageSubtitle: `
    Medsender allows you to tag received records with relevant information or to notify a 
    specific user within your organization.
  `,
  sections: [
    {
      title: 'Managing your Labels',
      id: 'manageLabels',
      subtitle: `Navigate to settings and find the "Manage Labels" tab. 
      From here, you can edit, add, or delete labels.`,
      img: ManageLabels,
    },
    {
      title: 'Labeling a Record',
      id: 'labelRecord',
      subtitle: `Within your received records page, select the record you wish to tag. 
      Hit "Add/Edit Label" in the toolbar. in the popup, select up to five labels from your label 
      library to associate with the record.`,
    },
    {
      title: 'Notifying a User',
      id: 'notifyUser',
      subtitle: `To notify a user about a record, select a record and hit the bell Notify button in 
      the toolbar. Input their information in the pop up and they will get an email notification about 
      the record.`,
      img: AddLabel,
    },
  ],
};

export default addingTaggingCopy;
