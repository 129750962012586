const initialState = {
  results: [],
  isLoading: false,
  errorStatus: {
    error: false,
    errorMessage: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_GET_SEARCH_RESULTS': {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case 'FINISHED_GET_SEARCH_RESULTS': {
      return {
        ...state,
        isLoading: false,
        results: action.payload.data.results,
      };
    }
    case 'FAILED_GET_SEARCH_RESULTS': {
      return {
        ...state,
        isLoading: false,
        errorStatus: {
          error: true,
          errorMessage: action.payload,
        },
      };
    }
    // When user navigates between pages, make sure to not persist selected data
    // between the different record pages (i.e. from sent to received)
    case '@@router/LOCATION_CHANGE': {
      return initialState;
    }
    default:
      return state;
  }
};
