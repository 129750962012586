import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddLabelToRecord from '../../components/modal/AddLabelToRecord';
import { editReceivedRecordLabels } from '../../actions/records';
import { getLabelsByPage, getLabelsByQuery } from '../../actions/labels';
import { rowSelected } from '../../actions/inboxTableActions';

class AddLabelToRecordDialog extends Component {
  static propTypes = {
    editReceivedRecordLabels: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddLabelToRecord
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null, {
  rowSelected,
  getLabelsByPage,
  getLabelsByQuery,
  editReceivedRecordLabels,
})(AddLabelToRecordDialog);
