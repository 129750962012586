/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';
import {
  Tag,
  Table,
  Button,
  Tooltip,
  Input,
  Popconfirm,
  Spin,
  message,
} from 'antd';
import { PlusCircleOutlined, CheckCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { verifyFeatureAccess } from '../../../global/featureFlags';

const { Search } = Input;

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ManageLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: null,
      currentPage: 1,
      selectedLabel: '',
      searchValue: '',
      canManageLabel: false,
      isDepartmentLabelsEnabled: false,
    };
  }

  componentDidMount = async (page = 1) => {
    const { getLabelsByPage, getUserInfo } = this.props;
    await getUserInfo();
    const params = {};
    params.page = page;
    const query = stringify(params, stringifyOptions);
    const canManageLabel = await verifyFeatureAccess('manage_label');
    const isDepartmentLabelsEnabled = await verifyFeatureAccess('department_labels');
    await getLabelsByPage(query);
    const { data } = this.props;
    this.setState({
      labels: data,
      canManageLabel,
      isDepartmentLabelsEnabled,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      labels: nextProps.data,
    });
  }

  getQueryData = async (page = 1) => {
    const { location, headers, getLabelsByPage } = this.props;
    const totalPages = (headers ? parseInt(headers.total_count, 10) : 1);
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
      page = parseInt((params.page <= totalPages ? params.page : 1), 10);
    }
    params.page = page;
    const query = stringify(params, stringifyOptions);
    await getLabelsByPage(query);
    const { data } = this.props;
    this.setState({ labels: data });
  }

  openAddLabelModal = (label) => {
    const {
      openModal,
      isAutoLabelingEnabled,
      isAdmin,
      departments: userDepartments,
    } = this.props;
    const { isDepartmentLabelsEnabled } = this.state;
    const modalType = {
      type: 'ADD_LABEL',
      data: {
        label,
        isAutoLabelingEnabled,
        isDepartmentLabelsEnabled,
        isAdmin,
        userDepartments,
      },
    };
    return openModal(modalType);
  }

  handleTableChange = (pagination, filters) => {
    const { current: page } = pagination;
    const { department } = filters;
    let searchValue = '';
    if (department && department.length > 0) {
      searchValue = department[0] !== 'all' ? department[0] : '';
      this.setState({
        searchValue,
      });
    }
    this.setState({
      currentPage: page,
    });
    const { getLabelsByQuery: fetchLabels } = this.props;
    const query = `?page=${page}&contains=${searchValue}`;
    fetchLabels(query);
  };

  searchLabels = (value) => {
    const { paramTools, getLabelsByQuery } = this.props;
    const { params } = paramTools;
    if (params.contains === '' || value === '') {
      delete params.contains;
      params.page = 1;
    } else {
      params.contains = value;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    this.setState({ searchValue: value, currentPage: 1 });
    const emptySearch = (value.trim() === '');
    if (emptySearch) return getLabelsByQuery('');
    getLabelsByQuery(query);
  }

  deleteLabel = async (label) => {
    const { deleteLabel } = this.props;
    await deleteLabel(label.id);
    const { error, errorCode } = this.props;
    if (error && errorCode === 403) {
      message.error('Cannot delete label. You need permission to perform this action');
    }

    this.getQueryData();
  }

  handlePageChange = async (page) => {
    const { getLabelsByPage, getLabelsByQuery: fetchLabels } = this.props;
    const { searchValue } = this.state;
    this.setState({
      currentPage: page,
    });
    const query = `?page=${page}&contains=${searchValue}`;
    await fetchLabels(query);
  };

  render() {
    const {
      labels,
      currentPage,
      selectedLabel,
      searchValue,
      canManageLabel,
      isDepartmentLabelsEnabled,
    } = this.state;
    const isEditing = label => label.name === selectedLabel;
    const {
      headers,
      isLoading,
      isAdmin,
      departments: userDepartments,
    } = this.props;
    const AllDepartments = [{ name: 'All departments' }, ...userDepartments];
    const totalResults = headers ? headers.total_count : 1;
    let columns = [
      {
        title: 'Labels',
        dataIndex: 'name',
        key: 'name',
        render: (label) => {
          const labelIndex = labels.map(labelObject => labelObject.name).indexOf(label);
          const labelTextColor = labels[labelIndex].color;
          const labelBackgroundColor = labels[labelIndex].is_dark_text ? 'black' : 'white';
          return (
            <Tag color={labelTextColor} style={{ color: labelBackgroundColor }} key={label}>
              {label}
            </Tag>
          );
        },
      },
      {
        title: (
          <Tooltip title="Associate additional words with this label so documents containing these words will also be labeled with this when autolabeled.">
            Positive Keywords
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>
        ),
        key: 'positive_keywords',
        dataIndex: 'positive_keywords',
        render: keyword => keyword.map(word => <Tag color="blue">{word}</Tag>),
      },
      {
        title: (
          <Tooltip title="Use negative key words to prevent documents containing these words from being automatically labeled with this label. Helpful to prevent mislabeling records.">
            Negative Keywords
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>
        ),
        key: 'negative_keywords',
        dataIndex: 'negative_keywords',
        render: keyword => keyword.map(word => <Tag color="red">{word}</Tag>),
      },
      {
        title: (
          <Tooltip title="These labels will be used to autolabel records when they are received">
            Autolabel
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'auto_label',
        dataIndex: 'auto_label',
        render: autolabel => <span>{autolabel && <CheckCircleFilled theme="twoTone" twoToneColor="#52c41a" />}</span>,
      },
      {
        title: 'Department',
        key: 'department',
        dataIndex: 'department',
        filters: AllDepartments.map(department => ({
          value: department.name === 'All departments' ? 'all' : department.name,
          text: department.name,
        })),
        filterMultiple: false,
      },
      {
        title: '',
        key: 'edit',
        align: 'left',
        render: (_, label) => {
          const editable = isEditing(label);
          return editable ? (
            <span>
              <Button
                // eslint-disable-next-line no-script-url
                href="javascript:;"
                onClick={() => this.save(label)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Button>
              <Popconfirm title="Cancel edit without saving?" onConfirm={() => this.setState({ selectedLabel: '' })}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Button disabled={selectedLabel !== ''} onClick={() => this.openAddLabelModal(label)} style={{ marginRight: 10 }}>
                Edit
              </Button>
              {}
              {
                // if is admin and can manage labels then show delete button
                // if cannot manage labels show delete button
                ((canManageLabel && isAdmin) || !canManageLabel)
                && (
                  <Popconfirm title="Delete this Label? (This action is permanent and cannot be reversed)" onConfirm={() => this.deleteLabel(label)}>
                    <a>Delete</a>
                  </Popconfirm>
                )
              }
            </span>
          );
        },
      },
    ];

    if (isLoading) return (<Spin size="large" />);
    columns = columns.filter(column => column.dataIndex !== 'department' || isDepartmentLabelsEnabled);

    return (
      // eslint-disable-next-line object-curly-newline
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <h2>Manage Labels</h2>
            {((canManageLabel && isAdmin) || !canManageLabel)
              ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={this.openAddLabelModal}
                >
                  Add Label
                </Button>
              )
              : (
                <p />
              )
            }
          </div>
          <div>
            <Search
              placeholder="search labels"
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
              onSearch={this.searchLabels}
              style={{ width: 200, height: '32px', marginRight: '10px' }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            dataSource={labels}
            columns={columns}
            rowKey={record => record.id}
            bordered
            onChange={this.handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              total: totalResults,
              current: currentPage,
            }}
          />
        </div>
      </div>
    );
  }
}

ManageLabels.defaultProps = {
  isLoading: false,
  data: [],
  headers: {
    page: 1,
    'per-page': '10',
    total_count: 1,
  },
  departments: [],
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  errorCode: '',
};

ManageLabels.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  headers: PropTypes.shape({
    page: PropTypes.number,
    'per-page': PropTypes.string,
    total_count: PropTypes.number,
  }),
  getLabelsByPage: PropTypes.func.isRequired,
  paramTools: PropTypes.shape({
    params: PropTypes.object,
    stringify: PropTypes.func,
    stringifyOptions: PropTypes.object,
    push: PropTypes.func,
  }),
  location: PropTypes.object.isRequired,
  deleteLabel: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  departments: PropTypes.array,
};

export default connect(state => ({
  data: state.labels.data,
  isLoading: state.labels.isLoading,
  isAdmin: state.userProfile.isAdmin,
  headers: state.labels.headers,
  error: state.labels.error,
  errorCode: state.labels.errorCode,
}))(ManageLabels);
