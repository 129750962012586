// Edit record component displays the modal content for updating a record.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, message } from 'antd';

import ModalWrapper from './ModalWrapper';

class SecureLink extends Component {
  copy = (event) => {
    this.textArea.select();
    document.execCommand('copy');
    event.target.focus();
    message.success({
      content: 'Link copied',
    });
  }

  modalMessage = () => {
    const {
      secureLink,
      isLoading,
      error,
      errorMessage,
      modalType: {
        data: {
          isFormDocument,
        },
      },
    } = this.props;

    if (error) {
      const message = errorMessage !== '' ? errorMessage : 'There was an issue getting the secure link. Please try again.';
      return (
        <div>{message}</div>
      );
    }

    if (!secureLink) {
      if (isLoading) {
        // If there is no secure link, but dialog is loading, notify user that we're retrieving the
        // link.
        return (
          <div>
            Loading... please wait.
          </div>
        );
      }

      // Otherwise, simply display that they don't have access to this feature if response is 403
      return (
        <div>
          You do not have access to this feature. Please contact Medsender for further information.
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p>
          {
            isFormDocument
              ? 'Copy the public link to the form and add it to your website or share it with your patients'
              : 'Copy the secure link and share it with others within your organization.'
          }
        </p>
        <Input
          value={secureLink}
          ref={(text) => { this.textArea = text; }}
          onClick={(e) => { e.target.select(); }}
          onFocus={(e) => { e.target.select(); }}
        />
        <br />
        <Button
          style={{ margin: '0 auto' }}
          onClick={this.copy}
          type="primary"
        >
          Copy Link
        </Button>
      </div>
    );
  }

  render() {
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={this.props.hideModal}
        width={800}
        customContentStyle={customContentStyle}
        dismiss={'Close'}
        modalTitle="Copy Secure Link"
      >
        {this.modalMessage()}
      </ModalWrapper>
    );
  }
}

SecureLink.defaultProps = {
  errorMessage: '',
};

SecureLink.propTypes = {
  hideModal: PropTypes.func.isRequired,
  secureLink: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  modalType: PropTypes.shape({
    data: PropTypes.shape({
      isFormDocument: PropTypes.bool.isRequired,
    }),
  }).isRequired,
};

export default SecureLink;
