// Passes the accessedPatient and accessedResetPassword as props from the global state
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LogoutUser from '../../components/modal/LogoutUser';

class LogoutUserDialog extends Component {
  render() {
    return (
      <LogoutUser {...this.props} />
    );
  }
}

export default connect(state => ({
  accessedPatient: state.auth.accessedPatient,
  accessedResetPassword: state.auth.accessedResetPassword,
}))(LogoutUserDialog);
