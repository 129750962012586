import React from 'react';
import LinearProgress from 'material-ui/LinearProgress';

const LoginLoader = () => {
  return (
    <LinearProgress color="rgb(0, 188, 212)" mode="indeterminate" />
  );
};

export default LoginLoader;
