import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
  Alert,
  Checkbox,
} from 'antd';

import ModalWrapper from '../../modal/ModalWrapper';
import { validSendEmail } from '../../../global/validation';

// Custom style for the modal
const customContentStyle = {
  width: '35%',
  disabled: 'true',
};

const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 16,
  },
};

class AddUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainMatches: true,
      ignoreDomain: false,
      userInitialInputEmail: '',
      email: '',
      name: '',
      notifications: true,
      skipWalkthrough: false,
      dontSendWelcomeEmail: false,
    };
  }

  componentDidMount() {
    // clearing the old error and success messages to allow users to add users consecutively without refreshing the page
    // eslint-disable-next-line react/destructuring-assignment
    this.props.clearAddUserModal();
    this.setState({
      ignoreDomain: false,
    });
  }

  componentWillUnmount() {
    this.props.clearAddUserModal();
  }

  componentWillReceiveProps(nextProps) {
    const newUserCreated = nextProps.newUser && nextProps.newUser.email;
    if (newUserCreated) setTimeout(() => this.closeModal(), 2000);
    // resets ignoreDomain so the check for different domains is made when user tries to submit again
    if (nextProps.modalErrorStatus.error) this.setState({ ignoreDomain: false });
  }

  handleSubmit = async () => {
    const allowedDomains = JSON.parse(localStorage.getItem('allowed_user_domains'));
    const {
      name,
      email,
      ignoreDomain,
      notifications,
      skipWalkthrough,
      dontSendWelcomeEmail,
    } = this.state;
    const { submit } = this.props;
    const validInput = validSendEmail(email) && name && name.length > 1;

    if (!validInput) return;

    const proposedUserDomain = email.trim().split('@')[1];
    const proposedUserDomainAllowed = allowedDomains && allowedDomains.includes(proposedUserDomain);

    const proposedDomainNoMatch = !proposedUserDomainAllowed && !ignoreDomain;

    // sets userInitialInputEmail to track if user is changing email field in between hitting the submit button
    if (proposedDomainNoMatch) {
      return this.setState({
        domainMatches: false,
        userInitialInputEmail: email,
      });
    }

    const user = { email, name, notification_fax_email: notifications, has_completed_walkthrough: skipWalkthrough, dont_send_welcome_email: dontSendWelcomeEmail };
    await submit(user);
    const { modalErrorStatus: { error } } = this.props;
    if (error) {
      return this.setState({
        name: '',
        email: '',
        notifications: true,
        skipWalkthrough: true,
        dontSendWelcomeEmail: false
      });
    }
  }

  handleIgnoreDomain = async () => {
    const { userInitialInputEmail, email } = this.state;
    // checks to see if user has changed email input since first submission
    // if changed, error message warning user domains are not the same will remain
    // if not, clicking submit a second time will allow user to submit create user request
    const userHasInputNewEmail = userInitialInputEmail !== email;
    if (!userHasInputNewEmail) {
      await this.setState({
        ignoreDomain: true,
      });
    }
    this.handleSubmit();
  }

  onFinish = (values) => {
    this.props.clearAddUserModal();

    this.handleSubmit(values);
  };

  handleFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleCheckedNotifs = (event) => {
    this.setState({
      notifications: event.target.checked,
    });
  }

  handleCheckedWalkthrough = (event) => {
    this.setState({
      skipWalkthrough: event.target.checked,
    });
  }

  handleWelcomeEmail = (event) => {
    this.setState({
      dontSendWelcomeEmail: event.target.checked,
    });
  }

  closeModal = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.show();
  }

  addUserForm = () => {
    const { modalErrorStatus: { error, errorMessage }, modalLoading, newUser } = this.props;
    const { domainMatches } = this.state;
    const newUserCreated = newUser.name && newUser.email;
    // error is true when user tries to add a new user with same email as existing user. This ensures the error
    // message and domain doesn't match message don't show at the same time to prevent confusing user with 2 errors
    const newUserWithDifferentDomain = !domainMatches && !error;
    return (
      <div>
        {modalLoading
          ? <p> Creating user. Please wait ... </p>
          : (
            <>
              <p style={{ marginBottom: '10px' }}>
                Enter the details of the user you&apos;d like to invite to your organization. Upon
                submission, they will receive an email to create their password.
              </p>
              <Form {...layout} id="AddUsers" name="control-ref" onFinish={this.onFinish}>
                <Form.Item
                  hasFeedback
                  name="email"
                  label="Email Address"
                  required
                  rules={[
                    {
                      validator: (_, value) => {
                        return validSendEmail(value)
                          ? Promise.resolve()
                          : Promise.reject('Invalid email address');
                      },
                    },
                  ]}
                >
                  <Input name="email" placeholder="email" onChange={this.handleFieldChange} />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  name="name"
                  label="Full Name"
                  required
                  rules={[
                    {
                      validator: (_, name) => {
                        if (name && name.trim() !== '' && name.length > 1) return Promise.resolve();
                        return Promise.reject('Invalid name');
                      },
                    },
                  ]}
                >
                  <Input name="name" placeholder="name" onChange={this.handleFieldChange} />
                </Form.Item>
                <Form.Item
                  name="notifications"
                  required
                  wrapperCol={{ offset: 7 }}
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Checkbox defaultChecked={true} name="notifications" placeholder="notifications" onChange={this.handleCheckedNotifs}>Enable Email Notifications</Checkbox>
                </Form.Item>
                <Form.Item
                  name="skip-walkthrough"
                  required
                  wrapperCol={{ offset: 7 }}
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox defaultChecked={false} name="skip-walkthough" placeholder="skip-walkthrough" onChange={this.handleCheckedWalkthrough}>Skip Walkthrough on Initial Sign In</Checkbox>
                </Form.Item>
                <Form.Item
                  name="welcome-email"
                  required
                  wrapperCol={{ offset: 7 }}
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox defaultChecked={true} name="welcome-email" placeholder="welcome-email" onChange={this.handleWelcomeEmail}>Don't Send Welcome Email</Checkbox>
                </Form.Item>
              </Form>
              { error && <Alert message={errorMessage} type="error" showIcon /> }
              {newUserWithDifferentDomain && !newUserCreated && (
                <p style={{ color: 'red' }}>
                  Domain does not match. Are you sure you want to proceed?
                </p>
              )}
            </>
          )
        }
      </div>
    );
  }

  render() {
    const { modalLoading, newUser } = this.props;
    const { domainMatches } = this.state;
    const newUserCreated = newUser.name && newUser.email;
    const isCreatingUser = (modalLoading || newUser.email);
    return (
      <ModalWrapper
        hideModal={this.closeModal}
        customContentStyle={customContentStyle}
        dismiss={!isCreatingUser ? 'Cancel' : ''}
        action={domainMatches ? this.onFinish : this.handleIgnoreDomain}
        actionName={!isCreatingUser ? 'Submit' : ''}
        modalTitle="Add a new user"
        form="AddUsers"
      >
        {newUserCreated ? <p>{`${newUser.email} has been successfully created.`}</p> : this.addUserForm()}
      </ModalWrapper>
    );
  }
}

AddUsers.propTypes = {
  submit: PropTypes.func.isRequired,
  modalErrorStatus: PropTypes.shape({
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  modalLoading: PropTypes.bool.isRequired,
  newUser: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  show: PropTypes.func.isRequired,
  clearAddUserModal: PropTypes.func.isRequired,
};

export default AddUsers;
