import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UploadRecord from '../../components/modal/UploadRecord';
import { uploadRecord, getUploadedRecordsByPage } from '../../actions/records';

class UploadRecordDialog extends Component {
  render() {
    return (
      <div>
        <UploadRecord
          {...this.props}
          uploadRecord={this.props.uploadRecord}
          getUploadedRecordsByPage={this.props.getUploadedRecordsByPage}
        />
      </div>
    );
  }
}

UploadRecordDialog.propTypes = {
  uploadRecord: PropTypes.func.isRequired,
  getUploadedRecordsByPage: PropTypes.func.isRequired,
};

export default connect(null, { uploadRecord, getUploadedRecordsByPage })(UploadRecordDialog);
