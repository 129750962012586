// copy for the alert message on the home dashboard
const homeMessageBold = 'Start saving time today.';
const kareoMessageBold = 'New:';

const homeMessageRegular = 'Schedule a 15 minute call with us to get access to our new AI-powered auto-labeling feature.';

const homeMessageKareo = 'Automatically upload incoming faxes to each patient’s chart in Kareo! Work smarter, not harder';

const chromeExtensionNotInstalled = "It looks like you don't have our Chrome extension installed.";

const installChromeExtension = "Click here to install it.";

export { homeMessageBold, homeMessageRegular, homeMessageKareo, kareoMessageBold, chromeExtensionNotInstalled, installChromeExtension };
