// Shows the modal loader component
import React from 'react';
import PropTypes from 'prop-types';

import { Spin } from 'antd';

const style = {
  loader: { left: '45%' },
  pStyle: { textAlign: 'center' }
};

const ModalLoading = ({ status }) => {
  return (
    <div>
      <p style={style.pStyle}>{status}</p>
      <Spin size="medium" />
    </div>
  );
};

ModalLoading.defaultProps = {
  status: ''
}

ModalLoading.propTypes = {
  status: PropTypes.string,
};

export default ModalLoading;
