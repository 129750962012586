import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Spin,
  Tag,
  Upload,
  Input,
  DatePicker,
} from 'antd';
import {
  CheckCircleOutlined,
  UploadOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import ModalWrapper from '../ModalWrapper';
import UploadSingleRecord from './UploadSingleRecord';
import { verifyFeatureAccess } from '../../../global/featureFlags';
import { DownloadApp } from '../../../assets/img';
import Box from '../../common/basic/Box';
import Dragger from './style';

const pStyle = {
  textAlign: 'center',
  fontWeight: '600',
};

const maxNumberOfUploadedFiles = 8;
let newFileList = [];

class UploadRecord extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      chosenFiles: [],
      display: false,
      skipGibraltarPrompt: false,
      doNotShowFieldChecked: false,
      uploadClicked: false,
      uploadedSuccessIndex: {},
      uploadedErrorIndex: {},
      requiredInfoFilledIndex: {},
      forSamePatient: false,
      patientName: '',
      patientDOB: '',
      isUserRedirected: false,
      canPublishForms: false,
    };
  }

  async componentDidMount() {
    this.determineVisbility();
    const getUserGibraltarPromptSettings = localStorage.getItem('m8_hideGibraltarPrompt');
    const canPublishForms = await verifyFeatureAccess('publish_forms');
    this.setState({
      skipGibraltarPrompt: getUserGibraltarPromptSettings,
      canPublishForms,
    });

    // fileList passed when drag and drop occurs
    const passedFileList = this.props.modalType?.data?.fileList;
    if (passedFileList?.length) {
      this.onChoosingFiles({ fileList: passedFileList });
    }
    window.addEventListener('dragover', (e) => {
      e.preventDefault();
    }, false);
    window.addEventListener('drop', (e) => {
      e.preventDefault();
    }, false);
  }

  componentDidUpdate() {
    const {
      uploadedSuccessIndex, uploadedErrorIndex, chosenFiles, isUserRedirected,
    } = this.state;
    const {
      hideModal, getUploadedRecordsByPage,
    } = this.props;
    const areAllFilesAttempted = chosenFiles?.length && chosenFiles.every(file => uploadedSuccessIndex[file.uid] || uploadedErrorIndex[file.uid]);

    if (areAllFilesAttempted && !isUserRedirected) {
      const currentPagePathname = this.context.router.history.location.pathname;
      const isUploadPage = currentPagePathname.includes('/app/uploads');
      // eslint-disable-next-line
      this.setState({ isUserRedirected: true });
      if (isUploadPage && chosenFiles?.length > 1) getUploadedRecordsByPage(1);
      setTimeout(() => {
        hideModal();
        if (!isUploadPage) this.context.router.history.push('/app/uploads');
      }, 1000);
    }
  }

  determineVisbility = async () => {
    const display = await verifyFeatureAccess('department_sharing');
    this.setState({ display });
  }

  upload = () => {
    if (this.state.chosenFiles?.length === 0) return;
    this.setState({
      uploadClicked: true,
    });
  };

  installWindowsClient = () => {
    const win = window.open(process.env.DOWNLOAD_APP_LINK, '_blank');
    win.opener = null;
  }

  gibraltarPrompt = () => {
    const { doNotShowFieldChecked } = this.state;
    return (
      <div>
        <p style={pStyle}>
          Want an easier way to upload to Medsender?
        </p>
        <p>
          Download our windows app to install Medsender as a printer on your local machine.
          This will allow you to &lsquo;print&lsquo; a file directly to Medsender.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <img src={DownloadApp} style={{ height: '207px', margin: '0 auto' }} alt="Download Application" />
          <Button type="primary" style={{ margin: '10px 0' }} onClick={this.installWindowsClient}>
            Download App
          </Button>
        </div>
        <p style={{ fontStyle: 'italic', marginTop: '10px' }}>
          If you&apos;re seeing this message and already have the desktop app installed,
          please download the latest version from above.
        </p>
        <Checkbox
          onChange={event => this.setState({ doNotShowFieldChecked: event.target.checked })}
          checked={doNotShowFieldChecked}
        >
          Do not show me this message again
        </Checkbox>
      </div>
    );
  }

  // This function is called few consecutive times with a new file every time
  // if the user selects multiple files
  // That's why we are using debounce, with the aid of the external variable newFileList
  onChoosingFiles = ({ file, fileList }) => {
    // We only pass fileList in case of drag and drop
    if (!file && fileList.length > 0) {
      const listStatesIndex = fileList.reduce((acc, cur) => { acc[cur.uid] = false; return acc; }, {});
      this.setState({ chosenFiles: fileList.map(draggedFile => draggedFile.originFileObj || draggedFile), uploadedSuccessIndex: listStatesIndex, uploadedErrorIndex: listStatesIndex });
      return;
    }

    let firingTimeout;
    clearTimeout(firingTimeout);
    newFileList = [...newFileList, file];

    firingTimeout = setTimeout(() => {
      const { chosenFiles } = this.state;
      const uniqueNewFileList = newFileList.filter(f => !(chosenFiles.find(existingFile => f.uid === existingFile.uid)));
      if (uniqueNewFileList.length === 0) return;
      const finalFileList = [...chosenFiles, ...uniqueNewFileList];
      const limitedFileList = finalFileList?.length > maxNumberOfUploadedFiles ? finalFileList.slice(0, maxNumberOfUploadedFiles) : finalFileList;
      const listStatesIndex = limitedFileList.reduce((acc, cur) => { acc[cur.uid] = false; return acc; }, {});
      this.setState({ chosenFiles: limitedFileList, uploadedSuccessIndex: listStatesIndex, uploadedErrorIndex: listStatesIndex });
      newFileList = [];
    });
  }

  isOnWindows = () => ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(window.navigator.platform) !== -1 && navigator.userAgent.indexOf('Windows NT 5.1') === -1;

  uploadMessage = () => {
    const { isLoading } = this.props;
    const { chosenFiles, uploadClicked } = this.state;
    const isSingleFile = chosenFiles?.length === 1;
    const maxNumberOfUploadedFilesUploaded = chosenFiles.length >= maxNumberOfUploadedFiles;

    if (isLoading || uploadClicked) {
      return (
        <span>
          Uploading, Please wait...
          {isSingleFile && <Spin />}
        </span>
      );
    }

    return (
      <>
        <p>Click below / or drag and drop to upload new record(s) and input the required information:</p>

        <Box m="-10px 0 10px">
          <strong>
            Select a maximum of
            {' '}
            {maxNumberOfUploadedFiles}
            {' '}
            files
          </strong>
        </Box>
        {!maxNumberOfUploadedFilesUploaded
          && (
          <Box m="10px 0">
            <Dragger multiple accept=".pdf" onChange={this.onChoosingFiles} previewFile={false} showUploadList={false} beforeUpload={() => false}>
              Click or drag file(s) here to upload (in PDF format)
            </Dragger>
            <Box m="10px 0 0" hidden={!this.isOnWindows()}>
              <small>You can use the print driver to upload non-PDF files</small>
            </Box>
          </Box>
          )
        }
      </>
    );
  }

  continueToUploadRecord = () => {
    const { doNotShowFieldChecked } = this.state;
    this.setState({
      skipGibraltarPrompt: true,
    });
    // eslint-disable-next-line no-unused-expressions
    doNotShowFieldChecked && localStorage.setItem('m8_hideGibraltarPrompt', true);
  }

  render() {
    const customContentStyle = {
      width: '45%',
    };
    const { skipGibraltarPrompt } = this.state;
    const hasGibraltar = localStorage.getItem('gibraltar_version');

    const seeGibraltarMessage = this.isOnWindows() && !hasGibraltar && !skipGibraltarPrompt;


    const {
      display, uploadClicked, chosenFiles, uploadedSuccessIndex, uploadedErrorIndex, requiredInfoFilledIndex, forSamePatient, patientName, patientDOB,
    } = this.state;
    const {
      uploadRecord, errorMessage, hideModal, isLoading,
    } = this.props;
    const areAllFilesAttempted = chosenFiles?.length && chosenFiles.every(file => uploadedSuccessIndex[file.uid] || uploadedErrorIndex[file.uid]);

    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];

    const requiredInfoIndexValues = Object.values(requiredInfoFilledIndex);
    const uploadButtonEnabled = (seeGibraltarMessage || (chosenFiles?.length && (forSamePatient ? patientName.trim() : requiredInfoIndexValues?.length === chosenFiles?.length && requiredInfoIndexValues.every(value => value))));

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={seeGibraltarMessage ? this.continueToUploadRecord : this.upload}
        actionName={seeGibraltarMessage ? 'Continue to Upload' : 'Upload'}
        disabled={!uploadButtonEnabled}
        dismiss="Cancel"
        modalTitle="New Upload"
        isLoading={!areAllFilesAttempted && isLoading}
        bodyStyle={{
          maxHeight: '60vh',
          overflow: 'auto',
        }}
      >
        {seeGibraltarMessage ? this.gibraltarPrompt()
          : (
            <>
              {this.uploadMessage()}
              {chosenFiles?.length > 0
                && (
                <>
                  <Box hidden={(chosenFiles?.length === 1) || uploadClicked} m="20px 0 0">
                    <Checkbox
                      onChange={e => this.setState({ forSamePatient: e.target.checked })}
                      checked={forSamePatient}
                    >
                      Selected records belong to the same patient
                    </Checkbox>

                    {
                      forSamePatient && (
                        <Box m="20px 0 0">
                          <Form initialValues={{ name: patientName, dob: moment(patientDOB).isValid() && moment(patientDOB) }}>
                            <Form.Item
                              hasFeedback
                              name="name"
                              label="Name"
                              rules={[
                                () => ({
                                  validator(_, name) {
                                    if (name && name.trim() !== '') return Promise.resolve();
                                    return Promise.reject('Name cannot be blank');
                                  },
                                }),
                              ]}
                            >
                              <Input
                                placeholder="Name"
                                onChange={e => this.setState({ patientName: e.target.value })}
                              />
                            </Form.Item>
                            <Form.Item
                              name="dob"
                              label="Date of Birth MM/DD/YYYY (optional)"
                            >
                              <DatePicker
                                onChange={(_, dateString) => this.setState({ patientDOB: dateString })}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Form>
                        </Box>
                      )
                    }

                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="flex-start" flexWrap="wrap" m="35px 0" hidden={chosenFiles?.length === 1}>
                    {chosenFiles.map((chosenFile) => {
                      let tagColor = 'processing';
                      let tagIcon = uploadClicked && <SyncOutlined spin />;
                      if (uploadedSuccessIndex[chosenFile?.uid]) {
                        tagColor = 'success';
                        tagIcon = <CheckCircleOutlined />;
                      }
                      if (uploadedErrorIndex[chosenFile?.uid]) {
                        tagColor = 'error';
                        tagIcon = <CloseCircleOutlined />;
                      }
                      return (
                        <Box m="0 10px 10px 0" key={chosenFile.uid}>
                          <Tag
                            color={tagColor}
                            icon={tagIcon}
                            closable
                            onClose={() => {
                              const newChosenFiles = chosenFiles.filter(f => f.uid !== chosenFile?.uid);
                              const newUploadedSuccessIndex = { ...uploadedSuccessIndex };
                              delete newUploadedSuccessIndex[chosenFile?.uid];
                              const newUploadedErrorIndex = { ...uploadedErrorIndex };
                              delete newUploadedErrorIndex[chosenFile?.uid];
                              const newRequiredInfoFilledIndex = { ...requiredInfoFilledIndex };
                              delete newRequiredInfoFilledIndex[chosenFile?.uid];

                              this.setState(prevState => ({
                                chosenFiles: newChosenFiles,
                                forSamePatient: newChosenFiles.length === 1 ? false : prevState.forSamePatient,
                                uploadedSuccessIndex: newUploadedSuccessIndex,
                                uploadedErrorIndex: newUploadedErrorIndex,
                                requiredInfoFilledIndex: newRequiredInfoFilledIndex,
                              }));
                            }}
                          >
                            {chosenFile?.name}
                          </Tag>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box hidden={uploadClicked}>
                    {chosenFiles.map((chosenFile, i) => (
                      <UploadSingleRecord
                        key={chosenFile.uid}
                        display={display}
                        file={chosenFile}
                        uploadRecord={uploadRecord}
                        errorMessage={errorMessage}
                        uploadClicked={uploadClicked}
                        isFirstRecord={i === 0}
                        isOnlyRecord={chosenFiles?.length === 1}
                        onUploadSuccess={() => this.setState(prev => ({ uploadedSuccessIndex: { ...prev.uploadedSuccessIndex, [chosenFile.uid]: true } }))}
                        onUploadError={() => this.setState(prev => ({ uploadedErrorIndex: { ...prev.uploadedErrorIndex, [chosenFile.uid]: true } }))}
                        onRequiredInfoCorrect={value => this.setState(prev => ({ requiredInfoFilledIndex: { ...prev.requiredInfoFilledIndex, [chosenFile.uid]: value } }))}
                        isUploaded={uploadedSuccessIndex[chosenFile?.uid]}
                        hasCommonPatient={forSamePatient}
                        patientName={patientName}
                        patientDOB={patientDOB}
                        canPublishForms={this.state.canPublishForms}
                      />
                    ))}
                  </Box>
                </>
)}
            </>
          )}
      </ModalWrapper>
    );
  }
}

UploadRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

UploadRecord.defaultProps = {
  errorMessage: '',
};

UploadRecord.propTypes = {
  hideModal: PropTypes.func.isRequired,
  uploadRecord: PropTypes.func.isRequired,
  getUploadedRecordsByPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  errorMessage: state.records.errorMessage,
}))(UploadRecord);
