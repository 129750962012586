import axios from 'axios';
import crud from './helpers/crud';

const getAddresses = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ADDRESSES',
      fail: 'FAILED_GET_ADDRESSES',
      end: 'FINISH_GET_ADDRESSES',
    },
    method: 'GET',
    url: '/api/v1/recipients',
  })
);

const addNewAddress = recipient => (
  crud({
    dispatch: {
      begin: 'BEGIN_ADD_NEW_ADDRESS',
      fail: 'FAILED_ADD_NEW_ADDRESS',
      end: 'FINISH_ADD_NEW_ADDRESS',
    },
    method: 'POST',
    url: '/api/v1/recipients',
    data: recipient,
  })
);

const updateAddress = (recipient, id) => (
  crud({
    dispatch: {
      begin: 'BEGIN_UPDATE_ADDRESS',
      fail: 'FAILED_UPDATE_ADDRESS',
      end: 'FINISH_UPDATE_ADDRESS',
    },
    method: 'PATCH',
    url: `/api/v1/recipients/${id}`,
    data: recipient,
  })
);

const deleteAddress = id => (
  crud({
    dispatch: {
      begin: 'BEGIN_DELETE_ADDRESS',
      fail: 'FAILED_DELETE_ADDRESS',
      end: 'DELETE_UPDATE_ADDRESS',
    },
    method: 'PATCH',
    url: `/api/v1/recipients/${id}/archive`,
  })
);

const getAddressesByQuery = query => (
  crud({
    dispatch: {
      begin: 'BEGIN_GET_ADDRESSES',
      fail: 'FAILED_GET_ADDRESSES',
      end: 'FINISH_GET_ADDRESSES',
    },
    method: 'GET',
    url: `/api/v1/recipients/${query}`,
    query,
  })
);

export {
  getAddresses,
  addNewAddress,
  updateAddress,
  deleteAddress,
  getAddressesByQuery,
};
