import axios from 'axios';

import { switchAuth } from './auth';
import crud from './helpers/crud';

/** Updates store and should render modal
 * @param {modalType} object  { data: null, type:, null}
 * data component data
 * type modal type e.g Archive record modal
 */
const openModal = modalType => (dispatch) => {
  dispatch({
    type: 'SHOW_MODAL',
    modalType,
  });
};

/**
 * Closes the modal and updates the store
 */
const hideModal = () => (dispatch) => {
  let root = document.getElementById('root')
  root.style.overflow = 'scroll'
  dispatch({
    type: 'HIDE_MODAL',
  });
};

/**
 * Calls the save record to medsender action.
 * @param {Number} fax - Fax number of the guest
 * @param {String} code - Access code for the guest
 * @param {Object} user - User object that contains uid and name
 * @param {Function} dispatch - function that triggers state change
 */
const saveRecordToMedsender = (fax, code, user, dispatch) => {
  axios({
    method: 'put',
    url: `/api/v1/receivers/${fax}/access/${code}`,
    headers: {
      'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content,
    },
  })
    .then((response) => {
      dispatch({
        type: 'FINISHED_SAVE_TO_MEDSENDER',
        payload: response,
      });
      switchAuth(user, dispatch);
    })
    .catch((error) => {
      // const errorMessage = error.response.data.errors.join(' ');
      dispatch({
        type: 'FAILED_SAVE_TO_MEDSENDER',
        payload: error,
      });
      switchAuth(user, dispatch);
    });
};

/**
 * Logs in user to Medsender via the modal.
 * @param {Object} data - Holds the login data.
 * @param {String} data.email - The name of the employee.
 * @param {String} data.password - The pass of the employee.
 * @param {Object} fax - Holds the fax data details.
 * @param {String} fax.fax_code - The name of the employee.
 * @param {String} fax.access_code - The pass of the employee.
 */
const modalLogin = (data, fax) => async (dispatch) => {
  dispatch({
    type: 'USER_AUTHENTICATING_VIA_MODAL',
  });
  axios({
    method: 'POST',
    url: '/auth/sign_in',
    data: {
      user: data,
    },
    headers: {
      'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch({
          type: 'USER_VERIFICATION_STEP',
          payload: {
            email: data.email,
            otpAuthToken: response.data.otp_auth_token,
          },
        });
      } else {
        window.raven.setUserContext({
          email: response.data.email,
        });
        dispatch({
          type: 'USER_AUTHENTICATED_VIA_MODAL',
          payload: {
            uid: response.data.email,
            name: response.data.name,
          },
        });
        const newCSRFToken = response.headers['x-csrf-token'];
        document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);

        // Sets the new access token, expiry and client token onto the localStorage.
        // Sets value to single space since ther is no expiry in the response.
        localStorage.setItem('m8_access', response.data.access_token);
        localStorage.setItem('m8_client', response.data.client);
        localStorage.setItem('m8_expiry', ' ');
        localStorage.setItem('m8_gibraltar', response.data.gibraltar_version)
        saveRecordToMedsender(fax.fax_code, fax.access_code, response.data, dispatch);
      }
      // Store the user email and name in localStorage
      localStorage.setItem('m8_uid', response.data.email);
      localStorage.setItem('m8_name', response.data.name);
      localStorage.setItem('m8_job_title', response.data.job_title);
      localStorage.setItem('m8_emr', response.data.emr);
    })
    .catch((error) => {
      // Updates the global state with th error meesage after invalid auth
      const errorMessage = error.response.data.error;
      dispatch({
        type: 'AUTH_VIA_MODAL_FAILED',
        payload: {
          error: errorMessage,
        },
      });
    });
};

// Action dispatched when the Resend Security code link is clicked
const resendModalOtp = data => async (dispatch) => {
  dispatch({
    type: 'RESENDING_OTP',
  });
  // Make a call to the resend code endpoint
  axios({
    method: 'POST',
    url: '/auth/resend_token',
    data: {
      user: data,
    },
    headers: {
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content,
    },
  }).then((response) => {
    // If a response OK is returned, show a toastr message, store variables to localStorage
    // and dispatch the action
    toastr.success(response.data.info);
    dispatch({
      type: 'FINISHED_RESENDING_OTP',
      payload: {
        otpAuthToken: response.data.otp_auth_token,
      },
    });
    // Update the CSRF token to match the authenticated session cookie
    const newCSRFToken = response.headers['x-csrf-token'];
    document.getElementsByName('csrf-token')[0].setAttribute('content', newCSRFToken);
    // Dispatch this action if theres an error returned from the backend
  }).catch((error) => {
    dispatch({
      type: 'FAILED_RESENDING_OTP',
      payload: {
        error: error.response.data.error,
      },
    });
  });
};

// Dispatch action to restart the login process
const startOver = () => async (dispatch) => {
  dispatch({
    type: 'START_OVER',
  });
};

const addNewUser = user => (
  crud({
    dispatch: {
      begin: 'BEGIN_ADDING_NEW_USER',
      fail: 'FAILED_ADDING_NEW_USER',
      end: 'FINISHED_ADDING_NEW_USER',
    },
    method: 'POST',
    url: '/auth/sign_up',
    data: user,
  })
);

const clearAddUserModal = () => async (dispatch) => {
  dispatch({
    type: 'CLEARING_ADD_USER_MODAL',
  });
};

const fetchAreaCodes = () => (
  crud({
    dispatch: {
      begin: 'BEGIN_FETCHING_AREA_CODES',
      fail: 'FAILED_FETCHING_AREA_CODES',
      end: 'FINISHED_FETCHING_AREA_CODES',
    },
    method: 'GET',
    url: '/api/v1/area_code',
  })
);

const addNewFaxNumber = faxNumber => (
  crud({
    dispatch: {
      begin: 'BEGIN_ADDING_NEW_FAX_NUMBER',
      fail: 'FAILED_ADDING_NEW_FAX_NUMBER',
      end: 'FINISHED_ADDING_NEW_FAX_NUMBER',
    },
    method: 'POST',
    url: '/api/v1/fax_numbers',
    data: faxNumber,
  })
);

export {
  openModal,
  hideModal,
  modalLogin,
  saveRecordToMedsender,
  resendModalOtp,
  startOver,
  addNewUser,
  fetchAreaCodes,
  addNewFaxNumber,
  clearAddUserModal,
};
