// Display the cell rows on the Sent Record Status Page
import React from 'react';
import PropTypes from 'prop-types';

const createTable = (value, styling) => {
  const div = [];

  // Outer loop to create parent
  for (let i = value.length - 1; i >= 0; i -= 1) {
    // Create the parent and add the children
    if (i === 0) {
      div.push(
        <div className={styling}>
          <h2>
            &nbsp;First Sent At:
          </h2>
          <p>
            <span style={{ fontWeight: '200' }}>
              {value[i]}
            </span>
          </p>
        </div>,
      );
    } else {
      div.push(
        <div className={styling}>
          <p>
            <span style={{ fontWeight: '200' }}>
              {value[i]}
            </span>
          </p>
        </div>,
      );
    }
  }

  return div;
};

const RecordDetail = ({ cellHeader, value, faxStatus = null }) => {
  const CheckIcon = (status) => {
    if (status === 'inprogress') {
      return <i className="material-icons pending">autorenew</i>;
    } else if (status === 'failure' || status === 'partialsuccess') {
      return <i className="material-icons failure">close</i>;
    } else if (status != null) {
      return <i className="material-icons success">done</i>;
    }
  };

  // Apply specific class if it's the fax status row
  const styling = faxStatus ? 'individual-detail fax-status' : 'individual-detail';

  if (cellHeader === 'Times Sent') {
    return createTable(value, styling);
  }

  // Capitalizes the first character
  const cellContent = value[0].toUpperCase() + value.substr(1);

  return (
    <div className={styling}>
      <h2>
        &nbsp;{cellHeader}:
      </h2>
      <p>
        <span style={{ fontWeight: '200' }}>
          {cellContent}
        </span>
      </p>
      {CheckIcon(faxStatus)}
    </div>
  );
};

RecordDetail.propTypes = {
  cellHeader: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default RecordDetail;
