/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Select,
  Input,
  Form,
  message,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalWrapper from './ModalWrapper';

class AddTaskProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingProviderID: '',
      providerName: props.modalType.data.provider.name,
      providerIsActive: props.modalType.data.provider.is_active,
      providerType: props.modalType.data.provider.provider_type,
      emrName: props.modalType.data.provider.emr_credential_name,
      emrId: props.modalType.data.provider.emr_credential_id,
      emrNames: props.modalType.data.availableEmrs,
    };
  }

  componentDidMount = async () => {
    const { modalType } = this.props;
    const { provider } = modalType.data;
    if (provider.name) {
      this.setState({
        editingProviderID: provider.id,
      });
    }
  }

  addProvider = async () => {
    const { createTaskProvider, getTaskProviders } = this.props;
    const {
      providerName,
      providerType,
      emrId,
    } = this.state;
    if (!providerName || providerName.trim() === '') return;
    const newTaskProvider = {
      name: providerName.trim(),
      provider_type: providerType,
      emr_credential_id: emrId,
      is_active: true,
    };
    await createTaskProvider(newTaskProvider);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error adding provider. Provider already exists.' : 'Error adding provider. Please try again.';
    if (!error) {
      hideModal();
      message.success({
        content: 'Task Provider successfully added',
        style: { marginTop: '70px' },
      });
      getTaskProviders();
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
      getTaskProviders();
    }
  }

  editProvider = async () => {
    const { updateTaskProvider, getTaskProviders } = this.props;
    const {
      editingProviderID,
      providerName,
      providerType,
      providerIsActive,
      emrId,
    } = this.state;
    let type = '';
    if ((providerType === 'user') || (providerType === 'User')) {
      type = 'user';
    } else {
      type = 'staff_group';
    }
    let active = false;
    if (providerIsActive === '1') {
      active = true;
    }
    const newTaskProvider = {
      name: providerName.trim(),
      provider_type: type,
      emr_credential_id: emrId,
      is_active: active,
    };
    await updateTaskProvider(editingProviderID, newTaskProvider);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error updating provider. Provider already exists.' : 'Error updating provider. Please try again.';
    hideModal();
    if (!error) {
      message.success({
        content: 'Task Provider successfully updated',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
    }
    getTaskProviders();
  }

  renderModal = () => {
    const { isLoading } = this.props;
    const {
      editingProviderID,
      providerName,
      providerType,
      providerIsActive,
      emrName,
      emrNames,
    } = this.state;

    const handleTaskProviderType = (e) => {
      this.setState({ providerType: e });
    };

    const handleStatusChange = (e) => {
      this.setState({ providerIsActive: e });
    };

    const handleEmrNameChange = (e) => {
      if (e === '') {
        this.setState({ emrId: null });
      } else {
        const emr = emrNames.find(singleEmr => singleEmr.id === e);
        this.setState({ emrId: emr.id, emrName: emr.name });
      }
    };

    if (isLoading) {
      return (<span> Adding new provider... </span>);
    }
    return (
      <React.Fragment>
        <Form
          layout="vertical"
          onFinish={!editingProviderID && this.addProvider}
          id="addProvider"
        >
          <Form.Item
            hasFeedback
            label="Provider Name"
            rules={[
              {
                required: true,
                message: 'Must provide a provider name',
              },
            ]}
          >
            <Input
              value={providerName}
              placeholder={providerName}
              onChange={event => this.setState({ providerName: event.target.value })}
            />
          </Form.Item>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              style={{ flex: 0.5 }}
            >
              <Tooltip title="Designation of provider as part of Staff Group or User Group.">
                Provider Type
                <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
              </Tooltip>
              <Select
                optionFilterProp="children"
                placeholder="Provider Type"
                onChange={handleTaskProviderType}
                filterOption={false}
                value={providerType}
                options={[
                  {
                    value: 'user',
                    label: 'User',
                  },
                  {
                    value: 'staff_group',
                    label: 'Staff Group',
                  },
                ]}
              >
                {}
              </Select>
            </Form.Item>
          </div>
          {
            (emrNames && emrNames.length > 2)
            && (
            <Form.Item
              style={{ flex: 0.5 }}
            >
              <Tooltip title="Provider that is assigned to this EMR Credential Name. Providers assigned at the Organization level are available when no Emr Credential is assigned to the Organization.">
                Emr Credential Name
                <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
              </Tooltip>
              <Select
                optionFilterProp="children"
                placeholder="Emr Credential Name"
                onChange={handleEmrNameChange}
                filterOption={false}
                value={emrName}
              >
                {this.state.emrNames.map(emr => <Select.Option key={emr.id} value={emr.id}>{emr.name}</Select.Option>)}
              </Select>
            </Form.Item>
            )
          }
          {
            (editingProviderID)
            && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                style={{ flex: 0.5 }}
              >
                <Tooltip title="Active Providers only display on Send to Chart and Create Task Modals.">
                  Status
                  <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
                </Tooltip>
                <Select
                  allowClear={false}
                  onChange={handleStatusChange}
                  placeholder="Status"
                  value={providerIsActive}
                  options={[
                    {
                      value: '1',
                      label: 'Active',
                    },
                    {
                      value: '0',
                      label: 'Inactive',
                    },
                  ]}
                />
              </Form.Item>
            </div>
            )
        }
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal, modalType: { data: { isAdmin } } } = this.props;
    const {
      editingProviderID,
      providerName,
      providerIsActive,
      providerType,
      emrName,
      emrNames,
    } = this.state;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={!editingProviderID ? this.addProvider : this.editProvider}
        actionName={editingProviderID ? 'Edit' : 'Add'}
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={!providerName || !providerType || !isAdmin || (emrNames.length > 2 && !emrName) || (editingProviderID && !providerIsActive)}
        form="addProvider"
        modalTitle={editingProviderID ? 'Edit Task Provider' : 'Add New Task Provider'}
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        >
          {this.renderModal()}
        </div>
      </ModalWrapper>
    );
  }
}

AddTaskProvider.defaultProps = {
  error: false,
  errorCode: null,
};

AddTaskProvider.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTaskProviders: PropTypes.func.isRequired,
  updateTaskProvider: PropTypes.func.isRequired,
  createTaskProvider: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorCode: PropTypes.string,
};

export default connect(state => ({
  allEmaTaskProviders: state.userProfile.allEmaTaskProviders,
  allDocumentCategories: state.userProfile.allDocumentCategories,
  isLoading: state.userProfile.isLoading,
  error: state.userProfile.error,
  errorCode: state.userProfile.errorCode,
}))(AddTaskProvider);
