/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons'
import { parse, stringify } from 'qs';

import { InboxTable, Header } from '../components';
import { chromeReceivedColumns, chromeUploadedColumns } from './records/shared/constants';
import { rowSelected } from '../actions/inboxTableActions';
import { changeDefaultOrganization } from '../actions/appActions';
import { getLoginInfo } from '../actions/auth';
import { changeInboxPage } from '../actions/inbox';
import { getReceivedRecordsByPage, getUploadedRecordsByPage, getRecordById } from '../actions/records';

const selectionButtonsContainerStyle = {
  display: 'block',
  marginTop: '20px',
  position: 'fixed',
  bottom: '0px',
  right: '0px',
};

const btnStyle = {
  marginRight: '10px',
  marginLeft: '10px',
  marginBottom: '10px',
};

const archiveBtnStyle = {
  marginRight: '10px',
  marginBottom: '10px',
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ChromeRecordSelector extends Component {
  constructor(props) {
    super(props);
    const {
      location,
      getUploadedRecordsByPage,
    } = props;
    const { pathname } = location;
    const viewerMode = {
      '/app/chrome/received_selector': {
        mode: 'Received',
        tableColumns: chromeReceivedColumns,
        fetchData: this.fetchReceivedRecordsByPage,
      },
      '/app/chrome/uploaded_selector': {
        mode: 'Uploaded',
        tableColumns: chromeUploadedColumns,
        fetchData: getUploadedRecordsByPage,
      },
    };
    const { mode, tableColumns, fetchData } = viewerMode[pathname];
    const { organizationDetails: { current_organization: currentOrganization } } = props;
    this.state = {
      tableColumns,
      fetchData,
      mode,
      data: [],
      searchQuery: '',
      openOrganizationFilterMenu: false,
      currentOrganization,
    };
  }

  componentDidMount() {
    const { fetchData } = this.state;
    fetchData(1, 5);
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/prop-types
    const { pathname } = this.props.location;
    const { receivedData, uploadedData } = nextProps;
    const data = {
      '/app/chrome/received_selector': receivedData,
      '/app/chrome/uploaded_selector': uploadedData,
    };
    this.setState({ data: data[pathname] });
  }

  handleSelection = async (archive) => {
    const { getRecordById: fetchRecordById, selectedData } = this.props;
    if (!selectedData || selectedData.length === 0) return;
    const {
      file_token: fileToken,
      patient_name: patientName,
      patient_dob: patientDOB,
      document_title: documentTitle,
    } = selectedData[0];
    const requestedFromSentRecords = false;
    const requestedFromExt = true;
    await fetchRecordById(fileToken, requestedFromSentRecords, requestedFromExt, archive);
    // On the Chrome Extension, the medsender-content.js script
    // listens for this event. Dispatching it allows the chrome extension
    // to assume that a URL has been retrieved. This is then passed
    // onto the background script
    const { signedUrl } = this.props;
    const name = patientName || '';
    const dob = patientDOB || '';

    // Pass in fileName so we can have patient name and DOB show up in the EMR as the filename so
    // they can import the record easily without having to go back and forth between the EMR and
    // chrome extension.
    let fileName = '';
    if (name) {
      fileName = name.replace(/[^a-zA-Z0-9]+/g, '_');
    } else {
      fileName = 'record';
    }

    if (dob) {
      fileName += `_${dob.replace(/[^a-zA-Z0-9]+/g, '_')}.pdf`;
    } else {
      fileName += '.pdf';
    }

    const triggerChange = new CustomEvent('chromeExtFileSelected', {
      detail: { url: signedUrl, fileName, documentTitle },
    });
    document.dispatchEvent(triggerChange);
    window.close();
  }

  handleClose = () => {
    const triggerChange = new CustomEvent('chromeExtFileSelected', {
      detail: { url: 'WINDOW_CLOSED' },
    });
    document.dispatchEvent(triggerChange);
    window.close();
  }

  handlePageChange = (pageParams) => {
    const params = parse(pageParams, { ignoreQueryPrefix: true });
    const selectedPage = parseInt(params.page, 10);
    const { fetchData, searchQuery } = this.state;
    fetchData(selectedPage, 5, searchQuery);
    // If anything was previously selected and the page is changed, then it will continue
    // to be selected and the "Choose" button will remain enabled.
    //
    // Clear so that on a new page change, Choose button becomes disabled again
    this.props.rowSelected([]);
  }

  handleChangeDefaultOrganization = async (org) => {
    // eslint-disable-next-line react/prop-types
    const {
      changeDefaultOrganization: handleChangeDefaultOrganization,
    } = this.props;
    this.setState({
      currentOrganization: org,
    });
    await handleChangeDefaultOrganization(org, this.reloadPage);
  }

  reloadPage = async () => {
    const {
      getLoginInfo: handleGetLoginInfo,
    } = this.props;
    await handleGetLoginInfo();
    window.location.reload();
  }

  fetchReceivedRecordsByPage = (page, pageSize, searchQuery = '') => {
    const { getReceivedRecordsByPage } = this.props;
    const queryParams = `&include_signed_replies=true${searchQuery}&record_selector=true`;
    getReceivedRecordsByPage(page, pageSize, queryParams);
  }

  fetchRecordsWithQuery = (selectedData, dataOnPage, searchQuery = '') => {
    this.setState({ searchQuery });
    const { fetchData } = this.state;
    fetchData(selectedData, dataOnPage, searchQuery);
  }

  render() {
    const {
      data,
      tableColumns,
      mode,
      openOrganizationFilterMenu,
      currentOrganization,
    } = this.state;
    const {
      headers: pageMetadata,
      history,
      location,
      organizationDetails: { organizations },
    } = this.props;

    const currentPage = (pageMetadata ? parseInt(pageMetadata.page, 10) : 1);
    const totalPages = (pageMetadata ? parseInt(pageMetadata['total-pages'], 10) : 1);
    const totalResults = (pageMetadata ? parseInt(pageMetadata.total, 10) : 0);
    let params = {};
    if (location.search) {
      params = parse(location.search, { ignoreQueryPrefix: true });
    }
    const paramTools = {
      params,
      stringify,
      stringifyOptions,
      push: history.push,
    };

    if (!data.length > 0) {
      return (
        <>
          <Header
            main={`${mode} Selector`}
            subtext="Choose a record"
            locationPath={[]}
            chromeSelectorPage={mode}
            fetchData={this.fetchRecordsWithQuery}
            selectedPage={currentPage}
            buttons={[
              {
                openFilterMenu: openOrganizationFilterMenu,
                text: currentOrganization,
                icon: <HomeOutlined />,
                title: 'Current Organization',
                disabled: false,
                hide: organizations.length < 2,
                func: () => this.setState({ openOrganizationFilterMenu: true }),
                filterRecords: true,
                filterOptions: organizations.map(({ name }) => ({ filter: name, text: name })),
                handleFilter: (_, child) => this.handleChangeDefaultOrganization(child.props.primaryText),
                handleRequestChange: value => this.setState({ openOrganizationFilterMenu: value }),
              },
            ]}
          />
        </>
      );
    }

    return (
      <>
        <Header
          main={`${mode} Selector`}
          subtext="Choose a record"
          locationPath={[]}
          chromeSelectorPage={mode}
          fetchData={this.fetchRecordsWithQuery}
          selectedPage={currentPage}
          buttons={[
            {
              openFilterMenu: openOrganizationFilterMenu,
              text: currentOrganization,
              icon: <HomeOutlined />,
              title: 'Current Organization',
              disabled: false,
              hide: organizations.length < 2,
              func: () => this.setState({ openOrganizationFilterMenu: true }),
              filterRecords: true,
              filterOptions: organizations.map(({ name }) => ({ filter: name, text: name })),
              handleFilter: (_, child) => this.handleChangeDefaultOrganization(child.props.primaryText),
              handleRequestChange: value => this.setState({ openOrganizationFilterMenu: value }),
            },
          ]}
        />
        <InboxTable
          rows={data.filter(row => row.file_token)}
          page={currentPage}
          totalPages={totalPages}
          actions={{
            changeInboxPage: this.props.changeInboxPage,
            getRecords: this.handlePageChange,
          }}
          push={history.push}
          mode={mode}
          userIsOnChromeExtensionViewer={true}
          totalResults={totalResults}
          selectedData={this.props.selectedData}
          paramTools={paramTools}
          rowSelected={this.props.rowSelected}
          columnTitles={tableColumns}
          enableSelectAll={false}
          getReplyData={() => {
            // TODO
            // Add support for displaying replies here and being able to select them
            // To view replies, remove the .filter array call in the rows prop
          }}
        />
        <div style={selectionButtonsContainerStyle}>
          <Button size="large" onClick={this.handleClose}>Cancel</Button>
          <Button
            size="large"
            type="primary"
            style={btnStyle}
            onClick={() => this.handleSelection(false)}
            disabled={this.props.selectedData.length === 0}
          >
            Choose
          </Button>
          <Button
            size="large"
            type="primary"
            style={archiveBtnStyle}
            onClick={() => this.handleSelection(true)}
            disabled={this.props.selectedData.length === 0}
          >
            Choose & Archive
          </Button>
        </div>
      </>
    );
  }
}

ChromeRecordSelector.defaultProps = {
  signedUrl: null,
  headers: null,
};

ChromeRecordSelector.propTypes = {
  getReceivedRecordsByPage: PropTypes.func.isRequired,
  getUploadedRecordsByPage: PropTypes.func.isRequired,
  rowSelected: PropTypes.func.isRequired,
  changeInboxPage: PropTypes.func.isRequired,
  selectedData: PropTypes.array.isRequired,
  getRecordById: PropTypes.func.isRequired,
  signedUrl: PropTypes.string,
  uploadedData: PropTypes.array.isRequired,
  receivedData: PropTypes.array.isRequired,
  headers: PropTypes.object,
  organizationDetails: PropTypes.shape({
    organizations: PropTypes.array.isRequired,
    current_organization: PropTypes.string,
  }).isRequired,
  changeDefaultOrganization: PropTypes.func.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
};

export default connect(state => ({
  signedUrl: state.records.currentRecord,
  receivedData: state.records.received_data,
  uploadedData: state.records.uploaded_data,
  selectedData: state.inboxTable.recordData,
  headers: state.records.headers,
  organizationDetails: state.auth.organizationDetails,
}), {
  getReceivedRecordsByPage,
  getUploadedRecordsByPage,
  getRecordById,
  rowSelected,
  changeInboxPage,
  changeDefaultOrganization,
  getLoginInfo,
})(ChromeRecordSelector);

export { ChromeRecordSelector };
