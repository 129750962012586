import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as AntButton } from 'antd';
import './button.scss';

const Button = ({
  type,
  size,
  label,
  icon,
  leftIcon,
  rightIcon,
  disabled,
  fullWidth,
  ...props
}) => {
  return (
    <div className={classNames('redesign-btn', { 'full-width': fullWidth })}>
      <AntButton
        className={classNames(type, size,
          { disabled },
          { 'icon-btn': icon })}
        disabled={disabled}
        {...props}
      >
        {icon
          ? (
            <div className={`icon ${size}`}>
              {icon}
            </div>
          )
          : (
            <>
              {
              leftIcon
              && (
              <div className={`icon ${size}`}>
                {leftIcon}
              </div>
              )
            }
              {label}
              {
              rightIcon
              && (
              <div className={`icon ${size}`}>
                {rightIcon}
              </div>
              )
            }
            </>
          )
        }
      </AntButton>
    </div>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  icon: PropTypes.any,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'primary',
  size: 'large',
  icon: null,
  leftIcon: null,
  rightIcon: null,
  disabled: false,
  onClick: null,
};

export default Button;
