// Shows modal for asking the user to logout
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

class LogoutUser extends Component {

  displayMessage() {
    if (this.props.accessedPatient) {
      return 'Please sign out of your account before accessing patient portal.';
    }
    return 'You need to sign out in order to reset your password.';
  }

  render() {
    const customContentStyle = {
      width: '37%',
    };

    return (
      <ModalWrapper
        hideModal={this.props.hideModal}
        primary={true}
        customContentStyle={customContentStyle}
        dismiss={'Cancel'}
        modalTitle="Sign Out"
      >
        <p><b>{this.displayMessage()}</b></p>
      </ModalWrapper>
    );
  }
}

LogoutUser.propTypes = {
  hideModal: PropTypes.func.isRequired,
  accessedPatient: PropTypes.bool.isRequired,
};

export default LogoutUser;
