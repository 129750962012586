import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import Paper from 'material-ui/Paper';
import Dialog from 'material-ui/Dialog';
import FontIcon from 'material-ui/FontIcon';

import { BILLING_ENUMS, chargebeeBasicPlans, essentialsPlan } from '../constants';
import './billing.scss';

// This component is based off of the <PlanSelector> component. For spec details, see that component.
class BasicPlan extends Component {
  static propTypes = {
    changeModal: PropTypes.func.isRequired,
  };

  state = {
    open: true,
    selected: -1,
  }

  checkout = () => {
    const { selected } = this.state;
    const { changeModal } = this.props;
    const selectedPlan = chargebeeBasicPlans[selected];
    this.setState({ open: false });
    changeModal(BILLING_ENUMS.PROCESS_NEW_SUBSCRIPTION_PAYMENT, selectedPlan, true);
  }

  renderPlans = () => {
    return [essentialsPlan].map((plan, i) => {
      const {
        name,
        pricing,
        subtext,
        features,
      } = plan;
      return (
        <div className="plan-container" style={{ marginLeft: '25%' }}>
          <Paper
            onClick={() => this.setState({ selected: i })}
            className={`plan-select${this.state.selected === i && 'ed'}`}
            style={{ borderRadius: '10px', padding: '20px' }}
          >
            <div className="plan-header">
              <h3>{name}</h3>
              <h1>{pricing}</h1>
            </div>
            {
              subtext && (
                <div className="plan-subtext">
                  {subtext}
                </div>
              )
            }
            {
              features.map(feature => (
                <div className="plan-feature">
                  <FontIcon className="material-icons">check</FontIcon>
                  &nbsp;
                  {feature}
                </div>
              ))
            }
          </Paper>
        </div>
      );
    });
  }

  render() {
    const { open, selected } = this.state;
    const { changeModal } = this.props;
    return (
      <Dialog
        title="Our most basic plan"
        titleStyle={{ textAlign: 'center' }}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="Back"
            onClick={() => {
              return changeModal(BILLING_ENUMS.PLAN_SELECTOR, null, true);
            }}
          />,
          <FlatButton
            label="Subscribe"
            disabled={selected === -1}
            primary={true}
            onClick={this.checkout}
          />,
        ]}
        open={open}
      >
        <div>
          As a testament to staying true to our roots of small solo practictioners, our ESSENTIALS plan includes the most basic features to
          get started with Medsender.
          <br />
          <br />
          We don&apos;t recommend it for most users, this plan only includes self-service support. EMR integration is also not included.
        </div>
        <div className="plan-selection-wrapper">
          {this.renderPlans()}
        </div>
      </Dialog>
    );
  }
}

export default BasicPlan;
