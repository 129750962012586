/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';
import {
  Table,
  Button,
  Tooltip,
  Input,
  Popconfirm,
  Spin,
  message,
} from 'antd';
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { verifyFeatureAccess } from '../../../global/featureFlags';

const { Search } = Input;

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ManageCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: null,
      currentPage: 1,
      selectedCategory: '',
      searchValue: '',
      canManageCategory: false,
    };
  }

  componentDidMount = async (page = 1) => {
    const { getCategoriesByPage, getUserInfo } = this.props;
    await getUserInfo();
    const params = {};
    params.page = page;
    const query = stringify(params, stringifyOptions);
    const canManageCategory = await verifyFeatureAccess('manage_category');
    await getCategoriesByPage(query);
    const { data } = this.props;
    this.setState({
      categories: data,
      canManageCategory,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      categories: nextProps.data,
    });
  }

  getQueryData = async (page = 1) => {
    const { location, headers, getCategoriesByPage } = this.props;
    const totalPages = (headers ? parseInt(headers.total_count, 10) : 1);
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
      // eslint-disable-next-line no-param-reassign
      page = parseInt((params.page <= totalPages ? params.page : 1), 10);
    }
    params.page = page;
    const query = stringify(params, stringifyOptions);
    await getCategoriesByPage(query);
    const { data } = this.props;
    this.setState({ categories: data });
  }

  openEditCategoryModal = (category) => {
    const {
      openModal,
      isAdmin,
    } = this.props;
    const modalType = {
      type: 'ADD_CATEGORY',
      data: {
        category,
        isAdmin,
      },
    };
    return openModal(modalType);
  }


  handleTableChange = (pagination) => {
    const { current: page } = pagination;
    const searchValue = '';
    this.setState({
      searchValue,
    });
    this.setState({
      currentPage: page,
    });
    const { getCategoriesByQuery: fetchCategories } = this.props;
    const query = `?page=${page}&contains=${searchValue}`;
    fetchCategories(query);
  };

  searchCategories = (value) => {
    const { paramTools, getCategoriesByQuery } = this.props;
    const { params } = paramTools;
    if (params.contains === '' || value === '') {
      delete params.contains;
      params.page = 1;
    } else {
      params.contains = value;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    this.setState({ searchValue: value, currentPage: 1 });
    const emptySearch = (value.trim() === '');
    if (emptySearch) return getCategoriesByQuery('');
    getCategoriesByQuery(query);
  }

  deleteCategory = async (category) => {
    const { deleteCategory } = this.props;
    await deleteCategory(category.id);
    const { error, errorCode } = this.props;
    if (error && errorCode === 403) {
      message.error('Cannot delete category. You need permission to perform this action');
    }

    this.getQueryData();
  }

  handlePageChange = async (page) => {
    const { getCategoriesByQuery: fetchCategories } = this.props;
    const { searchValue } = this.state;
    this.setState({
      currentPage: page,
    });
    const query = `?page=${page}&contains=${searchValue}`;
    await fetchCategories(query);
  };

  render() {
    const {
      categories,
      currentPage,
      selectedCategory,
      searchValue,
      canManageCategory,
    } = this.state;
    const isEditing = category => category.emr_category === selectedCategory;
    const {
      headers,
      isLoading,
      isAdmin,
    } = this.props;
    const totalResults = headers ? headers.total_count : 1;
    //localStorage.setItem('m8_document_categories', JSON.stringify(categories));
    let columns = [
      {
        title: (
          <Tooltip title="This is the dropdown list value of the category you want to be selected in your EMR during Auto-Upload. The category will be displayed in Medsender under the Document Title.">
            EMR Category Name
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'emr_category',
        dataIndex: 'emr_category',
      },
      {
        title: (
          <Tooltip title="This is your label currently assigned to the corresponding EMR Category Name. If the Medsender AI assigns an AI tag to a record, this EMR Category Name will be automatically applied as well.">
            AI Tag (Label)
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        dataIndex: 'ai_tag',
        key: 'ai_tag',
      },
      {
        title: '',
        key: 'edit',
        align: 'left',
        render: (_, category) => {
          const editable = isEditing(category);
          return editable ? (
            <span>
              <Button
                // eslint-disable-next-line no-script-url
                href="javascript:;"
                onClick={() => this.save(category)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Button>
              <Popconfirm title="Cancel edit without saving?" onConfirm={() => this.setState({ selectedCategory: '' })}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <Button disabled={selectedCategory !== ''} onClick={() => this.openEditCategoryModal(category)} style={{ marginRight: 10 }}>
                Edit
              </Button>
              {}
              {
                // if is admin and can manage categories then show delete button
                // if cannot manage categories show delete button
                ((canManageCategory && isAdmin) || !canManageCategory)
                && (
                  <Popconfirm title="Delete this Category? (This action is permanent and will remove any record reference for this category. This cannot be reversed. " onConfirm={() => this.deleteCategory(category)}>
                    <a>Delete</a>
                  </Popconfirm>
                )
              }
            </span>
          );
        },
      },
    ];

    if (isLoading) return (<Spin size="large" />);
    columns = columns.filter(column => column.dataIndex !== 'department');

    return (
      // eslint-disable-next-line object-curly-newline
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <h2>Manage Categories</h2>
            {((canManageCategory && isAdmin) || !canManageCategory)
              ? (
                <Button
                  style={{ marginLeft: '10px' }}
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  onClick={this.openEditCategoryModal}
                >
                  Add Category
                </Button>
              )
              : (
                <p />
              )
            }
          </div>
          <div>
            <Search
              placeholder="search categories"
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
              onSearch={this.searchCategories}
              style={{ width: 200, height: '32px', marginRight: '10px' }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            dataSource={categories}
            columns={columns}
            rowKey={record => record.ai_tag}
            bordered
            onChange={this.handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              total: totalResults,
              current: currentPage,
            }}
          />
        </div>
      </div>
    );
  }
}

ManageCategories.defaultProps = {
  isLoading: false,
  data: [],
  headers: {
    page: 1,
    'per-page': '10',
    total_count: 1,
  },
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  errorCode: '',
};

ManageCategories.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  headers: PropTypes.shape({
    page: PropTypes.number,
    'per-page': PropTypes.string,
    total_count: PropTypes.number,
  }),
  getCategoriesByPage: PropTypes.func.isRequired,
  paramTools: PropTypes.shape({
    params: PropTypes.object,
    stringify: PropTypes.func,
    stringifyOptions: PropTypes.object,
    push: PropTypes.func,
  }),
  location: PropTypes.object.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  getCategoriesByQuery: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(state => ({
  data: state.categories.data,
  isLoading: state.categories.isLoading,
  isAdmin: state.userProfile.isAdmin,
  headers: state.categories.headers,
  error: state.categories.error,
}))(ManageCategories);
