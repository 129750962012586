import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLoginInfo } from '../actions/auth';

class LoginMicrosoft extends Component {
  constructor() {
    super();
    this.state = {
      finishedLoad: false,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    const { getLoginInfo: getUserInfo } = this.props;
    await getUserInfo();
    this.setState({ finishedLoad: true });
  }

  render() {
    const { finishedLoad } = this.state;

    if (finishedLoad) {
      const path = sessionStorage.getItem('storedUrl');
      sessionStorage.removeItem('storedUrl');
      return (<Redirect to={path === 'null' ? '/app' : path} />);
    }

    return null;
  }
}

LoginMicrosoft.propTypes = {
  location: PropTypes.object.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
}

export default connect(null, { getLoginInfo })(LoginMicrosoft);

export {
  LoginMicrosoft,
};
