const initialState = {
  isLoading: false,
  error: false,
  errorMessage: null,
  sendingDialogVisible: false,
  canEditAfterSend: true,
};

const failedState = {
  ...initialState,
  error: true,
  isLoading: false,
  sendingDialogVisible: true,
};

function faxes(state = initialState, action) {
  switch (action.type) {
    case 'BEGIN_SEND_TO_MULTIPLE_RECIPIENTS':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
        sendingDialogVisible: true,
      };
    case 'FINISHED_SEND_TO_MULTIPLE_RECIPIENTS':
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_SEND_TO_MULTIPLE_RECIPIENTS':
      return {
        ...failedState,
        errorMessage:
          action.payload.response.data.message
          || action.payload.response.data.error,
      };
    case 'BEGIN_SEND_PATIENT_RECORD':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
        sendingDialogVisible: true,
      };
    case 'FINISHED_SEND_PATIENT_RECORD':
      return {
        ...state,
        isLoading: false,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_SEND_PATIENT_RECORD':
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
      }
    case 'CLOSE_SEND_DIALOG':
      return {
        ...state,
        sendingDialogVisible: false,
      };
    case 'FINISHED_GET_SINGLE_UPLOADED_RECORD': {
      return {
        ...state,
        canEditAfterSend: action.payload.data.can_edit,
      };
    }
    case 'UPDATE_CAN_EDIT_STATUS':
      return {
        ...state,
        canEditAfterSend: true,
      };
    default:
      return state;
  }
}

export default faxes;
