import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { connect } from 'react-redux';
import { message } from 'antd';

import {
  logout,
  validateAuth,
  redirectToCreateGuestAccount,
  addSubscription,
} from '../../actions/auth';
import { setNumberOfNewReferrals, incrementReferralCount, decrementReferralCount } from '../../actions/socket';
import {
  getUserInfo,
  getBillingUsage,
  changeModalState,
  loginAdmin,
  getDocumentationLink,
} from '../../actions/userProfile';
import { toggleSidebar } from '../../actions/appActions';
import { openModal } from '../../actions/modal';
import { hasAuthCreds } from '../../reducers/auth';
import theme from '../../global/material-ui-theme';

import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
} from '../../global/auth';

import { Nav, ConnectedSwitch, ErrorDisplay } from '../../components';
import BillingPrompt from '../../components/billing';
import HomeComponent from '../Home';
import LoginMicrosoftComponent from '../LoginMicrosoft';
import UploadedRecordsComponent from '../records/pages/UploadedRecords';
import ReceivedRecordsComponent from '../records/pages/ReceivedRecords';
import AssignedRecordsComponent from '../records/pages/AssignedRecords';
import ReferralManagementComponent from '../records/pages/ReferralManagement';
import ChromeRecordSelector from '../ChromeRecordSelector';
import ContactsComponent from '../../components/redesign/pages/Contacts';
import SentRecordsComponent from '../records/pages/SentRecords';
import SentRecordStatusComponent from '../SentRecordStatus';
// import LoginComponent from '../Login';
import LoginComponent from '../NewLogin/index';
import RecordViewerComponent from '../RecordViewer';
import RecordViewerGuestComponent from '../RecordViewerGuest';
import RecordViewerLinkComponent from '../RecordViewerLink';
import SignUpGuestComponent from '../SignUpGuest';
import PatientAccessComponent from '../PatientAccess';
import RecordViewerPatientComponent from '../RecordViewerPatient';
import RecordViewerIframeComponent from '../RecordViewerIframe';
import UserProfileComponent from '../../components/redesign/pages/UserProfile';
// import AccessCodeComponent from '../AccessCode';
import AccessCodeComponent from './AccessCode/index';
import Error404 from '../404';
import ModalRoot from '../ModalRoot';
// import ResetPasswordComponent from '../ResetPassword';
import ResetPasswordComponent from './ResetPassword/index';
// import ForgotPasswordComponent from '../ForgotPassword';
import ForgotPasswordComponent from '../ForgotPassword/index';
import IntroToMedsenderComponent from '../walkthrough/IntroToMedsender';
import PrinterSignInComponent from '../PrinterSignIn';
import MedsenderGuideComponent from '../MedsenderGuide';
import Dragger from './style';
import { verifyFeatureAccess } from '../../global/featureFlags';
import MessageNotificationsComponent from '../records/pages/MessageNotifications';

const Home = userIsAuthenticatedRedir(HomeComponent);
const LoginMicrosoft = userIsAuthenticatedRedir(LoginMicrosoftComponent);
const Login = userIsNotAuthenticatedRedir(LoginComponent);
const AccessCode = userIsNotAuthenticatedRedir(AccessCodeComponent);
const UploadedRecords = userIsAuthenticatedRedir(UploadedRecordsComponent);
const ReceivedRecords = userIsAuthenticatedRedir(ReceivedRecordsComponent);
const AssignedRecords = userIsAuthenticatedRedir(AssignedRecordsComponent);
const ReferralManagement = userIsAuthenticatedRedir(ReferralManagementComponent);
const ChromeRecordSelectorPage = userIsAuthenticatedRedir(ChromeRecordSelector);
const RecordViewerIframe = userIsAuthenticatedRedir(RecordViewerIframeComponent);
const SentRecords = userIsAuthenticatedRedir(SentRecordsComponent);
const SentRecordStatus = userIsAuthenticatedRedir(SentRecordStatusComponent);
const RecordViewer = userIsAuthenticatedRedir(RecordViewerComponent);
const Contacts = userIsAuthenticatedRedir(ContactsComponent);
const RecordViewerGuest = userIsNotAuthenticatedRedir(RecordViewerGuestComponent);
const SignUpGuest = userIsNotAuthenticatedRedir(SignUpGuestComponent);
const PatientAccess = userIsNotAuthenticatedRedir(PatientAccessComponent);
const RecordViewerPatient = userIsNotAuthenticatedRedir(RecordViewerPatientComponent);
const UserProfile = userIsAuthenticatedRedir(UserProfileComponent);
const ResetPassword = userIsNotAuthenticatedRedir(ResetPasswordComponent);
const ForgotPassword = userIsNotAuthenticatedRedir(ForgotPasswordComponent);
const IntroToMedsender = userIsAuthenticatedRedir(IntroToMedsenderComponent);
const PrinterSignIn = userIsAuthenticatedRedir(PrinterSignInComponent);
const MedsenderGuide = userIsAuthenticatedRedir(MedsenderGuideComponent);
const MesssageNotifications = userIsAuthenticatedRedir(MessageNotificationsComponent);

const MainRoutes = cable => (
  <ConnectedSwitch>
    <Route
      exact
      path="/app"
      render={props => (
        <Home
          {...props}
          cable={cable}
        />
      )}
    />
    <Route exact path="/app/login_microsoft" component={LoginMicrosoft} />
    <Route exact path="/app/contacts" component={Contacts} />
    <Route exact path="/app/uploads" component={UploadedRecords} />
    <Route exact path="/app/sent" component={SentRecords} />
    <Route exact path="/app/received" component={ReceivedRecords} />
    <Route exact path="/app/assigned" component={AssignedRecords} />
    <Route exact path="/app/referred" component={ReferralManagement} />
    <Route exact path="/app/view/:id" render={props => (<RecordViewer {...props} cable={cable} />)} />
    <Route exact path="/app/records/status/record/:record_id/receiver/:id" component={SentRecordStatus} />
    <Route exact path="/app/user" component={UserProfile} />
    <Route exact path="/app/access_code" component={AccessCode} />
    <Route exact path="/app/access" component={RecordViewerGuest} />
    <Route exact path="/app/view/:record_id/link/:id" component={RecordViewerLinkComponent} />
    <Route exact path="/app/access/sign_up" component={SignUpGuest} />
    <Route exact path="/app/patient/:patient_id/record/:id" component={PatientAccess} />
    <Route exact path="/app/patient" component={PatientAccess} />
    <Route exact path="/app/patient_access" component={RecordViewerPatient} />
    <Route exact path="/app/chrome/received_selector" component={ChromeRecordSelectorPage} />
    <Route exact path="/app/chrome/uploaded_selector" component={ChromeRecordSelectorPage} />
    <Route exact path="/app/chrome/view/:id" component={RecordViewerIframe} />
    <Route exact path="/app/forgot_password" component={ForgotPassword} />
    <Route exact path="/app/walkthrough" component={IntroToMedsender} />
    <Route exact path="/app/printer_sign_in" component={PrinterSignIn} />
    <Route exact path="/app/guide" component={MedsenderGuide} />
    <Route exact path="/app/unread_messages" component={MesssageNotifications} />
    <Route component={Error404} />
  </ConnectedSwitch>
);

const userAuthenticated = hasAuthCreds();

const HandleIndex = () => {
  if (userAuthenticated === true) {
    return (<Redirect to="/app" />);
  }
  return (<Redirect to="/login" />);
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      isBillingTracked: false,
      isUserTracked: false,
    };
    this.trackBilling = this.trackBilling.bind(this);
    this.trackUser = this.trackUser.bind(this);
    this.renderMainRoutes = this.renderMainRoutes.bind(this);
  }

  componentWillMount() {
    this.props.validateAuth();
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.trackBilling();
      this.trackUser();
    });
  }

  trackBilling() {
    const { user } = this.props;
    const { isBillingTracked } = this.state;
    const isAuthenticated = user !== null;
    const { profitwell } = window;
    const { isAdmin, intercomHmac } = this.props;

    if (isAuthenticated && !isBillingTracked) {
      const customerPaymentId = localStorage.getItem('customer_payment_id');

      let email = localStorage.getItem('email') || localStorage.getItem('m8_uid') || user.uid;
      if (email === 'undefined') {
        // Sometimes local storage has the value undefined. if so let's use the user uid attribute.
        email = user.uid;
      }

      let name = localStorage.getItem('m8_name') || user.fullName;
      if (name === 'undefined') {
        // Sometimes local storage has the value undefined. if so let's use the user uid attribute.
        name = user.fullName;
      }
      const customerPaymentIdExists = customerPaymentId && customerPaymentId !== 'null' && customerPaymentId !== 'undefined';

      if (name && intercomHmac) {
        const userFlags = JSON.parse(localStorage.getItem('flags'));

        let { autoUploadEmr } = user;
        if (autoUploadEmr === 'null') {
          autoUploadEmr = '';
        }

        const userData = {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.INTERCOM_APP_ID,
          name: user.fullName,
          email,
          user_hash: intercomHmac,
          organization: user.organization,
          plan_type: localStorage.getItem('m8_plan_type'),
          emr: autoUploadEmr || user.emr,
          validEMRCredentials: !!autoUploadEmr,
          isAdmin,
        };
        if (userFlags && userFlags.data) {
          userFlags.data.flags.forEach((flag) => {
            if (flag.name === 'referral_mgmt_trial' && flag.active && flag.expiration) {
              // If the flag is referral mgmt trial and there is an expiry, include
              // if trial is expired or not.
              const expiration = Date.parse(flag.expiration);
              userData.referralMgmtTrialExpired = expiration < new Date();
            }
            userData[flag.name] = flag.active;
          });
        }
        window.Intercom('boot', userData);
      }

      if ((customerPaymentIdExists || email) && profitwell) {
        profitwell('user_id', customerPaymentIdExists ? customerPaymentId : email);
        this.setState({ isBillingTracked: true });
      }
    }
    if (!isAuthenticated && isBillingTracked && profitwell) {
      profitwell('user_id', null);
      this.setState({ isBillingTracked: false });
    }
  }

  async trackUser() {
    const {
      user,
      billingData,
      isTrialUser,
      isAdmin,
    } = this.props;

    const { isUserTracked } = this.state;
    const isAuthenticated = user !== null;
    const { pendo } = window;

    const defaultVisitorId = 'VISITOR-UNIQUE-ID';
    const defaultAccountId = 'ACCOUNT-UNIQUE-ID';

    if (isAuthenticated && !isUserTracked) {
      const customerPaymentId = localStorage.getItem('customer_payment_id');
      const fullName = localStorage.getItem('m8_name');
      const email = localStorage.getItem('email') || localStorage.getItem('m8_uid') || user.uid;

      if (email && pendo) {
        const isPendoInitialized = pendo.visitorId === defaultVisitorId;
        if (isPendoInitialized) {
          pendo.identify(user.uid, user.organization);
        } else {
          const isAiEnabled = await verifyFeatureAccess('ai_extraction');
          const isDocClassificationEnabled = await verifyFeatureAccess('document_classification');
          const autoUploadEnabled = await verifyFeatureAccess('auto_upload_emr');
          const manualUploadEnabled = await verifyFeatureAccess('manual_upload_emr');
          const createChartEnabled = await verifyFeatureAccess('create_patient_chart');
          const titleExtractionEnabled = await verifyFeatureAccess('title_extraction');
          const aiTrial = await verifyFeatureAccess('ai_trial');
          const referralMgmtTrial = await verifyFeatureAccess('referral_mgmt_trial');
          pendo.initialize({
            visitor: {
              id: user.uid,
              email,
              full_name: fullName,
              role: user.jobTitle,
            },

            account: {
              id: user.organization,
              is_paying: !isTrialUser,
              emr: user.emr,
              name: user.organization,
              planLevel: billingData.plan_type,
              trialEndDate: billingData.trial_end_date,
              paymentId: customerPaymentId,
              isAdmin,
              aiTrial,
              isAiEnabled,
              isDocClassificationEnabled,
              autoUploadEnabled,
              manualUploadEnabled,
              createChartEnabled,
              titleExtractionEnabled,
              referralMgmtTrial,
            },
          });
        }

        this.setState({ isUserTracked: true });
      }
    }
    // user signs out
    if (!isAuthenticated && isUserTracked) {
      pendo.identify(defaultVisitorId, defaultAccountId);
      this.setState({ isUserTracked: false });
    }
  }

  logout() {
    window.Intercom('shutdown');
    this.props.logout();
  }

  renderMainRoutes() {
    let currentDroppedFiles = [];
    const isOnWindows = ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(window.navigator.platform) !== -1 && navigator.userAgent.indexOf('Windows NT 5.1') === -1;
    const onDropFiles = async (info) => {
      const canUpload = await verifyFeatureAccess('upload_record');
      const modal = canUpload ? 'UPLOAD_RECORD' : 'RAISE_FLAG';
      const droppedFileList = info?.fileList?.filter(file => currentDroppedFiles.some(droppedFile => droppedFile.uid === file.uid));
      currentDroppedFiles = [];

      const newFileList = droppedFileList.filter(file => file.type.includes('pdf'));

      // Error for when a non-pdf file is dragged and dropped
      const errorText = isOnWindows ? 'Only PDF files can be dragged. You can use the print driver to upload non-PDF files.' : 'Only PDF files can be uploaded';
      if (newFileList.length !== droppedFileList.length) {
        message.destroy();
        message.error({
          content: errorText,
          style: { marginTop: '70px' },
        });
      }

      if (!newFileList.length) return;

      const modalType = {
        type: modal,
        ...(canUpload ? { data: { fileList: newFileList } } : { featureFlag: 'upload_record' }),
      };
      this.props.openModal(modalType);
    };

    return (
      <Dragger
        multiple
        onChange={onDropFiles}
        maxCount={5}
        beforeUpload={(file) => { currentDroppedFiles.push(file); return false; }}
        previewFile={false}
        openFileDialogOnClick={false}
        showUploadList={false}
        disabled={this.props.user === null}
      >
        {MainRoutes(this.props.cable)}
      </Dragger>
    );
  }


  render() {
    const pagesToHideNavBar = ['/app/login_microsoft', '/app/forgot_password', '/app/access_code', '/reset_password'];
    const onMobile = window.innerWidth < 800;
    const {
      error,
      errorCode,
      errorMessage,
      loading,
      user,
    } = this.props;

    return (
      <ConnectedRouter history={this.props.history} cable={this.props.cable}>
        <MuiThemeProvider muiTheme={theme}>
          <>
            {(!pagesToHideNavBar.includes(window.location.pathname))
            && (
            <Nav
              auth={this.props.user !== null}
              guest={this.props.guest === true}
              createGuest={this.props.createGuest === true}
              user={this.props.user}
              logout={this.logout.bind(this)}
              docked={this.props.docked}
              toggle={this.props.toggleSidebar}
              openModal={this.props.openModal}
              changeModal={this.props.changeModalState}
              patient={this.props.patient}
              patientAuth={this.props.patientAuthenticated === true}
              faxSource={this.props.fax.source}
              redirectToCreateGuestAccount={this.props.redirectToCreateGuestAccount}
              billingTrialDetails={this.props.billingTrialDetails}
              loginErrorMessage={this.props.loginErrorMessage}
              adminLoginSuccess={this.props.adminLoginSuccess}
              loginAdmin={this.props.loginAdmin}
              isAdmin={this.props.isAdmin}
              cable={this.props.cable}
              setNumberOfNewReferrals={this.props.setNumberOfNewReferrals}
              incrementReferralCount={this.props.incrementReferralCount}
              decrementReferralCount={this.props.decrementReferralCount}
              newReferralsCount={this.props.newReferralsCount}
            />
            )}
            <div style={{ marginLeft: this.props.user !== null && !window.location.pathname.includes('app/chrome/') && !onMobile && '60px' }}>
              <ModalRoot />
              { /* TODO: Add billing for lockout */ }
              { (!this.props.billing && this.props.user !== null)
              && (
                <BillingPrompt
                  modalState={this.props.billingModalState}
                  modalPlan={this.props.billingModalPlan}
                  modalOpen={this.props.billingModalOpen}
                  changeModal={this.props.changeModalState}
                  billingUsage={this.props.billingUsage}
                  billingInfo={this.props.getBillingUsage}
                  userBillingInfo={{
                    name: this.props.userName,
                    email: this.props.userEmail,
                    organization: this.props.userOrganization,
                  }}
                  billingTrialDetails={this.props.billingTrialDetails}
                  planType={this.props.billingTrialDetails && this.props.billingTrialDetails.plan_type}
                  getUserInfo={this.props.getUserInfo}
                  addSubscription={this.props.addSubscription}
                  openModal={this.props.openModal}
                  logout={this.logout.bind(this)}
                />
              )
              }
              { error && !loading && user
                ? (
                  <div style={{ textAlign: 'center' }}>
                    <ErrorDisplay message={errorMessage} error={errorCode} />
                  </div>
                ) : (
                  <ConnectedSwitch>
                    <Route exact path="/" render={HandleIndex} />
                    <Route path="/app" component={this.renderMainRoutes} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/reset_password" component={ResetPassword} />
                    <Route component={Error404} />
                  </ConnectedSwitch>
                )
              }

            </div>
          </>
        </MuiThemeProvider>
      </ConnectedRouter>
    );
  }
}

App.propTypes = {
  logout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  redirectToCreateGuestAccount: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  validateAuth: PropTypes.func.isRequired,
  user: PropTypes.object,
  billingData: PropTypes.object.isRequired,
  isTrialUser: PropTypes.bool.isRequired,
  guest: PropTypes.bool.isRequired,
  createGuest: PropTypes.bool.isRequired,
  docked: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  patient: PropTypes.bool.isRequired,
  patientAuthenticated: PropTypes.bool.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  loginErrorMessage: PropTypes.string.isRequired,
  adminLoginSuccess: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  setNumberOfNewReferrals: PropTypes.func.isRequired,
  incrementReferralCount: PropTypes.func.isRequired,
  decrementReferralCount: PropTypes.func.isRequired,
  newReferralsCount: PropTypes.number.isRequired,
  cable: PropTypes.object.isRequired,
  intercomHmac: PropTypes.string.isRequired,
};

export default connect(state => ({
  user: state.auth.data,
  billingData: state.auth.billing,
  isTrialUser: state.auth.trialUser,
  fax: state.auth.fax,
  userName: state.userProfile.name,
  userEmail: state.userProfile.email,
  userOrganization: state.userProfile.organization,
  isAdmin: state.auth.isAdmin,
  guest: state.auth.guest,
  createGuest: state.auth.createGuest,
  patient: state.auth.patient,
  intercomHmac: state.auth.intercomHmac,
  billing: state.auth.billingOnFile,
  billingTrialDetails: state.auth.billing,
  billingModalState: state.userProfile.modalState,
  billingModalPlan: state.userProfile.modalPlan,
  billingModalOpen: state.userProfile.modalOpen,
  billingUsage: state.userProfile.billing,
  docked: state.appState.sidebarVisible,
  patientAuthenticated: state.auth.patientAuthenticated,
  loginErrorMessage: state.userProfile.loginErrorMessage,
  adminLoginSuccess: state.userProfile.loginSuccess,
  error: state.appState.error,
  errorMessage: state.appState.errorMessage,
  errorCode: state.appState.errorCode,
  loading: state.appState.isLoading,
  newReferralsCount: state.socket.newReferralsCount,
}), {
  logout,
  validateAuth,
  toggleSidebar,
  openModal,
  redirectToCreateGuestAccount,
  getBillingUsage,
  changeModalState,
  getUserInfo,
  setNumberOfNewReferrals,
  incrementReferralCount,
  decrementReferralCount,
  addSubscription,
  loginAdmin,
})(App);
