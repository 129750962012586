import React, { Component } from 'react';
import FallbackViewer from './FallbackViewer';
import PSPDFViewer from './PSPDFViewer';
import { isOnUnsupportedBrowser } from '../../global/browser.js'

class ViewerContainer extends Component {
  render() {
    if (isOnUnsupportedBrowser()) {
      return (<FallbackViewer {...this.props} />);
    }
    return (<PSPDFViewer {...this.props} />);
  }
}

export default ViewerContainer;
