import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddTaskTemplate from '../../components/modal/AddTaskTemplate';
import {
  createNewTaskTemplate,
  updateTaskTemplate,
  getTaskTemplates,
  getEmaTaskProviders,
  getDocumentCategories,
} from '../../actions/userProfile';
import { getUsedDepartmentCategories, getUsedOrganizationCategories } from '../../actions/categories';

class AddTaskTemplateDialog extends Component {
  static propTypes = {
    createNewTaskTemplate: PropTypes.func.isRequired,
    updateTaskTemplate: PropTypes.func.isRequired,
    getTaskTemplates: PropTypes.func.isRequired,
    getEmaTaskProviders: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <AddTaskTemplate
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  emaTaskTemplates: state.auth.data,
}),
{
  createNewTaskTemplate,
  updateTaskTemplate,
  getTaskTemplates,
  getEmaTaskProviders,
  getDocumentCategories,
  getUsedDepartmentCategories,
  getUsedOrganizationCategories,
})(AddTaskTemplateDialog);
