// 5/21: Replaces the old edit tag modal because this new feature only supports notifying other users/departments
// about new received records. Old tag feature has been migrated to new label feature.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Input,
  Form,
  Tag,
  message,
} from 'antd';

import ModalWrapper from './ModalWrapper';
import { validEmail } from '../../global/validation';

const { CheckableTag } = Tag;

const errorStyle = {
  color: 'red',
};

class Notify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: props.modalType.data.tag,
      sendTokens: props.modalType.data.record,
      departments: [],
      selectedDepartment: '',
      invalidEmailError: '',
    };
  }

  componentDidMount = async () => {
    const { getOrganizationDepartments } = this.props;
    await getOrganizationDepartments();
    const { allOrganizationDepartments, modalType } = this.props;
    this.setState({ departments: allOrganizationDepartments });
    // eslint-disable-next-line array-callback-return
    allOrganizationDepartments.map((department) => {
      if (department.name === modalType.data.tag) {
        this.setState({
          tag: '',
          selectedDepartment: modalType.data.tag,
        });
      }
    });
  }

  handleNotifyInputChange = (event) => {
    this.setState({
      tag: event.target.value,
      selectedDepartment: '',
    });
  }

  update = async (event) => {
    event.preventDefault();
    const { sendTokens, tag, selectedDepartment } = this.state;
    const { editTag } = this.props;
    // allowing users to submit empty tag field to clear the existing tag
    const trimmedTag = tag ? tag.trim() : '';
    // we're only allowing users to use one method of notification (i.e input or select a department) so depending on which one
    // is defined, we're sending in the correct field of information.
    const notifyInfo = trimmedTag || selectedDepartment;
    if (trimmedTag && !validEmail(trimmedTag)) {
      this.setState({ invalidEmailError: 'Please enter a valid email address' });
      return;
    }
    await editTag(sendTokens, notifyInfo);

    const { updatedTag, emailValidMessage, hideModal } = this.props;

    if (updatedTag) {
      hideModal();
      if (emailValidMessage === 'Unable to notify: User does not exist or is revoked') {
        const errorMessage = emailValidMessage;
        message.error({
          content: errorMessage,
          style: { marginTop: '70px' },
        });
      } else {
        message.success({
          content: emailValidMessage,
          style: { marginTop: '70px' },
        });
        this.updateRowSelected();
      }
    } else {
      message.error({
        content: 'Email doesn\'t exist',
        style: { marginTop: '70px' },
      });
    }
  }

  selectDepartment = (index) => {
    // eslint-disable-next-line react/destructuring-assignment
    const userSelectedDepartment = this.state.departments[index].name;
    this.setState({
      tag: '',
      // eslint-disable-next-line react/destructuring-assignment
      selectedDepartment: userSelectedDepartment,
    });
  }

  updateRowSelected() {
    const { tag, fileToken, selectedDepartment } = this.state;
    const { modalType, rowSelected } = this.props;
    // we're only allowing users to use one method of notification (i.e input or select a department) so depending on which one
    // is defined, we're sending in the correct field of information.
    const notifyInfo = tag || selectedDepartment;
    const selectedRow = {
      tag: notifyInfo,
      file_token: fileToken,
      index: modalType.data.record.index,
    };
    rowSelected([selectedRow]);
  }

  renderModal = () => {
    const { isLoading } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { tag, invalidEmailError, departments, selectedDepartment } = this.state;
    if (isLoading) {
      return (<span> Updating tag, Please wait... </span>);
    }

    return (
      <React.Fragment>
        <p>Want to notify someone in your organization about this record? Type their email address below and we'll notify them.</p>
        <p style={errorStyle}>{invalidEmailError}</p>
        <Form
          onFinish={this.update}
          id="notifyUser"
        >
          <Form.Item
            name="tag"
            label="Notify"
            hasFeedback
          >
            <div style={{ display: 'none' }}>{this.state.tag}</div>
            <Input
              onChange={this.handleNotifyInputChange}
              value={tag}
              ref={(input) => { if (input != null) input.focus(); }}
              rules={[
                () => ({
                  validator(_, email) {
                    if (email && validEmail(email.trim())) return Promise.resolve();
                    return Promise.reject('Please enter a valid email');
                  },
                }),
              ]}
            />
          </Form.Item>
          <p style={{ marginBottom: '5px' }}>Or select a department from the list below.</p>
          <div style={{ fontSize: '12px', color: 'grey' }}>
            Note: If you select a department you are not a part of, you will be unable to view the record from your received inbox
          </div>
          <Form.Item
            name="department"
          >
            {departments && departments.map((department, index) => (
              <CheckableTag
                checked={selectedDepartment === department.name}
                style={{ cursor: 'pointer', border: '1px dotted #1890ff' }}
                onClick={() => this.selectDepartment(index)}
              >
                {department.name}
              </CheckableTag>
            ))}
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal } = this.props;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.update}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={isLoading}
        modalTitle="Notify"
        form="notifyUser"
        isDraggable={true}
      >
        {this.renderModal()}
      </ModalWrapper>
    );
  }
}

Notify.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  editTag: PropTypes.func.isRequired,
  rowSelected: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  emailValidMessage: PropTypes.string.isRequired,
  updatedTag: PropTypes.bool.isRequired,
  allOrganizationDepartments: PropTypes.array.isRequired,
  getOrganizationDepartments: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedTag: state.records.updatedTag,
  data: state.labels.data,
  departments: state.userProfile.departments,
  allOrganizationDepartments: state.userProfile.allOrganizationDepartments,
}))(Notify);
