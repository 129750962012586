import { EmailNotif, ChangePassword } from '../../../assets/img/guide';

const faqCopy = {
  pageTitle: 'Frequently Asked Questions',
  sections: [
    {
      title: 'How do I reset my password?',
      id: 'resetPass',
      subtitle: `When you sign into Medsender for the first time on a new computer, you may need to install the 
      Medsender Chrome extension to see the Medsender options inside of your EMR system.
      `,
      img: ChangePassword,
      divider: true,
    },
    {
      title: 'How do I turn on/off email notifications for incoming records?',
      id: 'emailNotifs',
      subtitle: `
      You can enable or disable email notifications for incoming records by navigating to the 
      Settings tile on the Medsender dashboard. Once in settings, you can click on the tab at the top that says “Settings.” 
      To stop receiving notifications for incoming records, simply uncheck the box.`,
      img: EmailNotif,
      divider: true,
    },
    {
      title: 'Porting a fax line',
      id: 'portingFax',
      subtitle: `You can transfer any fax number that you own to Medsender! When you transfer a number to 
      Medsender, you will receive all your faxes electronically right into Medsender.
      Once you submit the request, our porting team will reach out to your current provider 
      to port over the number. Prior to the transfer, we will let you know when the number is 
      scheduled to be ported. The transition is seamless—there is no downtime and you will not lose faxes. 
      Note that you must not cancel your current service until the number has officially been transferred 
      into your Medsender account, and any physical fax machine using that number will no longer be able to utilize the number.`,
    },
    {
      subtitle: 'To transfer a number, complete the port request form here.',
      link: 'https://medsender.com/port/',
    },
  ],
};

export default faqCopy;
