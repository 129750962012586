import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import isOnChrome from '../../../global/browser';

const chromeExtLink = `https://chrome.google.com/webstore/detail/medsender/${process.env.CHROME_EXT_ID}`;

class DownloadChromeExtension extends Component {
  state = {
    chromeExtensionInstalled: false,
    isUsingEdgeOrChrome: false,
  }

  componentDidMount() {
    if (isOnChrome()) {
      this.showChromeExtensionInformation();
      this.setState({ isUsingEdgeOrChrome: true });
    }
  }

  checkChromeExtensionInstallation = () => {
    const ping = { message: 'ping' };
    const chromeExtId = process.env.CHROME_EXT_ID;
    return new Promise((resolve, reject) => {
      /* global chrome */
      chrome.runtime.sendMessage(chromeExtId, ping, (response) => {
        if (response && response.message) return resolve();
        return reject(chrome.runtime.lastError.message || 'Error');
      });
    });
  }

  showChromeExtensionInformation = () => {
    this.checkChromeExtensionInstallation()
      .then(() => {
        this.setState({ chromeExtensionInstalled: true });
      })
      .catch(() => {
        this.setState({ chromeExtensionInstalled: false });
      });
  }

  render() {
    const { isUsingEdgeOrChrome, chromeExtensionInstalled } = this.state;
    const { hideModal } = this.props;
    return (
      <Dialog
        open={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="CLOSE"
            primary={true}
            keyboardFocused={true}
            onClick={hideModal}
          />,
        ]}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span style={{ color: 'black', fontWeight: '700', marginBottom: '15px' }}>Forward to EMR</span>
          {chromeExtensionInstalled
            ? (
              <p style={{ lineHeight: '30px', textAlign: 'center' }}>
                {`Looks like you already have our chrome extension installed! To import documents into any patient’s chart without
                having to download or upload it manually, open your EMR in this web browser,
                then navigate to the documents section and click on the purple Medsender button.`}
              </p>
            )
            : (
              <>
                <p style={{ lineHeight: '30px', textAlign: 'center' }}>
                Good news! Medsender has a custom chrome browser integration for your EMR. Install it now for easy
                receiving and sending straight to your EMR.
                </p>
                <RaisedButton
                  label="Install Chrome Extension"
                  style={{ margin: '12px', backgroundColor: '#794ab2', borderRadius: '4px', color: 'white' }}
                  onClick={() => {
                    const win = window.open(chromeExtLink, 'chrome link', 'width=1000, height=600');
                    win.opener = null;
                  }}
                  primary={true}
                />
                <p style={{ lineHeight: '30px', textAlign: 'center', marginBottom: '0' }}>
                  {`Once the extension is installed, open your EMR in ${isUsingEdgeOrChrome ? 'this web browser' : 'the Chrome browser'},
                  navigate to the documents section and click the purple Medsender button to import documents straight into any patient's chart.`}
                </p>
              </>
            )
          }
        </div>
      </Dialog>
    );
  }
}

export default DownloadChromeExtension;
