import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import ModalWrapper from './ModalWrapper';

const btnStyle = {
  textAlign: 'center',
  margin: '5px 0',
};

class FaxNumberInstructions extends Component {
  // Handler for the Add Fax Number button. Shows the modal to upload new record.
  handleOpenAddFaxNumberModal = () => {
    const { openModal } = this.props;
    const modalType = {
      type: 'ADD_FAX_NUMBER',
    };
    return openModal(modalType);
  }

  renderInstructions = () => {
    const { isAdmin } = this.props;
    return (
      <ol>
        {
          isAdmin && (
            <li style={{ marginTop: '20px' }}>
              If you would like a new fax number, click here to select a number with your preferred area code&nbsp;
              <div style={btnStyle}>
                <Button
                  onClick={this.handleOpenAddFaxNumberModal}
                >
                  NEW FAX NUMBER
                </Button>
              </div>
            </li>
          )
        }
        <li>
          <span>
          To port an existing fax number that you own to Medsender, please click here&nbsp;
          </span>
          <div style={btnStyle}>
            <Button
              type="primary"
              href="https://medsender.com/port"
              target="blank"
            >
              PORT FAX NUMBER
            </Button>
          </div>
        </li>
      </ol>
    );
  };

  render() {
    const { hideModal } = this.props;
    return (
      <ModalWrapper
        hideModal={hideModal}
        width={675}
        dismiss="Close"
        modalTitle="Instructions for setting up a fax number"
      >
        {this.renderInstructions()}
      </ModalWrapper>
    );
  }
}

FaxNumberInstructions.propTypes = {
  openModal: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default FaxNumberInstructions;
