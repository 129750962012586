/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Select,
  Input,
  Form,
  message,
  AutoComplete,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalWrapper from './ModalWrapper';

class AddTaskTemplate extends Component {
  constructor(props) {
    super(props);
    this.scopeOptions = ['User', 'Department', 'Organization'];
    this.priorityOptions = ['Normal', 'High', 'STAT'];
    this.taskOptions = [
      'Authorization', 'Communication', 'Fax', 'Notify', 'Obtain', 'Order', 'Pathology', 'Prescribe', 'Reassure',
      'Recall', 'Re-Evaluate', 'Refer', 'Return To Clinic', 'Review', 'Schedule', 'Sign', 'Test',
    ];

    this.taskDetailsOptions = [
      'Additional Testing', 'Benign', 'Claim Review', 'Discuss at Follow-up', 'Follow-up Appt', 'Labs', 'PCP',
      'Medication', 'Normal', 'No Further Treatment', 'Procedure', 'Referring Provider', 'Results', 'Sign/Cosign',
      'Surgery',
    ];
    this.scopeOptionNames = {
      User: [localStorage.getItem('m8_name')],
      Department: props.modalType.data.userDepartments,
      Organization: [props.modalType.data.userOrganization],
    };
    this.state = {
      templateTaskName: props.modalType.data.template.task_name,
      searchedNames: null,
      editingTemplateID: '',
      task: props.modalType.data.template.task_type,
      searchedTasks: this.taskOptions,
      taskDetails: props.modalType.data.template.task_details,
      scopeOption: props.modalType.data.template.scope,
      priority: props.modalType.data.template.priority,
      scopeOptionNameOption: props.modalType.data.template.scope_name,
      assignTo: props.modalType.data.template.assign_to_as_array || undefined,
      scopeOptionNameOptions: this.scopeOptionNames[props.modalType.data.template.scope] || this.scopeOptionNames.User,
      taskDetailsSearchOptions: this.taskDetailsOptions,
      documentCategory: props.modalType.data.template.emr_category,
      documentCategoryId: props.modalType.data.template.document_category_id,
      documentCategories: [],
      usedOrgCategories: [],
      usedDepCategories: [],
      availableCategories: [],
    };
  }

  // Helper function to set available document categories to choose from depending on if scope is
  // Organization or Department.
  setUsedCategories(deptName) {
    const {
      documentCategories,
      usedOrgCategories,
      usedDepCategories,
      documentCategoryId,
      scopeOption,
    } = this.state;
    if (scopeOption === 'Organization') {
      this.state.availableCategories = [];
      documentCategories.forEach((category) => {
        // If the category does not coantin a used category we will add that to the availableCatgories array for the user
        // to pick from. Additonally if the user is editing an existing Template any documentCategory that was
        // previously assigned to the template is also available.
        if (!usedOrgCategories.includes(category.id) || category.id === documentCategoryId) {
          this.state.availableCategories.push({ id: category.id, emr_category: category.emr_category });
        }
      });
    } else if (scopeOption === 'Department') {
      this.state.availableCategories = [];
      const deptCategoryObjects = JSON.parse(usedDepCategories);
      if (deptCategoryObjects.length === 0) {
        // No Dept categories exist, make available all document categories for the department.
        this.state.availableCategories = documentCategories;
        return;
      }
      deptCategoryObjects.forEach((deptCategoryObject) => {
        if (deptCategoryObject.dept_name === deptName) {
          documentCategories.forEach((category) => {
            // If the category does not coantin a used category we will add that to the availableCatgories array for the user
            // to pick from. Additonally if the user is editing an existing Template any documentCategory that was
            // previously assigned to the template is also available.
            if (!deptCategoryObject.used_document_category_ids.includes(category.id) || category.id === documentCategoryId) {
              this.state.availableCategories.push({ id: category.id, emr_category: category.emr_category });
            }
          });
        }
      });
    } else {
      return;
    }
    this.state.availableCategories.sort((a, b) => (a.emr_category > b.emr_category ? 1 : -1));
  }

  componentDidMount = async () => {
    const { modalType } = this.props;
    const { template } = modalType.data;
    const {
      getEmaTaskProviders,
      getDocumentCategories,
      getUsedOrganizationCategories,
      getUsedDepartmentCategories,
    } = this.props;
    const { sendToken } = this.state;
    await getEmaTaskProviders(sendToken);
    await getDocumentCategories();
    await getUsedOrganizationCategories();
    this.setState({ usedOrgCategories: this.props.orgUsedCategories });
    await getUsedDepartmentCategories();
    this.setState({ usedDepCategories: this.props.deptsUsedCategories });
    this.setState({
      searchedNames: this.props.allEmaTaskProviders,
      documentCategories: this.props.allDocumentCategories.categories,
    });
    this.setState({ searchedNames: this.props.allEmaTaskProviders });
    const { assignTo } = this.state;
    if (this.state.assignTo) {
      const assignToAsArray = assignTo.sort();
      this.setState({ assignTo: assignToAsArray });
    }
    if (template.task_name) {
      this.setUsedCategories(this.state.scopeOptionNameOption);
      this.setState({
        editingTemplateID: template.id,
        documentCategoryId: template.document_category_id,
      });
    }
  }

  addTemplate = async () => {
    const { createNewTaskTemplate, getTaskTemplates } = this.props;
    const {
      templateTaskName,
      assignTo,
      task,
      taskDetails,
      priority,
      scopeOption,
      scopeOptionNameOption,
      documentCategoryId,
    } = this.state;
    if (!templateTaskName || templateTaskName.trim() === '') return;
    const newTaskTemplate = {
      task_name: templateTaskName.trim(),
      task_type: task,
      assign_to: assignTo,
      task_details: taskDetails,
      priority,
      scope: scopeOption,
      scope_name: scopeOptionNameOption,
      document_category_id: documentCategoryId,
    };
    await createNewTaskTemplate(newTaskTemplate);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error adding template. Duplicate template value already exists.' : 'Error adding template. Please try again.';
    if (!error) {
      hideModal();
      message.success({
        content: 'Task Template successfully added',
        style: { marginTop: '70px' },
      });
      getTaskTemplates();
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
      getTaskTemplates();
    }
    window.location.reload();
  }

  editTemplate = async () => {
    const { updateTaskTemplate, getTaskTemplates } = this.props;
    const {
      templateTaskName,
      editingTemplateID,
      assignTo,
      task,
      taskDetails,
      priority,
      scopeOption,
      scopeOptionNameOption,
      documentCategoryId,
    } = this.state;
    let assignToArray = assignTo;
    if (!Array.isArray(assignTo)) {
      assignToArray = [assignTo];
    }
    const newTaskTemplate = {
      task_name: templateTaskName.trim(),
      task_type: task,
      assign_to: assignToArray,
      task_details: taskDetails,
      priority,
      scope: scopeOption,
      scope_name: scopeOptionNameOption,
      document_category_id: documentCategoryId,
    };
    await updateTaskTemplate(editingTemplateID, newTaskTemplate);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error updating template.' : 'Error updating template. Please try again.';
    hideModal();
    if (!error) {
      message.success({
        content: 'Task Template successfully updated',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
    }
    getTaskTemplates();
    window.location.reload();
  }

  renderModal = () => {
    const { isLoading, modalType, allEmaTaskProviders } = this.props;
    const { isAdmin } = modalType.data;
    const {
      templateTaskName,
      editingTemplateID,
      task,
      taskDetails,
      scopeOption,
      scopeOptionNameOption,
      assignTo,
      priority,
      documentCategory,
    } = this.state;

    const handleAssignToChange = (e) => {
      this.setState({ assignTo: e });
    };

    const handleAssignToSearch = (e) => {
      if (e) {
        const filtered = allEmaTaskProviders.filter(provider => provider.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedNames: filtered });
      } else {
        this.setState({ searchedNames: allEmaTaskProviders });
      }
    };

    const handleTaskSearch = (e) => {
      if (e) {
        const filtered = this.taskOptions.filter(tasktype => tasktype.toLowerCase().includes(e.toLowerCase()));
        this.setState({ searchedTasks: filtered });
      } else {
        this.setState({ searchedTasks: this.taskOptions });
      }
    };

    const handleTaskChange = (e) => {
      this.setState({ task: e });
    };

    const handleSearchTaskDetails = (e) => {
      this.setState({ taskDetails: e });
      const filteredOptions = this.taskDetailsOptions.filter(option => (
        option.toLowerCase().includes(e.toLowerCase())));
      this.setState({ taskDetailsSearchOptions: filteredOptions });
    };

    const handleTaskDetailsSelection = (e) => {
      this.setState({ taskDetails: e });
    };

    const handlePriorityChange = (e) => {
      this.setState({ priority: e });
    };

    const handleDocumentCategoryChange = (e) => {
      if (e === '') {
        // We will clear any previously selected document category when scope or scope name
        // changes.
        this.setState({ documentCategoryId: null, documentCategory: null });
      } else {
        const { documentCategories } = this.state;
        const category = documentCategories.find(docCategory => docCategory.id === e);
        if (category) {
          this.setState({ documentCategoryId: category.id, documentCategory: category.emr_category });
        } else {
          // They cleared the select input
          this.setState({ documentCategoryId: null, documentCategory: null });
        }
      }
    };

    const handleScopeNameChange = (e) => {
      this.setState({ scopeOptionNameOption: e });
      // If the Scope changes we will reset the document category as different options
      // exist for specific scopes.
      this.setUsedCategories(e);
      handleDocumentCategoryChange('');
      this.setState({ documentCategoryId: null });
    };

    const handleScopeChange = (e) => {
      this.setState({ scopeOption: e, scopeOptionNameOptions: this.scopeOptionNames[e] });
      // If the Scope changes we will reset the document category as different options
      // exist for specific scopes. We will also clear availableCategories as they are
      // dependent on the selection of the ScopeNameOption.
      handleScopeNameChange('');
      handleDocumentCategoryChange('');
      this.setState({ documentCategoryId: null, availableCategories: [] });
      this.setState({ documentCategoryId: null });
    };

    if (isLoading) {
      return (<span> Adding new template... </span>);
    }
    return (
      <React.Fragment>
        <Form
          layout="vertical"
          onFinish={!editingTemplateID && this.addTemplate}
          id="addTemplate"
        >
          <Form.Item
            hasFeedback
            label="Template Task Name"
            rules={[
              {
                required: true,
                message: 'Must provide a template task name',
              },
            ]}
          >
            <Input
              value={templateTaskName}
              placeholder={templateTaskName}
              onChange={event => this.setState({ templateTaskName: event.target.value })}
            />
          </Form.Item>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Task"
              style={{ flex: 0.5 }}
            >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Task"
                onChange={handleTaskChange}
                onSearch={handleTaskSearch}
                filterOption={false}
                value={task}
              >
                {this.state.searchedTasks.map(e => <Select.Option key={e} value={e}>{e}</Select.Option>)}
              </Select>

            </Form.Item>

            <Form.Item
              label="Task Details"
              style={{ flex: 0.5 }}
            >
              <AutoComplete
                placeholder="Task Details"
                value={taskDetails}
                options={this.state.taskDetailsSearchOptions.map(option => ({ value: option }))}
                onSearch={handleSearchTaskDetails}
                onSelect={handleTaskDetailsSelection}
              />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Form.Item
              label="Assign to"
              style={{ flex: 0.5 }}
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="Select a provider"
                optionFilterProp="children"
                onChange={handleAssignToChange}
                onSearch={handleAssignToSearch}
                filterOption={false}
                value={assignTo}
                allowClear
              >
                {this.state.searchedNames && this.state.searchedNames.map((e, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Select.Option key={index} value={e}>
                    {e}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>

            <Form.Item
              label="Priority"
              style={{ flex: 0.5 }}
            >
              <Select
                allowClear={false}
                options={this.priorityOptions.map(option => ({ value: option }))}
                onChange={handlePriorityChange}
                placeholder="Priority"
                value={priority}
              />
            </Form.Item>
          </div>
          {
            (isAdmin)
            && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Scope"
                  style={{ flex: 0.5 }}
                >
                  <Select
                    label="Scope"
                    style={{ flex: 0.5 }}
                    onChange={handleScopeChange}
                    placeholder="Scope"
                    value={scopeOption}
                    options={this.scopeOptions.map(scope => ({ label: scope, value: scope }))}
                  />
                </Form.Item>
                <Form.Item
                  label="Scope Name"
                  style={{ flex: 0.5 }}
                >
                  <Select
                    style={{ flex: 0.5 }}
                    placeholder="Scope Name"
                    value={scopeOptionNameOption}
                    onChange={handleScopeNameChange}
                    options={this.state.scopeOptionNameOptions.map(name => ({ label: name, value: name }))}
                  />
                </Form.Item>
              </div>
            )
          }
          {
            (isAdmin && (scopeOption !== 'User'))
            && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                style={{ flex: 0.5 }}
              >
                <Tooltip title="Document Category values are populated based on selection of Scope and Scope Name.
                When a Document Category is assigned to a Task Template then that category will be selected during auto upload for a received fax."
                >
                  Document Category
                  <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
                </Tooltip>
                <Select
                  style={{ flex: 0.5 }}
                  placeholder="Document Category"
                  value={documentCategory}
                  onChange={handleDocumentCategoryChange}
                  allowClear
                >
                  {this.state.availableCategories.map(emrCategory => <Select.Option key={emrCategory.id} value={emrCategory.id}>{emrCategory.emr_category}</Select.Option>)}
                </Select>
              </Form.Item>
            </div>
            )
          }
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal, modalType: { data: { isAdmin } } } = this.props;
    const {
      editingTemplateID,
      templateTaskName,
      task,
      taskDetails,
      scopeOption,
      scopeOptionNameOption,
      priority,
      assignTo,
    } = this.state;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={!editingTemplateID ? this.addTemplate : this.editTemplate}
        actionName={editingTemplateID ? 'Edit' : 'Add'}
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={!templateTaskName || !task || !taskDetails || !priority || !assignTo || (isAdmin && (!scopeOption || !scopeOptionNameOption))}
        form="addTemplate"
        modalTitle={editingTemplateID ? 'Edit Task Template' : 'Add New Task Template'}
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        >
          {this.renderModal()}
        </div>
      </ModalWrapper>
    );
  }
}

AddTaskTemplate.defaultProps = {
  error: false,
  errorCode: null,
};

AddTaskTemplate.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTaskTemplates: PropTypes.func.isRequired,
  updateTaskTemplate: PropTypes.func.isRequired,
  createNewTaskTemplate: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorCode: PropTypes.string,
  getEmaTaskProviders: PropTypes.func.isRequired,
  allEmaTaskProviders: PropTypes.array.isRequired,
  allDocumentCategories: PropTypes.object.isRequired,
  orgUsedCategories: PropTypes.string.isRequired,
  deptsUsedCategories: PropTypes.string.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
  getUsedOrganizationCategories: PropTypes.func.isRequired,
  getUsedDepartmentCategories: PropTypes.func.isRequired,
};

export default connect(state => ({
  allEmaTaskProviders: state.userProfile.allEmaTaskProviders,
  allDocumentCategories: state.userProfile.allDocumentCategories,
  orgUsedCategories: state.categories.usedOrgCategories,
  deptsUsedCategories: state.categories.usedDeptCategories,
  isLoading: state.userProfile.isLoading,
  error: state.userProfile.error,
  errorCode: state.userProfile.errorCode,
}))(AddTaskTemplate);
