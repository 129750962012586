/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import {
  message,
  DatePicker,
  Input,
  Radio,
  Form,
  Select,
} from 'antd';
import { validPhoneNumber, validZipCode } from '../../global/validation';
import ModalWrapper from './ModalWrapper';

class CreateNewChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: props.modalType.data.fileName,
      sendToken: props.modalType.data.record,
      documentCategory: props.modalType.data.documentCategory || '',
      documentCategories: [],
      genderOptions: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
      ],
      patientName: this.props.modalType.data.name,
      patientDOB: this.props.modalType.data.dob,
      patientGender: this.props.modalType.data.gender?.toLowerCase(),
      patientPhoneNumber: this.props.modalType.data.phoneNumber || '',
      receiverType: this.props.modalType.data.receiverType,
      patientZipCode: this.props.modalType.data.zipCode || '',
      patientCity: this.props.modalType.data.city || '',
      practitionerName: this.props.modalType.data.practitionerName || '',
      patientAddress: this.props.modalType.data.patient_address,
      patientState: this.props.modalType.data.state || '',
      patientEmail: this.props.modalType.data.patient_email || '',
      referringProvider: this.props.modalType.data.referring_provider || '',
      providerOffice: this.props.modalType.data.provider_office || '',
      primaryPlan: this.props.modalType.data.primary_plan || '',
      primaryMemberId: this.props.modalType.data.primary_member_id || '',
      patientFirstName: '',
      patientMiddleName: '',
      patientLastName: '',
      secondaryPhone: '',
    };
    if (!this.state.fileName && this.props.selectedRecord[0] && this.props.selectedRecord[0].document_title) {
      this.state.fileName = this.props.selectedRecord[0].document_title;
    } else if (this.props.modalType.data.fileName) {
      this.state.fileName = this.props.modalType.data.fileName;
    } else {
      this.state.fileName = '';
    }

    if (this.props.modalType.data.address) {
      if (this.state.patientCity) {
        const indexToCut = this.props.modalType.data.address.toLowerCase().indexOf(this.state.patientCity.toLowerCase());
        const streetAddress = this.props.modalType.data.address.slice(0, indexToCut).replace(/,\s*$/, '').trim();
        this.state.patientAddress = streetAddress;
      } else {
        this.state.patientAddress = this.props.modalType.data.address;
      }
    } else {
      this.state.patientAddress = '';
    }
  }

  componentDidMount = async () => {
    const { getDocumentCategories, modalType: { data: { callerName, receivedAt } } } = this.props;
    await getDocumentCategories();
    const org = localStorage.m8_org;
    // Frontier Dermatology does not want to below filename format
    if (!this.state.fileName && org !== 'Frontier Dermatology') {
      const receivedAtVal = receivedAt || this.props.receivedAt;
      const callerNameVal = callerName || this.props.callerName;
      this.setState({ fileName: (receivedAtVal && callerNameVal) ? `${receivedAtVal.replace(/ /g, '_')}_${callerNameVal.replace(/ /g, '_')}` : '' });
    }
    this.setState({ documentCategories: this.props.allDocumentCategories.categories });
    const autoUploadEmr = localStorage.m8_auto_upload_emr;
    const { patientName } = this.state;
    if (autoUploadEmr === 'EMA' && patientName) {
      // Handle edge case where the patient_name has 2 surnames (this is common for Spanish names)
      if (patientName.includes(',') && patientName.split(',')[0].trim().includes(' ') && patientName.split(',')[0].trim().split(' ').length > 1) {
        const parsedNames = this.parseName(patientName);
        this.setState({
          patientFirstName: parsedNames.firstName,
          patientLastName: parsedNames.lastName,
        });
      } else {
        this.setState({
          patientFirstName: parseFullName(patientName).first,
          patientMiddleName: parseFullName(patientName).middle,
          patientLastName: parseFullName(patientName).last,
        });
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { hideModal, triggerAutoUpload } = this.props;
    const {
      sendToken,
      fileName,
      documentCategory,
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientDOB,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      receiverType,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
    } = this.state;
    const splitDate = patientDOB.split('/');
    const formattedDOB = `${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`;
    await triggerAutoUpload({
      sendToken,
      receiverType,
      documentCategory,
      fileName,
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientDOB: formattedDOB,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      chartCreationEnabled: true,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
    });
    const { error, errorMessage } = this.props;
    hideModal();
    if (!error) {
      message.success({
        content: 'Patient chart will be created shortly. We will notify you of any issues via email.',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessage,
        style: { marginTop: '70px' },
      });
    }
  };

  parseName(name) {
    let firstName = '';
    let lastName = '';
    const parts = name.split(',');
    lastName = parts[0].trim();
    firstName = parts[1].trim();

    // Handle edge cases for Spanish names with multiple surnames
    if (lastName.split(' ').length > 1 && !lastName.includes('-')) {
      const lastNames = lastName.split(' ');
      lastName = `${lastNames[0]} ${lastNames[1]}`;
    }

    return { firstName, lastName };
  }


  render() {
    const { modalType: { data: { name } }, isLoading, hideModal } = this.props;
    const { genderOptions } = this.state;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    const autoUploadEmr = localStorage.getItem('m8_auto_upload_emr');
    const isNymbl = autoUploadEmr === 'Nymbl';
    const isAdvancedMD = autoUploadEmr === 'AdvancedMD';
    const isEMA = autoUploadEmr === 'EMA';
    const customContentStyle = {
      width: '340px',
    };
    const {
      patientName,
      patientFirstName,
      patientMiddleName,
      patientLastName,
      secondaryPhone,
      patientDOB,
      patientPhoneNumber,
      patientGender,
      patientAddress,
      patientZipCode,
      patientCity,
      practitionerName,
      documentCategory,
      documentCategories,
      fileName,
      patientEmail,
      patientState,
      referringProvider,
      providerOffice,
      primaryMemberId,
      primaryPlan,
      formattedDOB,
    } = this.state;

    const validatePhoneNumber = (rule, value) => {
      if (!value) {
        return Promise.reject('Please input your phone number!');
      } if (!validPhoneNumber(value)) {
        return Promise.reject('Please enter a valid phone number!');
      }
      return Promise.resolve();
    };

    const validateZipCode = (rule, value) => {
      if (!value) {
        return Promise.reject('Please input a zip code!');
      } if (!validZipCode(value)) {
        return Promise.reject('Please enter a valid zip code!');
      }
      return Promise.resolve();
    };

    const handleFieldChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    /**
     * @param {String} dateString format MM/DD/YYYY
     */
    const handleDateChange = (_, dateString) => {
      this.setState({ patientDOB: dateString });
    };

    const disableSubmitButton = () => {
      if (isNymbl) {
        return (
          patientName === ''
          || patientDOB === null || patientDOB === ''
          || (patientPhoneNumber !== '' && !validPhoneNumber(patientPhoneNumber))
        );
      }
      if (isAdvancedMD) {
        return (
          patientName === ''
          || patientDOB === null || patientDOB === ''
          || patientPhoneNumber === '' || !validPhoneNumber(patientPhoneNumber)
          || patientGender === null
          || patientAddress === ''
          || patientZipCode === '' || !validZipCode(patientZipCode)
          || patientCity === ''
          || practitionerName === ''
        );
      }
      if (isEMA) {
        return (
          (patientFirstName === '' || patientLastName === '')
          || patientDOB === null || patientDOB === ''
          || patientGender === null || patientGender === ''
          || (patientPhoneNumber && !validPhoneNumber(patientPhoneNumber))
          || (!documentCategory)
        );
      }
      if (autoUploadEmr === 'DrChrono') {
        return (
          patientName === null
          || patientDOB === null || patientDOB === ''
          || !patientGender
        );
      }
      return (
        patientName === null
        || patientDOB === null || patientDOB === ''
        || patientGender === null
        || patientPhoneNumber === null
        || !validPhoneNumber(patientPhoneNumber)
        || (autoUploadEmr === 'EMA' && !documentCategory)
      );
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={this.handleSubmit}
        actionName="Submit"
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableSubmitButton()}
        modalTitle="Create New Chart"
        form="createNewChart"
        isDraggable
      >
        <Form
          id="createNewChart"
          onFinish={hideModal}
          layout="vertical"
          initialValues={{ patientDOB: patientDOB && moment(patientDOB, dateFormat), fileName }}
        >
          <div>
            {autoUploadEmr !== 'EMA' ? (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Patient Name"
                  name="form_patient_name"
                  style={{ flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: 'Must provide a name',
                    },
                  ]}
                >
                  <Input
                    placeholder={name ? undefined : 'Enter patient name'}
                    name="patientName"
                    defaultValue={patientName}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Patient Date of Birth"
                  name="form_dob"
                  rules={[
                    {
                      required: true,
                      message: 'Must provide a date of birth',
                    },
                  ]}
                >
                  <DatePicker
                    onChange={handleDateChange}
                    name="patientDOB"
                    format={dateFormat}
                    defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                    value={patientDOB && moment(patientDOB, dateFormat)}
                  />
                </Form.Item>
              </div>
            ) : (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Patient First Name"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="First name"
                    name="patientFirstName"
                    value={patientFirstName}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Patient Middle Name"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Middle name"
                    name="patientMiddleName"
                    value={patientMiddleName}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Patient Last Name"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Last name"
                    name="patientLastName"
                    value={patientLastName}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {(autoUploadEmr !== 'DrChrono')
            && (
              <Form.Item
                label={isNymbl ? 'Phone Number (optional)' : 'Phone Number'}
                rules={[
                  {
                    validator: validatePhoneNumber,
                  },
                ]}
                onChange={handleFieldChange}
                style={{ flex: 0.5 }}
              >
                <Input
                  placeholder="Enter phone number"
                  name="patientPhoneNumber"
                  defaultValue={patientPhoneNumber}
                  style={{ flex: 0.5 }}
                />
              </Form.Item>
            )}
            {(autoUploadEmr === 'EMA' || autoUploadEmr === 'Kareo')
            && (
              <Form.Item label="Document category" style={{ flex: 0.5 }}>
                <Select
                  showSearch
                  label="Document Category"
                  name="documentCategory"
                  allowClear
                  onChange={event => this.setState({ documentCategory: event })}
                  value={documentCategory}
                  style={{ flex: 0.5 }}
                >
                  {documentCategories.map(emrCategory => (
                    <Select.Option
                      key={emrCategory.id}
                      value={emrCategory.emr_category}
                    >
                      {emrCategory.emr_category}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <div style={{ display: 'flex', gap: '10px' }}>
              {isEMA
                && (
                  <Form.Item
                    label="Patient Date of Birth"
                    name="form_dob"
                    rules={[
                      {
                        required: true,
                        message: 'Must provide a date of birth',
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={handleDateChange}
                      name="patientDOB"
                      format={dateFormat}
                      defaultValue={patientDOB && moment(patientDOB, dateFormat)}
                      value={patientDOB && moment(patientDOB, dateFormat)}
                    />
                  </Form.Item>
                )}
            </div>
          </div>
          <div style={{ flex: 0.5 }}>
            {isEMA
            && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Secondary Phone Number"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Secondary Phone Number"
                    name="secondaryPhone"
                    defaultValue={secondaryPhone}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Email Address"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Email Address"
                    name="patientEmail"
                    defaultValue={patientEmail}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </div>
            )}
          </div>
          <div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item
                label={isNymbl ? 'Gender (optional)' : 'Gender'}
                style={{ flex: 0.5 }}
                name="form_gender"
                rules={[
                  {
                    required: true,
                    message: 'Please select your gender!',
                  },
                ]}
              >
                <Radio.Group
                  options={genderOptions}
                  name="patientGender"
                  onChange={handleFieldChange}
                  defaultValue={patientGender}
                />
              </Form.Item>
            </div>
          </div>
          {isAdvancedMD
          && (
          <>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item label="Patient Address" style={{ flex: 0.5 }}>
                <Input
                  placeholder="Enter address"
                  name="patientAddress"
                  onChange={handleFieldChange}
                  defaultValue={patientAddress}
                />
              </Form.Item>
              <Form.Item
                name="patientZipCode"
                label="Patient Zip Code"
                style={{ flex: 0.5 }}
                rules={[
                  {
                    validator: validateZipCode,
                  },
                ]}
              >
                <Input
                  placeholder="Enter zip code"
                  name="patientZipCode"
                  onChange={handleFieldChange}
                  defaultValue={patientZipCode}
                />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Form.Item label="Patient City" style={{ flex: 0.5 }}>
                <Input
                  placeholder="Enter city"
                  name="patientCity"
                  onChange={handleFieldChange}
                  defaultValue={patientCity}
                />
              </Form.Item>

              <Form.Item label="Provider Name" style={{ flex: 0.5 }}>
                <Input
                  placeholder="Last name, First name"
                  name="practitionerName"
                  onChange={handleFieldChange}
                  defaultValue={practitionerName}
                />
              </Form.Item>
            </div>
          </>
          )}
          {isEMA
          && (
            <div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Address"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Address"
                    name="patientAddress"
                    defaultValue={patientAddress}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="City"
                  style={{ flex: 0.4 }}
                >
                  <Input
                    placeholder="City"
                    name="patientCity"
                    defaultValue={patientCity}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="State"
                  style={{ flex: 0.2 }}
                >
                  <Input
                    placeholder=""
                    name="patientState"
                    defaultValue={patientState}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Zip"
                  style={{ flex: 0.3 }}
                >
                  <Input
                    placeholder=""
                    name="patientZipCode"
                    defaultValue={patientZipCode}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Payer"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Payer"
                    name="primaryPlan"
                    defaultValue={primaryPlan}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Policy Number"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Policy Number"
                    name="primaryMemberId"
                    defaultValue={primaryMemberId}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <Form.Item
                  label="Referring Practice"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Referring Practice"
                    name="providerOffice"
                    defaultValue={providerOffice}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Referring Provider"
                  style={{ flex: 1 }}
                >
                  <Input
                    placeholder="Referring Provider"
                    name="referringProvider"
                    defaultValue={referringProvider}
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </div>
            </div>
          )}
          {!isEMA && (autoUploadEmr !== 'DrChrono')
          && (
            <Form.Item label="File Name (optional)">
              <Input placeholder="File name" name="fileName" onChange={handleFieldChange} defaultValue={fileName} />
            </Form.Item>
          )}
        </Form>
      </ModalWrapper>
    );
  }
}

CreateNewChart.defaultProps = {
  error: false,
  errorMessage: null,
  receivedAt: '',
  callerName: '',
};

CreateNewChart.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  receivedAt: PropTypes.string,
  callerName: PropTypes.string,
  allDocumentCategories: PropTypes.object.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.records.isLoading,
  emailValidMessage: state.records.emailValidMessage,
  updatedLabel: state.records.updatedLabel,
  data: state.labels.data,
  selectedRecord: state.inboxTable.recordData,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
  receivedAt: state.records.receivedAt,
  callerName: state.records.callerName,
  allDocumentCategories: state.userProfile.allDocumentCategories,
}))(CreateNewChart);
