import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

class UpdatePaymentMethod extends Component {
  componentDidMount() {
    const cb = document.createElement('script');
    cb.innerHTML = `Chargebee.init({
          site: "${process.env.CB_SITE}",
          publishableKey: "${process.env.CB_KEY}"
        })`;
    document.body.appendChild(cb);
    /* global Chargebee */
    const chargebeeCallbacks = () => {};
    return this.updatePaymentDetails(Chargebee.getInstance(), chargebeeCallbacks);
  }

  updatePaymentDetails = (cbInstance, chargebeeCallbacks) => {
    const { closeModal } = this.props;
    const callbacks = chargebeeCallbacks();
    cbInstance.openCheckout({
      ...callbacks,
      hostedPage: async () => {
        return axios.get(`${window.location.origin}/api/v1/payments/update_payment_method`).then(response => response.data);
      },
      close: () => {
        closeModal();
      },
    });
  }

  render() {
    return null;
  }
}

UpdatePaymentMethod.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default UpdatePaymentMethod;
