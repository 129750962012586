// InstallMedsender component displays the modal content.

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';

import ModalWrapper from './ModalWrapper';

// See comment in app/containers/RecordViewerPatient.jsx for more explanation
let printedPdfWindow;

class GetEmail extends Component {

  render() {
   // Custom style for the modal
    const customContentStyle = {
      width: '26%',
      textAlign: 'center',
      disabled: 'true',
    };

    // If the email is not yet saved in localStorage then ask the user to provide it
    // otherwise ask the user to confirm it
    const modalHeader = localStorage.getItem('m8_guest_email') ?
      `If you\'ve entered your email correctly, continue to print.`
      :
      'For additional security please enter your email below.';

    const fieldDetails = {
      header: modalHeader,
      name: 'email',
      hintText: 'user@medical.com',
      floatingLabelText: 'Email Address',
    };

    const fieldStyle = {
      width: '330px',
      marginTop: '-20px',
    };
    return (
      <div>
        <ModalWrapper
          hideModal={this.props.hideModal}
          customContentStyle={customContentStyle}
          dismiss={'Cancel'}
          action={() => {
            printedPdfWindow = window.open();
            this.props.printRecord(printedPdfWindow);
          }}
          actionName={`Print Record`}
          disabled={!this.props.validEmail}
          modalTitle="Your Email"
        >
          <p style={{ fontWeight: '600' }}>{fieldDetails.header}</p>
          <TextField
            name={fieldDetails.name}
            style={fieldStyle}
            onChange={this.props.handleChange}
            value={this.props.email || ''}
            hintText={fieldDetails.hintText}
            floatingLabelText={fieldDetails.floatingLabelText}
            type="text"
            errorText={this.props.validEmail ? '' : 'Please enter a valid email'}
          />

          <Checkbox
            label="Remember me"
            checked={this.props.isChecked}
            style={{ marginTop: '10px', marginLeft: '-5px', textAlign: 'left', marginBottom: '-30px' }}
            onCheck={(event, isChecked) => {
              this.props.toggleChecked(isChecked);
            }}
          />
          <br />
        </ModalWrapper>
      </div>
    );
  }
}

GetEmail.defaultProps = {
  email: null,
};

GetEmail.propTypes = {
  hideModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  printRecord: PropTypes.func.isRequired,
  email: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  toggleChecked: PropTypes.func.isRequired,
  validEmail: PropTypes.bool.isRequired,
};

export default GetEmail;
