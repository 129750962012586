import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'react-router-dom/Link';
import { parse } from 'qs';
import { Button, Badge, Alert } from 'antd';
import Icon, {
  IdcardFilled,
  QuestionCircleFilled,
  SendOutlined,
  MailFilled,
  CloudFilled,
  CloudUploadOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  SettingFilled,
  HomeOutlined,
  ExceptionOutlined,
  TeamOutlined,
  BellOutlined,
} from '@ant-design/icons';

import { Header, Tutorial } from '../components';
import { openModal } from '../actions/modal';
import { getNotifications } from '../actions/notifications';
import { getLoginInfo, resetPatientAccessAttempt } from '../actions/auth';
import { getUserInfo, changeModalState } from '../actions/userProfile';
import { changeDefaultOrganization } from '../actions/appActions';
import { verifyFeatureAccess } from '../global/featureFlags';
import { BILLING_ENUMS } from '../components/billing/constants';
import isOnChrome from '../global/browser';

import {
  homeMessageBold,
  homeMessageRegular,
  homeMessageKareo,
  kareoMessageBold,
  chromeExtensionNotInstalled,
  installChromeExtension,
} from '../assets/copy/homePage';

const helpButton = {
  position: 'absolute',
  bottom: '20px',
  right: '80px',
  width: '60px',
  borderRadius: '100px',
  boxShadow: '0px 1px 5px lightgrey',
  backgroundColor: '#6A1B9A',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '50px',
  color: 'white',
  paddingBottom: '5px',
};

const tutorialContainer = {
  position: 'absolute',
  bottom: '30px',
  right: '30px',
};

const buttonText = {
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
  textTransform: 'uppercase',
  height: 50,
  fontWeight: 500,
};

const DashboardButtonContainer = (props) => {
  return (
    <div style={{ margin: '55px 55px 0 0' }}>
      <Badge count={props.count}>
        <Button
          style={{
            height: '150px', width: '138px', textAlign: 'center', backgroundColor: 'rgba(237, 237, 237, 0.78)',
          }}
          disabled={props.disabled}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {
              // NOTE (Erica)
              // The whiteSpace style to allow words to break into the next line if the text is long enough.
              // The height will keep the div size consistent--> If labels ever reach three lines this will break.
            }
            <p style={buttonText}>{props.text}</p>
            <Icon className="material-icons" component={props.icon} style={{ fontSize: '30px' }} />
          </div>
        </Button>
      </Badge>
    </div>
  );
};

DashboardButtonContainer.defaultProps = {
  disabled: false,
  count: 0,
};

DashboardButtonContainer.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  count: PropTypes.number,
};

const DashboardButton = (props) => {
  if (props.external) {
    return (
      <a href={props.to} target="_blank" rel="noopener noreferrer">
        <DashboardButtonContainer {...props} />
      </a>
    );
  }
  return (
    <Link to={props.to} style={{ flexBasis: 138 }}>
      <DashboardButtonContainer {...props} />
    </Link>
  );
};

DashboardButton.defaultProps = {
  external: false,
};

DashboardButton.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

class Home extends Component {
  constructor(props) {
    super();
    const { organizationDetails: { current_organization: currentOrganization } } = props;
    this.state = {
      showTutorial: false,
      userEMR: localStorage.getItem('m8_emr') || props.emr,
      isSmallScreen: false,
      openOrganizationFilterMenu: false,
      currentOrganization,
      chromeExtensionInstalled: true,
      referralManagementAccess: false,
      referralUpgradeReminderTime: localStorage.getItem('referral_upgrade_reminder_time') || new Date(0),
      referralMgmtTrial: false,
    };
  }

  checkChromeExtensionInstallation() {
    const ping = { message: 'ping' };
    const chromeExtId = 'onpcajmldffklnfccjolcccoijaogggl';

    return new Promise((resolve, reject) => {
      /* global chrome */
      chrome.runtime.sendMessage(chromeExtId, ping, (response) => {
        if (response && response.message) return resolve();
        return reject(chrome.runtime.lastError.message || 'Error');
      });
    });
  }

  showChromeExtensionInformation() {
    this.checkChromeExtensionInstallation()
      .then(() => {
        this.setState({ chromeExtensionInstalled: true });
      })
      .catch((error) => {
        this.setState({ chromeExtensionInstalled: false });
      });
  }

  componentWillMount() {
    if (this.props.isAuthenticated && this.props.organizationDetails) {
      this.props.getNotifications();
    }
    if (this.props.hasCompletedWalkthrough) return;
    this.props.history.replace('/app/walkthrough');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  componentDidMount = async () => {
    if (isOnChrome()) {
      this.showChromeExtensionInformation();
    }
    window.addEventListener('resize', this.resize.bind(this));
    this.resize.bind(this);
    if (!this.state.userEMR) {
      await this.props.getUserInfo();
      this.setState({ userEMR: this.props.emr });
    }
    const {
      accessedResetPassword,
      accessedPatient,
      hasCompletedWalkthrough,
      openModal: openDialog,
    } = this.props;

    const canViewReferralMgmt = await verifyFeatureAccess('referral_management');
    this.setState({
      referralManagementAccess: canViewReferralMgmt,
    });

    if (accessedResetPassword || accessedPatient) {
      const modalType = {
        type: 'LOGOUT_USER',
      };
      openDialog(modalType);
    }

    if (localStorage.getItem('minimizeTutorial') === null) {
      this.setState({
        showTutorial: true,
      });
    }

    const { location } = this.props;
    const params = parse(location.search, { ignoreQueryPrefix: true });

    if (params.providerSwitched === 'true' && !hasCompletedWalkthrough) {
      const { history } = this.props;
      history.replace('./');
      this.openProviderSwitchedDialog();
    }

    // one day is 1000 * 60 * 60 * 24 milliseconds
    let { referralUpgradeReminderTime } = this.state;
    if (referralUpgradeReminderTime) {
      referralUpgradeReminderTime = Number(referralUpgradeReminderTime);
    }
    const showReferralUpgradeReminder = Date.now() - referralUpgradeReminderTime > 21600000;
    const referralMgmtTrial = await verifyFeatureAccess('referral_mgmt_trial');
    this.setState({ referralMgmtTrial });

    if (this.props.isAdmin && referralMgmtTrial && showReferralUpgradeReminder) {
      const modalType = {
        type: 'TRIAL_EXPIRY',
      };
      openDialog(modalType);
    }
  }

  openProviderSwitchedDialog = () => {
    const { openModal: openDialog } = this.props;
    const modalType = {
      type: 'PROVIDER_SWITCHED',
    };
    openDialog(modalType);
  }

  handleClose = () => {
    this.props.resetPatientAccessAttempt();
  }

  // Handler for the Upload Record button. Shows the modal to upload new record.
  openUploadModal = async () => {
    const canUpload = await verifyFeatureAccess('upload_record');
    const modal = canUpload ? 'UPLOAD_RECORD' : 'RAISE_FLAG';
    const modalType = {
      type: modal,
      ...(!canUpload && { featureFlag: 'upload_record' }),
    };

    this.props.openModal(modalType);
  }

  // Handler for the Add Fax Number button. Shows the modal to upload new record.
  openAddFaxNumberModal = () => {
    const { openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'ADD_FAX_NUMBER',
    };
    return handleOpenModal(modalType);
  }

  showTutorial = () => {
    this.setState({
      showTutorial: true,
    });
  }

  hideTutorial = () => {
    if (localStorage.getItem('minimizeTutorial') === null) {
      localStorage.setItem('minimizeTutorial', true);
    }
    this.setState({
      showTutorial: false,
    });
  }

  handleChangeDefaultOrganization = async (org) => {
    // eslint-disable-next-line react/prop-types
    const {
      changeDefaultOrganization: handleChangeDefaultOrganization,
    } = this.props;
    this.setState({
      currentOrganization: org,
    });
    await handleChangeDefaultOrganization(org, this.reloadPage);
  }

  reloadPage = async () => {
    const {
      getLoginInfo: handleGetLoginInfo,
    } = this.props;
    await handleGetLoginInfo();
    this.context.router.history.push('/app');
  };

  openWebinarLink = () => {
    const win = window.open('https://meetings.hubspot.com/zain3/15-minute-demo', '_blank');
    win.opener = null;
  }

  openKareoLink = () => {
    const win = window.open('https://link.medsender.com/auto-upload-to-kareo');
    win.opener = null;
  }

  handleChromeExtension = () => {
    const win = window.open('https://chrome.google.com/webstore/detail/medsender/onpcajmldffklnfccjolcccoijaogggl');
    win.opener = null;
  }

  openBillingModal = () => {
    const { changeModalState } = this.props;
    changeModalState(BILLING_ENUMS.PLAN_SELECTOR, -1, true);
  };

  resize() {
    const isSmallScreen = (window.innerWidth <= 800);
    if (isSmallScreen !== this.state.isSmallScreen) {
      this.setState({ isSmallScreen });
    }
  }


  render() {
    const {
      showTutorial,
      openOrganizationFilterMenu,
      currentOrganization,
      chromeExtensionInstalled,
      referralManagementAccess,
      referralMgmtTrial,
    } = this.state;
    const {
      isAdmin,
      isTrialUser,
      billingTrialDetails,
      organizationDetails: { organizations },
      openModal: openDialog,
    } = this.props;
    const kareoUser = this.state.userEMR === 'Kareo';
    const { trial_days_remaining: trialDaysRemaining } = billingTrialDetails;
    const remainingDaysOnTrial = trialDaysRemaining;
    const onMobile = window.innerWidth < 800;
    let buttonMessage = '';
    if (isTrialUser) {
      if (remainingDaysOnTrial > 0) {
        buttonMessage = `Upgrade: ${remainingDaysOnTrial} day(s) left in trial`;
      } else {
        buttonMessage = 'Trial expired';
      }
    } else {
      buttonMessage = 'Upgrade Plan';
    }

    const openTrialUpgradeDialog = () => {
      const modalType = {
        type: 'TRIAL_EXPIRY',
      };
      openDialog(modalType);
    };

    return (
      <div>
        <Helmet>
          <title>Home - Medsender</title>
        </Helmet>
        <Header
          main="Dashboard"
          subtext="Welcome to your dashboard"
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
          ]}
          buttons={[
            {
              icon: <Badge size='small' count={this.props.notifications.length}><Icon className="material-icons" component={BellOutlined} style={{ fontSize: '17px' }} /></Badge>,
              tooltipTitle: 'Unread Notifications',
              disabled: false,
              func: () => this.props.history.replace('/app/unread_messages'),
              type: 'Notification',
            },
            {
              openFilterMenu: openOrganizationFilterMenu,
              text: currentOrganization,
              icon: <HomeOutlined />,
              tooltipTitle: 'Current Organization',
              disabled: false,
              hide: organizations.length < 2,
              func: () => this.setState({ openOrganizationFilterMenu: true }),
              filterRecords: true,
              filterOptions: organizations.map(({ name }) => ({ filter: name, text: name })),
              handleFilter: (_, child) => this.handleChangeDefaultOrganization(child.props.primaryText),
              handleRequestChange: value => this.setState({ openOrganizationFilterMenu: value }),
            },
            {
              type: 'FlatButton',
              text: 'New Upload',
              icon: <CloudUploadOutlined />,
              disabled: false,
              func: this.openUploadModal,
              secondary: true,
            },
            {
              type: 'FlatButton',
              text: 'Upgrade To Referral Management',
              disabled: false,
              hide: !(isAdmin && referralMgmtTrial),
              func: openTrialUpgradeDialog,
              secondary: true,
              style: {
                backgroundColor: '#8444ac', color: 'white', position: this.state.isSmallScreen ? 'static' : 'absolute', right: '20px', top: onMobile ? '75px' : '15px',
              },
            },
            {
              type: 'FlatButton',
              text: buttonMessage,
              icon: trialDaysRemaining > 0 ? <FieldTimeOutlined /> : <></>,
              disabled: false,
              hide: !isAdmin || !(billingTrialDetails.plan_type?.includes('edsender_') || billingTrialDetails.plan_type === 'free' || !billingTrialDetails.plan_type),
              func: this.openBillingModal,
              secondary: true,
              style: {
                backgroundColor: '#8444ac', color: 'white', position: this.state.isSmallScreen ? 'static' : 'absolute', right: '20px', top: onMobile ? '75px' : '15px',
              },
            },
          ]}
        />
        <div style={{
          textAlign: 'center', display: 'flex', maxWidth: 590, flexWrap: 'wrap', margin: '0 auto', justifyContent: 'center',
        }}
        >
          {/* add in new message here for any notifications we want to show to our in app users */}
          <Alert
            style={{ margin: '55px 55px -20px 0', cursor: 'pointer', width: 535 }}
            message={(
              <div>
                <span style={{ fontWeight: 500 }}>{kareoUser ? kareoMessageBold : homeMessageBold}</span>
                &nbsp;
                {kareoUser ? homeMessageKareo : homeMessageRegular}
              </div>
            )}
            onClick={kareoUser ? this.openKareoLink : this.openWebinarLink}
            type="info"
            showIcon
          />
          {!chromeExtensionInstalled
            && (
            <Alert
              style={{ margin: '55px 55px -20px 0', cursor: 'pointer', width: 535 }}
              message={(
                <div>
                  <span style={{ fontWeight: 500 }}>{chromeExtensionNotInstalled}</span>
                  &nbsp;
                  {installChromeExtension}
                </div>
              )}
              onClick={this.handleChromeExtension}
              type="warning"
              showIcon
            />
            )}
          <br />
          <DashboardButton to="/app/uploads" icon={CloudFilled} text="Uploads" />
          <DashboardButton to="/app/sent" icon={SendOutlined} text="Sent" />
          <DashboardButton to="/app/received" icon={MailFilled} text="Inbox" />
          <DashboardButton to="/app/assigned" icon={ExceptionOutlined} text="Assigned" />
          <DashboardButton to="/app/referred" count={referralManagementAccess ? this.props.newReferralsCount : null} icon={TeamOutlined} text="Referral Management" />
          <DashboardButton to="/app/contacts" icon={IdcardFilled} text="Contacts" />
          <DashboardButton to="/app/user" icon={SettingFilled} text="Settings" />
          <DashboardButton to="/app/guide" icon={QuestionCircleFilled} text="Help/ Support" />
          <br />
          {isAdmin
            && (
              <Button
                className="add-fax-number-button"
                style={{
                  margin: '100px 100px 0 0',
                  width: '300px',
                  backgroundColor: 'rgba(237, 237, 237, 0.78)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                labelStyle={{ fontSize: '14px' }}
                onClick={this.openAddFaxNumberModal}
                icon={<PlusOutlined />}
              >
                ADD FAX NUMBER
              </Button>
            )
          }
        </div>
        {showTutorial
          ? <div style={tutorialContainer}><Tutorial hideTutorial={this.hideTutorial} /></div>
          : <Icon className="material-icons" style={helpButton} component={QuestionCircleFilled} onClick={this.showTutorial}>help</Icon>
        }
      </div>
    );
  }
}

Home.defaultProps = {
  accessedPatient: false,
  accessedResetPassword: false,
};

Home.contextTypes = {
  router: PropTypes.object.isRequired,
};

Home.propTypes = {
  hasCompletedWalkthrough: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  resetPatientAccessAttempt: PropTypes.func.isRequired,
  accessedPatient: PropTypes.bool,
  accessedResetPassword: PropTypes.bool,
  emr: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isTrialUser: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  billingTrialDetails: PropTypes.object,
  getUserInfo: PropTypes.func.isRequired,
  organizationDetails: PropTypes.shape({
    organizations: PropTypes.array.isRequired,
    current_organization: PropTypes.string,
  }).isRequired,
  changeDefaultOrganization: PropTypes.func.isRequired,
  getLoginInfo: PropTypes.func.isRequired,
  newReferralsCount: PropTypes.number.isRequired,
  notifications: PropTypes.array.isRequired,
  getNotifications: PropTypes.func.isRequired,
};

export default connect(state => ({
  hasCompletedWalkthrough: state.auth.hasCompletedWalkthrough,
  accessedPatient: state.auth.accessedPatient,
  accessedResetPassword: state.auth.accessedResetPassword,
  emr: state.auth.data.emr,
  jobTitle: state.auth.data.jobTitle,
  isAdmin: state.auth.isAdmin,
  isTrialUser: state.auth.trialUser,
  billingTrialDetails: state.auth.billing,
  organizationDetails: state.auth.organizationDetails,
  newReferralsCount: state.socket.newReferralsCount,
  notifications: state.notifications.notifications,
}), {
  resetPatientAccessAttempt, openModal, getUserInfo, changeDefaultOrganization, getLoginInfo, changeModalState, getNotifications,
})(Home);

export {
  Home,
};
