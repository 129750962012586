const initialState = {
  isLoading: false,
  data: [],
  usedOrgCategories: [],
  usedDeptCategories: [],
  error: false,
  errorMessage: null,
  headers: null,
  errorCode: '',
};

const failedState = {
  ...initialState,
  isLoading: false,
  error: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BEGIN_GET_CATEGORIES': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_GET_CATEGORIES': {
      return {
        ...failedState,
        errorMessage: action.payload.data.message,
      };
    }
    case 'FINISH_GET_CATEGORIES': {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data.categories,
        aiTag: action.payload.data.categories.ai_tag,
        headers: action.payload.data.meta,
      };
    }
    case 'BEGIN_CREATE_CATEGORY': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_CREATE_CATEGORY': {
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_CREATE_CATEGORY': {
      return {
        isLoading: false,
        error: false,
      };
    }
    case 'BEGIN_DELETE_CATEGORY': {
      return {
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_DELETE_CATEGORY': {
      return {
        ...failedState,
        errorMessage: action.payload.response.data.error,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_DELETE_CATEGORY': {
      return {
        isLoading: false,
        error: false,
      };
    }
    case 'BEGIN_UPDATE_CATEGORY': {
      return {
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_UPDATE_CATEGORY': {
      return {
        ...failedState,
        errorCode: action.payload.response.status,
      };
    }
    case 'FINISH_UPDATE_CATEGORY': {
      return {
        isLoading: false,
        error: false,
      };
    }
    case 'BEGIN_GET_USED_ORGANIZATION_CATEGORIES': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_GET_USED_ORGANIZATION_CATEGORIES': {
      return {
        ...failedState,
        errorMessage: action.payload.data.message,
      };
    }
    case 'FINISH_GET_USED_ORGANIZATION_CATEGORIES': {
      return {
        ...state,
        isLoading: false,
        error: false,
        usedOrgCategories: action.payload.data.used_organization_category_ids,
        headers: action.payload.data.meta,
      };
    }
    case 'BEGIN_GET_USED_DEPARTMENT_CATEGORIES': {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case 'FAILED_GET_USED_DEPARTMENT_CATEGORIES': {
      return {
        ...failedState,
        errorMessage: action.payload.data.message,
      };
    }
    case 'FINISH_GET_USED_DEPARTMENT_CATEGORIES': {
      return {
        ...state,
        isLoading: false,
        error: false,
        usedDeptCategories: action.payload.data.used_department_category_ids,
        headers: action.payload.data.meta,
      };
    }
    default:
      return state;
  }
};
