export const BILLING_ENUMS = {
  MODAL_CLOSE: -1,
  DISPLAY_WELCOME: 0,
  DISPLAY_TRIAL_ENDING: 1,
  PLAN_SELECTOR: 2,
  BASIC_PLAN: 3,
  PROCESS_NEW_SUBSCRIPTION_PAYMENT: 4,
  PROCESS_UPGRADE_SUBSCRIPTION_PAYMENT: 5,
  UPGRADE_PLAN: 6,
  UPDATE_PAYMENT_METHOD: 7,
};

const essentialsPlanFeatures = [
  `HIPAA compliant faxing and email`,
  `Never-busy fax line`,
  `1-click sending from any file or EMR`,
  `Receive faxes from anywhere`,
  `300 pages per month included`,
  `No support included`,
  `No EMR integration`,
  'Optional AI automation',
];

export const essentialsPlan = {
  name: 'E S S E N T I A L S',
  pricing: '$20/mo',
  subtext: '',
  features: essentialsPlanFeatures,
  popular: false,
};

const basicPlanFeatures = [
  `1,500 pages per month included`,
  `$0.07/add. page`,
  `AI-labeled faxes with name, DOB, and category`,
  `Fill and sign documents`,
  `Split and merge documents`,
  `Customizable cover sheet with your logo`,
  `Limited support`,
  `Optional AI automation add-on`,

];

const basicPlan = {
  name: 'B A S I C',
  pricing: '$99/mo',
  subtext: '',
  features: basicPlanFeatures,
  popular: false,
};

const plusPlanFeatures = [
  `7,500 pages per month included`,
  `$0.06/add. page`,
  `Allow recipients to fill & sign with a click`,
  `Concierge on-boarding`,
  `Phone and email support`,
  `API access available`,
  `Optional AI automation add-on`,
];

const plusPlan = {
  name: 'P L U S',
  pricing: '$549/mo',
  subtext: 'Everything in BASIC and',
  features: plusPlanFeatures,
  popular: true,
};

const completePlanFeatures = [
  `20,000 pages per month included`,
  `$0.05/add. page`,
  `Microsoft SSO`,
  `Add secure forms to your website`,
  `Priority support`,
  `Scanner integrations`,
  `Integrate with most EMRs`,
  `Optional AI automation add-on`,
];

const completePlan = {
  name: 'C O M P L E T E',
  pricing: 'Contact Us',
  subtext: 'Everything in PLUS and',
  features: completePlanFeatures,
  popular: false,
};

export const billingPlans = [
  basicPlan,
  plusPlan,
  completePlan,
];

// These correspond 1:1 with billingPlans above
export const chargebeePlans = [
  'Medsender-basic-1500',
  'medsender-plus-with-ai',
  'medsender-complete-with-ai',
];

export const chargebeeBasicPlans = [
  'medsender-essentials-plan',
  'Medsender_starter_v2', // Medsender Essential plan (Legacy)
  'Medsender-basic-1500',
];

export const upgradablePlans = [
  'Medsender_starter_v2',
  'medsender-premium-v3',
  'medsender-essentials-plan',
  'medsender-solo-v1',
  'medsender-group-plan',
  'Medsender-basic-1500',
  'medsender-plus-with-ai',
  'medsender-complete-with-ai',
];

// These values correspond to the internal ChargeBee plan names
export const planLevels = {
  'medsender-premium-v3': 0, // Medsender Solo Plan (legacy)
  'Medsender-basic-1500': 0, // Medsender Basic Plan
  'medsender-plus-with-ai': 1,
  'medsender-complete-with-ai': 2,
};
