import {
  AssignDocument,
  AssignedDocumentsButton,
  AssignedDocuments,
  EditingAssignedDocument,
  MarkComplete,
} from '../../../assets/img/guide';

const assigningRecords = {
  pageTitle: 'Assigning Records',
  pageSubtitle: 'Medsender allows you to assign documents to other users',
  sections: [
    {
      title: '1. How to assign a document',
      id: 'howToAssign',
      subtitle: `You can assign a document from the inbox page, or when viewing a received record.
      You should see the "Assign Document" button on the top of the page. If you click on it, you can
      input the user, you'd like to assign to (has to be a user in one of your departments) and the status you'd
      like to set it as. You can also leave an optional note. You can modify the list of statuses available by having
      an admin go to their admin dashboard.`,
      img: AssignDocument,
      divider: true,
    },
    {
      title: '2. Getting to assigned documents',
      id: 'viewAssigned',
      subtitle: `From the home page or nav bar, click on thge "Assigned" button. Here, you'll see
      a list of documents that have been assigned to you.`,
      img: AssignedDocumentsButton,
      divider: true,
    },
    {
      title: '3. Viewing assigned documents',
      id: 'viewAssigned',
      subtitle: `When you're viewing the assigned document page, you can search for a specific record using
      any of the provided filters, and you can also view documents assigned to other users in your
      department, or documents that have been assigned by another user in your department. By default,
      we only show documents that haven't been marked as completed. If you'd like to view completed
      documents, you can click on the "Show completed documents only" checkbox.`,
      img: AssignedDocuments,
      divider: true,
    },
    {
      title: '4. Editing an assigned document',
      id: 'editAssignedDocument',
      subtitle: `You can change the status, or who the document is assigned to by clicking the
      "Update Assigned Document" button. Note: this will not mark the document as complete.`,
      img: EditingAssignedDocument,
      divider: true,
    },
    {
      title: '5. Marking a document as complete',
      id: 'markComplete',
      subtitle: `Once you are finished with a document, there are three ways to mark it as "complete"
      This can be done in the new inbox, or in the record viewer. If you hover over the "Mark Complete"
      button, you'll see three options. The first is to "Mark Complete" which will remove it from the list
      of assigned records. The second is to "Mark Complete and Reassign". This allows you to assign the document
      to a new user in one of your departments. You'll also be able to specify a note. The last option is to
      "Mark Complete and Assign Back", this will complete the assignment and assign it back to the original assigner.`,
      img: MarkComplete,
      divider: true,
    },
  ],
};

export default assigningRecords;
