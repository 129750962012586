import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GuestLogout from '../../components/modal/GuestLogout';

import { redirectToCreateGuestAccount, logoutGuest } from '../../actions/auth';

class GuestLogoutDialog extends Component {

  redirect = async () => {
    this.props.redirectToCreateGuestAccount();
    this.props.hideModal();
  }

  hide = async () => {
    this.props.hideModal();
    localStorage.removeItem('m8_guest_token');
    this.props.logoutGuest();
  }

  render() {
    return (
      <GuestLogout
        {...this.props}
        redirect={this.redirect}
        hide={this.hide}
      />
    );
  }
}

GuestLogoutDialog.propTypes = {
  hideModal: PropTypes.func.isRequired,
  redirectToCreateGuestAccount: PropTypes.func.isRequired,
  logoutGuest: PropTypes.func.isRequired,
};

export default connect(null, { redirectToCreateGuestAccount, logoutGuest })(GuestLogoutDialog);
