import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import {
  Select,
} from 'antd';
import { getDocumentCategories } from '../../../actions/userProfile';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class CategorySearchSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: props.paramTools.params.category,
      documentCategories: [],
    };
  }

  componentDidMount = async () => {
    await this.props.getDocumentCategories();
    this.setState({ documentCategories: this.props.allDocumentCategories.categories });
  }

  updateQuery = async (category) => {
    this.setState({ selectedCategory: category });
    const { paramTools, inboxActions, queryType } = this.props;
    const { params } = paramTools;
    const { getRecords } = inboxActions;
    const emptySearch = (!category || category.trim() === '');
    if (params[queryType] === '' || emptySearch) {
      delete params[queryType];
      params.page = 1;
    } else {
      params[queryType] = category;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getRecords(query);
  }

  render() {
    const { documentCategories, selectedCategory } = this.state;
    return (
      <div>
        <Select
          showSearch
          style={{ width: '220px', marginTop: 20 }}
          label="Document Category"
          placeholder="Search by Category"
          allowClear
          value={selectedCategory}
          onChange={option => this.updateQuery(option)}
        >
          {documentCategories.map(emrCategory => <Select.Option key={emrCategory.id} value={emrCategory.emr_category}>{emrCategory.emr_category}</Select.Option>)}
        </Select>
      </div>
    );
  }
}

CategorySearchSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  queryType: PropTypes.string,
  allDocumentCategories: PropTypes.object.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
};

CategorySearchSettings.defaultProps = {
  queryType: 'category',
};

export default connect(state => ({
  documentCategories: state.auth,
  allDocumentCategories: state.userProfile.allDocumentCategories,
}), {
  getDocumentCategories,
})(CategorySearchSettings);
