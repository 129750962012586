/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  Anchor,
  BackTop,
} from 'antd';

const { Link } = Anchor;

class pageContent extends Component {
  showExtensions = () => {
    return (
      <ul style={{ fontSize: 17, marginTop: '-20px' }}>
        <li>Athenahealth</li>
        <li>eClinicalWorks (Web App)</li>
        <li>Kareo</li>
        <li>Nymbl Systems</li>
        <li>EMA / Modernizing Medicine</li>
        <li>DrChrono</li>
        <li>Practice Fusion</li>
        <li>AdvancedMD</li>
        <li>Simple Practice</li>
      </ul>
    );
  }

  showUploadRecordMenu = () => {
    const { selectingGuideMenuItem } = this.props;
    return (
      <ol style={{ fontSize: 17, marginTop: '-20px' }}>
        <li>
          <div role="link" onClick={() => selectingGuideMenuItem({ key: 10 })} style={{ cursor: 'pointer' }}>
            Medsender Printer Driver
          </div>
        </li>
        <li>
          <div role="link" onClick={() => selectingGuideMenuItem({ key: 9 })} style={{ cursor: 'pointer' }}>
            Medsender EMR integration
          </div>
        </li>
        <li>Manual upload to Medsender</li>
      </ol>
    );
  }

  render = () => {
    const { copy, selectingGuideMenuItem } = this.props;
    const {
      sections,
      pageTitle,
      pageSubtitle,
      page,
    } = copy;
    return (
      <div
        style={{ width: '100%', display: 'flex', justfiyContent: 'space-between' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', padding: '40px 80px'}}>
          <BackTop style={{ zIndex: 1000 }}/>
          <h1>{pageTitle}</h1>
          <p style={{ marginTop: '10px', marginBottom: '30px' }}>{pageSubtitle}</p>
          {page === 'chrome_extensions' && this.showExtensions()}
          {page === 'upload_record' && this.showUploadRecordMenu()}
          {sections.map((section, index) => (
            <>
              <h2 data-scrollama-index={index} id={section.id}>{section.title}</h2>
              {section.link
                ? (
                  <a target="_blank" rel="noopener noreferrer" href={section.link} style={{ marginTop: '-60px', fontSize: '16px' }}>{section.subtitle}</a>
                ) : (
                  <p style={{ marginTop: '10px', marginBottom: '50px' }}>{section.subtitle}</p>
                )
              }
              {section.img && <img alt="" style={{ boxShadow: '0px 0px 20px lightgrey', width: '60%' }} src={section.img} />}
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '50px' }}>
                {section.cards && section.cards.map(card => (
                  <Card title={card.title} style={{ width: '280px', margin: '10px 10px', cursor: 'pointer' }} onClick={() => selectingGuideMenuItem({ key: card.menuItemIndex })}>
                    {card.sections.map(labels => (
                      <p>{labels}</p>
                    ))}
                  </Card>
                ))}
              </div>
              {section.divider && <Divider />}
            </>
          ))}
        </div>
        <Anchor style={{ width: '250px', marginTop: '30px' }}>
          {sections.map(section => (
            section.title && <Link href={`#${section.id}`} title={section.title} />
          ))}
        </Anchor>
      </div>
    );
  }
}

pageContent.propTypes = {
  copy: PropTypes.object.isRequired,
  selectingGuideMenuItem: PropTypes.func.isRequired,
};

export default pageContent;
