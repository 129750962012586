import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { stringify } from 'qs';
import {
  Select,
} from 'antd';

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class AssignerSearchSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: props.paramTools.params.assigner,
    };
  }

  updateQuery = async (user) => {
    this.setState({ selectedUser: user });
    const { paramTools, inboxActions, queryType } = this.props;
    const { params } = paramTools;
    const { getRecords } = inboxActions;
    const emptySearch = (!user || user.trim() === '');
    if (params[queryType] === '' || emptySearch) {
      delete params[queryType];
      params.page = 1;
    } else {
      params[queryType] = user;
      params.page = 1;
    }
    const query = stringify(params, stringifyOptions);
    await getRecords(query);
  }

  render() {
    const { selectedUser } = this.state;
    const { departmentUsers } = this.props;
    return (
      <div>
        <Select
          showSearch
          style={{ width: '220px', marginTop: 20 }}
          label="Assigner"
          placeholder="Search by Assigner"
          allowClear
          value={selectedUser}
          onChange={option => this.updateQuery(option)}
        >
          {departmentUsers.map(user => <Select.Option key={user[1]} value={user[1]}>{user[0]}</Select.Option>)}
        </Select>
      </div>
    );
  }
}

AssignerSearchSettings.propTypes = {
  inboxActions: PropTypes.object.isRequired,
  paramTools: PropTypes.object.isRequired,
  queryType: PropTypes.string,
  departmentUsers: PropTypes.array.isRequired,
};

AssignerSearchSettings.defaultProps = {
  queryType: 'assigner',
};

export default connect(state => ({
  departmentUsers: state.records.department_users,
}))(AssignerSearchSettings);
