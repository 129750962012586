import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import Subtext from './subtext';
import HeaderButtons from './buttons';

const { Search } = Input;

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  searchChromeRecords() {
    const searchTrigger = () => {
      const { fetchData, selectedPage } = this.props;
      const { search } = this.state;
      if (search) {
        fetchData(1, 5, `&contains=${search}`);
        return;
      }
      fetchData(selectedPage, 5, search);
    };

    const { search } = this.state;
    return (
      <div style={{ padding: '16px' }}>
        <Search
          onPressEnter={searchTrigger}
          onSearch={searchTrigger}
          onChange={(e) => {
            this.setState({ search: e.target.value });
          }}
          value={search}
          placeholder="Find a record by name or tag"
          style={{ width: '250px' }}
        />
      </div>
    );
  }

  render() {
    const {
      main,
      subtext,
      locationPath,
      buttons,
      chromeSelectorPage,
      searchField,
      searchProps,
    } = this.props;
    return (
      <div>
        <div className="header-parent">
          <h1 className="main-header-text">
            { main }
          </h1>
          <Subtext text={subtext} />
          <HeaderButtons buttons={buttons} />
          {searchField && searchProps.placeholder
            && (
              <Search
                placeholder={searchProps.placeholder}
                loading={searchProps.loading}
                onKeyUp={(e) => { searchProps.onKeyUp(e) }}
                onSearch={(value) => { searchProps.onSearch(value)}}
                size="medium"
                style={{ width: 200, marginRight: '1rem', marginLeft: 'auto' }}
              />
            )
          }
          {chromeSelectorPage && this.searchChromeRecords()}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  main: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  buttons: PropTypes.array,
  locationPath: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })).isRequired,
  chromeSelectorPage: PropTypes.string,
  fetchData: PropTypes.func,
  selectedPage: PropTypes.number,
  searchField: PropTypes.bool,
  searchProps: PropTypes.shape({
    placeholder: PropTypes.string,
    loading: PropTypes.bool,
    onKeyUp: PropTypes.func,
    onSearch: PropTypes.func,
  })
};

Header.defaultProps = {
  subtext: '',
  buttons: [],
  chromeSelectorPage: '',
  fetchData: null,
  searchField: false,
  selectedPage: 0,
};

export default Header;
