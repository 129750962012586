// https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618

const isOnChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  if (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
    return true;
  } return false;
};

export default isOnChrome;

export const isOnIE11 = () => {
  return ((navigator.appName == 'Microsoft Internet Explorer') ||
  ((navigator.appName == 'Netscape') &&
  (new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})").exec(navigator.userAgent) != null)));
};

/**
 * Microsoft has updated Edge >= 80 to use Chromium underneath. These newer versions of Edge
 * practically support the same features that Chrome does
 * 
 * 
 */
export const isOnOldMsEdge = () => {
  return (
    window.navigator.userAgent.toLowerCase().indexOf('edge') > -1
  )
};

export const isOnUnsupportedBrowser = () => {
  return isOnIE11() || isOnOldMsEdge();
};
