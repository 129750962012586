import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';
import './link.scss';

const Link = ({ ...props }) => {
  const {
    bar,
    inline,
    size = 'large',
    label,
    leftIcon,
    rightIcon,
    ...inputProps
  } = props;
  return (
    <div className={classNames('link', { inline })}>
      <Button className={size} type="link" {...inputProps}>
        <div className="link-body">
          {
            leftIcon
            && (
            <div className={`link-icon ${size}`}>
              {leftIcon}
            </div>
            )
          }
          {label}
          {
            rightIcon
            && (
            <div className={`link-icon ${size}`}>
              {rightIcon}
            </div>
            )
          }
        </div>
        {
          bar && <div className="bar" />
        }
      </Button>
    </div>
  );
};

Link.propTypes = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  label: PropTypes.string.isRequired,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  bar: PropTypes.bool,
};

Link.defaultProps = {
  size: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  leftIcon: null,
  rightIcon: null,
  disabled: false,
  inline: false,
  bar: false,
};

export default Link;
