import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TrialExpiry from '../../components/modal/TrialExpiry';
import { triggerSmsMessage } from '../../actions/records';

class TrialExpiryDialog extends Component {
  render() {
    return (
      <div>
        <TrialExpiry
          {...this.props}
        />
      </div>
    );
  }
}

TrialExpiryDialog.defaultProps = {
};

TrialExpiryDialog.propTypes = {
};

export default TrialExpiryDialog;
