/** Initial State */
const initialState = {
  modalType: {
    type: null,
    data: null,
  },
  isLoading: false,
  loggedIn: false,
  needs2FA: false,
  otpAuthToken: '',
  user: {},
  errorStatus: {
    errorMessage: '',
    error: false,
  },
  newUser: {
    email: null,
    name: null,
  },
  newFaxNumber: {
    number: '',
  },
  isCreatingFaxNumber: false,
  areaCodes: [],
  recordSaved: false,
  errorSaving: false,
  url: '',
};

const handlingErrors = (response) => {
  if (response.status === 500) {
    return 'Oh no! We\'re currently experiencing issues on our end. Please try again.';
  }
  if (response.status === 520) {
    return `An unexpected error occured, please try again.`;
  }
  if (response.status === 404) {
    return 'Whoops! The file you\'re looking for can\'t be found!';
  }
  if (response.status === 422) {
    return response.data.error || 'We were unable to process that request. Please try again.';
  }

  if (response.status === 403) {
    if (response.data?.error === 'Not signed in') {
      return 'Not signed in, please refresh the page.';
    }
    return 'Only administrators are allowed to perform this operation.';
  }
  return response.data?.error || 'Oops, we ran into an issue. Please try again.';
};

/** Modal reducer */
export default function (state = initialState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...initialState,
        modalType: action.modalType,
      };
    case 'HIDE_MODAL':
      return initialState;
    case 'USER_AUTHENTICATING_VIA_MODAL' :
      return {
        ...state,
        isLoading: true,
      };
    case 'USER_VERIFICATION_STEP' :
      return {
        ...state,
        errorStatus: {
          errorMessage: '',
          error: false,
        },
        user: {
          uid: action.payload.email,
        },
        isLoading: false,
        needs2FA: true,
        otpAuthToken: action.payload.otpAuthToken,
      };
    case 'USER_AUTHENTICATED_VIA_MODAL' :
      return {
        ...state,
        errorStatus: {
          errorMessage: '',
          error: false,
        },
        message: '',
        isLoading: false,
        user: {
          uid: action.payload.uid,
          name: action.payload.name,
        },
        loggedIn: true,
      };
    case 'AUTH_VIA_MODAL_FAILED' :
      return {
        ...state,
        errorStatus: {
          errorMessage: action.payload.error,
          error: true,
        },
        isLoading: false,
      };
    case 'BEGIN_SAVE_TO_MEDSENDER':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_SAVE_TO_MEDSENDER':
      return {
        ...state,
        isLoading: false,
        errorSaving: true,
        errorStatus: {
          errorMessage: action.payload.response.data.error.message,
          error: true,
        },
        url: `/app/view/${action.payload.response.data.error.file_token}`,
      };
    case 'FINISHED_SAVE_TO_MEDSENDER':
      return {
        ...state,
        isLoading: false,
        recordSaved: true,
        url: `/app/view/${action.payload.data.file_token}`,
      };
    case 'FINISHED_RESENDING_OTP':
      return {
        ...state,
        isLoading: false,
        otpAuthToken: action.payload.otpAuthToken,
      };
    case 'START_OVER':
      return {
        ...state,
        errorStatus: {
          errorMessage: '',
          error: false,
        },
        needs2FA: false,
        otpAuthToken: '',
      };
    case 'FAILED_RESENDING_OTP':
      return {
        ...state,
        isLoading: false,
        errorStatus: {
          errorMessage: action.payload.response.data.error,
          error: true,
        },
      };
    case 'BEGIN_ADDING_NEW_USER':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_ADDING_NEW_USER':
      return {
        ...state,
        isLoading: false,
        errorStatus: {
          error: true,
          errorMessage: handlingErrors(action.payload.response),
        },
      };
    case 'FINISHED_ADDING_NEW_USER':
      return {
        ...state,
        isLoading: false,
        newUser: {
          name: action.payload.data.name,
          email: action.payload.data.email,
        },
      };
    case 'BEGIN_FETCHING_AREA_CODES':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_FETCHING_AREA_CODES':
      return {
        ...state,
        isLoading: false,
        areaCodes: [],
      };
    case 'FINISHED_FETCHING_AREA_CODES':
      return {
        ...state,
        isLoading: false,
        areaCodes: action.payload.data.area_codes,
      };
    case 'BEGIN_ADDING_NEW_FAX_NUMBER':
      return {
        ...state,
        isCreatingFaxNumber: true,
      };
    case 'FAILED_ADDING_NEW_FAX_NUMBER':
      return {
        ...state,
        isCreatingFaxNumber: false,
        errorStatus: {
          error: true,
          errorMessage: handlingErrors(action.payload.response),
        },
      };
    case 'FINISHED_ADDING_NEW_FAX_NUMBER':
      return {
        ...state,
        isCreatingFaxNumber: false,
        newFaxNumber: {
          number: action.payload.data.fax_number.number,
        },
      };
    case 'CLEARING_ADD_USER_MODAL':
      return {
        ...state,
        errorStatus: {
          errorMessage: '',
          error: false,
        },
        newUser: {
          name: '',
          email: '',
        },
      };
    default:
      return state;
  }
}
