import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';

import {
  TutorialIntro,
  SendTutorial,
  UploadRecord,
  ReceiveRecord,
  RecordStatus,
  RecordViewer,
  DownloadApp,
  AddressBook,
} from '../../assets/img';

const tutorialContainer = {
  height: '500px',
  width: '400px',
  boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.1)',
  fontSize: '15px',
  borderRadius: '5px',
  backgroundColor: 'white',
};

const gif = {
  width: '100%',
  height: '45%',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '15px',
};

const tutorialCopy = {
  fontSize: '18px',
  padding: '10px 20px 20px 20px',
  lineHeight: '30px',
};

const nextButton = {
  backgroundColor: '#48238E',
  color: 'white',
  width: '150px',
  padding: '10px',
  borderRadius: '5px',
  textAlign: 'center',
  cursor: 'pointer',
};

const closeButton = {
  color: '#512da8',
  backgroundColor: 'white',
  border: '3px solid #512da8',
  boxSizing: 'border-box',
  width: '170px',
  padding: '10px',
  borderRadius: '5px',
  textAlign: 'center',
  cursor: 'pointer',
};

const gifStyling = {
  height: '115%',
  margin: '0 auto',
  marginTop: '-20px',
  marginLeft: '-0px',
  position: 'relative',
  zIndex: '0',
};

const platform = window.navigator.platform;

const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

const onWindows = windowsPlatforms.indexOf(platform) !== -1 && navigator.userAgent.indexOf('Windows NT 5.1') === -1; 

const copyHeader = [
  'Hey there! Welcome to Medsender.',
  'Uploading a Record',
  'Sending a Record',
  'Receiving a Record',
  'Check Status of a Sent Record',
  'View a Record',
  'Contacts',
];

const uploadCopy = onWindows ? `Download our app, view a record and print it
to "Medsender" to upload directly.`
  : 'In the main dashboard or "Uploaded Records", find the pink upload button in the grey header.';

const receivedCopy = (
  <React.Fragment>
    <a href="https://medsender.com/port" target="_blank"><RaisedButton label="Link your fax number to Medsender" backgroundColor="#fff" /></a>
    <br />{'Then, view all your received faxes in Medsender straight from the dashboard.'}
  </React.Fragment>);

const copy = [
  <React.Fragment>{'Let’s get you started with these quick tips to help you use Medsender to navigate your everyday tasks.'}</React.Fragment>,
  uploadCopy,
  <React.Fragment>{'In "Uploaded Records", select a record to send, and hit the pink send button in the header.'}</React.Fragment>,
  receivedCopy,
  <React.Fragment>{'When viewing your sent records, click the blue status button to the right of each record to view its status'}</React.Fragment>,
  <React.Fragment>{'Click the black view button to the right of each record and search within the record using the search bar above the viewer'}</React.Fragment>,
  <React.Fragment>{'Add and manage your your contacts in Medsender through our contacts feature'}</React.Fragment>,

];

const gifs = [TutorialIntro, onWindows ? DownloadApp : UploadRecord, SendTutorial, ReceiveRecord, RecordStatus, RecordViewer, AddressBook];

class Tutorial extends PureComponent {
  constructor() {
    super();
    this.state = {
      slideNum: 0,
    };
  }

  nextSlide = () => {
    const { slideNum } = this.state;
    if (slideNum < 6) {
      this.setState(prevState => ({
        slideNum: prevState.slideNum + 1,
      }));
    }
  }

  prevSlide = () => {
    const { slideNum } = this.state;
    if (slideNum > 0) {
      this.setState(prevState => ({
        slideNum: prevState.slideNum - 1,
      }));
    }
  }

  // Handler for Download Medsender Windows Client button,
  // opens the download link in a new page
  installWindowsClient = () => {
    const win = window.open(process.env.DOWNLOAD_APP_LINK, '_blank');
    win.opener = null;
  }

  render() {
    const { slideNum } = this.state;
    const { hideTutorial } = this.props;
    const welcomeSlide = slideNum === 0;
    const lastSlide = slideNum === 6;
    return (
      <div style={tutorialContainer}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', zIndex: '10', position: 'relative' }}>
          <div style={{ height: '5px', width: '20px', backgroundColor: '#48238E', cursor: 'pointer', margin: '10px' }} onClick={hideTutorial}/>
        </div>
        <div style={gif}>
          { welcomeSlide
            ? <img style={{ height: '80%', paddingTop: '20px' }} src={TutorialIntro} alt="medsender logo" />
            : <img style={gifStyling} src={gifs[slideNum]} alt="gif of tutorial copy" />
          }
        </div>
        <div style={{maxWidth: '100px', display: 'flex', margin: '0 auto', justifyContent: 'space-between'}}>
          {copyHeader.map((content, index) => (
            <div key={`gif${index}`} style={{ height: '8px', width: '8px', borderRadius: '100px', backgroundColor: slideNum === index ? '#512da8' : 'lightgrey' }} />
          ))}
        </div>
        <div style={tutorialCopy}>
          <div style={{ fontWeight: '800', paddingBottom: '10px' }}>{copyHeader[slideNum]}</div>
          {slideNum === 1 && onWindows && (
          <>
            <RaisedButton
              style={{ marginBottom: '5px' }}
              label="Download Medsender App"
              backgroundColor="rgb(163,237,255)"
              onClick={this.installWindowsClient}
            />
            <br />
          </>
          )}
          {copy[slideNum]}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '0px 15px', position: 'absolute', bottom: '20px', width: '90%'}}>
          <div style={{height: '20px', padding: '10px', cursor: 'pointer'}} onClick={welcomeSlide ? hideTutorial : this.prevSlide}>
            {welcomeSlide ? 'CLOSE' : 'BACK'}
          </div>
          {lastSlide
            ? <div style={closeButton} onClick={hideTutorial}>DONE</div>
            : <div style={nextButton} onClick={this.nextSlide}>{welcomeSlide ? 'GET STARTED' : 'NEXT' }</div>}
        </div>
      </div>
    );
  }
}

Tutorial.propTypes = {
  hideTutorial: PropTypes.func.isRequired,
};

export default Tutorial;
