import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { FlatButton, Dialog, DropDownMenu, MenuItem, TextField } from 'material-ui';
import { MODAL_ENUMS } from '../../../reducers/directMessaging';

const btnStyle = {
  border: '0px',
  color: '#522da8',
  fontSize: '15px',
  fontWeight: '500',
  height: '40px',
  marginTop: '0px',
  marginBottom: '-20px',
  width: '200px',
};

class VerifyDirectAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errorMessage, error } = nextProps;
    this.setState({ errorMessage, error });
  }

  selectDirectAddressFromList = (event, index, value) => {
    const { changeSelectedDirectAddress } = this.props;
    changeSelectedDirectAddress(value);
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitVerificationCode = async () => {
    const { verificationCode } = this.state;
    this.setState({ resent: false });
    if (!verificationCode) return this.setState({ error: true, errorMessage: 'Please enter a verification code' });
    const { confirmVerificationCode, addresses, indexSelectedDirectAddress, changeModalState } = this.props;
    const { slug: selectedAddressID } = addresses[indexSelectedDirectAddress];
    await confirmVerificationCode(selectedAddressID, verificationCode);
    const { verificationCodeTimeout, error } = this.props;
    if (verificationCodeTimeout || error) return;
    return changeModalState(MODAL_ENUMS.CONFIRM_FORWARDING);
  }

  // function to change the modal state when the modal is closed on this window so when the modal is reopened
  // the confirm forwarding modal will show if there is at least one verified address

  clearSelectedAddress = () => {
    const { hideModal, changeModalState } = this.props;
    changeModalState(MODAL_ENUMS.LOADING);
    hideModal();
  }

  render() {
    const modalDescription = 'A verification code has been sent to the selected DIRECT Address. Please enter it below.';
    const { addresses, indexSelectedDirectAddress, changeModalState, resendVerificationCode } = this.props;
    const { errorMessage, error, resent } = this.state;
    return (
      <Dialog
        open={true}
        autoScrollBodyContent={true}
        actions={[
          <FlatButton
            label="CLOSE"
            primary={true}
            keyboardFocused={true}
            onClick={this.clearSelectedAddress}
          />,
          <FlatButton
            label="VERIFY"
            primary={true}
            onClick={this.submitVerificationCode}
          />,
        ]}
      >
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ color: 'black', fontWeight: '700', marginBottom: '15px' }}>Forward to EMR</span>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p>Select the DIRECT Address to verify:</p>
              <DropDownMenu
                style={{ width: '300px', marginBottom: '0px'}}
                autoWidth={false}
                value={indexSelectedDirectAddress}
                onChange={this.selectDirectAddressFromList}
              >
                { addresses && addresses.map((address, index) =>
                  !address.verified && <MenuItem value={index} key={index + address} primaryText={address.address} />)
                }
              </DropDownMenu>
            </div>
            <p style={{ marginBottom: '15px', lineHeight: '30px' }}>{modalDescription}</p>
          </div>
          { errorMessage && error && <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p> }
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              // eslint-disable-next-line object-curly-newline
              style={{ height: '50px', width: '50%', paddingLeft: '10px', marginTop: '10px', fontSize: '15px' }}
              placeholder="Verification Code"
              name="verificationCode"
              onChange={this.handleInputChange}
            />
            <div style={{ display: 'flex' }}>
              <FlatButton
                type="button"
                style={btnStyle}
                onClick={() => changeModalState(MODAL_ENUMS.ENTER_DIRECT_ADDRESS)}
              >
                Add new DIRECT Address
              </FlatButton>
              <FlatButton
                type="button"
                style={btnStyle}
                onClick={() => {
                  resendVerificationCode(addresses[indexSelectedDirectAddress].slug);
                  this.setState({ resent: true });
                }}
              >
                Resend Verification Code
              </FlatButton>
            </div>
            { resent && <p>A new verification code has been sent to your DIRECT address</p>}
          </div>
        </>
      </Dialog>
    );
  }
}

VerifyDirectAddress.defaultProps = {
  indexSelectedDirectAddress: 0,
  verificationCodeTimeout: false,
  errorMessage: null,
  error: false,
  addresses: [],
};

VerifyDirectAddress.propTypes = {
  confirmVerificationCode: PropTypes.func.isRequired,
  indexSelectedDirectAddress: PropTypes.number,
  changeSelectedDirectAddress: PropTypes.func.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  verificationCodeTimeout: PropTypes.bool,
  changeModalState: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  addresses: PropTypes.array,
};

export default VerifyDirectAddress;
