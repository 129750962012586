import React, { Component } from 'react';
import Link from 'react-router-dom/Link';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Form,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { stringify } from 'qs';

import { validFaxNumber, validSendEmail } from '../../../global/validation';

const { Search } = Input;

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

// The typing timer is used in the search bar. It waits until the user's
// keystrokes are idle for 1 second before sending the request
// to Rails
let typingTimer = null;

class AddRecipient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: true,
      labels: [],
      selectedLabels: [],
      selectedAddress: {},
      searchLoading: false,
      validFaxNumber: false,
    };
    this.formRef = React.createRef(null);
  }

  componentDidMount = async () => {
    const { selectedAddress, getLabelsByPage } = this.props;
    if (selectedAddress.recipient_type === 'fax_number') {
      this.setState({ validFaxNumber: true });
    }
    if (selectedAddress && selectedAddress.id) {
      this.setState({ selectedAddress });
      const {
        organization_name: organizationName, contact, contact_name: contactName, provider_office: providerOffice,
      } = selectedAddress;
      this.formRef.current.setFieldsValue({
        contact, organization_name: organizationName, contact_name: contactName, provider_office: providerOffice,
      });
    }
    if (selectedAddress && selectedAddress.labels) {
      this.setState({ selectedLabels: selectedAddress.labels });
    }
    await getLabelsByPage();
    const { labelData } = this.props;
    this.setState({
      labels: labelData,
    });
  }

  onFinish = (values) => {
    const { selectedLabels, selectedAddress } = this.state;
    const updatedValues = { ...values };
    if (!validFaxNumber(updatedValues.contact).valid) {
      updatedValues.provider_office = null;
    }
    const selectedLabelIds = [];
    selectedLabels.map(label => selectedLabelIds.push(label.id));
    // eslint-disable-next-line no-param-reassign
    updatedValues.label_ids = selectedLabelIds;
    this.clearAddressAndLabelValues();
    const { addNewRecipient } = this.props;
    addNewRecipient(updatedValues, selectedAddress.id);
  };

  clearAddressAndLabelValues = () => {
    this.setState({
      selectedAddress: [],
      selectedLabels: [],
    });
  }

  closeModal = async () => {
    await this.clearAddressAndLabelValues();
    this.setState({ showAddressModal: false });
    const { showModal } = this.props;
    showModal();
  }

  selectLabel = (label) => {
    const { selectedLabels } = this.state;
    const currentlySelected = [...selectedLabels];
    const indexOfSelectedLabel = currentlySelected.findIndex(selectedLabel => selectedLabel.name === label.name);
    const labelAlreadySelected = currentlySelected.find(selectedLabel => selectedLabel.name === label.name);
    if (!labelAlreadySelected) {
      this.setState({
        selectedLabels: [...currentlySelected, label],
      });
    } else {
      currentlySelected.splice(indexOfSelectedLabel, 1);
      this.setState({
        selectedLabels: currentlySelected,
      });
    }
  }

  deselectLabel = (index) => {
    const { selectedLabels } = this.state;
    const currentlySelected = selectedLabels;
    currentlySelected.splice(index, 1);
    this.setState({
      selectedLabels: [...currentlySelected],
    });
  }

  searchQuery = async (q) => {
    // eslint-disable-next-line react/destructuring-assignment
    await this.props.getLabelsByQuery(`?contains=${q}`);
    this.setState({ searchLoading: false });
  }

  onSearch = async (value) => {
    const { getLabelsByQuery } = this.props;
    const params = {
      page: 1,
      page_size: 20,
      contains: value,
    };
    const query = stringify(params, stringifyOptions);
    await getLabelsByQuery(query);
    const { labelData } = this.props;
    this.setState({
      labels: labelData,
      searchLoading: false,
    });
  }

  handleContactChange = async (e) => {
    if (validFaxNumber(e.target.value).valid) {
      this.setState({ validFaxNumber: true });
    } else {
      this.setState({ validFaxNumber: false,  });
    }
  }

  render() {
    // eslint-disable-next-line object-curly-newline
    const { labels, selectedLabels, selectedAddress, searchLoading, showAddressModal } = this.state;
    const maxAdditionalLabels = 3 - selectedLabels.length;
    const allowedAdditionalLabels = maxAdditionalLabels > 0;
    const noLabelsSelected = selectedLabels.length === 0;
    const { hideModal } = this.props;

    return (
      <Modal
        title={selectedAddress ? 'Edit contact' : 'Add a new contact'}
        centered
        onCancel={this.closeModal}
        visible={showAddressModal}
        footer={[
          <Button key="back" onClick={this.closeModal}>
            Cancel
          </Button>,
          <Button form="addRecipient" key="submit" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          id="addRecipient"
          name="control-ref"
          onFinish={this.onFinish}
          ref={this.formRef}
        >
          <Form.Item
            name="contact"
            label="Contact (fax number or email)"
            rules={[
              {
                required: true,
                message: 'Email or Fax number is required',
              },
              {
                validator: (_, value) => {
                  return validFaxNumber(value).valid || validSendEmail(value)
                    ? Promise.resolve()
                    : Promise.reject('Invalid email or fax number');
                },
              },
            ]}
          >
            <Input onChange={this.handleContactChange} />
          </Form.Item>
          <Form.Item
            name="organization_name"
            label="Organization Name"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const contactNameVal = getFieldValue('contact_name');
                  if (!contactNameVal && !value) return Promise.reject('At least one of organization or contact are required');
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact_name"
            label="Contact Name"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const orgName = getFieldValue('organization_name');
                  if (!orgName && !value) return Promise.reject('At least one of organization or contact are required');
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          {this.state.validFaxNumber
          && (
            <Form.Item
              name="provider_office"
              label="Set the office name displayed for records received from this number"
            >
              <Input />
            </Form.Item>
          )}
          <Tooltip placement="left" title="Associate labels with contacts to have received records from them automatically labeled appropriately">
            Autolabel
            <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
          </Tooltip>
          <p style={{ fontWeight: '800', display: 'flex', alignItems: 'flex-end', marginTop: 10 }}>
            Selected Labels
            <p style={{
              color: allowedAdditionalLabels ? 'grey' : 'red',
              fontSize: '12px',
              marginBottom: '3px',
              marginLeft: '10px',
            }}
            >
              {allowedAdditionalLabels
                ? `(You can add up to ${maxAdditionalLabels} more label(s))`
                : 'Remove some current labels to add additional labels.'
              }
            </p>
          </p>
          {noLabelsSelected
            ? <div>No labels selected</div>
            : (
              selectedLabels.map((label, index) => (
                <Tag
                  color={label.color}
                  closable={true}
                  key={`${label.name}${index}`}
                  onClose={() => this.deselectLabel(index)}
                  style={{
                    color: label.is_dark_text ? 'black' : 'white',
                    marginBottom: '5px',
                  }}
                >
                  {label.name}
                </Tag>
              ))
            )
          }
          <p style={{ fontWeight: 800, marginTop: '40px' }}>Additional Labels</p>
          <p style={{ color: 'grey', fontSize: 14 }}>
            Navigate to
            <Link to="/app/user?tab=manage_labels" onClick={() => hideModal()}> Manage Labels </Link>
            in settings to add, edit, and delete labels.
          </p>
          <Search
            placeholder="search labels"
            allowClear
            onSearch={this.onSearch}
            style={{ width: 200 }}
            disabled={!allowedAdditionalLabels}
            loading={searchLoading}
            onKeyUp={(e) => {
              this.setState({ searchLoading: true });
              // Maintain a 1 second timer before sending request
              // If any new key is pressed during the course of that
              // timer, reset the timer
              clearTimeout(typingTimer);
              const query = e.target.value;
              typingTimer = setTimeout(() => {
                this.onSearch(query);
              }, 1000);
            }}
          />
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
            {labels.map((label) => {
              const labelIsSelected = selectedLabels.find(selectedLabel => selectedLabel.name === label.name);
              const recordAlreadyHasLabel = selectedLabels.find(selectedRecordLabel => selectedRecordLabel.name === label.name);
              const labelTextColor = label.is_dark_text ? 'black' : 'white';
              return (
                <Tag
                  onClick={() => !recordAlreadyHasLabel && allowedAdditionalLabels && this.selectLabel(label)}
                  color={recordAlreadyHasLabel ? 'white' : label.color}
                  style={{
                    color: recordAlreadyHasLabel ? label.color : labelTextColor,
                    marginBottom: '15px',
                    cursor: allowedAdditionalLabels ? 'pointer' : 'not-allowed',
                    border: recordAlreadyHasLabel ? `1px dotted ${label.color}` : (labelIsSelected && '2px solid black'),
                  }}
                >
                  {label.name}
                </Tag>
              );
            })}
          </div>
        </Form>
      </Modal>
    );
  }
}

AddRecipient.defaultProps = {
  labelData: [],
  selectedAddress: [],
};

AddRecipient.propTypes = {
  hideModal: PropTypes.func.isRequired,
  labelData: PropTypes.array,
  selectedAddress: PropTypes.array,
  getLabelsByPage: PropTypes.func.isRequired,
  addNewRecipient: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  getLabelsByQuery: PropTypes.func.isRequired,
};

export default AddRecipient;
