// Failed SSO component displays the modal content notifying user they aren't signed up with Medsender.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from './ModalWrapper';

const pStyle = {
  textAlign: 'center',
  fontWeight: '600',
};

class FailedSSO extends Component {
  render() {
    const { modalType: { data: { invalidDomain } }, hideModal } = this.props;
    const customContentStyle = {
      width: '400px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        dismiss="Close"
      >
        {invalidDomain ? (
          <div>
            <p style={pStyle}>Login Failed</p>
            <p>
              You are trying to login with an invalid email domain for your Organization. Please contact your Medsender Admin.
            </p>
          </div>
        ) : (
          <div>
            <p style={pStyle}>You aren't signed up for Medsender</p>
            <p>
              Before you can use Medsender, you can sign up at&nbsp;
              <a href="https://medsender.com">medsender.com</a>
              &nbsp;and start using Medsender immediately.
            </p>
          </div>
        )}
      </ModalWrapper>
    );
  }
}

FailedSSO.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.func.isRequired,
};

export default FailedSSO;
