import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import moment from 'moment-timezone';
import Icon, { StopOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import RecordDetail from './RecordDetail';
import RecordDetailHeader from './RecordDetailHeader';
import './sentRecordStatus.scss';
import { verifyFeatureAccess } from '../../global/featureFlags';

const recordStatus = {
  can_view_and_send: 'View and Send',
  can_view: 'View only',
  revoked: 'Revoked',
};

class SentRecordStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: moment.tz.guess(),
      patientName: props.data.patient_name || 'Not Provided',
      patientDOB: props.data.patient_dob || 'Not Provided',
    };

    this.displayRecords = this.displayRecords.bind(this);
  }

  getRecipientAccess(viewCount, recordStatus) {
    const sentAsFax = recordStatus === 'direct_fax';
    if (sentAsFax) return 'The recipient received this record as a fax and has digital access as well';
    return 'The recipient has digital access to this record';
  }

  getViewCount(viewCount) {
    if (viewCount === 0) return 'This record has not yet been viewed';
    return viewCount;
  }

  /**
   * Opens the modal dialog
   * First, Checks if user can revoke record.
   */
  handleDialogOpen = async () => {
    const { fileToken, openModal } = this.props;
    const canRevoke = await verifyFeatureAccess('revoke_record');
    if (canRevoke) {
      const data = {
        recordId: fileToken,
        // eslint-disable-next-line react/destructuring-assignment
        sendToken: this.props.data.send_token,
      };
      const modalType = {
        type: 'REVOKE_RECORDS',
        data,
      };
      return openModal(modalType);
    }
    const modalType = {
      type: 'RAISE_FLAG',
      featureFlag: 'revoke_record',
    };
    openModal(modalType);
  };

  // displays status of record send through fax
  faxRecordDetails = () => {
    const { timezone, patientName, patientDOB } = this.state;
    const { data } = this.props;
    const { resent_at } = data;

    // Format date and add timezone
    const completedAt = data.completed_at
      ? moment.tz(data.completed_at, timezone).format('lll')
      : 'Delivery pending';
    const expiry = data.expiry === null
      ? 'N/A'
      : `Expires ${moment.tz(data.expiry, timezone).format('lll')}`;
    const sentAt = moment.tz(data.date_sent, timezone).format('lll');
    const resentDates = resent_at.map(date => moment.tz(date, timezone).format('lll'));
    const memo = data.memo || 'N/A';
    const pagesSent = data.total_pages_sent ? data.total_pages_sent : 'N/A';
    const numPages = data.num_pages ? data.num_pages : 'N/A';
    let details = [
      { cellHeader: 'Delivery Status', value: data.fax_status, faxStatus: data.fax_status },
      { cellHeader: 'Name', value: patientName },
      { cellHeader: 'Patient DOB', value: patientDOB },
      { cellHeader: 'Memo', value: memo },
      { cellHeader: 'Access status', value: expiry },
      { cellHeader: 'Delivery Completion Time', value: completedAt },
      { cellHeader: 'Last Sent At', value: sentAt },
      { cellHeader: 'Times Sent', value: resentDates },
      { cellHeader: 'No. pages in original record', value: numPages },
      { cellHeader: 'Pages Sent', value: pagesSent },
    ];

    if (data.fax_error_message) {
      details = [...details, { cellHeader: 'Fax Error', value: data.fax_error_message }];
    }

    return details;
  };

  // displays status of records sent through email
  emailRecordDetails = () => {
    const { timezone, patientName, patientDOB } = this.state;
    const { data } = this.props;
    const sentAt = moment.tz(data.date_sent, timezone).format('lll');
    const { record_status, resent_at } = data;
    const resentDates = resent_at.map(date => moment.tz(date, timezone).format('lll'));
    const memo = data.memo || 'N/A';
    const pagesSent = data.total_pages_sent ? data.total_pages_sent : 'N/A';
    const numPages = data.num_pages ? data.num_pages : 'N/A';
    const details = [
      { cellHeader: 'Record Status', value: recordStatus[record_status] },
      { cellHeader: 'Name', value: patientName },
      { cellHeader: 'Patient DOB', value: patientDOB },
      { cellHeader: 'Memo', value: memo },
      { cellHeader: 'Last Sent At', value: sentAt },
      { cellHeader: 'Times Sent', value: resentDates },
      { cellHeader: 'No. pages in original record', value: numPages },
      { cellHeader: 'Pages Sent', value: pagesSent },
    ];

    return details;
  };

  // Displays all rows in tabular format
  displayRecords() {
    const { fax_status } = this.props.data;
    const statusDetails = fax_status ? this.faxRecordDetails() : this.emailRecordDetails();

    return statusDetails.map((detail) => {
      return (<RecordDetail key={detail.cellHeader} {...detail} />);
    });
  }

  render() {
    const { data, patient } = this.props;
    const viewCount = this.getViewCount(data.view_count, data.record_status);
    const receiver = data.receiver_name ? `${data.receiver_name} at ${data.receiver}` : data.receiver;
    const recipientAccess = this.getRecipientAccess(data.view_count, data.record_status);
    return (
      <div>
        <div className="sent-records-header">
          <h1>Sent Record Details</h1>
          {data && (data.record_status === 'revoked')
            && (
              <div className="record-status">
                <Icon component={ExclamationCircleOutlined} style={{ marginTop: '10px' }} />
                <p>The recipient&#39;s access to view this record has been revoked</p>
              </div>
            )
          }
        </div>
        { data
          ? (
            <div className="record-details">
              <RecordDetailHeader title="Recipient" content={receiver} />
              <RecordDetailHeader title="Access" content={recipientAccess} />
              <RecordDetailHeader title="View Count" content={viewCount} />
              <div className="main-details">
                {this.displayRecords()}
              </div>
              <div className="button-holder">
                <Button
                  style={{ backgroundColor: 'rgb(255,0,0)', color: 'rgb(255, 255, 255)' }}
                  onClick={() => this.handleDialogOpen()}
                  disabled={data.record_status === 'revoked' || patient}
                  icon={<StopOutlined />}
                >
                  Revoke Access
                </Button>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

SentRecordStatus.contextTypes = {
  router: PropTypes.object.isRequired,
};

SentRecordStatus.defaultProps = {
  patient: false,
};

SentRecordStatus.propTypes = {
  data: PropTypes.shape({
    fax_status: PropTypes.string,
    fax_error_message: PropTypes.string,
    receiver: PropTypes.string.isRequired,
    patient_name: PropTypes.string.isRequired,
    patient_dob: PropTypes.string.isRequired,
    date_sent: PropTypes.string.isRequired,
    resent_at: PropTypes.array.isRequired,
    completed_at: PropTypes.number,
    expiry: PropTypes.string,
    record_status: PropTypes.string.isRequired,
    memo: PropTypes.string,
    total_pages_sent: PropTypes.string,
    num_pages: PropTypes.string,
  }).isRequired,
  openModal: PropTypes.func.isRequired,
  patient: PropTypes.bool,
  fileToken: PropTypes.string.isRequired,
};

export default SentRecordStatus;
