import React, { Component } from 'react';
import { connect } from 'react-redux';

import SelectOrganization from '../../components/modal/SelectOrganization';
import { changeDefaultOrganization } from '../../actions/appActions';
import { getLoginInfo } from '../../actions/auth';

class SelectOrganizationDialog extends Component {
  render() {
    return (
      <div>
        {(window.location.pathname !== '/app/walkthrough'
          ? (
            <SelectOrganization
              {...this.props}
            />
          ) : null
        )}
      </div>
    );
  }
}

export default connect(state => ({
  errorChangingOrganization: state.appState.error,
}),
{
  changeDefaultOrganization,
  getLoginInfo,
})(SelectOrganizationDialog);
