import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

class ResendRecordsMenu extends PureComponent {
  render() {
    const { openMenu, handleResendRecord, handleRequestChange, disableSingleResend } = this.props;
    return (
      <>
        <IconMenu
          iconButtonElement={<IconButton style={{ display: 'none' }} />}
          open={openMenu}
          targetOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onItemClick={handleResendRecord}
          onRequestChange={handleRequestChange}
          style={{ top: '16px', marginLeft: '10px'}}
        >
          <MenuItem value="1" primaryText="Resend selected record" disabled={disableSingleResend} />
          <MenuItem value="3" primaryText="Resend all records not viewed in 3 Days" disabled={!disableSingleResend} />
          <MenuItem value="7" primaryText="Resend all records not viewed in 7 Days" disabled={!disableSingleResend} />
          <MenuItem value="10" primaryText="Resend all records not viewed in 10 Days" disabled={!disableSingleResend} />
        </IconMenu>
      </>
    );
  }
}

ResendRecordsMenu.propTypes = {
  handleResendRecord: PropTypes.func.isRequired,
  handleRequestChange: PropTypes.func.isRequired,
  openMenu: PropTypes.bool.isRequired,
  disableSingleResend: PropTypes.bool.isRequired,

};

export default ResendRecordsMenu;
