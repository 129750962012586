import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';

// Sidebar sub-components
// Search settings on records pages
import SidebarSettings from './SidebarSettings';
// Returns rest of sidebar interface, determined by 'page' prop
import SidebarInput from './SidebarInput';

import './sidebar.scss';

class Sidebar extends Component {
  render() {
    const {
      page,
      guest,
      docked,
      actions,
      inboxActions,
      paramTools,
      viewerTools,
      mode,
      getPageDataWithQuery,
      getLabelsByQuery,
      data,
      location,
      filterData,
      triggerDock,
      labels,
    } = this.props;
    const onMobile = window.innerWidth < 800;
    return (
      <Drawer
        title="Search Record"
        placement="left"
        mask={false}
        closable={false}
        visible={docked}
        style={{ overflow: 'visible', zIndex: 900, width: 256, padding: 0, marginLeft: !onMobile && 60, marginTop: onMobile && 64 }}
      >
        <div style={{ padding: 0 }}>
          <SidebarInput
            page={page}
            guest={guest}
            inboxActions={inboxActions}
            paramTools={paramTools}
            viewerTools={viewerTools}
            mode={mode}
            location={location}
            getPageDataWithQuery={getPageDataWithQuery}
            data={data}
          />
          {
            guest === false
              && (
                <SidebarSettings
                  page={page}
                  actions={actions}
                  inboxActions={inboxActions}
                  filterData={filterData}
                  paramTools={paramTools}
                  mode={mode}
                  location={location}
                  getPageDataWithQuery={getPageDataWithQuery}
                  getLabelsByQuery={getLabelsByQuery}
                  labels={labels}
                />
              )
          }
        </div>
      </Drawer>
    );
  }
}

Sidebar.defaultProps = {
  guest: false,
  inboxActions: {
    changeInboxPage: () => {},
    getRecords: () => {},
    getRecordsByPage: () => {},
    rowSelected: () => {},
  },
  actions: {
    changeSidebarDate: () => {},
    changeSidebarMenu: () => {},
    checkTriggered: () => {},
  },
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  viewerTools: {
    hiddenButtons: [],
    shownButtons: [],
    postButtons: () => {},
    updateButtons: () => {},
  },
  mode: 'Uploaded',
  labels: [],
};

Sidebar.propTypes = {
  actions: PropTypes.shape({
    changeSidebarDate: PropTypes.func.isRequired,
    changeSidebarMenu: PropTypes.func.isRequired,
    checkTriggered: PropTypes.func.isRequired,
  }),
  filterData: PropTypes.shape({
    departments: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  inboxActions: PropTypes.shape({
    changeInboxPage: PropTypes.func.isRequired,
    getRecords: PropTypes.func.isRequired,
    getRecordsByPage: PropTypes.func.isRequired,
    rowSelected: PropTypes.function,
  }),
  docked: PropTypes.bool.isRequired,
  guest: PropTypes.bool,
  page: PropTypes.string.isRequired,
  paramTools: PropTypes.shape({
    push: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    stringify: PropTypes.func.isRequired,
    stringifyOptions: PropTypes.object.isRequired,
  }),
  getPageDataWithQuery: PropTypes.func.isRequired,
  viewerTools: PropTypes.shape({
    hiddenButtons: PropTypes.array,
    shownButtons: PropTypes.array,
    postButtons: PropTypes.func,
    updateButtons: PropTypes.func,
  }),
  getLabelsByQuery: PropTypes.func.isRequired,
  mode: PropTypes.string,
  labels: PropTypes.array,
};

export default Sidebar;
