import React, { Component } from 'react';
import { connect } from 'react-redux';

import UpdateAssignedDocument from '../../components/modal/UpdateAssignedDocument';
import { getDocumentStatuses, getUserDepartmentsByQuery } from '../../actions/userProfile';

class UpdateAssignedDocumentDialog extends Component {
  render() {
    return (
      <div>
        <UpdateAssignedDocument
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(null, {
  getUserDepartmentsByQuery,
  getDocumentStatuses,
})(UpdateAssignedDocumentDialog);
