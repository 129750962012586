import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, DatePicker } from 'antd';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

import { formMapping } from './constants';
import { validSendEmail, validPatientRecipient } from '../../global/validation';

class Fields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorStatus: {
        error: false,
        field1ErrorMsg: '',
        field2ErrorMsg: '',
      },
      value: '',
      form: formMapping[props.form],
    };
  }

  /**
   * As the parent container error validation changes,
   * update the error inputs to show/hide erorrs
   * @param  {Object} nextProps
   * @return {Void}
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.error.error !== this.state.error) {
      this.setState({
        errorStatus: {
          error: nextProps.error.error,
          // TODO: Clean up way error messages are prioritized (ie if two errors, only first one will show)
          field1ErrorMsg: nextProps.error.accessCodeMsg || nextProps.error.usernameMsg || nextProps.error.otpErrorMsg || nextProps.error.recipientMsg || nextProps.error.securityErrorMsg,
          field2ErrorMsg: nextProps.error.faxCodeMsg || nextProps.error.passwordMsg || nextProps.error.dobMsg,
        },
      });
    }
  }

  /**
   * The inputs receive an error object from the parent component as soon as
   * the submit button is clicked.
   *
   * If the error status has changed or an error message has changed, we need
   * to update this component to display/not to display the errors
   * underneath the inputs
   *
   * Because this component handles both the Login and AccessCode containers,
   * abstractions were made in the field1Error, field2Error, field1ErrorMsg, etc.
   * @param  {Object} nextProps
   * @return {Boolean}
   */
  shouldComponentUpdate(nextProps) {
    // Abstraction so this component works for both Login and AccessCode containers
    const field1Error = nextProps.error.accessCodeMsg || nextProps.error.usernameMsg;
    const field2Error = nextProps.error.faxCodeMsg || nextProps.error.passwordMsg;

    // If the overall error status has changed, update the component
    // to add/remove the errors appropriately
    if (nextProps.error.error !== this.state.errorStatus.error) {
      return true;
    } else if (field1Error !== this.state.errorStatus.field1ErrorMsg) {
      // If field1's errorStatus has changed, update the component
      return true;
    } else if (field2Error !== this.state.errorStatus.field2ErrorMsg) {
      // If field2's errorStatus has changed, update the component
      return true;
    }
    // Otherwise do not re-render and update the component
    return false;
  }

  // Send input value changes to the parent component
  handleChange = (name, event) => {
    if (name === 'dob') {
      this.setState({ value: event });
      this.props.update(name, event);
      return;
    }
    if (this.props.form === 'patient') {
      this.setState({ value: event.target.value });
    }
    event.preventDefault();
    this.props.update(name, event.target.value);
  }

  // Focus on the input on page load
  focusUsernameInput(input) {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  }

  render() {
    // => form: dynamic values to render, depending on whether parent
    // container is Login or AccessCode

    // => errorStatus: object that keeps track of errorStatus, inherited from
    // parent containers

    // => rememberFaxNumber: saved text from remember me
    const { form, errorStatus } = this.state;
    const { rememberFaxNumber, rememberEmail } = this.props;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    return (
      <div>
        {this.props.needs_2FA
          ? (
          // Renders 1 text field if the OTP is to be filled else renders 2
            <Form.Item
              name={form[0].funcVal}
              rules={[
                {
                  validator: (_, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject('Please enter a valid code');
                  },
                },
              ]}
              className="login-form-input"
              label={form[0].labelText}
              suffix={<UserOutlined />}
            >
              <Input size="large" placeholder={form[0].hintText} />
            </Form.Item>
          ) : (
            <div>
              <Form.Item
                name={form[0].funcVal}
                label={form[0].labelText}
                className="login-form-input"
                defaultValue={rememberEmail}
                rules={[
                  {
                    validator: (_, value) => {
                      if (form[0].funcVal === 'username') {
                        return validSendEmail(value)
                          ? Promise.resolve()
                          : Promise.reject('Please enter a valid email address');
                      }
                      if (form[0].funcVal === 'recipient') {
                        return validPatientRecipient(value)
                          ? Promise.resolve()
                          : Promise.reject('Please enter a valid email address or cell phone number');
                      }
                      return value
                        ? Promise.resolve()
                        : Promise.reject('Please enter a valid code');
                    },
                  },
                ]}
              >
                <Input size="large" placeholder={form[0].hintText} />
              </Form.Item>
              {this.props.form === 'patient' ? (
                <Form.Item
                  label="Date of Birth"
                  className="login-form-input"
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    defaultValue={rememberFaxNumber}
                    placeholder="MM/DD/YYYY"
                    value={this.state.value && moment(this.state.value, dateFormat)}
                    onChange={(_, dateString) => this.handleChange('dob', dateString)}
                    format={dateFormat}
                    size="large"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name={form[1].funcVal}
                  rules={[
                    {
                      validator: (_, value) => {
                        return value
                          ? Promise.resolve()
                          : Promise.reject('Password is required');
                      },
                    },
                  ]}
                  label={form[1].labelText}
                  className="login-form-input"
                  defaultValue={rememberFaxNumber}
                >
                  <Input
                    type={form[1].type}
                    size="large"
                    placeholder={form[1].hintText}
                  />
                </Form.Item>
              )}
            </div>
          )
        }
      </div>
    );
  }
}

Fields.defaultProps = {
  rememberFaxNumber: '',
  rememberEmail: '',
  needs_2FA: false,
};

Fields.propTypes = {
  update: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  error: PropTypes.object.isRequired,
  rememberFaxNumber: PropTypes.string.isRequired,
  rememberEmail: PropTypes.string.isRequired,
  needs_2FA: PropTypes.bool,
};

export default Fields;
