import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import AppBar from 'material-ui/AppBar';
import RaisedButton from 'material-ui/RaisedButton';

import {
  Menu,
  Button,
  Layout,
  Badge,
} from 'antd';
import {
  IdcardFilled,
  QuestionCircleFilled,
  SendOutlined,
  MailFilled,
  CloudFilled,
  HomeFilled,
  PoweroffOutlined,
  TeamOutlined,
  SettingOutlined,
  ExceptionOutlined,
} from '@ant-design/icons';
import { verifyFeatureAccess } from '../../global/featureFlags';


import StatusBar from './statusBar';
import newMedsenderLogo from '../../assets/img/medsender_logo_new.png';

import './nav.scss';
import '../sidebar/sidebar.scss';

const { Sider } = Layout;

const EmptyElem = () => {
  return null;
};

// Removes guest token from localStorage.
const clearSession = () => {
  localStorage.removeItem('m8_guest_token');
};

const buttonStyle = {
  textTransform: 'none',
  fontWeight: '500',
  fontSize: '15px',
};

let cableSubscription;

const UserStatusBar = (props) => {
  // User is authenticated, show drop down with user info and logout button
  if (props.auth) {
    return (
      <StatusBar logout={props.logout} user={props.user} {...props} />
    );
  }

  if (props.guest) {
    // User is a guest record viewer, show buttons to create an account
    return (
      <div>
        <RaisedButton
          label="Create free account"
          labelStyle={buttonStyle}
          style={{ marginTop: '7px', marginRight: '7px' }}
          backgroundColor="#07e90b"
          onClick={props.redirectToCreateGuestAccount}
        />
        {props.faxSource !== 'api'
        && (
          <RaisedButton
            label="Save to Medsender"
            labelStyle={buttonStyle}
            style={{ marginTop: '7px', marginRight: '7px' }}
            onClick={props.openModal}
          />
        )}
        <RaisedButton
          secondary={true}
          label="Log out"
          labelStyle={buttonStyle}
          style={{ marginTop: '7px' }}
          onClick={props.openGuestLogoutDialog}
        />
      </div>
    );
  }
  // User is on login page, render nothing
  return null;
};

UserStatusBar.propTypes = {
  auth: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
  }),
  faxSource: PropTypes.string,
  openModal: PropTypes.func.isRequired,
  guest: PropTypes.bool.isRequired,
};

UserStatusBar.defaultProps = {
  user: null,
  faxSource: 'web',
};

class Nav extends Component {
  constructor(props) {
    super(props);
    // Default to keep sidebar open upon page load
    this.state = {
      dockStatus: this.props.docked,
      collapsed: true,
      referralManagementAccess: false,
    };

    this.openModal = this.openModal.bind(this);
    this.openGuestLogoutDialog = this.openGuestLogoutDialog.bind(this);
  }

  componentDidMount = async () => {
    const canViewReferralMgmt = await verifyFeatureAccess('referral_management');
    this.setState({
      referralManagementAccess: canViewReferralMgmt,
    });
  }

  // If the sidebar is open automatically on a page (through initialState),
  // clicking on toggle will not be in sync with the state of the app in the
  // store. This is because the initialState will pass down props to the nav as
  // false, but the location (a little later) will pass it as true.
  // When the location passes it down, this function makes sure its up-to-date
  // with the store
  componentWillReceiveProps(nextProps) {
    if (this.state.docked !== nextProps.docked) {
      this.setState({
        dockStatus: nextProps.docked,
      });
    }

    if (nextProps.auth && !cableSubscription) {
      const { user } = nextProps;
      let canViewReferrals = this.state.referralManagementAccess;
      if (user) {
        if (user?.referralManagement === 'true' || user?.referralManagement === true) {
          canViewReferrals = true;
        } else {
          canViewReferrals = false;
        }
      }

      if (canViewReferrals) {
        cableSubscription = this.props.cable.subscriptions.create(
          {
            channel: 'ReferralsChannel',
          },
          {
            received: (message) => {
              if (message === 'increment') {
                this.props.incrementReferralCount();
              } else if (message === 'decrement') {
                this.props.decrementReferralCount();
              } else {
                this.props.setNumberOfNewReferrals(message);
              }
            },
          },
        );
      }
    }
  }

  componentWillUnmount() {
    if (cableSubscription) {
      cableSubscription.unsubscribe();
    }
  }

  linkToSupportSite = () => {
    const win = window.open('https://medsender.com/support', '_blank');
    win.opener = null;
  };

  /**
   * Trigger open of sidebar
   *
   * If the burger in the nav is clicked, it should either open or close the
   * sidebar, depending on its current state
   * @param  {Object} e [Click event]
   * @return {null}
   */
  handleClick() {
    // Update sidebar to either open or close - i.e.
    // do the opposite of the current dockStatus
    this.setState({ dockStatus: !this.state.dockStatus });
    this.props.toggle(this.state.dockStatus);
  }

  openModal() {
    const modalType = {
      type: 'ADD_RECORD',
    };
    this.props.openModal(modalType);
  }

  openGuestLogoutDialog() {
    const modalType = {
      type: 'GUEST_LOGOUT',
    };
    this.props.openModal(modalType);
  }

  /**
   * Redirect user to page depending on their level of authorization
   * @param  {Object} props {this.props}
   * @return {String}       route to redirect to
   */
  redirect(props) {
    if (props.auth) {
      return '/app';
    } if (props.guest || props.createGuest) {
      return '/app/access_code';
    } if (props.patient) {
      return '/app/patient';
    } return '/login';
  }

  onMenuCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  displayHamburger() {
    const recordsPages = ['/app/received', '/app/uploads', '/app/sent', '/app/assigned', '/app/referred'];
    if (this.props.auth && recordsPages.includes(window.location.pathname)) return undefined;
    return (<EmptyElem />);
  }

  renderRedirectButton() {
    const isOnReceivedSelector = window.location.pathname === '/app/chrome/received_selector';
    const chromeSelectorLink = isOnReceivedSelector ? '/app/chrome/uploaded_selector' : '/app/chrome/received_selector';
    const labelName = isOnReceivedSelector ? 'Uploaded' : 'Received';
    return (
      <Button href={chromeSelectorLink}>
        {`Switch to ${labelName} Records`}
      </Button>
    );
  }

  loginAsAdmin = async () => {
    const { loginAdmin } = this.props;
    await loginAdmin();

    const { adminLoginSuccess, loginErrorMessage } = this.props;
    if (adminLoginSuccess) {
      const win = window.open(`${window.location.origin}/admin`, '_blank');
      win.opener = null;
    } else {
      /* global toastr */
      toastr.error(loginErrorMessage);
    }
  }

  render() {
    const { redirect } = this;
    const isOnChromeExt = window.location.pathname.includes('/app/chrome/');
    // eslint-disable-next-line object-curly-newline
    const { auth, openModal, logout, user } = this.props;
    const statusBarProps = {
      ...this.props,
      openModal: this.openModal,
      openAnyModal: openModal,
      openGuestLogoutDialog: this.openGuestLogoutDialog,
    };
    const isLoginPage = window.location.pathname.includes('/login');

    let canViewReferrals = this.state.referralManagementAccess;
    if (user) {
      if (user?.referralManagement === 'true' || user?.referralManagement === true) {
        canViewReferrals = true;
      } else {
        canViewReferrals = false;
      }
    }

    const onMobile = window.innerWidth < 800;
    // noHighLightOnSelect is set to prevent menu buttons linking to external pages from being shown as selected
    // filters out all false values so trial expired button isn't shown
    let referralsIcon = <Badge size="small" count={this.props.newReferralsCount}><TeamOutlined style={{ color: 'rgba(255, 255, 255, 0.65)' }} /></Badge>;

    if (!this.state.collapsed || !canViewReferrals) {
      // If the nav bar is not collapsed (open), then don't display notification badge.
      referralsIcon = <TeamOutlined />;
    }

    const navButtons = [
      {
        label: 'Home',
        link: '/app',
        icon: <HomeFilled />,
        style: { color: 'white' },
        key: 'home',
      },
      {
        label: 'Uploads',
        link: '/app/uploads',
        icon: <CloudFilled />,
        key: 'uploads',
      },
      {
        label: 'Sent',
        link: '/app/sent',
        icon: <SendOutlined />,
        key: 'sent',
      },
      {
        label: 'Inbox',
        link: '/app/received',
        icon: <MailFilled />,
        key: 'inbox',
      },
      {
        label: 'Assigned',
        link: '/app/assigned',
        icon: <ExceptionOutlined />,
        key: 'assigned',
      },
      {
        label: 'Referral Management',
        link: '/app/referred',
        icon: referralsIcon,
        key: 'referrals',
      },
      {
        label: 'Contacts',
        link: '/app/contacts',
        icon: <IdcardFilled />,
        key: 'contacts',
      },
    ].filter(Boolean);

    const bottomNavButtons = [
      {
        label: 'Settings',
        link: '/app/user',
        icon: <SettingOutlined />,
        style: { position: 'absolute', bottom: '230px', width: '100%' },
        key: 'settings',
      },
      {
        label: 'Guide',
        link: '/app/guide',
        icon: <QuestionCircleFilled />,
        key: 'guide',
        style: { position: 'absolute', bottom: '170px', width: '100%' },
      },
      {
        label: 'Admin Login',
        icon: <TeamOutlined />,
        externalLink: this.loginAsAdmin,
        noHighlightOnSelect: true,
        key: 'admin-login',
        style: { position: 'absolute', bottom: '110px', width: '100%' },
      },
      {
        label: 'Sign Out',
        icon: <PoweroffOutlined />,
        externalLink: logout,
        noHighlightOnSelect: true,
        style: {
          position: 'absolute', bottom: '50px', width: '100%', borderTop: '1px solid rgba(255, 255, 255, 0.65)',
        },
        key: 'sign-out',
      },
    ].filter(Boolean);

    return (
      // hide the new nav bar before logging in and completing the walkthrough or if the user is on mobile
      (auth && window.location.pathname !== '/app/walkthrough' && !onMobile && !isOnChromeExt)
        ? (
          // z-index set so nav will always be on top of the inbox sidebars that have a z-index of 1000
          <div style={{ height: '100%', position: 'absolute', zIndex: 1000 }}>
            <Sider id="sidebar-nav" collapsible collapsed={this.state.collapsed} onCollapse={this.onMenuCollapse} style={{ height: '100%', position: 'fixed' }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
              >
                <Menu
                  mode="inline"
                  theme="dark"
                >
                  {navButtons.map(button => (
                      (
                        <Menu.Item
                          key={button.key}
                          icon={button.icon}
                          onClick={button.externalLink ? button.externalLink : () => this.onMenuCollapse(true)}
                          style={button.style}
                          className={button.link && window.location.pathname === button.link && !button.noHighlightOnSelect && 'add-menu-item-selected-highlight'}
                        >
                          {button.link
                            ? (
                              <Link to={button.link}>
                                {button.label}
                              </Link>
                            ) : (
                              button.label
                            )
                          }
                        </Menu.Item>
                      )
                  ))}
                </Menu>
                <Menu
                  mode="inline"
                  theme="dark"
                >
                  {bottomNavButtons.map(button => (
                    <Menu.Item
                      key={button.key}
                      icon={button.icon}
                      onClick={button.externalLink ? button.externalLink : () => this.onMenuCollapse(true)}
                      style={button.style}
                      className={button.link && window.location.pathname === button.link && !button.noHighlightOnSelect && 'add-menu-item-selected-highlight'}
                    >
                      {button.link
                        ? (
                          <Link to={button.link}>
                            {button.label}
                          </Link>
                        ) : (
                          button.label
                        )
                      }
                    </Menu.Item>
                  ))}
                </Menu>
              </div>
            </Sider>
          </div>
        )
        : (!isLoginPage && (
        <AppBar
          className="app-bar"
          iconElementLeft={this.displayHamburger()}
          onLeftIconButtonClick={this.handleClick.bind(this)}
          title={(
            <Link to={redirect(this.props)}>
              <img alt="logo" className="nav-logo" src={newMedsenderLogo} />
            </Link>
              )}
          iconElementRight={!isOnChromeExt ? <UserStatusBar {...statusBarProps} /> : this.renderRedirectButton()}
        />
        )
        )
    );
  }
}

Nav.propTypes = {
  auth: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  guest: PropTypes.bool.isRequired,
  docked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  redirectToCreateGuestAccount: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  loginAdmin: PropTypes.func.isRequired,
  setNumberOfNewReferrals: PropTypes.func.isRequired,
  incrementReferralCount: PropTypes.func.isRequired,
  decrementReferralCount: PropTypes.func.isRequired,
  newReferralsCount: PropTypes.number.isRequired,
};

Nav.defaultProps = {
  user: '',
};

export default Nav;
