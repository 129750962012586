import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

class FiltersMenu extends PureComponent {
  render() {
    const {
      openFilterMenu,
      handleRequestChange,
      filterOptions,
      handleFilter
    } = this.props;
    return (
      <>
        <IconMenu
          iconButtonElement={<IconButton style={{ display: 'none' }}/>}
          open={openFilterMenu}
          targetOrigin={{ vertical: 'top', horizontal: 'left' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onItemClick={handleFilter}
          onRequestChange={handleRequestChange}
          style={{ top: '16px', marginLeft: '15px'}}
        >
          {filterOptions.map(({ text }, index) => {
            return(
              <MenuItem key={index} value={index} primaryText={text} />
            )}
          )}
        </IconMenu>
      </>
    );
  }
}

FiltersMenu.propTypes = {
  handleRequestChange: PropTypes.func.isRequired,
  openFilterMenu: PropTypes.bool.isRequired,
  filterOptions: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
};

export default FiltersMenu;
