import axios from 'axios';
import { push } from 'react-router-redux';

const refireRequest = (request, retries, dispatch) => {
  setTimeout(() => {
    dispatch({
      type: 'RETRYING_REQUEST',
      payload: {
        retries
      },
    });
    crud(request, retries)(dispatch);
  }, 2000);
}

/**
 * Handle 401 API calls, which would typically be session timeouts
 */
const redirectToLogin = (dispatch, error) => {
  dispatch({
    type: 'INVALID',
  });
  const forceRefresh = error.response.data.force || false;
  const invalidCredentials = 'Your session has expired. Please login again to continue';
  if (forceRefresh) {
    localStorage.setItem('m8_error', invalidCredentials);
  } else {
    localStorage.setItem('m8_error', 'Invalid username or password');
  }
  window.location.reload();
};

const requestStatuses = {
  notFired: 0,
  success: 1,
  failure: 2,
};

const crud = (request, retries = 0) => async (dispatch) => {
  if (request.query) {
    dispatch(push(request.query));
  }

  // Dispatch start of request
  dispatch({
    type: request.dispatch.begin,
  });

  let response;
  let requestStatus = requestStatuses.notFired;

  try {
    // Make API request
    response = await axios({
      method: request.method,
      url: request.url,
      data: request.data ? request.data : null,
      headers: {
        'X-CSRF-TOKEN': document.getElementsByName('csrf-token')[0].content,
      },
    });
    requestStatus = requestStatuses.success;
  } catch (error) {
    if (retries > 5) dispatch({ type: 'RETRYING_FAILED' });
    // Only retry requests up to 5 times after the first initial failure
    if (error.response && error.response.status === 503 && retries <= 5) {
      retries += 1;
      // TODO (Ammar Khan)
      // Cancel retry if user navigates away from page
      // => This could be done by storing retries in a global retries reducer
      return refireRequest(request, retries, dispatch);
    }
    // Send 500 errors to sentry
    if (error.response && error.response.status === 500) {
      window.raven.captureException(error);
    }

    // If user is unauthorized redirect to login
    if (error.response && error.response.status === 401) {
      redirectToLogin(dispatch, error);
    }
    // Construct error object
    let errorResponse = error.response;
    const errorStatus = errorResponse?.status;
    const errorMessage = errorResponse?.data?.error;
    if (!errorStatus || !errorMessage) {
      errorResponse = {
        status: errorStatus || 520,
        data: {
          error:
            errorMessage || 'An unexpected error occurred, please try again.',
        },
      };
    }
    const errorObject = {
      ...JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
      response: errorResponse,
    };

    requestStatus = requestStatuses.failure;

    // Dispatch failure
    dispatch({
      type: request.dispatch.fail,
      payload: errorObject,
    });
    return { ...errorObject, error: true };
  }

  if (requestStatus === requestStatuses.success) {
    // Dispatch success
    dispatch({
      type: request.dispatch.end,
      payload: response,
    });

    // Redirect to another route if needed
    if (request.redirect) {
      dispatch(push(request.redirect));
    }

    // Run any callback/dispatch action after the request as needed
    if (request.callback) dispatch(request.callback(response));
    // Run onSuccess function after request is successfull
    if (request.onSuccess) request.onSuccess();
  }
};

export default crud;
