import React, { Component } from 'react';

import UpgradeBrowser from '../../components/modal/UpgradeBrowser';


class UpgradeBrowserDialog extends Component {
  render() {
    return (
      <div>
        <UpgradeBrowser
          {...this.props}
        />
      </div>
    );
  }
}

export default UpgradeBrowserDialog;
