import {
  ReceivedRecord,
  ReceivedInbox,
} from '../../../assets/img/guide';

const receivingRecordCopy = {
  pageTitle: 'Receiving a Document',
  pageSubtitle: `You can view your received records by clicking the “Received Records” tile 
  from the Medsender dashboard.`,
  sections: [
    {
      subtitle: 'Here, you can see all incoming faxes, forms, and secure email replies.',
      img: ReceivedRecord,
    },
    {
      title: 'Record permissions, downloads, and more',
      id: 'permissions',
      subtitle: `
        An eye icon indicates that you have viewed a record, a download icon indicates how many times a record has been downloaded 
        (or uploaded into a patient’s chart), and a chat bubble indicates that a reply is attached.
        You will also be able to see the sharing permissions on each record, to see if it was shared either 
        with you, an entire department, etc.
      `,
      img: ReceivedInbox,
      divider: true,
    },
  ],
};

export default receivingRecordCopy;
