function isTouchDevice() {
  if (
    !(
      'ontouchstart' in window ||
       (window.DocumentTouch && document instanceof DocumentTouch)
    )
  ) {
    return false;
  }
  return true;
}

export default isTouchDevice;
