import React, { useState, useEffect } from 'react';
import {
  Input,
  DatePicker,
  Checkbox,
  Form,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import PrivacyLevel from '../../PrivacyLevel';
import { formatDate, validDOB } from '../../../../global/validation';
import Box from '../../../common/basic/Box';
import theme from '../../../../assets/stylesheets/theme';

const UploadSingleRecord = ({
  file, display, errorMessage, uploadRecord, data,
  uploadClicked, isLoading, isOnlyRecord,
  onUploadSuccess, onUploadError, isUploaded, hasCommonPatient,
  patientName: passedPatientName, patientDOB: passedPatientDOB,
  onRequiredInfoCorrect, canPublishForms,
}) => {
  const [patientName, setPatientName] = useState('');
  const [patientDOB, setPatientDOB] = useState('');
  const [isFormDocument, setIsFormDocument] = useState(false);
  const [privacyLevel, setPrivacyLevel] = useState(localStorage.getItem('privacy_level') || 'department');
  const [useSelectedPrivacyLevel, setUseSelectedPrivacyLevel] = useState(true);

  const [hasShownFeedback, setHasShownFeedback] = useState(false);

  const validateName = (name) => {
    if (name && name.trim() !== '') return true;
    return false;
  };

  const upload = async () => {
    const usedName = hasCommonPatient ? passedPatientName : patientName;
    const usedDOB = hasCommonPatient ? passedPatientDOB : patientDOB;

    const validName = validateName(usedName);
    const validDate = validDOB(usedDOB) || usedDOB === '';

    // Do not send request if any fields are invalid
    if (!validName || !validDate || !file) return;

    const dob = formatDate(usedDOB);
    // Set up form data for data.
    const bodyFormData = new FormData();
    bodyFormData.append('name', usedName);
    bodyFormData.append('dob', dob);
    bodyFormData.append('file', file);
    bodyFormData.append('privacy_level', privacyLevel);
    bodyFormData.append('is_form_document', isFormDocument);
    const e = await uploadRecord(bodyFormData);
    if (e?.error) {
      toastr.error(`Error when uploading ${file.name}, ${e.message || 'please try again'}`);
      onUploadError();
    } else {
      localStorage.setItem('from_page', 'uploaded');
      onUploadSuccess();
    }
    setHasShownFeedback(true);
  };

  useEffect(() => {
    if (isLoading || !uploadClicked || hasShownFeedback) return;

    if (data.url && isOnlyRecord) {
      if (useSelectedPrivacyLevel) {
        localStorage.setItem('privacy_level', privacyLevel);
      } else {
        localStorage.removeItem('privacy_level');
      }
      window.open(`/app/view/${data.url}?from=uploaded`, '_self');
    }
  }, [data]);


  useEffect(() => {
    if (uploadClicked && !isUploaded) {
      upload();
    }
  }, [uploadClicked]);

  const handleNameChange = (event) => {
    setPatientName(event.target.value);
    onRequiredInfoCorrect(!!event.target.value);
  };
  const handleDateChange = (_, dateString) => setPatientDOB(dateString);
  const handleIsFormDocumentChange = event => setIsFormDocument(event.target.checked);

  const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];

  return (
    <Box b={`2px solid ${theme.colors.gray}`} borderRadius="5px" p={isOnlyRecord ? '25px 10px' : '25px 10px 0'} m="20px 0" position="relative">
      <Box position="absolute" top="-15px" hidden={!file?.name}>
        <Tag color="blue">{file?.name}</Tag>
      </Box>
      <Form>
        {
          !hasCommonPatient && (
          <>
            <Form.Item
              hasFeedback
              name="name"
              label="Name"
              rules={[
                () => ({
                  validator(_, name) {
                    if (name && name.trim() !== '') return Promise.resolve();
                    return Promise.reject('Name cannot be blank');
                  },
                }),
              ]}
            >
              <Input
                placeholder="Name"
                onChange={handleNameChange}
              />
            </Form.Item>
            <Form.Item
              label="Date of Birth MM/DD/YYYY (optional)"
            >
              <DatePicker
                onChange={handleDateChange}
                format={dateFormat}
                value={moment(patientDOB).isValid() && moment(patientDOB)}
              />
            </Form.Item>
          </>
          )
        }
        {
          canPublishForms
          && (
          <Form.Item>
            <Checkbox
              onChange={handleIsFormDocumentChange}
            >
              Is this document a form?
            </Checkbox>
          </Form.Item>
          )
        }
        <p>Viewing Permissions</p>
        <PrivacyLevel
          valueSelected={privacyLevel}
          display={display}
          handleChange={(value) => {
          // eslint-disable-next-line no-shadow
            const privacyLevel = value;
            setPrivacyLevel(privacyLevel);
          }}
        />
        <br />
        {
          display && isOnlyRecord
          && (
            <React.Fragment>
              <Checkbox onChange={event => setUseSelectedPrivacyLevel(event.target.checked)} checked={useSelectedPrivacyLevel}>
                Use selected privacy level for all future uploads.
              </Checkbox>
            </React.Fragment>
          )
        }
      </Form>
    </Box>
  );
};

UploadSingleRecord.contextTypes = {
  router: PropTypes.object.isRequired,
};

UploadSingleRecord.defaultProps = {
  errorMessage: '',
  data: [],
};

UploadSingleRecord.propTypes = {
  uploadRecord: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    originFileObj: PropTypes.shape({}),
  }).isRequired,
  errorMessage: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]),
};

export default connect(state => ({
  data: state.records.data,
  isLoading: state.records.isLoading,
}))(withRouter(UploadSingleRecord));
