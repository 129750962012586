const initialState = {
  recordData: [],
};

const userSelectedNone = (data) => (
  data && data.length > 0 && data[0] === 'none'
);

const userhadAllPreviouslySelected = ({ recordData }) => (
  recordData && recordData.length > 0 && recordData[0] === 'all'
);

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SELECTED_ROWS': {
      // On the received records page, when select all checkbox is unchecked, the payload
      // is an array with 'none' as the only element.
      //
      // In this case, we want to set the data to an empty array
      //
      // There is also a longstanding bug in v0 of material-ui that does not handle
      // de-selecting rows if "all" are selected. See: https://github.com/mui-org/material-ui/issues/5964
      //
      // As a result, if "all" is currently selected and anything else is selected outside,
      // we will not return the selected data and instead reset the selected rows.
      if (userSelectedNone(action.payload) || userhadAllPreviouslySelected(state)) {
        return { recordData: [] }
      }

      // If it's array, no need to convert object to array. We need to check this because inbox
      // pages expect the recordData to be of type array, but when updating the patient info, it
      // returns an object.
      if (!Array.isArray(action.payload)) {
        return {
          recordData: [action.payload],
        };
      }

      return {
        // The payload will consist of the Row Data from the Inbox Table for the
        // specific rows selected.
        recordData: action.payload,
      };
    }
    case 'FINISHED_MERGE_RECORDS': {
      // After records are merged, selected data should be cleared
      return initialState;
    }
    case 'FINISHED_UPDATE_RECORD': {
      // After record is edited, selected data should be cleared
      return initialState;
    }
    // When user navigates between pages, make sure to not persist selected data
    // between the different record pages (i.e. from sent to received)
    case '@@router/LOCATION_CHANGE': {
      return initialState;
    }
    default:
      return state;
  }
};
