import { replace } from 'react-router-redux';
import { stringify } from 'qs';
import crud from './helpers/crud';

const toggleSidebar = toggled => (dispatch) => {
  dispatch({
    type: 'SIDEBAR_TOGGLE',
    payload: {
      toggled,
    },
  });
};

/**
 * This fxn allows switching between different orgs/accounts
 * A request is made to the server, to switch the location/org/branch
*/
const changeDefaultOrganization = (org, onSuccess = () => {}) => (
  crud({
    dispatch: {
      begin: 'BEGIN_CHANGE_DEFAULT_ORGANIZATION',
      fail: 'FAILED_CHANGE_DEFAULT_ORGANIZATION',
      end: 'FINISHED_CHANGE_DEFAULT_ORGANIZATION',
    },
    method: 'PUT',
    data: {
      users: {
        default_organization: org,
      },
    },
    url: '/api/v1/users/set_default_organization',
    onSuccess,
  })
);

/**
 * Update application state to close dialog after confirmation/error
 *
 * See containers/Inbox.jsx for example usage
 * @return {Void}
 */
const closeSendDialog = () => (dispatch) => {
  dispatch({
    type: 'CLOSE_SEND_DIALOG',
  });
};

// Gibraltar has a setting to allow the send records dialog to be opened as soon
// as the record viewer page is loaded. This is accomplished through a query
// param ?send=true.
//
// In the event the user refreshes the page, the query param would still be
// there which is why once the component mounts on the DOM, we will set send to false and keep the
// from param (signifies which inbox the user came from when viewing record).
const removeSendDialogParam = (inboxPage, sendToken, assignedDocumentId) => (dispatch) => {
  // Set the param to ?send=false
  //
  // In practice, this means that subsequent page refreshes will not have the
  // send records dialog open.
  const query = stringify({ send: 'false', from: inboxPage, sendToken, assignedDocumentId });
  dispatch(replace({ search: query }));
};

const sendReferral = data => (
  crud({
    dispatch: {
      begin: 'BEGIN_SEND_REFERRAL',
      fail: 'FAILED_SEND_REFERRAL',
      end: 'FINISHED_SEND_REFERRAL',
    },
    method: 'POST',
    url: '/api/v1/referrals',
    data,
  })
);

const clearReferral = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_REFERRAL',
  });
};

/* eslint import/prefer-default-export:0 */
export {
  toggleSidebar,
  changeDefaultOrganization,
  closeSendDialog,
  removeSendDialogParam,
  sendReferral,
  clearReferral,
};
