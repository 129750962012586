// Passes on the trial User info from the localStorage

import React, { Component } from 'react';
import { connect } from 'react-redux';

import RaiseFlag from '../../components/modal/RaiseFlag';

class RaiseFlagDialog extends Component {
  render() {
    return (
      <div>
        <RaiseFlag
          {...this.props}
        />
      </div>
    );
  }
}

export default connect(state => ({
  trialUser: state.auth.trialUser }),
null,
)(RaiseFlagDialog);
