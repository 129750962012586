// Connects the Install Medsender modal action

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import GetEmail from '../../components/modal/GetEmail';
import { printGuestRecord } from '../../actions/records';
import { validSendEmail } from '../../global/validation';

class GetEmailDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem('m8_guest_email'),
      checked: true,
      validEmail: true,
    };
  }


  // Handler for the email input box. Passed to the Get Email modal component via props
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      validEmail: true,
    });
  }

  // Validates the email entered then calls the printGuestRecord action and
  // downloads the record from the signed URL sent by Rails
  printRecord = async (printWindow, action) => {
    if (validSendEmail(this.state.email)) {
      const payload = Object.assign(
        {},
        this.props.recordAccessCodes,
        { email: this.state.email }
      );
      await this.props.printGuestRecord(payload);
      printWindow.location.href = this.props.guestAccessUrl;
      printWindow.opener = null;
      this.props.hideModal();
      this.updateLocalStorage();
    } else {
      this.setState({
        validEmail: false,
      });
    }
  }

  updateLocalStorage = () => {
    if (this.state.checked) {
      localStorage.setItem('m8_guest_email', this.state.email);
    } else {
      localStorage.removeItem('m8_guest_email');
    }
  }

 // Handler for the Remember me checkbox
  toggleChecked = (isChecked) => {
    this.setState({
      checked: isChecked,
    });
  }

  render() {
    return (
      <div>
        <GetEmail
          {...this.props}
          handleChange={this.handleChange}
          printRecord={this.printRecord}
          email={this.state.email}
          isChecked={this.state.checked}
          toggleChecked={this.toggleChecked}
          validEmail={this.state.validEmail}
        />
      </div>
    );
  }
}

GetEmailDialog.propTypes = {
  guestAccess: PropTypes.string.isRequired,
  printGuestRecord: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  recordAccessCodes: PropTypes.object.isRequired,
  guestAccessUrl: PropTypes.string,
};

export default connect(state => ({
  guestAccess: state.auth.guestAccess,
  recordAccessCodes: state.auth.fax,
  guestAccessUrl: state.records.guestAccessUrl,
}),
{ printGuestRecord })(GetEmailDialog);
