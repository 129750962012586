import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Checkbox,
  Input,
  message,
  Button,
} from 'antd';

import ModalWrapper from './ModalWrapper';
import { SortedEMRs, SupportedEMRs } from '../../containers/walkthrough/constants';
import isOnChrome from '../../global/browser';

const { Option } = Select;

class ChangeEMR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EMRInput: props.modalType.data.emr,
      hasSupportedChromeExtension: false,
      supportedFeatures: [],
      chromeExtensionInstalled: false,
      // if no EMR is selected, assume this was checked in the walkthrough and automatically check this box
      checked: props.modalType.data.emr,
    };
  }

  componentDidMount = () => {
    const emrThatHasExtension = SupportedEMRs.filter(emr => emr.emr === this.props.emr);
    this.setState({
      hasSupportedChromeExtension: emrThatHasExtension[0],
      supportedFeatures: emrThatHasExtension[0] ? emrThatHasExtension[0].supportedFeatures : [],
      checked: !this.props.modalType.data.emr,
    });
  }

  handleFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      checked: false,
    });
  }

  handleChange = (EMRInput) => {
    this.setState({
      EMRInput,
      checked: false,
      hasSupportedChromeExtension: false,
    });
    const emrThatHasExtension = SupportedEMRs.filter(emr => emr.emr === EMRInput);
    if (emrThatHasExtension.length) {
      this.setState({
        hasSupportedChromeExtension: true,
        supportedFeatures: emrThatHasExtension[0].supportedFeatures,
      });
    }
  }

  // Handler for the 'I have no EMR' checkbox
  toggleChecked = (isChecked) => {
    this.setState({
      checked: isChecked,
      EMRInput: null,
      hasSupportedChromeExtension: false,
    });
  };

  handleSubmit = async () => {
    const { EMRInput } = this.state;
    const { updateUserDetails, hideModal } = this.props;
    localStorage.setItem('m8_emr', EMRInput);
    await updateUserDetails({ emr: EMRInput });
    hideModal();
    message.success({
      content: 'EMR successfully updated',
      style: { marginTop: '70px' },
    });
    window.location.reload();
  }

  showChromeExtensionInformation() {
    const { supportedFeatures, chromeExtensionInstalled, EMRInput } = this.state;
    const chromeExtLink = 'https://chrome.google.com/webstore/detail/medsender/onpcajmldffklnfccjolcccoijaogggl';

    if (isOnChrome()) {
      return (
        <>
          <p style={{
            marginBottom: '0px',
            fontWeight: 600,
            lineHeight: '40px',
            fontSize: '16px',
            marginTop: '20px',
          }}
          >
            {`${EMRInput} has first class Chrome support within Medsender!`}
            <ul>
              {supportedFeatures.map(feature => (
                <li key={`${feature}`} style={{ fontWeight: 400, textAlign: 'left', fontSize: 16 }}>
                  {feature}
                </li>
              ))}
            </ul>
          </p>
          {chromeExtensionInstalled
            ? (
              <p style={{ color: 'grey', fontSize: '16px', fontWeight: 200, fontStyle: 'italic' }}>
                Looks like you've already installed our Chrome Extension. You're good to go!
              </p>
            )
            : (
              <Button
                style={{ marginLeft: '28%' }}
                label="Install Chrome Extension"
                backgroundColor="rgb(163,237,255)"
                onClick={() => window.open(chromeExtLink, 'chrome link', 'width=880, height=600')}
                type="primary"
              >
                Install Chrome Extension
              </Button>
            )
          }
        </>
      );
    }
  }

  render() {
    const { hideModal, emr } = this.props;
    const { EMRInput, hasSupportedChromeExtension, checked } = this.state;
    const selectedOtherOption = (EMRInput === 'Other');
    return (
      <div>
        <ModalWrapper
          hideModal={hideModal}
          dismiss="Cancel"
          action={this.handleSubmit}
          actionName="Submit"
          modalTitle="Update your EMR"
          disabled={emr === EMRInput}
        >
          <p style={{ marginBottom: '10px' }}>
            Select your EMR from the dropdown below. If your EMR has Medsender Chrome Extension support, you will be prompted to download the extension.
          </p>
          <div className="dropdown-container">
            <Select
              style={{ width: 250, marginTop: 20 }}
              onChange={this.handleChange}
              value={EMRInput}
              placeholder="Select your EMR"
            >
              {SortedEMRs.map(emrVal => <Option key={emrVal} value={emrVal}>{emrVal}</Option>)}
            </Select>
          </div>
          {selectedOtherOption
            && (
              <Input
                placeholder="Type the name of your EMR"
                value={EMRInput}
                name="userEMR"
                onChange={this.handleFieldChange}
                style={{ marginTop: 15 }}
              />
            )}
          <Checkbox
            style={{ margin: '20px 0 0px 170px', fontSize: '14px' }}
            checked={checked}
            onChange={(event) => {
              this.toggleChecked(event.target.checked);
            }}
          >
            I have no EMR
          </Checkbox>
          {hasSupportedChromeExtension && this.showChromeExtensionInformation()}
        </ModalWrapper>
      </div>
    );
  }
}

ChangeEMR.defaultProps = {
  emr: null,
};

ChangeEMR.propTypes = {
  updateUserDetails: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  emr: PropTypes.string,
};

export default ChangeEMR;
