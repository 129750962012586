import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { parse } from 'qs';
import { Form } from 'antd';
import SignInHeader from '../../SignInHeader';
import PrivacyPolicy from '../../PrivacyPolicy';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import './resetPassword.scss';
import { resetPassword, validateResetToken } from '../../../actions/auth';
import {
  validPassword,
  InvalidPasswordMessage,
} from '../../../global/validation';
import { COLORS } from '../../../../../../storybook/constants/colors';

const parseOptions = {
  ignoreQueryPrefix: true,
};

const ResetPassword = ({ ...props }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [sending, setSending] = useState(false);
  const {
    errorMessage,
    loggedIn,
    resetPassword,
    resetPasswordToken,
    resetPasswordEmail,
  } = props;

  useEffect(() => {
    if (!resetPasswordToken) {
      const { validateResetToken: handleValidateResetToken, location } = props;
      const { reset_token: resetToken, password_set: passwordSet } = parse(location.search, parseOptions);
      const data = {
        reset_password_token: resetToken,
      };
      handleValidateResetToken(data, passwordSet === 'true');
    }
  }, []);

  const handleSubmit = async () => {
    setSending(true);
    await resetPassword({
      password: newPassword,
      password_confirmation: confirmedPassword,
      reset_password_token: resetPasswordToken,
    });
    setSending(false);
  };

  return (
    <>
      <SignInHeader
        prompt={'Don\'t have an account?'}
        buttonLabel="Sign up"
        route="https://www.medsender.com/sign-up?ref=loginpage"
      />
      <div className="reset-password">
        <div className="reset-form">
          <div className="reset-title">
            <Typography variant="h3">Set New Password</Typography>
            <Typography variant="text" size="large">
              for:&nbsp;
              {resetPasswordEmail}
            </Typography>
          </div>
          <Form
            name="reset-password"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'A new password is required',
                },
                {
                  min: 8,
                  message: 'Must be at least 8 characters',
                },
                {
                  pattern: validPassword,
                  message: InvalidPasswordMessage,
                },
              ]}
            >
              <Input
                password
                required
                label="New Password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password-confirmation"
              rules={[
                {
                  required: true,
                  message: 'Password confirmation is required',
                },
                {
                  min: 8,
                  message: 'Must be at least 8 characters',
                },
                {
                  pattern: validPassword,
                  message: InvalidPasswordMessage,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) return Promise.resolve();
                    return Promise.reject('The two passwords that you entered do not match');
                  },
                }),
              ]}
            >
              <Input
                password
                required
                label="Confirm New Password"
                value={confirmedPassword}
                onChange={e => setConfirmedPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                label="Submit"
                htmlType="submit"
                disabled={sending}
              />
            </Form.Item>
            {
              errorMessage
              && (
              <Typography variant="subtitleTwo" color={COLORS.ERROR_LITE}>
                {errorMessage}
              </Typography>
              )
            }
          </Form>
        </div>
      </div>
      <PrivacyPolicy />
    </>
  );
};

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  validateResetToken: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  resetPasswordToken: PropTypes.string,
  resetPasswordEmail: PropTypes.string,
  errorMessage: PropTypes.string,
};

ResetPassword.defaultProps = {
  resetPasswordToken: null,
  resetPasswordEmail: null,
  errorMessage: null,
};

export default connect(state => ({
  errorMessage: state.auth.message,
  loggedIn: state.auth.hasAuthCreds,
  resetPasswordToken: state.auth.resetPasswordToken,
  resetPasswordEmail: state.auth.resetPasswordEmail,
}), { resetPassword, validateResetToken })(ResetPassword);
