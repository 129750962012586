import {
  ManageTaskTemplates,
  AddTaskTemplate,
} from '../../../assets/img/guide';

const addingTaskTemplateCopy = {
  pageTitle: 'Manage Task Template',
  pageSubtitle: `
    Medsender allows you to create custom Task Templates. Users will be able to pick from a Task Template to 
    auto fill fields on the Send to Chart and Create Task Modals.`,
  sections: [
    {
      title: 'Managing your Task Templates',
      id: 'manageTaskTemplates',
      subtitle: `Navigate to settings and find the "Manage Task Templates" tab. 
      From here, you can edit, add, or delete templates. Only an admin will be able to create a template that
      is visibible to the Department or Organization scope level.`,
      img: ManageTaskTemplates,
    },
    {
      title: 'Adding a Task Template',
      id: 'addingTaskTemplate',
      subtitle: `Name you Template Task that will be visible as a dropdown choice from the Send to Chart and
      Create Task Modals. All other fields are required to create a Template Task. If you are an admin, you
      will have the option to make your template task visible to yourself, Department, or Organization.rub`,
      img: AddTaskTemplate,
    },
  ],
};

export default addingTaskTemplateCopy;
