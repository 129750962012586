import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Select,
  Alert,
  Input,
  Form,
  message,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ModalWrapper from './ModalWrapper';
import { verifyFeatureAccess } from '../../global/featureFlags';
import { SortedAILabels } from '../../containers/walkthrough/constants';

const { Option } = Select;

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryEmrName: props.modalType.data.category.emr_category,
      AIInput: props.modalType.data.category.ai_tag,
      editingCategoryID: '',
      canManageCategory: true,
    };
  }

  componentDidMount = async () => {
    const { modalType } = this.props;
    const { category } = modalType.data;
    const canManageCategory = await verifyFeatureAccess('manage_category');
    if (category.emr_category) {
      const {
        id,
      } = category;
      this.setState({
        editingCategoryID: id,
        canManageCategory,
      });
    } else {
      this.setState({ canManageCategory });
    }
  }

  addCategory = async () => {
    const { createNewCategory, getCategoriesByPage } = this.props;
    const {
      categoryEmrName,
      AIInput,
    } = this.state;
    if (!categoryEmrName || categoryEmrName.trim() === '') return;
    const newCategory = {
      emr_category: categoryEmrName.trim(),
      ai_tag: AIInput,
    };
    await createNewCategory(newCategory);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error adding category. Duplicate emr category name already exists.' : 'Error adding category. Please try again.';
    if (!error) {
      hideModal();
      message.success({
        content: 'Category successfully added',
        style: { marginTop: '70px' },
      });
      getCategoriesByPage();
    } else {
      hideModal();
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
      getCategoriesByPage();
    }
  }

  editCategory = async () => {
    const { updateCategory, getCategoriesByPage } = this.props;
    const {
      categoryEmrName,
      editingCategoryID,
      AIInput,
    } = this.state;
    const newCategory = {
      emr_category: categoryEmrName,
      ai_tag: AIInput,
    };
    await updateCategory(editingCategoryID, newCategory);
    const { error, hideModal, errorCode } = this.props;
    const errorMessageContent = (errorCode === 400) ? 'Error updating category. Category and AI Tag must be unique' : 'Error updating category. Please try again.';
    hideModal();
    if (!error) {
      message.success({
        content: 'Category successfully updated',
        style: { marginTop: '70px' },
      });
    } else {
      message.error({
        content: errorMessageContent,
        style: { marginTop: '70px' },
      });
    }
    getCategoriesByPage();
  }

  renderModal = () => {
    const { isLoading, modalType } = this.props;
    const { isAdmin } = modalType.data;
    const {
      categoryEmrName,
      editingCategoryID,
      canManageCategory,
      AIInput,
    } = this.state;
    const disableButton = canManageCategory && !isAdmin;
    if (isLoading) {
      return (<span> Adding new category... </span>);
    }
    return (
      <React.Fragment>
        <Form
          layout="vertical"
          onFinish={!editingCategoryID && this.addCategory}
          id="addCategory"
        >
          <Form.Item
            hasFeedback
            name="categoryEmrName"
            label={(
              <div>
                EMR Category Name
                <Tooltip title="This is the dropdown list value of the category you want to be selected in your EMR during Auto-Upload. The category will be displayed in Medsender under the Document Title.">
                  <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
                </Tooltip>
              </div>
            )}
            rules={[
              {
                required: true,
                message: 'Must provide a emr category name',
              },
            ]}
          >
            <Input
              value={categoryEmrName}
              placeholder={categoryEmrName}
              onChange={event => this.setState({ categoryEmrName: event.target.value })}
              disabled={disableButton}
            />
          </Form.Item>
          <Tooltip placement="left" title="When a Cateogry is assigned to an AI tag, any time our AI classifies a document with this value we'll automatically apply this category.">
            Assign AI tag (optional)
            <InfoCircleOutlined style={{ paddingLeft: '2px', marginTop: '6px', height: '8px' }} />
          </Tooltip>
          <div className="dropdown-container">
            <Select
              style={{ width: 500, marginTop: 20 }}
              onChange={event => this.setState({ AIInput: event })}
              value={AIInput}
            >
              {SortedAILabels.map(aiVal => <Option key={aiVal} value={aiVal}>{aiVal}</Option>)}
            </Select>
          </div>
        </Form>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, hideModal, modalType: { data: { isAdmin } } } = this.props;
    const { editingCategoryID, canManageCategory, categoryEmrName } = this.state;
    const disableButton = canManageCategory && !isAdmin;
    const customContentStyle = {
      width: '340px',
    };

    return (
      <ModalWrapper
        hideModal={hideModal}
        customContentStyle={customContentStyle}
        action={!editingCategoryID ? this.addCategory : this.editCategory}
        actionName={editingCategoryID ? 'Edit' : 'Add'}
        dismiss={isLoading ? '' : 'Cancel'}
        disabled={disableButton || isLoading || (!categoryEmrName && !editingCategoryID)}
        form="addCategory"
        modalTitle={editingCategoryID ? 'Edit Category' : 'Add New Category'}
      >
        <div onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        >
          {disableButton && (
            <div style={{ marginBottom: '25px' }}>
              <Alert
                message="You do not have permissions to add/edit/delete categories. Please contact your admin to make any changes."
                type="info"
                showIcon
              />
            </div>
          )}
          {this.renderModal()}
        </div>
      </ModalWrapper>
    );
  }
}

AddCategory.defaultProps = {
  error: false,
  errorCode: null,
};

AddCategory.propTypes = {
  hideModal: PropTypes.func.isRequired,
  modalType: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getCategoriesByPage: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  createNewCategory: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorCode: PropTypes.string,
};

export default connect(state => ({
  isLoading: state.categories.isLoading,
  error: state.categories.error,
  errorCode: state.categories.errorCode,
}))(AddCategory);
