import {
  LoadingOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  PrinterOutlined,
  HighlightOutlined,
} from '@ant-design/icons';

const failureIcon = message => ({
  name: ExclamationCircleOutlined,
  color: '#b31c0e',
  text: message,
});

const defaultIcon = {
  name: CheckCircleOutlined,
  color: '#037d03',
  text: 'The record was sent successfully.',
};

const signatureRequestCompletedIcon = {
  name: HighlightOutlined,
  color: '#037d03',
  text: 'This record has been signed by the recipient.',
};

const signatureRequestNotCompletedIcon = {
  ...signatureRequestCompletedIcon,
  color: '#fc1703',
  text: 'This record has not been signed yet.',
};

const viewCountIcon = {
  ...defaultIcon,
  name: EyeOutlined,
  text: 'The record has been viewed by the recipient.',
};

const inProgressIcon = {
  name: LoadingOutlined,
  color: '#ffee00',
  text: 'The record is still being sent.',
};

const directFaxSuccessIcon = {
  ...defaultIcon,
  name: PrinterOutlined,
  text: 'The record was sent as a fax',
};

const getIcon = (row) => {
  const {
    view_count: viewCount,
    fax_status: faxStatus,
    record_status: recordStatus,
  } = row;

  let icon = defaultIcon;
  const successfulDirectFax = (faxStatus === 'success' && recordStatus === 'direct_fax');
  const transmissionFailure = (faxStatus === 'failure' || faxStatus === 'partialsuccess');

  if (viewCount >= 1) icon = viewCountIcon;
  if (faxStatus === 'inprogress') icon = inProgressIcon;
  else if (successfulDirectFax && viewCount < 1) icon = directFaxSuccessIcon;
  else if (transmissionFailure) icon = failureIcon(row.fax_error_message);

  return icon;
};

const getSentToProviderIcon = (row) => {
  const {
    view_count: viewCount,
    fax_status: faxStatus,
    record_status: recordStatus,
  } = row;

  let icon = defaultIcon;
  const successfulDirectFax = (faxStatus === 'success' && recordStatus === 'direct_fax');
  const transmissionFailure = (faxStatus === 'failure' || faxStatus === 'partialsuccess');

  if (viewCount >= 1) icon = viewCountIcon;
  if (faxStatus === 'inprogress') icon = inProgressIcon;
  else if (successfulDirectFax && viewCount < 1) icon = directFaxSuccessIcon;
  else if (transmissionFailure) icon = failureIcon(row.fax_error_message);

  return icon;
};

const getSentToPatientIcon = (row) => {
  const {
    view_count: viewCount,
    status: smsDeliveryStatus,
  } = row;

  let icon = defaultIcon;
  const hasDeliveryFailed = ['delivery_failed', 'sending_failed'].includes(smsDeliveryStatus);
  const isSmsQueued = ['queued', 'sending', 'delivery_unconfirmed', 'sent'].includes(smsDeliveryStatus);

  if (viewCount >= 1) icon = viewCountIcon;
  if (isSmsQueued) icon = inProgressIcon;
  if (hasDeliveryFailed) icon = failureIcon('Record transmission failed');

  return icon;
};

const getSignatureCompletedIcon = (row) => {

  const { signature_request_completed: signatureRequestCompleted } = row;

  switch (signatureRequestCompleted) {
    case true:
      return signatureRequestCompletedIcon;
    case false:
      return signatureRequestNotCompletedIcon;
    default:
      return null;
  }
};

export {
  getIcon,
  getSignatureCompletedIcon,
  getSentToPatientIcon,
  getSentToProviderIcon,
};
