import React from 'react';
import Typography from '../../components/Typography';
import Link from '../../components/Link';
import { COLORS } from '../../../../../storybook/constants/colors';
import './privacyPolicy.scss';

const PrivacyPolicy = () => {
  const size = 'xsmall';
  return (
    <div className="privacy-policy">
      <Typography color={COLORS.TEXT_TERTIARY_LITE} variant="text" size="xsmall">
        By using Medsender, you agree to the&nbsp;
        <Link inline href="https://static.medsender.com/docs/m7r_privacy_policy.pdf" size={size} label="Privacy Policy" />
        &nbsp;and&nbsp;
        <Link inline href="https://static.medsender.com/docs/m7r_terms_of_use.pdf" size={size} label="Terms of Use" />
        <br />
      </Typography>
      <Typography color={COLORS.TEXT_TERTIARY_LITE} variant="text" size="xsmall">
        © 2024 Medsender, Inc.
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;
