import React, { Component } from 'react';
import { Menu } from 'antd';
import { PageContent } from '../components/guide';

import {
  welcomePageCopy,
  printerDriverCopy,
  adminToolsCopy,
  faqCopy,
  gettingStartedCopy,
  chromeExtensionCopy,
  UploadingCopy,
  SendingCopy,
  ReceivingCopy,
  SigningAndAnnotatingCopy,
  MergingRecordsCopy,
  AddingTaggingCopy,
  AddingCategoryCopy,
  AddingTaskTemplateCopy,
  AssigningRecordsCopy,
  AddingTaskProviderCopy,
} from '../components/guide/copy';

const { Item } = Menu;

class MedsenderGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem: 1,
    };
  }

  selectingGuideMenuItem = (item) => {
    this.setState({ selectedMenuItem: item.key });
  }

  render = () => {
    const pageContent = {
      1: welcomePageCopy,
      2: gettingStartedCopy,
      3: UploadingCopy,
      4: SendingCopy,
      5: ReceivingCopy,
      6: SigningAndAnnotatingCopy,
      7: MergingRecordsCopy,
      8: AddingTaggingCopy,
      9: chromeExtensionCopy,
      10: printerDriverCopy,
      11: adminToolsCopy,
      12: faqCopy,
      13: AddingCategoryCopy,
      14: AddingTaskTemplateCopy,
      15: AssigningRecordsCopy,
      16: AddingTaskProviderCopy,
    };
    const { selectedMenuItem } = this.state;
    return (
      <div
        style={{ width: '100%', display: 'flex' }}
      >
        <Menu
          mode="inline"
          style={{ width: 256, height: '100%' }}
          defaultSelectedKeys={[selectedMenuItem]}
          onClick={item => this.selectingGuideMenuItem(item)}
        >
          <Item key="1" className="remove-purple-highlight">Welcome to Medsender</Item>
          <Item key="2" className="remove-purple-highlight">Getting Started</Item>
          <Item key="3" className="remove-purple-highlight">Uploading Records</Item>
          <Item key="4" className="remove-purple-highlight">Sending Records</Item>
          <Item key="5" className="remove-purple-highlight">Receiving Records</Item>
          <Item key="6" className="remove-purple-highlight">Signing/Annotating Records</Item>
          <Item key="7" className="remove-purple-highlight">Merging Records</Item>
          <Item key="8" className="remove-purple-highlight">Tagging/ Labeling Records</Item>
          <Item key="15" className="remove-purple-highlight">Assigning Records (NEW)</Item>
          <Item key="13" className="remove-purple-highlight">Managing Categories</Item>
          <Item key="14" className="remove-purple-highlight">Managing Task Templates</Item>
          <Item key="16" className="remove-purple-highlight">Managing Task Providers</Item>
          <Item key="9" className="remove-purple-highlight">Chrome Extension</Item>
          <Item key="10" className="remove-purple-highlight">Printer Driver</Item>
          <Item key="11" className="remove-purple-highlight">Admin Tools</Item>
          <Item key="12" className="remove-purple-highlight">FAQ</Item>
        </Menu>
        <div style={{ top: '80px', left: '256px' }}>
          <div style={{ height: '100%', width: '100%' }}>
            <PageContent selectingGuideMenuItem={this.selectingGuideMenuItem} copy={pageContent[selectedMenuItem]} />
          </div>
        </div>
      </div>
    );
  }
}

export default MedsenderGuide;
