/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse, stringify } from 'qs';
import {
  Table,
  Button,
  Tooltip,
  Input,
  Popconfirm,
  Spin,
  message,
  Select,
} from 'antd';
import { PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Search } = Input;

const parseOptions = {
  ignoreQueryPrefix: true,
};

const stringifyOptions = {
  format: 'RFC1738',
  addQueryPrefix: true,
  sort: (a, b) => (a.localeCompare(b)),
};

class ManageTaskTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emaTaskTemplates: null,
      currentPage: 1,
      selectedTemplate: '',
      searchValue: '',
      priority: null,
      searchedCategories: [],
      documentCategories: [],
      documentCategory: null,
      scopeType: null,
      scopeNames: [],
      scopeName: null,
      scopeOptionNames: {},
      userDepartments: [],
      scopeNameDisabled: true,
    };
  }

  componentDidMount = async (page = 1) => {
    const { getTaskTemplates, getUserInfo, getDocumentCategories } = this.props;
    await getDocumentCategories();
    await getUserInfo();
    const { departments, organizationDetails } = this.props;
    this.setState({
      departments,
      organizationDetails,
    });
    const userDepartments = [];
    departments.forEach((dept) => {
      if (!userDepartments.includes(dept.name)) {
        userDepartments.push(dept.name);
      }
    });
    userDepartments.sort();
    this.setState({
      userDepartments,
      scopeOptionNames: {
        User: [localStorage.getItem('m8_name')],
        Department: userDepartments,
        Organization: [organizationDetails.current_organization],
      },
    });

    const params = {};
    params.page = page;
    const query = stringify(params, stringifyOptions);
    await getTaskTemplates(query);
    this.setState({
      emaTaskTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
      documentCategories: this.props.allDocumentCategories.categories,
      searchedCategories: this.props.allDocumentCategories.categories,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      emaTaskTemplates: nextProps.allEmaTaskTemplates.ema_task_templates,
    });
  }

  getQueryData = async (page = 1) => {
    const { location, headers, getTaskTemplates } = this.props;
    const totalPages = (headers ? parseInt(headers.total_count, 10) : 1);
    let params = {};
    if (location.search) {
      params = parse(location.search, parseOptions);
      // eslint-disable-next-line no-param-reassign
      page = parseInt((params.page <= totalPages ? params.page : 1), 10);
    }
    params.page = page;
    const query = stringify(params, stringifyOptions);
    await getTaskTemplates(query);
    this.setState({
      emaTaskTemplates: this.props.allEmaTaskTemplates.ema_task_templates,
    });
  }

  openEditTemplateModal = (template) => {
    const {
      openModal,
      isAdmin,
    } = this.props;
    const { userDepartments } = this.state;
    const userOrganization = this.state.organizationDetails.current_organization;
    const modalType = {
      type: 'ADD_TASK_TEMPLATE',
      data: {
        template,
        userDepartments,
        userOrganization,
        isAdmin,
      },
    };
    return openModal(modalType);
  }


  handleTableChange = (pagination) => {
    const { current: page } = pagination;
    this.setState({
      currentPage: page,
    });
    const { paramTools, getTaskTemplatesByQuery: fetchTemplates } = this.props;
    const { params } = paramTools;
    params.page = page;
    const query = `${stringify(params, stringifyOptions)}`;

    fetchTemplates(query);
  };

  searchTemplates = (value, paramType) => {
    const { paramTools, getTaskTemplatesByQuery } = this.props;
    const { params } = paramTools;
    const {
      departments,
      documentCategory,
      priority,
      scopeName,
      scopeType,
      searchValue,
    } = this.state;
    if (!searchValue) {
      delete params.contains;
    }
    if (!priority) {
      delete params.priority;
    }
    if (!documentCategory) {
      delete params.document_category_id;
    }
    if (!scopeName) {
      delete params.owner_type;
      delete params.owner_id;
    }
    if (scopeType !== 'User') {
      delete params.user_owner;
    }
    if (searchValue) {
      params.contains = searchValue;
    }
    if (priority) {
      params.priority = priority;
    }
    if (documentCategory) {
      params.document_category_id = documentCategory;
    }
    if (scopeName) {
      params.owner_type = scopeType;
      if (scopeType === 'Organization') {
        params.owner_id = localStorage.m8_org_id;
      } else if (scopeType === 'User') {
        delete params.owner_id;
        params.user_owner = decodeURIComponent(localStorage.m8_uid);
      } else if (scopeType === 'Department') {
        const depId = departments.find(d => d.name === scopeName).id;
        params.owner_id = depId;
      }
    }
    params.page = 1;
    const query = `${stringify(params, stringifyOptions)}`;
    if (paramType === 'templateName') {
      this.setState({ searchValue: value, currentPage: 1 });
    } else {
      this.setState({ currentPage: 1 });
    }
    const emptySearch = !searchValue.trim() && !priority && !documentCategory && !scopeName;
    if (emptySearch) return getTaskTemplatesByQuery('');
    getTaskTemplatesByQuery(query);
  }

  deleteTaskTemplate = async (template) => {
    const { deleteTaskTemplate } = this.props;
    await deleteTaskTemplate(template.id);
    const { error, errorCode } = this.props;
    if (error && errorCode === 403) {
      message.error('Cannot delete Task Template. You need permission to perform this action');
    }

    this.getQueryData();
  }

  handleScopeChange = async (e) => {
    if (e === undefined) {
      await this.setState({
        scopeType: null,
        scopeNames: [],
        scopeName: null,
        scopeNameDisabled: true,
      });
      await this.searchTemplates('', 'scope');
    } else {
      const { scopeOptionNames } = this.state;
      await this.setState({ scopeType: e, scopeNames: scopeOptionNames[e] });
      if (e === 'User' || e === 'Organization') {
        const scopeName = this.state.scopeNames[0];
        await this.setState({ scopeName, scopeNameDisabled: true });
        await this.searchTemplates(scopeName, 'scope');
      }
      if (e === 'Department') {
        await this.setState({ scopeName: null, scopeNameDisabled: false });
      }
    }
  };

  handleScopeNameChange = async (e) => {
    if (e === undefined) {
      await this.setState({ scopeName: null });
      await this.searchTemplates('', 'scope');
    } else {
      await this.setState({ scopeName: e });
      await this.searchTemplates(e, 'scope');
    }
  }

  handlePriorityChange = async (value) => {
    if (value === undefined) {
      await this.setState({ priority: null });
      await this.searchTemplates('', 'priority');
    } else {
      await this.setState({ priority: value });
      await this.searchTemplates(value, 'priority');
    }
  }

  handleDocumentCategorySearch = async (e) => {
    const unfilteredObjects = this.state.documentCategories;
    if (e) {
      const filtered = this.state.documentCategories.map(category => category.emr_category).filter(option => option.toLowerCase().includes(e.toLowerCase()));
      const filteredObject = [];
      filtered.forEach((category) => {
        const index = this.state.documentCategories.map(c => c.emr_category).indexOf(category);
        filteredObject.push(this.state.documentCategories[index]);
      });
      await this.setState({ searchedCategories: filteredObject });
    } else {
      await this.setState({ searchedCategories: unfilteredObjects });
    }
  }

  handleDocumentCategoryChange = async (e) => {
    if (e === undefined) {
      await this.setState({ documentCategory: null });
      await this.searchTemplates('', 'documentCategory');
    } else {
      await this.setState({ documentCategory: e });
      await this.searchTemplates(e, 'documentCategory');
    }
  }

  render() {
    const {
      emaTaskTemplates,
      currentPage,
      selectedTemplate,
      searchValue,
      priority,
      documentCategory,
      searchedCategories,
      scopeType,
      scopeName,
      scopeNameDisabled,
    } = this.state;
    const isEditing = template => template.task_name === selectedTemplate;
    const {
      headers,
      isLoading,
      isAdmin,
    } = this.props;
    const userName = localStorage.getItem('m8_name');
    const totalResults = headers ? headers.total_count : 1;
    let columns = [
      {
        title: (
          <Tooltip title="Custom name of your template that displays on Send to Chart and Create Task Modals.">
            Task Template Name
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        key: 'task_name',
        dataIndex: 'task_name',
      },
      {
        title: 'Type',
        dataIndex: 'task_type',
        key: 'task_type',
      },
      {
        title: 'Details',
        dataIndex: 'task_details',
        key: 'task_details',
      },
      {
        title: 'Assign To',
        dataIndex: 'assign_to_as_string',
        key: ':assign_to_as_string',
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: (
          <Tooltip title="Either visible for the User, Department, or Organization.">
            Scope
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        dataIndex: 'scope_name',
        key: 'scope_name',
      },
      {
        title: (
          <Tooltip title="Document Category Assigned to this Task Template">
            Document Category
            <InfoCircleOutlined style={{ paddingLeft: '3px', marginTop: '6px', height: '10px' }} />
          </Tooltip>),
        dataIndex: 'emr_category',
        key: 'emr_category',
      },
      {
        title: '',
        key: 'edit',
        align: 'left',
        render: (_, template) => {
          const editable = isEditing(template);
          return editable ? (
            <span>
              <Button
                // eslint-disable-next-line no-script-url
                href="javascript:;"
                onClick={() => this.save(template)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Button>
              <Popconfirm title="Cancel edit without saving?" onConfirm={() => this.setState({ selectedTemplate: '' })}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              {}
              {
                ((isAdmin || (template.scope_name === userName)))
                && (
                <Button disabled={selectedTemplate !== ''} onClick={() => this.openEditTemplateModal(template)} style={{ marginRight: 10 }}>
                  Edit
                </Button>
                )
              }
              {}
              {
              ((isAdmin || (template.scope_name === userName)))
                && (
                <Popconfirm title="Delete this Template? (This action is permanent and cannot be reversed. " onConfirm={() => this.deleteTaskTemplate(template)}>
                  <a>Delete</a>
                </Popconfirm>
                )
              }
            </span>
          );
        },
      },
    ];

    if (isLoading) return (<Spin size="large" />);
    columns = columns.filter(column => column.dataIndex !== 'department');

    return (
      // eslint-disable-next-line object-curly-newline
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '10px 40px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <h2>Manage Task Templates</h2>
            <Button
              style={{ marginLeft: '10px' }}
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={this.openEditTemplateModal}
            >
              Add Task Template
            </Button>
          </div>
          <div>
            <Select
              allowClear
              showSearch
              placeholder="Scope"
              value={scopeType}
              style={{ width: 120, marginRight: 10 }}
              onChange={this.handleScopeChange}
            >
              <Select.Option value="User">User</Select.Option>
              <Select.Option value="Department">Department</Select.Option>
              <Select.Option value="Organization">Organization</Select.Option>
            </Select>
            <Select
              allowClear
              showSearch
              placeholder={scopeNameDisabled ? 'Scope Name' : 'Department'}
              value={scopeName}
              disabled={scopeNameDisabled}
              style={{ width: 150, marginRight: 10 }}
              onChange={this.handleScopeNameChange}
              options={this.state.scopeNames.map(name => ({ label: name, value: name }))}
            />
            <Select
              allowClear
              showSearch
              placeholder="Document Category"
              value={documentCategory}
              onSearch={this.handleDocumentCategorySearch}
              onChange={this.handleDocumentCategoryChange}
              filterOption={false}
              style={{ width: 180, marginRight: 10 }}
            >
              {searchedCategories.map(e => (
                <Select.Option key={e.id} value={e.id}>
                  {e.emr_category}
                </Select.Option>
              ))}
            </Select>
            <Select
              allowClear
              showSearch
              style={{ width: 120, marginRight: 10 }}
              placeholder="Priority"
              value={priority}
              onChange={this.handlePriorityChange}
            >
              <Select.Option value="Normal">Normal</Select.Option>
              <Select.Option value="High">High</Select.Option>
              <Select.Option value="STAT">STAT</Select.Option>
            </Select>

            <Search
              placeholder="Template name"
              value={searchValue}
              onChange={e => this.setState({ searchValue: e.target.value })}
              onSearch={() => this.searchTemplates(searchValue, 'templateName')}
              style={{ width: 200, height: '32px', marginRight: '10px' }}
            />
          </div>
        </div>
        <div style={{ width: '100%' }}>
          <Table
            size="small"
            dataSource={emaTaskTemplates}
            columns={columns}
            rowKey={record => record.id}
            bordered
            onChange={this.handleTableChange}
            pagination={{
              position: ['bottomCenter'],
              total: totalResults,
              current: currentPage,
            }}
          />
        </div>
      </div>
    );
  }
}

ManageTaskTemplates.defaultProps = {
  isLoading: false,
  data: [],
  allEmaTaskTemplates: {},
  departments: [],
  organizationDetails: {},
  headers: {
    page: 1,
    'per-page': '10',
    total_count: 1,
  },
  paramTools: {
    push: () => {},
    params: {},
    stringify: () => {},
    stringifyOptions: {},
  },
  errorCode: '',
};

ManageTaskTemplates.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  headers: PropTypes.shape({
    page: PropTypes.number,
    'per-page': PropTypes.string,
    total_count: PropTypes.number,
  }),
  getTaskTemplates: PropTypes.func.isRequired,
  paramTools: PropTypes.shape({
    params: PropTypes.object,
    stringify: PropTypes.func,
    stringifyOptions: PropTypes.object,
    push: PropTypes.func,
  }),
  location: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  departments: PropTypes.array,
  organizationDetails: PropTypes.object,
  deleteTaskTemplate: PropTypes.func.isRequired,
  getTaskTemplatesByQuery: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  emaTaskTemplates: PropTypes.object.isRequired,
  allDocumentCategories: PropTypes.object.isRequired,
  getDocumentCategories: PropTypes.func.isRequired,
  allEmaTaskTemplates: PropTypes.object,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
};

export default connect(state => ({
  allEmaTaskTemplates: state.userProfile.allEmaTaskTemplates,
  emaTaskTemplates: state.auth.data,
  allDocumentCategories: state.userProfile.allDocumentCategories,
  isLoading: state.userProfile.isLoading,
  isAdmin: state.userProfile.isAdmin,
  headers: state.userProfile.headers,
  error: state.userProfile.error,
}))(ManageTaskTemplates);
