// Connects the Edit patient info modal component with store
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SecureLink from '../../components/modal/SecureLink';
import { getRecordLink } from '../../actions/records';

class SecureLinkDialog extends Component {

  componentDidMount() {
    const { modalType: { data: { fileToken: recordId } }, getRecordLink: getLink } = this.props;
    getLink(recordId);
  }

  render() {
    return (
      <div>
        <SecureLink
          {...this.props}
        />
      </div>
    );
  }
}

SecureLinkDialog.defaultProps = {
  secureLink: '',
  errorMessage: '',
};

SecureLinkDialog.propTypes = {
  getRecordLink: PropTypes.func.isRequired,
  secureLink: PropTypes.string,
  errorMessage: PropTypes.string,
  modalType: PropTypes.shape({
    data: PropTypes.shape({
      fileToken: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default connect(state => ({
  secureLink: state.records.secure_link,
  isLoading: state.records.isLoading,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
  records: state.records,
}), { getRecordLink })(SecureLinkDialog);
