import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'qs';
import {
  ArrowLeftOutlined,
  EditOutlined,
  SelectOutlined,
  FolderOpenOutlined,
  BellOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { openModal } from '../actions/modal';

import { Header, Viewer } from '../components';

import { 
  getRecordById,
  saveAnnotationsOnRecord,
  setUnsavedAnnotationsOnRecord,
  updateSignatures,
  getSignature,
} from '../actions/records';
import '../components/main.scss';

class RecordViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { match, getRecordById } = this.props;
    const { id } = match.params;
    this.setState({ id });
    getRecordById(id);
  }

  editPatientOpen = () => {
    const patient = {
      file_token: this.props.match.params.id,
      patient_name: this.props.patientName,
      patient_dob: this.props.patientDOB,
      privacy_level: this.props.privacyLevel,
      can_edit_privacy_level: this.props.canEditPrivacyLevel,
      can_edit_patient_info: this.props.canEditPatientInfo,
    };

    const queryParams = parse(this.props.location.search, { ignoreQueryPrefix: true });
    const fromPage = !queryParams.from ? queryParams.from : queryParams.from.toLowerCase();

    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient,
        fromPage,
      },
    };
    this.props.openModal(modalType);
  };

  openEditTagModal = () => {
    const { sendToken, openModal: handleOpenModal, receiverType } = this.props;
    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendToken,
        receiverType,
      },
    };
    return handleOpenModal(modalType);
  }

  openNotifyModal = () => {
    const { sendToken, openModal: handleOpenModal } = this.props;
    const modalType = {
      type: 'NOTIFY',
      data: {
        record: sendToken,
      },
    };
    return handleOpenModal(modalType);
  }

  editLabel = () => {
    const {
      sendToken,
      openModal: handleOpenModal,
      selectedLabels,
      receiverType,
      hasWrongLabelingBeenReported,
      aiStatus,
      isLabelEdited,
    } = this.props;
    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendToken,
        selectedLabelsRecordViewer: selectedLabels,
        receiverType,
        reportAiDetails: {
          hasWrongLabelingBeenReported,
          aiStatus,
          isLabelEdited,
        },
      },
    };
    return handleOpenModal(modalType);
  }


  selectRecord = async (archive) => {
    const { id: fileToken, recordBlobObject } = this.state;
    const { getRecordById: fetchRecordById, match: { params: { id } } } = this.props;

    // If record has unsaved edits, save the edits first.
    if (recordBlobObject) {
      await this.beginSaveAnnotationsOnRecord(recordBlobObject, id);
    }

    const requestedFromSentRecords = false;
    const requestedFromExt = true;
    await fetchRecordById(fileToken, requestedFromSentRecords, requestedFromExt, archive);
    // On the Chrome Extension, the medsender-content.js script
    // listens for this event. Dispatching it allows the chrome extension
    // to assume that a URL has been retrieved. This is then passed
    // onto the background script
    const {
      signedUrl,
      patientName,
      patientDOB,
      documentTitle,
    } = this.props;
    const name = patientName || '';
    const dob = patientDOB || '';

    // Pass in fileName so we can have patient name and DOB show up in the EMR as the filename so
    // they can import the record easily without having to go back and forth between the EMR and
    // chrome extension.
    let fileName = '';
    if (name) {
      fileName = name.replace(/[^a-zA-Z0-9]+/g, '_');
    } else {
      fileName = 'record';
    }

    if (dob) {
      fileName += `_${dob.replace(/[^a-zA-Z0-9]+/g, '_')}.pdf`;
    } else {
      fileName += '.pdf';
    }

    const triggerChange = new CustomEvent('chromeExtFileSelected', {
      detail: { url: signedUrl, fileName, documentTitle },
    });
    document.dispatchEvent(triggerChange);
    window.close();
  }

  beginSaveAnnotationsOnRecord = async (file, recordId) => {
    // Save edits to the record, sets the state on recordHasunsavedEdits to false to allow
    // the record to be sent. We don't allow users to send files that have unsaved edits.
    const { saveAnnotationsOnRecord: saveAnnotations, error } = this.props;
    this.setState({ loading: true });
    await saveAnnotations(file, recordId);
    /* global toastr */
    this.setState({ loading: false });
    if (!error) return toastr.success('Changes saved');
    toastr.error('Unable to save changes. Please try again');
  }

  updateRecordBlob = (file) => {
    // Updates the file blob saved in state, run on initial load and any when changes are made
    // after that. Passed into beginSaveAnnotationsOnRecord() when document is autosaved when
    // user tries to save.
    this.setState({
      recordBlobObject: file,
    });
  }

  render() {
    const {
      record,
      canEditPrivacyLevel,
      canEditPatientInfo,
      // eslint-disable-next-line no-shadow
      setUnsavedAnnotationsOnRecord,
      recordHasUnsavedEdits,
      signedUrl,
      updateSignatures: handleUpdateSignatures,
      getSignature: handleGetSignature,
      signatureData,
      sendToken,
    } = this.props;

    const { loading } = this.state;

    return (
      <div>
        <Helmet>
          <title>Record Viewer - Medsender</title>
        </Helmet>
        <div
          style={{ marginLeft: '0' }}
          className="record-viewer"
        >
          <Header
            main="Document Viewer"
            subtext=""
            locationPath={[]}
            buttons={[
              {
                text: 'Back',
                icon: <ArrowLeftOutlined />,
                disabled: false,
                func: () => this.props.history.goBack(),
              },
              {
                text: 'Edit Record Info',
                icon: <EditOutlined />,
                hide: !record,
                disabled: (!canEditPrivacyLevel && !canEditPatientInfo) || !record,
                func: this.editPatientOpen,
              },
              {
                text: 'Notify',
                icon: <BellOutlined />,
                disabled: false,
                hide: !sendToken || !record,
                func: () => this.openNotifyModal(),
              },
              {
                text: 'Add/Edit Label',
                icon: <TagOutlined />,
                func: this.editLabel,
              },
              {
                text: 'Choose Record',
                icon: <SelectOutlined />,
                disabled: false,
                func: () => this.selectRecord(false),
                secondary: true,
              },
              {
                text: 'Choose & Archive',
                icon: <FolderOpenOutlined />,
                disabled: false,
                func: () => this.selectRecord(true),
                secondary: true,
              },
            ]}
          />
          <Viewer
            documentUrl={signedUrl}
            recordId={this.props.match.params.id}
            canEditRecord={true}
            updateRecordBlob={this.updateRecordBlob}
            setUnsavedEdits={setUnsavedAnnotationsOnRecord}
            recordNeedsToBeSaved={recordHasUnsavedEdits}
            saveAnnotationsOnRecord={this.beginSaveAnnotationsOnRecord}
            saveLoading={loading}
            updateSignatures={handleUpdateSignatures}
            getSignature={handleGetSignature}
            signatureData={signatureData}
            viewer="User"
            user={this.props.name}
          />
        </div>
      </div>
    );
  }
}

RecordViewer.defaultProps = {
  signedUrl: '',
  record: '',
  patientName: '',
  patientDOB: '',
  documentTitle: '',
  privacyLevel: '',
  sendToken: null,
  receiverType: null,
};

RecordViewer.propTypes = {
  getRecordById: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  signedUrl: PropTypes.string,
  record: PropTypes.string,
  canEditPrivacyLevel: PropTypes.bool.isRequired,
  canEditPatientInfo: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  privacyLevel: PropTypes.string,
  patientName: PropTypes.string,
  patientDOB: PropTypes.string,
  documentTitle: PropTypes.string,
  setUnsavedAnnotationsOnRecord: PropTypes.func.isRequired,
  updateSignatures: PropTypes.func.isRequired,
  getSignature: PropTypes.func.isRequired,
  saveAnnotationsOnRecord: PropTypes.func.isRequired,
  recordHasUnsavedEdits: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  signatureData: PropTypes.array.isRequired,
  sendToken: PropTypes.string,
  receiverType: PropTypes.string,
  aiStatus: PropTypes.string.isRequired,
  hasWrongLabelingBeenReported: PropTypes.bool.isRequired,
  isLabelEdited: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(state => ({
  signedUrl: state.records.currentRecord,
  record: state.records.currentRecord,
  canEditPatientInfo: state.records.canEditPatientInfo,
  canEditPrivacyLevel: state.records.canEditPrivacyLevel,
  selectedLabels: state.records.selectedLabels,
  privacyLevel: state.records.privacyLevel,
  patientName: state.records.patientName,
  patientDOB: state.records.patientDOB,
  documentTitle: state.records.documentTitle,
  recordHasUnsavedEdits: state.records.recordHasUnsavedEdits,
  signatureData: state.records.signatureData,
  error: state.records.error,
  sendToken: state.records.sendToken,
  receiverType: state.records.receiverType,
  hasWrongLabelingBeenReported: state.records.hasWrongLabelingBeenReported,
  aiStatus: state.records.aiStatus,
  isLabelEdited: state.records.isLabelEdited,
  name: state.userProfile.name,
}), {
  getRecordById,
  openModal,
  saveAnnotationsOnRecord,
  setUnsavedAnnotationsOnRecord,
  updateSignatures,
  getSignature,
})(RecordViewer);
export {
  RecordViewer,
};
