import React from 'react';
import PropTypes from 'prop-types';
import './signInHeader.scss';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import FullLogo from '../../assets/img/redesign/fullLogo.png';
import { useBreakpoint } from '../../hooks/useBreakpoint';

const SignInHeader = ({ prompt, buttonLabel, route }) => {
  const breakpoint = useBreakpoint();
  return (
    <div className="sign-in-header">
      <div>
        <a href="https://www.medsender.com/">
          <img src={FullLogo} width="200px" alt="fullLogo.png" />
        </a>
      </div>
      {
          breakpoint !== 'xsmall'
          && (
            <div className="prompt-button">
              <Typography variant="text" size="medium" bold>
                {prompt}
              </Typography>
              <Button label={buttonLabel} href={route} size="medium" />
            </div>
          )
        }
    </div>
  );
};

SignInHeader.propTypes = {
  prompt: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  route: PropTypes.string,
};

SignInHeader.defaultProps = {
  route: null,
};

export default SignInHeader;
