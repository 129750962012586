import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddNewUser from '../../components/modal/AddNewUser';
import { addNewUser } from '../../actions/modal';

class AddNewUserDialog extends Component {
  render() {
    return (
      <div>
        <AddNewUser {...this.props} />
      </div>
    );
  }
}

AddNewUserDialog.propTypes = {
  addNewUser: PropTypes.func.isRequired,
};

export default connect(state => ({
  isLoading: state.modal.isLoading,
  errorStatus: state.modal.errorStatus,
  newUser: state.modal.newUser,
}), { addNewUser })(AddNewUserDialog);
