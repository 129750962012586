import {
  Login,
  ChromeExtensionButton,
  PrinterDriver,
} from '../../../assets/img/guide';

const gettingStartedCopy = {
  pageTitle: 'Getting Started',
  pageSubtitle: `Getting started with Medsender is simple. When you login to Medsender for 
  the first time, you will be walked through just a few, quick steps and you will be ready to start using Medsender!`,
  sections: [
    {
      title: 'Logging into Medsender',
      id: 'loggingIntoMedsender',
      subtitle: `
        Your organization’s administrator will send you an email invitation to Medsender. 
        Once you receive your account setup email, click on the link to create your password 
        and go through the setup process (this will take only a few minutes). 
        Please note that this link will expire after 24 hours from when it is first sent. 
        If the link does expire, you can go to https://app.medsender.com/app 
        and click "Forgot Password" to request a new link via email.
      `,
      img: Login,
      divider: true,
    },
    {
      title: 'Two Factor Authentication',
      id: 'tfa',
      subtitle: `
        For your security, the first time you log in, Medsender may ask you to enter a code that 
        will have been emailed to you. Enter the code in the email to finish logging in. 
        Depending on your email service, filters, or other security software, it may take up to a 
        few minutes for the code to arrive in your email. If the code doesn’t arrive, please 
        contact your IT administrator.
      `,
    },
    {
      subtitle: `Note to IT: Please ensure both e.medsender.com and 
      medsender.com domains are whitelisted in your email filtering software.`,
      divider: true,
    },
    {
      title: 'Installing Chrome Extension for EMR integration',
      id: 'chromeExtension',
      subtitle: `
        When you sign into Medsender for the first time on a new computer, you may need to install 
        the Medsender Chrome extension to see the Medsender options inside of your EMR system. From 
        the Medsender dashboard, navigate to the Settings page by clicking the "Settings" tile or the 
        gear icon on your navigation bar. On the Settings page, click the button in the lower right 
        corner that says “Install Chrome Extension.” A pop up will open. Click “Add to Chrome” and the 
        extension will be installed on your browser. If the button is not present, check (1) if you are 
        currently in Chrome, or (2) if you already have the extension installed. You can click the puzzle 
        icon in the top right corner of Chrome to see your extensions.`,
      img: ChromeExtensionButton,
      divider: true,
    },
    {
      title: 'Installing Printer Driver (Windows Users Only)',
      id: 'printerDriver',
      subtitle: `When you sign into Medsender for the first time on a new computer, you may need to install 
      the Medsender Printer Driver. This will allow you to save time when uploading documents to Medsender by being 
      able to virtually “print” them to Medsender instead of manually saving and uploading the files. From the Medsender 
      dashboard, navigate to the Settings page by clicking the "Settings" tile or the gear icon on your navigation bar. 
      On the Settings page, click the button in the lower right corner that says “Install Print Driver.” Once the file has 
      downloaded, open it to launch the installer. Agree to the Terms of Service. Click “Sign in to Medsender.” If Chrome is 
      set as the default browser, you will not need to login. Otherwise, you may be prompted to login. Once you have logged 
      in, you have completed the installation process. To upload a document to Medsender via the print driver, simply click 
      print and select the Medsender printer as the destination. Note, the print driver is currently supported only on Windows.
      `,
      img: PrinterDriver,
      divider: true,
    },
  ],
};

export default gettingStartedCopy;
