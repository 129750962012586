import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SplitSelectedRecord from '../../components/modal/SplitSelectedRecord';
import { splitUploadedRecord, splitReceivedRecord } from '../../actions/records';

class SplitSelectedRecordDialog extends Component {
  static propTypes = {
    splitUploadedRecord: PropTypes.func.isRequired,
    splitReceivedRecord: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div>
        <SplitSelectedRecord
          {...this.props}
          splitUploadedRecord={this.props.splitUploadedRecord}
          splitReceivedRecord={this.props.splitReceivedRecord}
        />
      </div>
    );
  }
}

export default connect(state => ({
  mode: state.records.mode,
  isLoading: state.records.isLoading,
  error: state.records.error,
  errorMessage: state.records.errorMessage,
}), { splitUploadedRecord, splitReceivedRecord })(SplitSelectedRecordDialog);
