const initialState = {
  isLoading: false,
  data: [],
  error: false,
  errorMessage: null,
  attachmentUrl: null,
};

function replies(state = initialState, action) {
  switch (action.type) {
    case 'BEGIN_GET_SINGLE_RECORD_RESPONSE':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_GET_SINGLE_RECORD_RESPONSE':
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload.response,
      };
    case 'FINISHED_GET_SINGLE_RECORD_RESPONSE':
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case 'BEGIN_SEND_REPLY_TO_RECORD':
      return {
        ...state,
        isLoading: true,
        error: false,
        errorMessage: null,
      };
    case 'FAILED_SEND_REPLY_TO_RECORD':
      return {
        isLoading: false,
        error: true,
        errorMessage: action.payload.response.data.error,
      };
    case 'FINISHED_SEND_REPLY_TO_RECORD':
      return {
        ...state,
        isLoading: false,
      };
    case 'BEGIN_GET_ATTACHMENT_URL':
      return {
        ...state,
        isLoading: true,
      };
    case 'FAILED_GET_ATTACHMENT_URL':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'FINISHED_GET_ATTACHMENT_URL':
      return {
        ...state,
        isLoading: false,
        error: false,
        attachmentUrl: action.payload.data.signed_url,
      };
    default:
      return state;
  }
}

export default replies;
