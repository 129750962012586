export const emailForm = [
  {
    hintText: 'user@medical.com',
    labelText: 'Email address',
    funcVal: 'username',
    type: '',
    fixed: false,
  },
  {
    hintText: '',
    labelText: 'Password',
    funcVal: 'password',
    type: 'password',
    fixed: false,
  },
];

export const accessCodeForm = [
  {
    hintText: 'ABC123',
    labelText: 'Access Code',
    funcVal: 'accessCode',
    type: '',
    fixed: false,
  },
  {
    hintText: '000',
    labelText: 'Fax Code',
    funcVal: 'faxCode',
    type: '',
    fixed: false,
  },
];

export const patientAccessForm = [
  {
    hintText: 'patient@email.com or (347) 966-4439',
    labelText: 'Email or Cell Phone Number',
    funcVal: 'recipient',
    type: '',
    fixed: false,
  },
  {
    hintText: '',
    labelText: 'Date of Birth',
    funcVal: 'dob',
    type: 'date',
    max: '9999-12-31',
    fixed: true,
  },
];

export const patientSecurityForm = [
  {
    hintText: '',
    labelText: 'Answer',
    funcVal: 'patientSecurity',
    type: '',
    fixed: true,
  },
];

// Will be set as attributes when the otp form is rendered
export const otpForm = [
  {
    hintText: 'Enter verification code here',
    labelText: 'Verification code',
    funcVal: 'otp_code',
    type: '',
    fixed: false,
  },
];

export const formMapping = {
  email: emailForm,
  access: accessCodeForm,
  otp: otpForm,
  patient: patientAccessForm,
  patientSecurity: patientSecurityForm,
};
