import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
  Form,
  Input,
  Select,
  Button,
  Upload,
  DatePicker,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import ModalWrapper from './ModalWrapper';
import { validPatientRecipient, validPhoneNumber, validSendEmail } from '../../global/validation';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const labelStyle = {
  margin: '0',
  fontSize: '14px',
};

const spanStyle = {
  color: '#ff4d4f',
  fontSize: '11px',
};

class SubmitForm extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    modalType: PropTypes.shape({
      data: PropTypes.shape({
        fileToken: PropTypes.string.isRequired,
        accessCode: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };


  state = {
    firstName: null,
    lastName: null,
    patientDOB: null,
    contact: null,
    fileList: [],
  };

  attachFile = (fileObject) => {
    this.setState(prevState => ({
      fileList: [fileObject.file, ...prevState.fileList],
    }));
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmitForm = async () => {
    const {
      submitForm,
      modalType: {
        data: {
          fileToken: recordId,
          accessCode,
          file,
        },
      },
      hideModal,
    } = this.props;


    const {
      firstName,
      lastName,
      patientDOB,
      contact,
      fileList: attachments,
    } = this.state;

    if (!firstName || !lastName || !validPatientRecipient(contact)) return;
    const fullName = `${firstName} ${lastName}`;

    const bodyFormData = new FormData();
    bodyFormData.append('name', fullName);
    bodyFormData.append('patient_dob', patientDOB);
    bodyFormData.append('contact', contact);
    bodyFormData.append('file', file, 'signed_record.pdf');
    attachments.forEach(attachment => (bodyFormData.append('attachments[]', attachment)));

    // eslint-disable-next-line react/prop-types
    await submitForm(recordId, accessCode, bodyFormData);

    const { error } = this.props;
    if (!error) toastr.success('Form submitted successfully');
    return hideModal();
  }

  renderLoadingText = () => {
    return (
      <div>
        Loading... please wait.
      </div>
    );
  }

  renderSubmitForm = () => {
    const { fileList, contact } = this.state;
    const dateFormat = ['MM/DD/YYYY', 'MM-DD-YYYY', 'MMDDYYYY'];
    return (
      <Form
        {...formItemLayout}
        onFinish={this.handleSubmitForm}
        initialValues={{ contact_option: 'email' }}
        id="submitForm"
      >
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'First name is required' }]}
        >
          <Input
            style={{ width: '100%' }}
            name="firstName"
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Last name is required' }]}
        >
          <Input
            style={{ width: '100%' }}
            name="lastName"
            onChange={this.handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name="patientDOB"
          label="Date of Birth"
        >
          <DatePicker
            style={{ width: '100%' }}
            name="patientDOB"
            onChange={(_, dateString) => this.setState({ patientDOB: dateString })}
            format={dateFormat}
          />
        </Form.Item>
        <Form.Item
          name="file"
          label="File"
        >
          <Upload
            multiple={true}
            accept=".pdf,.jpeg,.jpg,.heic"
            customRequest={this.attachFile}
            fileList={fileList}
            onChange={({ file }) => {
              this.setState(prevState => ({
                fileList: prevState.fileList.filter(uploadedFile => (file !== uploadedFile)),
              }));
            }}
          >
            <Button icon={<UploadOutlined />}>Upload attachments</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label={(
            <p style={labelStyle}>
              <span style={spanStyle}>* </span>
              Contact
            </p>
          )}
          rules={[{ required: true }]}
        >
          <Input.Group compact style={{ width: '100%' }}>
            <Form.Item
              name="contact_option"
              style={{ width: '30%' }}
            >
              <Select
                name="contact_option"
                style={{ width: '100%' }}
              >
                <Option value="email">Email</Option>
                <Option value="phone_number">Cellphone</Option>
              </Select>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.contact_option !== currentValues.contact_option}
              style={{ width: '70%' }}
            >
              {({ getFieldValue }) => (
                getFieldValue('contact_option') === 'phone_number'
                  ? (
                    <Form.Item
                      name="contact"
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, contact = '') {
                            if (validPhoneNumber(contact)) return Promise.resolve();
                            return Promise.reject('Invalid Phone');
                          },
                        }),
                      ]}
                    >
                      <Input
                        name="contact"
                        placeholder="1234567890"
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="contact"
                      rules={[
                        {
                          validator: (_, value) => {
                            return validSendEmail(value)
                              ? Promise.resolve()
                              : Promise.reject('Invalid email address');
                          },
                        },
                      ]}
                    >
                      <Input
                        name="contact"
                        placeholder="john.doe@example.com"
                        value={contact || ''}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                  )
              )}
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  }

  render() {
    const { hideModal, isLoading } = this.props;

    return (
      <ModalWrapper
        hideModal={hideModal}
        width={600}
        dismiss="Close"
        modalTitle="One Last Step"
        action={this.handleSubmitForm}
        actionName="Submit"
        form="submitForm"
        isLoading={isLoading}
      >
        {isLoading ? this.renderLoadingText() : this.renderSubmitForm()}
      </ModalWrapper>
    );
  }
}


export default SubmitForm;
