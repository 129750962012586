// Prompts user if he/she has a Medsender account
import React from 'react';

const MedsenderUser = () => {
  return (
    <span style={{ fontWeight: '600' }}>
      <p>Do you have a Medsender Account?</p>
    </span>
  );
};

export default MedsenderUser;
