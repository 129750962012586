import {
  ManageCategories,
  AddCategory,
} from '../../../assets/img/guide';

const addingCategoryCopy = {
  pageTitle: 'Manage Category',
  pageSubtitle: `
    Medsender AI allows you to associate an EMR Category Name to an existing Label your organization has created. 
    Auto Uploads to your EMR will select the category you define based on the associated label that is 
    tagged on the Received Fax.`,
  sections: [
    {
      title: 'Managing your Categories',
      id: 'manageCategories',
      subtitle: `Navigate to settings and find the "Manage Categories" tab. 
      From here, you can edit, add, or delete categories. A Category can only be associated to one existing Label.`,
      img: ManageCategories,
    },
    {
      title: 'Adding a Cateogry',
      id: 'addingCategory',
      subtitle: `Input the EMR cateogry you wish to be selected during auto upload. If the Received Fax is tagged
      the associated label, then this category will be selected during auto upload.`,
      img: AddCategory,
    },
  ],
};

export default addingCategoryCopy;
