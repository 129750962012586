/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import snakeCase from 'snake-case';
import { Helmet } from 'react-helmet';
import { message, Menu } from 'antd';
import { parseFullName } from 'parse-full-name';
import {
  CloudDownloadOutlined,
  DesktopOutlined,
  EditOutlined,
  TagOutlined,
  FolderOpenOutlined,
  MergeCellsOutlined,
  CloudUploadOutlined,
  BellOutlined,
  HomeOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import RecordsHOC from '../RecordsHOC';
import { Header } from '../../../components';
import { referredColumns } from '../shared/constants';
import { getReferredStatuses } from '../../../actions/userProfile';

import { openModal } from '../../../actions/modal';
import {
  printUserRecord,
  downloadZip,
  unarchiveReceivedRecords,
  triggerAutoUpload,
  getReferralsQuery,
  getReferralsByPage,
  mergeReferrals,
} from '../../../actions/records';
import download from '../../../actions/helpers/download';
import { verifyFeatureAccess } from '../../../global/featureFlags';

/* global toastr */

class ReferralManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canForward: false,
      autoUploadToEMR: false,
      createPatientChart: false,
      taskCreation: false,
      sendSmsMessage: false,
      referralStatuses: [],
    };
  }

  componentDidMount = async () => {
    const canForward = await verifyFeatureAccess('fwd_to_emr');
    const autoUploadToEMR = await verifyFeatureAccess('manual_upload_emr');
    const createPatientChart = await verifyFeatureAccess('create_patient_chart');
    const taskCreation = await verifyFeatureAccess('task_creation');
    const sendSmsMessage = await verifyFeatureAccess('referral_sms');
    await this.props.getReferredStatuses();
    this.setState({
      canForward, autoUploadToEMR, createPatientChart, taskCreation, referralStatuses: this.props.allReferralStatuses, sendSmsMessage,
    });
  }

  downloadRecord = async () => {
    const {
      selectedData,
      data: allDataOnPage,
      openModal,
      downloadZip,
      printUserRecord,
    } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Download one individual record
      const file = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const { file_token: recordId, patient_name: patientName, record_status: recordStatus } = file;

      // Users shouldn't be able to download revoked records
      if (recordStatus === 'revoked') return toastr.error('You can not download a record that has been revoked');

      // Get the signed url for the record we want to download
      await printUserRecord(recordId);
      // De-structure once signed url has been obtained so we download
      // correctly
      const { downloadUrl } = this.props;
      const patientNameSnakeCase = snakeCase(patientName);
      const customFileName = `${patientNameSnakeCase}-${recordId.slice(-7).toLowerCase()}`;
      await download(downloadUrl, customFileName);
      return;
    }

    // Open modal to show download
    openModal({ type: 'DOWNLOAD_ZIP' });

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of file tokens to be downloaded in a single zip file
    const recordsToDownload = (selectedAll ? allDataOnPage : selectedData)
      // Don't allow users to download records that have been revoked
      // TODO (Ammar Khan) We need to enforce this on the backend
      .filter(record => record.record_status !== 'revoked')
      .map(x => x.file_token);
    await downloadZip(recordsToDownload);
  }

  handleMergeRecords = async (objects) => {
    const receiverType = objects[0].receiver_type;
    let incompatibleMerge = false;
    objects.forEach((element) => {
      if (element.receiver_type !== receiverType) {
        incompatibleMerge = true;
      }
    });
    if (!incompatibleMerge) {
      const tokens = objects.map(x => x.send_token);
      await this.props.mergeReferrals(tokens);
      location.reload();
      return;
    }
    // raise error
    return message.error({
      content: 'You can not merge incompatible record types',
      style: { marginTop: '70px' },
    });
  }

  handleMergeAndArchiveRecords = async (objects) => {
    const receiverType = objects[0].receiver_type;
    let incompatibleMerge = false;
    objects.forEach((element) => {
      if (element.receiver_type !== receiverType) {
        incompatibleMerge = true;
      }
    });
    if (!incompatibleMerge) {
      const tokens = objects.map(x => x.send_token);
      const mergeResponse = await this.handleMergeRecords(objects);
      if (!mergeResponse?.error) {
        this.archiveRecordOpen(tokens, '/app/referred', true);
      }
    } else {
      return message.error({
        content: 'You can not merge incompatible record types',
        style: { marginTop: '70px' },
      });
    }
  }

  editTag = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const { send_token: sendToken, tag, receiver_type: receiverType } = receiver;

      const modalType = {
        type: 'ADD_LABEL_TO_RECORD',
        data: {
          reportAiDetails: {
            isLabelEdited: selectedData[0].is_label_edited,
            hasWrongLabelingBeenReported: selectedData[0].has_wrong_labeling_been_reported,
            aiStatus: selectedData[0].ai_status,
          },
          record: sendToken,
          tag,
          receiverType,
        },
      };
      openModal(modalType);
      return;
    }

    if (selectedData.length > 1) {
      const sendTokens = [];
      const receiverTypes = [];
      for (let i = 0; i < selectedData.length; i++) {
        sendTokens.push(selectedData[i].send_token);
        receiverTypes.push(selectedData[i].receiver_type);
      }

      const modalType = {
        type: 'ADD_LABEL_TO_MULTIPLE_RECORDS',
        data: {
          record: sendTokens,
          receiverTypes,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  }

  newChart = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const {
        patient_dob: PatientDOB, patient_name: PatientName, send_token: sendToken, receiver_type: receiverType,
      } = receiver;


      const modalType = {
        type: 'CREATE_NEW_CHART',
        data: {
          name: PatientName,
          dob: PatientDOB,
          record: sendToken,
          gender: selectedData[0].patient_gender,
          phoneNumber: selectedData[0].patient_phone_number,
          address: selectedData[0].patient_address,
          zipCode: selectedData[0].patient_zip_code,
          city: selectedData[0].patient_city,
          practitionerName: selectedData[0].practitioner_name,
          receiverType,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
          documentCategory: this.props.selectedData[0].document_category,
          referral: '',
          patient_phone_number: selectedData[0].patient_phone_number,
          patient_address: selectedData[0].patient_address,
          patient_zip_code: selectedData[0].patient_zip_code,
          patient_city: selectedData[0].patient_city,
          state: selectedData[0].patient_state,
          patient_email: selectedData[0].patient_email,
          patient_first_name: selectedData[0].patient_first_name,
          patient_last_name: selectedData[0].patient_last_name,
          referring_provider: selectedData[0].referring_provider,
          provider_office: selectedData[0].provider_office,
          primary_plan: selectedData[0].primary_plan,
          primary_member_id: selectedData[0].primary_member_id,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'ADD_LABEL_TO_RECORD',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  }

  createTask = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';
    //  left this logic, though I don't think it's needed

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const {
        patient_dob: PatientDOB, patient_name: PatientName, send_token: sendToken, receiver_type: receiverType,
      } = receiver;


      const modalType = {
        type: 'CREATE_TASK',
        data: {
          name: PatientName,
          dob: PatientDOB,
          record: sendToken,
          practitionerName: selectedData[0].practitioner_name,
          receiverType,
          receivedAt: selectedData[0].received_at,
          callerName: selectedData[0].caller_name,
        },
      };
      openModal(modalType);
    }
  }

  notifyModal = async () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const oneRecordSelected = selectedData.length === 1;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const selectedAll = selectedData[0] === 'all';

    if ((oneRecordSelected && !selectedAll) || hasOneReceivedRecord) {
      // Add tag to one receiver.
      const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
      const { send_token: sendToken, tag } = receiver;

      const modalType = {
        type: 'NOTIFY',
        data: {
          record: sendToken,
          tag,
        },
      };
      openModal(modalType);
      return;
    }

    // If the select all is checked, we will use the data for the entire page as it is stored
    // in redux. Otherwise, we'll take the multiple selected rows
    // This returns an array of send tokens to tag.
    const sendTokens = (selectedAll ? allDataOnPage : selectedData)
      .map(x => x.send_token);

    const modalType = {
      type: 'NOTIFY',
      data: {
        record: sendTokens,
      },
    };
    openModal(modalType);
  }


  editPatientOpen = () => {
    const { selectedData, data: allDataOnPage, openModal } = this.props;
    const hasOneReceivedRecord = allDataOnPage.length === 1;
    const receiver = hasOneReceivedRecord ? allDataOnPage[0] : selectedData[0];
    const { send_token: sendToken, tag, receiver_type: receiverType } = receiver;
    const modalType = {
      type: 'EDIT_PATIENT',
      data: {
        patient: selectedData[0],
        fromPage: 'received',
        record: sendToken,
        tag,
        receiverType,
      },
    };
    openModal(modalType);
  };

  performAction = async (recordId, action) => {
    const canFwdToEMR = await verifyFeatureAccess('fwd_to_emr');
    const type = canFwdToEMR ? action : 'RAISE_FLAG';
    const { openModal } = this.props;
    const modalType = {
      type,
      data: recordId,
      inboxLink: '/app/received',
      featureFlag: 'fwd_to_emr',
    };
    openModal(modalType);
  }

  archiveRecordOpen = (tokens, inboxLink, autoArchive) => {
    const { openModal } = this.props;
    const recordArrayData = { tokens };
    const modalType = {
      type: 'ARCHIVE_RECORD',
      data: recordArrayData,
      inboxLink,
      autoArchive,
    };
    openModal(modalType);
  }

  unarchiveRecordOpen = async (tokens) => {
    const { unarchiveReceivedRecords } = this.props;
    await unarchiveReceivedRecords(tokens);

    const { archiveErrorMessage } = this.props;
    this.setState({ completedArchiveRecord: true })
    if (archiveErrorMessage) {
      message.error({
        content: 'We were unable to unarchive the selected records. Please try again',
        style: { marginTop: '70px' },
      });
    } else {
      message.success({
        content: 'Successfully unarchived selected record(s)',
        style: { marginTop: '70px' },
      });
    }
    window.location.reload();
  }

  // Checks if a row containing a reply is selected. It will loop through all the selected rows and
  // check if one of the rows is a reply to a sent record. If it is, it returns true that the
  // selectedData contains a reply. Otherwise return false.
  isAReplySelected = (selectedData) => {
    for (let i = 0; i < selectedData.length; i++) {
      if (selectedData[i].uri) {
        return true;
      }
    }
    return false;
  }

  // Checks if a row of type form is selected.
  isFormRecord = selectedData => selectedData.some(data => data?.form_id);

  isEmailRecord = selectedData => selectedData.some(data => data?.attachment_id);

  autoUploadRecord = async (selectedRecord) => {
    const {
      patient_name: patientName,
      patient_dob: patientDOB,
      send_token: sendToken,
      receiver_type: receiverType,
    } = selectedRecord;
    const { autoUploadEmr, currentOrganizationId } = this.props;
    // If EMR is EMA, we will allow Send Chart Modal to open as Patient MRN is an option to send with
    // chart instead of Patient Name and DOB.
    if (autoUploadEmr !== 'EMA' && (!patientName || !patientDOB)) {
      message.error({
        content: 'Cannot upload a document without a valid name and date of birth. Edit record details and try again.',
        style: { marginTop: '70px' },
      });
    } else {
      const {
        triggerAutoUpload,
        openModal,
      } = this.props;

      const fileNameSupported = ['Kareo', 'OncoEMR', 'Nymbl', 'EMA', 'AllegianceMD', 'Elation'];

      // We want to enable modal support for new ECW practices without affect the old practices' workflow
      if (fileNameSupported.includes(autoUploadEmr) || (autoUploadEmr === 'ECW' && currentOrganizationId > 4206)) {
        let recordFileName = selectedRecord.document_title;
        if (!recordFileName && selectedRecord.is_email_submission !== true) {
          recordFileName = `${selectedRecord.received_at.replace(/ /g, '_')}_${selectedRecord.caller_name.replace(/ /g, '_')}`;
        } else if (!recordFileName) {
          recordFileName = `${selectedRecord.created_at.replace(/ /g, '_')}_${selectedRecord.sender_name.replace(/ /g, '_')}`;
        }
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            fileName: recordFileName,
            providerName: this.props.selectedData[0].practitioner_name,
            patientName: this.props.selectedData[0].patient_name,
            patientDOB: this.props.selectedData[0].patient_dob,
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
          },
        };
        openModal(modalType);
      } else if (autoUploadEmr === 'AdvancedMD') {
        const modalType = {
          type: 'AUTO_UPLOAD',
          data: {
            providerName: this.props.selectedData[0].practitioner_name, // Once we have AI extraction, this will be replaced with the extracted value
            documentCategory: this.props.selectedData[0].document_category,
            sendToken,
            receiverType,
            autoUploadEmr,
          },
        };
        openModal(modalType);
      } else {
        await triggerAutoUpload({ sendToken, receiverType });
        const { error } = this.props;
        if (!error) {
          message.success({
            content: 'Record will be uploaded shortly. We will notify you of any issues via email.',
            style: { marginTop: '70px' },
          });
        } else {
          message.error({
            content: 'Unable to autoupload document.',
            style: { marginTop: '70px' },
          });
        }
      }
    }
  }


  smsMessage = async (selectedRecord, smsMessageFeature) => {
    const {
      openModal,
    } = this.props;
    const {
      send_token: sendToken,
      receiver_type: receiverType,
      patient_name: patientName,
      patient_dob: patientDOB,
      patient_phone_number: patientPhoneNumber,
    } = selectedRecord;
    if (!patientName || !patientDOB) {
      message.error({
        content: 'Cannot send a message without a valid patient name and date of birth. Edit record details and try again.',
        style: { marginTop: '70px' },
      });
    } else {
      const parsedName = parseFullName(patientName)?.first;
      const firstName = parsedName ? parsedName : patientName;
      const messageTemplates = JSON.parse(localStorage.getItem('referral_sms_template'));
      let defaultTemplateIndex = 0;
      for (let idx = 0; idx < messageTemplates.length; idx++){
        const template = messageTemplates[idx];
        if (template.default_template){
          defaultTemplateIndex = idx
        }
      }
      const modalType = {
        type: 'SMS_MESSAGE',
        data: {
          sendToken,
          receiverType,
          patientName,
          messageTemplates: messageTemplates,
          messageText: `Hello ${firstName}, ${JSON.parse(localStorage.getItem('referral_sms_template'))[defaultTemplateIndex].message}`,
          url: JSON.parse(localStorage.getItem('referral_sms_template'))[defaultTemplateIndex].short_url,
          originalUrl: JSON.parse(localStorage.getItem('referral_sms_template'))[defaultTemplateIndex].url,
          patientPhoneNumber,
          smsMessageFeature,
        },
      };
      return openModal(modalType);
    }
  }

  isUploadDisabled(receiverType) {
    if (receiverType === 'received_fax') {
      return false;
    }
    if (receiverType === 'email_attachment') {
      return false;
    }
    return true;
  }

  render() {
    const { selectedData, data: allDataOnPage } = this.props;
    const canEdit = !(selectedData.length === 1 && (selectedData[0].can_edit_patient_info || selectedData[0].can_edit_privacy_level));
    const selectedAll = selectedData[0] === 'all';
    const {
      canForward, autoUploadToEMR, createPatientChart, taskCreation, sendSmsMessage,
    } = this.state;
    const viewingArchivedRecords = window.location.search.includes('archived');
    const {
      organizationDetails: { organizations },
      changeDefaultOrganization: handleChangeDefaultOrganization,
      currentOrganization,
      openOrganizationFilterMenu,
      handleOrganizationMenu,
    } = this.props;

    const mergeDropdownMenu = (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            this.handleMergeRecords(this.props.selectedData);
          }}
        >
          Merge Records
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            this.handleMergeAndArchiveRecords(this.props.selectedData);
          }}
        >
          Merge and Archive Records
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Helmet>
          <title>Referral Management - Medsender</title>
        </Helmet>
        <Header
          main="Referral management"
          subtext="Choose a record below"
          locationPath={[
            { icon: 'home', text: 'Home', link: '/app' },
            { icon: 'inbox', text: 'Referred', link: '/app/referred' },
          ]}
          buttons={[
            {
              openFilterMenu: openOrganizationFilterMenu,
              text: currentOrganization,
              icon: <HomeOutlined />,
              tooltipTitle: 'Current Organization',
              disabled: false,
              hide: organizations.length < 2,
              func: () => handleOrganizationMenu(true),
              filterRecords: true,
              filterOptions: organizations.map(({ name }) => ({ filter: name, text: name })),
              handleFilter: (_, child) => handleChangeDefaultOrganization(child.props.primaryText),
              handleRequestChange: value => handleOrganizationMenu(value),
            },
            {
              text: 'Download',
              icon: <CloudDownloadOutlined />,
              disabled: !selectedData.length >= 1 || this.isAReplySelected(selectedData),
              func: () => this.downloadRecord(),
            },
            {
              text: 'Forward to EMR',
              icon: <DesktopOutlined />,
              disabled: selectedData.length !== 1 || selectedData[0] === 'all' || this.isAReplySelected(selectedData),
              hide: !canForward || autoUploadToEMR,
              func: () => this.performAction(selectedData[0].send_token, 'FORWARD_RECORD'),
            },
            {
              text: 'Send to Chart',
              icon: <CloudUploadOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !autoUploadToEMR,
              func: () => this.autoUploadRecord(selectedData[0]),
            },
            {
              text: 'Create New Chart',
              icon: <CloudUploadOutlined id="createChart" />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !createPatientChart,
              func: () => this.newChart(),
              //  not sure what isAReplySelected means exactly but added as it's used in others
            },
            {
              text: 'Create Task',
              icon: <CloudUploadOutlined id="createTask" />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              hide: !taskCreation,
              func: () => this.createTask(),
            },
            {
              text: 'Send SMS',
              icon: <MessageOutlined />,
              disabled: selectedData.length !== 1 || this.isAReplySelected(selectedData) || this.isUploadDisabled(selectedData[0].receiver_type),
              func: () => this.smsMessage(selectedData[0], sendSmsMessage),
            },
            {
              text: 'Edit',
              icon: <EditOutlined />,
              disabled: canEdit || selectedData.length !== 1,
              func: this.editPatientOpen,
            },
            {
              text: selectedData.length > 1 ? 'Add Labels' : 'Add/Edit Label',
              icon: <TagOutlined />,
              disabled: selectedData.length === 0 || this.isAReplySelected(selectedData) || this.isEmailRecord(selectedData),
              func: this.editTag,
            },
            {
              text: 'Notify',
              icon: <BellOutlined />,
              disabled: selectedData.length < 1 || this.isAReplySelected(selectedData) || this.isEmailRecord(selectedData),
              func: this.notifyModal,
            },
            {
              text: viewingArchivedRecords ? 'Unarchive' : 'Archive',
              icon: <FolderOpenOutlined />,
              disabled: selectedData.length < 1,
              func: () => {
                const tokens = (selectedAll ? allDataOnPage : selectedData).map(x => x.send_token);
                viewingArchivedRecords ? this.unarchiveRecordOpen(tokens) : this.archiveRecordOpen(tokens, '/app/received');
              },
            },
            {
              type: 'dropdown',
              text: 'Merge Records',
              icon: <MergeCellsOutlined />,
              disabled: selectedData.length <= 1,
              menu: mergeDropdownMenu,
            },
          ]}
        />
      </div>
    );
  }
}

const passedProps = {
  getSelectedPageData: getReferralsByPage,
  getPageDataWithQuery: getReferralsQuery,
  data: 'received_data',
  mode: 'Referred',
  enableSelectAll: true,
  columnTitles: referredColumns,
};

ReferralManagement.defaultProps = {
  selectedData: [],
  downloadUrl: '',
  errorMessage: null,
  autoUploadEmr: '',
  errorCode: null,
};

ReferralManagement.propTypes = {
  selectedData: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  downloadUrl: PropTypes.string,
  downloadZip: PropTypes.func.isRequired,
  unarchiveReceivedRecords: PropTypes.func.isRequired,
  triggerAutoUpload: PropTypes.func.isRequired,
  allReferralStatuses: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorCode: PropTypes.number,
  organizationDetails: PropTypes.shape({
    organizations: PropTypes.array.isRequired,
  }).isRequired,
  changeDefaultOrganization: PropTypes.func.isRequired,
  currentOrganization: PropTypes.string.isRequired,
  openOrganizationFilterMenu: PropTypes.bool.isRequired,
  handleOrganizationMenu: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  autoUploadEmr: PropTypes.string,
  currentOrganizationId: PropTypes.number.isRequired,
  getReferredStatuses: PropTypes.func.isRequired,
  mergeReferrals: PropTypes.func.isRequired,
};

export default RecordsHOC(passedProps)(
  connect(state => ({
    selectedData: state.inboxTable.recordData,
    downloadUrl: state.records.downloadUrl,
    data: state.records.received_data,
    archiveErrorMessage: state.records.archiveErrorMessage,
    errorMessage: state.records.errorMessage,
    organizationDetails: state.auth.organizationDetails,
    autoUploadEmr: state.auth.data.autoUploadEmr,
    referralStatuses: state.auth.data.referralStatuses,
    currentOrganizationId: state.auth.currentOrganizationId,
    allReferralStatuses: state.userProfile.allReferralStatuses,
  }), {
    printUserRecord,
    downloadZip,
    openModal,
    unarchiveReceivedRecords,
    getReferredStatuses,
    mergeReferrals,
    triggerAutoUpload,
  })(ReferralManagement),
);
