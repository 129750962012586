import { PrinterDriver } from '../../../assets/img/guide';

const printerDriverCopy = {
  pageTitle: 'Printer Driver (Windows users only)',
  pageSubtitle: `When you sign into Medsender for the first time on a new computer, you may need to 
  install the Medsender Printer Driver. This will allow you to save time when uploading documents to 
  Medsender by being able to virtually “print” them to Medsender instead of manually saving 
  and uploading the files.`,
  sections: [
    {
      title: 'Installing the Printer Driver',
      id: 'installPrinterDriver',
      subtitle: `From the Medsender dashboard, navigate to the Settings tile. Once on the Settings page, 
      click the button in the lower right corner that says “Install Print Driver.`,
      img: PrinterDriver,
    },
    {
      subtitle: 'Once the file has downloaded, open it to launch the installer. Agree to the Terms of Service',
    },
    {
      subtitle: `Once the program launches, click “Sign in to Medsender.” If Chrome is set as the default browser, 
      you will not need to login. Otherwise, you may be prompted to login. Once you have logged in, you have completed 
      the installation process. To upload a document to Medsender via the print driver, simply click print and select 
      the Medsender printer as the destination. Note, the print driver is currently supported only on Windows.`,
    },
  ],
};

export default printerDriverCopy;
